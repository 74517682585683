import {environment} from '../../../../environments/environment';

export const RoleRoutes = {
  filter: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/moduleRoles/filter`
  },
  get: {
    method: 'Get',
    url: `${environment.baseUrl}/api/${environment.version}/moduleRoles/get` // /:id
  },
  create: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/moduleRoles/create`
  },
  update: {
    method: 'Patch',
    url: `${environment.baseUrl}/api/${environment.version}/moduleRoles/update` // /:id
  },
  delete: {
    method: 'Delete',
    url: `${environment.baseUrl}/api/${environment.version}/moduleRoles/delete`
  },
  template: {
    projects: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/moduleRoles/projects/getTemplate`
    },
    lead: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/moduleRoles/lead/getTemplate`
    },
    insurance: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/moduleRoles/insurance/getTemplate`
    }
  },
};
