import {UserListItem} from '../modules/layout/user/interfaces/user-list-item';

export class Activity {
  id?: number;
  subject: number;
  subject_type: string;
  subject_name: string;
  parent: number;
  parent_type: string;
  parent_name: string;
  action: string;
  loginData: any; //Make interface
  modificationData: string;
  createdBy: UserListItem;
  createdAt: number;

  constructor(init: any) {
    this.id = init.id;
    this.subject = init.subject;
    this.subject_type = init.subject_type;
    this.subject_name = init.subject_name;
    this.parent = init.parent;
    this.parent_type = init.parent_type;
    this.parent_name = init.parent_name;
    this.action = init.action;
    this.loginData = init.loginData;
    this.modificationData = init.modificationData;
    this.createdBy = new UserListItem(init.createdBy);
    this.createdAt = init.createdAt;
  }
}
