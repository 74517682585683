import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {WorkgroupModalComponent} from './workgroup-modal.component';
import {BaseModalModule} from "../base-modal/base-modal.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BaseModalModule
  ],
  providers: [
  ],
  declarations: [
    WorkgroupModalComponent
  ],
  exports: [
    WorkgroupModalComponent
  ]
})
export class WorkgroupModalModule {
}
