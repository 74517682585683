import {Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';
import {ClickedElementService} from '../../services/clicked-element.service';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appOutsideClick]'
})
export class OutsideClickDirective implements OnDestroy {

  @Output('appOutsideClick') appOutsideClick: EventEmitter<void> = new EventEmitter<void>();
  private clickedElementSubscription: Subscription;

  constructor(
    private element: ElementRef,
    private clickedElement: ClickedElementService
  ) {
    this.setClickedElementSubscription();
  }

  setClickedElementSubscription(): void {
    this.clickedElementSubscription = this.clickedElement.element.subscribe(clickedElement => {
      if(!this.element.nativeElement.contains(clickedElement))this.appOutsideClick.next();
    });
  }

  ngOnDestroy(): void {
    this.clickedElementSubscription.unsubscribe();
  }

}
