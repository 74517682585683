import {Directive, Output, EventEmitter, HostListener} from '@angular/core';
import {ToggleService} from '../../services/toggle.service';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  private localEvent = null;
  @Output('appClickOutside') appClickOutside: EventEmitter<any> = new EventEmitter();

  @HostListener('click') trackEvent(event) {
    if (this._toggle.datepickerState) return false;
    this.localEvent = event;
  }

  @HostListener('document: click') compareEvent(event) {
    if (this._toggle.datepickerState) return false;
    if (event !== this.localEvent) this.appClickOutside.emit(event);
    this.localEvent = null;
  }

  constructor(public _toggle: ToggleService) {
  }
}
