import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaginationComponent} from './pagination.component';
import {FormsModule} from '@angular/forms';
import {ClickOutsideModule} from '../../directives/clickOutside/clickOutside.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ClickOutsideModule
  ],
  providers: [
  ],
  declarations: [
    PaginationComponent
  ],
  exports: [
    PaginationComponent
  ]
})
export class PaginationModule {
}
