import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ClickedElementService {

  element: Subject<HTMLElement> = new Subject<HTMLElement>();

  constructor() {}

}
