import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserSidebarComponent} from './user-sidebar.component';
import {ScrollPanelModule} from 'primeng/primeng';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ScrollPanelModule
  ],
  providers: [
  ],
  declarations: [
    UserSidebarComponent
  ],
  exports: [
    UserSidebarComponent
  ]
})
export class UserSidebarModule {
}
