import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {FilterData} from '../../../../classes/filter-data';
import {CompanyService} from '../company.service';
import {Observable} from 'rxjs';
import {Company} from '../classes/company';
import {ApiService} from '../../../../services/api.service';

@Injectable()
export class CompanyAllResolver implements Resolve<any> {

  constructor(private _company: CompanyService, private _api: ApiService) {
  }

  resolve(next: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Company[]> {
    return this._api.handleRequest(this._company.getCompanies(new FilterData({
      filters: [
        {key: 'archived', value: false, default: true}
      ]
    })));
  }
}
