import {environment} from '../../../../environments/environment';

export const ProductMaterialRoutes = {
  product: {
    commons: {
      label: {
        get: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/product/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/product/label/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/product/label/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/product/label/delete`
        }
      },
    },
    category: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/product/category/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/product/category/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/product/category/update`
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/product/category/delete`
      }
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/product/get`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/product/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/product/delete` // /:id
    },
    addToStore: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/addToStore`
    },
    removeFromStore: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/removeFromStore`
    }
    //TODO cms rotues?
    // assignPages: {
    //   method: 'Put',
    //   url: `${environment.baseUrl}/api/${environment.version}/cms/apiKeyScope/assignProduct`
    // }
  },
  material: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/product/material/get`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/material/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/material/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/product/material/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/product/material/delete` // /:id
    }
  },
  commercialProfile: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/product/commercialProfile/get`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/commercialProfile/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/commercialProfile/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/product/commercialProfile/update`
    },
    archive: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/product/commercialProfile/archive`
    },
    restore: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/product/commercialProfile/restore`
    }
  },
  referral: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/referral/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/referral/create`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/product/referral/get`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/product/referral/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/product/referral/delete`
    }
  },
  coupon: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/coupons/filter`
    },
    generate: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/coupons/generate`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/product/coupons/get`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/product/coupons/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/product/coupon/delete`
    }
  },
  specialPrice: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/product/specialPrice/get`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/specialPrice/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/specialPrice/add`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/product/specialPrice/update`
    },
    archive: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/product/specialPrice/archive`
    },
    restore: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/product/specialPrice/restore`
    }
  },
  manufacturer: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/product/manufacturer/get`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/manufacturer/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/manufacturer/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/product/manufacturer/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/product/manufacturer/delete`
    },
  },
  brand: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/product/brand/get`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/brand/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/brand/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/product/brand/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/product/brand/delete`
    },
  },
  tag: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/product/tag/get`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/tag/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/product/tag/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/product/tag/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/product/tag/delete`
    },
  }
};
