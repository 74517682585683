import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {UserService} from "../user.service";
import {ApiService} from "../../../../services/api.service";
import {Observable} from "rxjs/index";
import {User} from "../classes/user";
import {Injectable} from "@angular/core";

@Injectable()
export class WebUserGetResolver implements Resolve<User> {

  constructor(private _user: UserService, private _api: ApiService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<User> {
    return this._api.handleRequest(this._user.getWebUser(parseInt(route.params.id)));
  }

}
