export const CroatianTranslations = {
  optionsSelected: 'odabrane stavke',
  externalAssociateContract: 'Izvezi Ugovor za vanjskog suradnika',
  externalAcademicYear: 'Kopiraj Akademsku godinu',
  externalToAcademicYear: 'Kopiraj u Akademsku godinu',
  copyToAcademicYear: 'Kopiraj u godinu',
  academicYearCopy: 'Akademska godina kopirana',
  academicToYearCopy: 'Kopirano u akademsku godinu',
  disabledPopUp: 'Molimo Vas, isključite Pop-up blocker i pokušajte ponovno',
  group_number: 'Broj grupe',
  createOpportunity: 'Kreiraj priliku',
  update_audience_info: 'Lista uspješno ažurirana',
  update_member_info: 'Korisnik uspješno ažuriran',
  update_campaign_info: 'Kampanja uspješno ažurirana',
  delete_campaign_info: 'Kampanja je uspješno obrisana',
  delete_audience: 'List uspješno obrisana',
  campaigns: 'Kampanje',
  editCampaign: 'Uredi kampanju',
  createCampaign: 'Kreiraj kampanju',
  edit_audience: 'Ažuriranje liste primatelja',
  audience_title: 'Lista',
  create_audience_sucessfull: 'Nova lista je kreirana',
  send_campaign_info: 'Uspješno poslana kampanja, pogledajte mail',
  add_campaign: 'Nova kampanja dodana',
  campaign_title: 'Kampanja',
  delete_member: 'Pretplatnik obrisan',
  add_new_member: 'Novi pretplatnik kreiran',
  member_info: 'Pretplatnik',
  marketing_permissions: 'Marketing dozvole',
  marketing_permissions_details: 'Ima li lista omogućene marketing dozvole npr. GDPR',
  use_archive_bar_details: 'Da li kampanje za ovu listu imaju zadano korištenje Archive Bar',
  email_type_option_details: 'Podržava li lista više formata za email',
  email_type: 'Vrsta emaila',
  double_optin_details: 'Treba li tražiti od pretplatnika da potvrdi pretplatu putem emaila',
  create_audience_error: 'Trenutno imate maksimalni broj liste, molimo obrišite jednu listu',
  email_subscribe: 'Email notifikacija za  registirane korisnike',
  email_unsubcribe: 'Email notifikacija za  neregistirane korisnike',
  permission_reminder: 'Notifikacija o dopuštenju',
  email_address: 'Email adresa',
  campaing_type: 'Tip kampanje',
  preview_text: 'Pregled teksta',
  campaign_name: 'Ime kampanje',
  choose_audience: 'Izaberite listu',
  campaignType: 'Tip kampanje',
  from_email: 'Email pošiljatelja',
  from_name: 'Ime pošiljatelja',
  notify_on_unsubscribe: 'Notify on unsubscribe',
  notify_on_subscribe: 'Notifikacija na prijavi',
  permmision_reminder: 'Notifikacija o dopuštenju',
  addr1: 'Adresa',
  addr2: 'Dodatna adresa',
  double_optinMailchinp: 'Ova osoba je dala dopuštenje da ju kontaktiramo',
  double_optinMailchinpDescritption: 'Ova osoba neće primiti konfirmacijski mail od mailchimpa. Kako ovog primatelja unosite ručno, on neće imati opt-in IP adresu zabilježenu u vašim zapisima, tako da budite sigurni da imate dopuštenje za slanje. Prikaži više (link koji vodi na njihovu stranicu u novom tabu)',
  marketing_permissionsMailchimp: 'Ova osoba je već na popisu, uredite postojeći profil.',
  unsubscribe: 'Odjaviti se',
  subscribe: 'Pretplatiti se',
  email_sent: 'Poslani Email-ovi',
  email_marketing: 'Email Marketing',
  send_campaign: 'Pošalji kampanju',
  all_campaigns: 'Sve kampanje',
  subscribers: 'Pretplatnici',
  subscriber: 'Preplatnik',
  leave: 'Napusti',
  channelInfo: ' Channel Info:',
  idle: 'Besposlen',
  busy: 'zaposlen',
  ganttchart: 'Gantt chart',
  gantt: 'Gantt',
  addedToCms: 'Ankete dodane u cms',
  removeCms: 'Obriši iz Cms-a',
  react: 'React',
  quote: 'Quote',
  removeToCms: 'Ankete obrisane u cms',
  addToCMS: 'Dodaj u CMS',
  removeFromCms: 'Obriši iz CMS-a',
  new_audience: 'Nova lista primatelja',
  audiences: 'Liste primatelja',
  audience: 'Lista primatelja',
  mailchimp: 'Mailchimp',
  mailchimpFreeAccAudienceHeaderText: 'Dosegli ste limit lista primatelja.',
  mailchimpFreeAccAudienceBodyText: 'Već imate maksimalan broj lista primatelja dozvoljenih u vašem planu, ali možete dodati dodatne kontakte u postojeću listu i organizirati ih putem tagova. Ukoliko želite dodati novu listu pretplatnika trebate nadograditi vaš MailChimp račun.',
  sites: 'Stranice',
  yourPassword: 'Vaša lozinka',
  login: 'Prijavi se',
  qualified: 'Qualified',
  unqualified: 'Unqualified',
  score: 'Score',
  percentage: 'Percentage',
  examPoints: 'Exam points',
  passPoints: 'Pass points',
  isPassed: 'Passed',
  participated: 'Participated',
  signatureText: 'Tekst potpisa',
  gallery: 'Galerija',
  convertlead: 'Convert lead',
  convert: 'Pretvori',
  tableView: 'Table view',
  gridView: 'Grid view',
  coverImage: 'Cover image',
  member: 'Korisnik',
  members: 'Nastavnici i suradnici',
  uploadCoverImage: 'Upload cover image',
  newteam: 'New team',
  editteam: 'Edit team',
  teams: 'Teams',
  team: 'Team',
  sendOffer: 'Send offer',
  noAddresses: 'No addresses',
  noHistory: 'No history',
  editOpportunity: 'Edit opportunity',
  stage: 'Stage',
  stages: 'Stages',
  estimatedTime: 'Estimated time',
  estimatedValue: 'Estimated value',
  editopportunity: 'Edit opportunity',
  editedBy: 'Edited by',
  archivedBy: 'Archived by',
  restoredBy: 'Restored by',
  completedBy: 'Completed by',
  reassignedTo: 'Reassigned to',
  showHistory: 'Show history',
  history: 'History',
  messageConfirmConfirm: 'Are you sure ?',
  reassign: 'Reassign',
  reassignActivity: 'Reassign activity',
  onlyThisActivity: 'Only this activity',
  allActivitiesOfThisType: 'All activities of this type',
  allActivities: 'All activities',
  totals: 'Totali',
  noOfFamilyMembers: 'Broj članova',
  notExportFile: 'Please upload the project export file',
  reply: 'Odgovori',
  forward: 'Proslijedi',
  toTask: 'Pretvori u zadatak',
  work: 'Posao',
  travel: 'Putovanja',
  newGroup: 'Nova grupa',
  sendMessage: 'Pošalji poruku',
  attachments: 'Prilozi',
  deleteForever: 'Obriši zauvijek',
  nn: 'NN',
  dueTime: 'Datum dospijeća',
  estimateBy: 'Procijenite do',
  cc: 'CC',
  bcc: 'BCC',
  marketing: 'Marketing',
  templates: 'Predložak',
  customerExperienceResearch: 'Istraživanje korisničkog iskustva',
  createPollsSurvey: 'Stvorite ankete i još mnogo toga',
  apiKey: 'Apikey',
  subscribeMonthly: 'Pretplatite se na naš mjesečni newsletter',
  sms: 'SMS',
  sendViewSms: 'Pošaljite i pregledajte sve svoje SMS poruke',
  surveyList: 'Lista Anketa',
  newSurvey: 'Nova Anketa',
  previewSurvey: 'Prošla anketa',
  surveyName: 'Naziv Ankete',
  forClient: 'Za klijenta',
  prizeType: 'Tip Nagrade',
  prize: 'Nagrada',
  publishSurvey: 'Objavi anketu',
  anonimous: 'Anonimna',
  surveyReset: 'Anketa je vraćena na početno stanje',
  surveySubmitted: 'Anketa predana',
  surveyDeleted: 'Anketa je obrisana',
  SurveyRestored: 'Anketea vraćena',
  gradeSurvey: 'Ocjenite anketu',
  viewSurvey: 'Pogledaj Anketu',
  takeSurvey: 'Ispuni Anketu',
  editsurvey: 'Editiraj anketu',
  editSurvey: 'Editiraj anketu',
  submitSurvey: 'Predaj Anketu',
  takesurvey: 'Ispuni Survey',
  newsurvey: 'Nova Anketa',
  resultssurvey: 'Rezultat Ankete',
  chat: 'Chat',
  createNewChanel: 'Kreiraj novi kanal',
  channel: 'kanal',
  directMessages: 'Direktna Poruka',
  cannotEmptyMessage: 'Ne mozes poslati praznu poruku!',
  userInfo: 'User Info',
  atLeastOneQuestion1: 'Anketa mora imati barem jedno pitanje',
  //Global
  myProfile: 'Moj profil',
  move: 'Prebaci',
  selectAll: 'Označi sve',
  deselectAll: 'Odznači sve',
  showRows: 'Prikaži redove',
  showGrid: 'Prikaži kartice',
  add: 'Dodaj',
  remove: 'Ukloni',
  create: 'Kreiraj',
  save: 'Spremi',
  saveChanges: 'Spremi promjene',
  view: 'Pogledaj',
  edit: 'Uredi',
  archive: 'Arhiviraj',
  manageProductStores: 'Upravljaj trgovinama',
  restore: 'Vrati',
  cancel: 'Odustani',
  delete: 'Obriši',
  search: 'Pretraži...',
  select: '- Odaberi -',
  back: 'Natrag',
  success: 'Uspjeh',
  yes: 'Da',
  no: 'Ne',
  ok: 'U redu',
  new: 'Novi',
  clear: 'Očisti',
  download: 'Preuzmi',
  downloads: 'Preuzimanja',
  category: 'Kategorija',
  categories: 'Kategorije',
  label: 'Oznaka',
  labels: 'Oznake',
  activity: 'Aktivnost',
  activities: 'Aktivnosti',
  calendar: 'Kalendar',
  calendars: 'Kalendari',
  manage: 'Upravljaj',
  generalData: 'Osnovni podatci',
  enabled: 'Omogućeno',
  disabled: 'Onemogućeno',
  image: 'Slika',
  images: 'Slike',
  newCategory: 'Nova kategorija',
  editCategory: 'Uredi kategoriju',
  categoryCreated: 'Kategorija kreirana',
  categoryUpdated: 'Kategorija uređena',
  setDefault: 'Postavi kao osnovno',
  startDate: 'Datum početka',
  endDate: 'Datum završetka',
  startTime: 'Vrijeme početka',
  endTime: 'Vrijeme završetka',
  tag: 'Tag',
  tags: 'Tagovi',
  description: 'Opis',
  showMore: 'Prikaži više',
  showLess: 'Prikaži manje',
  uploadVideo: 'Učitaj video',
  uploadFile: 'Učitaj datoteku',
  uploadImage: 'Učitaj sliku',
  reUploadImage: 'Učitaj drugu sliku',
  uploadSeoImage: 'Učitaj SEO sliku',
  reUploadSeoImage: 'Učitaj drugu SEO sliku',
  all: 'Sve',
  none: 'Ništa',
  online: 'Online',
  offline: 'Offline',
  by: 'Od',
  created: 'Kreiran',
  viewAll: 'Prikaži sve',
  goTo: 'Idi na',
  createComment: 'Kreiraj komentar',
  addNew: 'Dodaj',
  edit_member: 'Ažuriraj pretplatnika',
  add_member: 'Dodaj pretplatnika',
  milestone: 'Milestone',
  milestones: 'Milestones',
  warning: 'Upozorenje',
  completed: 'Završeno',
  message: 'Poruka',
  viewProfile: 'Pogledaj profil',
  supervisor: 'Nositelj',
  group: 'Grupa',
  street: 'Ime ulice',
  houseNumber: 'Kućni broj',
  zipCode: 'Poštanski broj',
  city: 'Grad',
  region: 'Regija',
  country: 'Država',
  active: 'Aktivan',
  deleted: 'Obrisan',
  type: 'Tip',
  projectDiscussions: 'Diskusije projekta',
  classrooms: 'Učionice',
  courseDicussions: 'Diskusije tečaja',
  close: 'Zatvori',
  hours: 'Broj sati',
  expense: 'Trošak',
  saved: 'Spremljeno',
  notifications: 'Notifikacije',
  notification: 'Notifikacija',
  ibans: 'IBAN',
  bank: 'Banka',
  accountNo: 'Broj računa',
  pricelist: 'Cjenik',
  name: 'Naziv',
  item: 'Stavka',
  currency: 'Valuta',
  vatClass: 'Porezna stopa',
  updated: 'Editiran',
  validTo: 'Validan do',
  number: 'Broj',
  start: 'Početak',
  end: 'Kraj',
  leader: 'Nositelj',
  createdBy: 'Kreirao',
  open: 'Otvoreno',
  closed: 'Zatvoreno',
  withCategories: 'Kategorijama',
  project: 'Projekt',
  task: 'Zadatak',
  person: 'Osoba',
  entry: 'Unos',
  noRecords: 'Nema pronađenih podataka.',
  anonymise: 'Anonimiziraj',
  indentify: 'Identificiraj',
  notSet: 'NIJE POSTAVLJENO',
  shippingMethods: 'Dostavne metode',
  taxes: 'Porezi',
  domains: 'Domene',
  estimatedBudget: 'Procijenjeni budžet',
  client: 'Klijent',
  pdf: 'Export u PDF',
  offer_outgoing: 'Ulazna ponuda',
  offer_inbound: 'Izlazna ponuda',
  invoice_outgoing: 'Izlazni račun',
  invoice_inbound: 'Ulazni račun',
  order_inbound: 'Ulazna narudžbenica',
  order_outgoing: 'Izlazna narudžbenica',
  pattern: 'Pattern',
  firstNumber: 'Početni broj',
  convertOfferToInvoice: 'Izradi račun iz punude',
  newValue: 'Nova vrijednost',
  oldValue: 'Stara vrijednost',
  incoterms: 'Incoterms',
  update: 'Ažurirano',
  outRefNumber: 'Ref. broj',
  paymentType: 'Tip plaćanja',
  confirm: 'Potvrdi',
  externalImageLink: 'Vanjska poveznica (slika)',
  itemsUpdated: 'Stavke uređene',
  slugEditWarning: 'Uređivanje slug-a može dovesti greške u postojećoj poveznici',
  archived: 'Arhivirano',
  restored: 'Vraćeno',
  export: 'Izvezi',
  import: 'Uvezi',
  items: 'Stavke',
  issuedFrom: 'Izdavatelj',
  issuedTo: 'Primatelj',
  issuedFromContact: 'Kontakt osoba',
  issuedFromContactValue: 'Kontakt',
  issuedToContact: 'Kontakt osoba',
  issuedToContactValue: 'Kontakt',
  gmail: 'Gmail',
  other: 'Ostalo',
  email: 'E-mail',
  password: 'Lozinka',
  pop3: 'POP 3',
  imap: 'IMAP',
  smtp: 'SMTP',

  documentDiscount: 'Popust',
  documentDiscountAmount: 'Dok. iznos popusta',
  intRefNumber: 'Int. ref. broj',
  intRefNumberId: 'Int. ref. broj ID',
  intRefNumberDate: 'Int. ref. broj datuma',
  outRefNumberId: 'Izl. ref. broj ID',
  outRefNumberDate: 'Izl. ref. broj date',
  lnkRefNumber: 'Lnk. ref. broj',
  lnkRefNumberId: 'Lnk. ref. broj ID',
  lnkRefNumberDate: 'Lnk. ref broj date',
  deliveryDetails: 'Detalji dostave',
  deliveryCompany: 'Tvrtka za isporuku',
  deliveryPerson: 'Dostavljač',

  media: 'Media',
  content: 'Sadržaj',
  deliveryVehicleReg: 'Registracija vozila',
  externalReceivedBy: 'Primljeno od',
  shippingMethod: 'Metoda slanja',
  transportCost: 'Troškovi prijevoza',
  paymentTerm: 'Uvjeti plaćanja',
  invoiceType: 'Vrsta dostavnice',
  device: 'Uređaj',
  internalNote: 'Interna bilješka',
  authorize: 'Odobriti',
  authorizeDate: 'Odredite datum',
  cancelled: 'Otkazan',
  cancelledRelation: 'Otkazan odnos',
  isLegacy: 'Nasljedstvo',
  createdByType: 'Izrađeno prema tipu',
  status: 'Status',
  documentTax: 'Porez na dokumente',
  supplier: 'Dobavljač',
  supplierType: 'Tip dobavljača',
  phase: 'Faza',
  pdfTemplate: 'PDF predložak',
  bin: 'Bin',
  basicPrice: 'Osnovna cijena',
  itemNote: 'Napomena za stavku',
  deliveryDate: 'Datum dostave',
  itemSku: 'SKU',
  taxClassValue: 'Vrijednost klase poreza',
  originDocument: 'Izvorni dokument',

  originDocumentItem: 'Stavka prethodnog dokumenta',

  itemType: 'Vrsta stavke',
  shippingCost: 'Cijena prijevoza',
  shippingTax: 'Porez na prijevoz',
  invalidFile: 'Nevažeća datoteka',
  filter: 'Filtriraj',
  discussions: 'Diskusije',
  tasks: 'Zadaci',
  user: 'Nastavnici i suradnici',
  available: 'Dostupno',
  selected: 'Odabrano',
  get: 'Get',
  roles: 'Role',
  projectParticipants: 'Sudionici',
  showArchive: 'Prikaži arhivu',
  showActive: 'Prikaži aktivne',
  noRoles: 'Nema uloga, napravite ulogu',
  visibility: 'Vidljivost',
  settings: 'Postavke',
  generate: 'Generiraj',
  invoice: 'Račun',
  discussionSubscribers: 'Pretplatnici na raspravu',
  taskAssignees: 'Zadatak naručitelja',
  homeWelcomeMsg: 'Dobrodošli u Gauss Box.',
  assethandoutform_internal: 'Obrazac za zaduženje robe',
  assetreturnform_internal: 'Obrazac za razduženje robe',
  contract_special: 'Ugovor',
  deliverynote_internal: 'Otpremnica',
  goodsreceipt_internal: 'Primka',
  inquiry_inbound: 'Ulazni upit',
  inquiry_outgoing: 'Izlazni upit',
  issuenote_internal: 'Izdatnica',
  letter_inbound: 'Ulazni dopis',
  letter_internal: 'Interni dopis',
  letter_outgoing: 'Izlazni dopis',
  requisitionwarrant_internal: 'Zahtjev za nabavu',
  warehousetransfer_internal: 'Međuskladišnica',
  publish: 'Objavi',
  unpublish: 'Odjavi',  // TOMO
  changeDomainSure: 'Sve nespremljene promjene na trenutnoj domeni biti će izgubljene. Jeste li sigurni da želite promijeniti trenutnu domenu?',
  campainName: 'Naziv kampanje',
  recipientList: 'Lista primatelja',
  senderName: 'Naziv pošiljatelja',
  senderEmail: 'Email pošiljatelja',
  previweText: 'Pregled sadržaja',

  //HOME

  revenueMonthly: 'Mjesečni prihodi',
  inboundVsOutgoingInv: 'Ulazni i izlazni računi',
  invoicesByDueDate: 'Računi po datumu dospijeća',
  totalDocuments: 'Ukupno dokumenti',
  topSellingProdServ: 'Najprodavaniji proizvodi i usluge',
  top6customers: 'Top 6 kupaca',
  revenue: 'Prihod',
  revenueWeekly: 'TJEDNI PRIHOD',
  revenueDaily: 'DNEVNI PRIHOD',

  //CALENDAR

  createEvent: 'Stvori događaj',
  eventName: 'Naziv događaja',
  eventStartTime: 'Početak događaja',
  eventEndTime: 'Završetak događaja',
  recurring: 'Ponavljajući',
  recurrenceEndTime: 'Završetak ponavljanja',
  notes: 'Bilješke',
  editThisEvent: 'Uredi ovaj događaj',
  editRecurringSeries: 'Uredi ponavljajuću seriju',
  deleteRecurringSeries: 'Ukloni ponavljajući događaj',
  deleteEvent: 'Ukloni događaj',
  editSeries: 'Uredi seriju',
  editEvent: 'Uredi događaj',
  today: 'Danas',
  day: 'Dan',
  week: 'Tjedan',
  month: 'Mjesec',
  private: 'Privatno',
  public: 'Javno',
  eventcreated: 'Događaj stvoren!',
  eventupdated: 'Događaj uređen!',
  eventdeleted: 'Događaj uklonjen!',

  //LIST

  contactName: 'Ime kontakta',
  mobile: 'Mobitel',
  occupation: 'Radno mjesto',
  phone: 'Telefon',
  address: 'Adresa',
  state: 'Stanje',
  retailPrice: 'MPC',
  createdAt: 'Datum kreiranja',
  loggedUnit: 'Upisana jedinica',
  date: 'Datum',
  value: 'Vrijednost',
  billable: 'Naplativo',
  taskCategory: 'Kategorija zad.',
  summary: 'Opis',
  capacity: 'Kapacitet',
  timeElapsed: 'Proteklo vrijeme',
  submittedOn: 'Predan',
  results: 'Rezultat',
  netPrice: 'Neto cijena',
  totalPrice: 'Ukupna cijena',
  vat1: 'PDV',
  sku: 'SKU',
  displayName: 'Puno ime s titulom',
  title: 'Naslov',
  personalIdNumber: 'Oib',
  skype: 'Skype',
  permission: 'Ovlaštenje',
  vatNumber: 'Porezni broj',
  gender: 'Spol',
  birthDate: 'Datum rođenja',
  newsletter: 'Newsletter',
  site: 'Site',
  website: 'Website',
  hasAccess: 'Ima pristup',
  firstName: 'Ime',
  lastName: 'Prezime',
  salutation: 'Titula',
  placeOfBirth: 'Mjesto rođenja',
  main: 'Glavna adresa',
  fax: 'Fax',
  workgroups: 'Radne skupine',
  username: 'Korisničko ime',
  shortName: 'Kratko ime',
  industryType: 'Vrsta industrije',
  companyIdNumber: 'ID broj tvrtke',
  logo: 'Logo',
  barcode: 'Barkod',
  grandTotal: 'Ukupno',
  documentType: 'Tip dokumenta',
  documentFlow: 'Orijentacija',
  ean: 'EAN',  // TOMO
  qrCode: 'QR kod',  // TOMO
  tariffNumber: 'Tarifni broj', // TOMO
  breakage: 'Lom', // TOMO
  shortDescription: 'Kratki opis', // TOMO
  featured: 'Izdvojen', // TOMO
  taxClass: 'Klasa poreza', // TOMO
  hasWeight: 'Težina', // TOMO
  leaderImage: 'Slika voditelja', // TOMO
  createdByImage: 'Slika tvorca', // TOMO
  barCode: 'Bar kod',//TOMO
  subject: 'Predmet',
  level: 'Razina',
  budget: 'Budžet',
  totalProjects: 'Ukupno projekata',
  pageTitle: 'Naslov',  // TOMO
  author: 'Autor',  // TOMO
  readability: 'Čitljivost',  // TOMO
  slug: 'Slug',  // TOMO
  termTitle: 'Naslov',  // TOMO
  countOfTasks: 'Broj taskova',
  completedTasks: 'Završeni taskovi',
  estimatedHours: 'Estimirani sati',
  loggedHours: 'Ulogirani sati',
  estimatedPriceTotal: 'Ukupna estimirana cijena',
  completedTasksPercentage: 'Postotak završenih zadataka',
  estimatedHoursInTotal: 'Ukupno estimiranih sati',
  totalHours: 'Ukupno sati',
  buyDate: 'Datum kupovine',
  model: 'Model',
  document: 'Dokument',
  invoiceNumber: 'Broj računa',
  manufacturer: 'Proizvođač',
  serialNo: 'Serijski broj',
  assignType: 'Dodijeli vrstu',
  coordinates: 'Koordinate',
  quantity: 'Količina',
  reserved: 'Rezervirano',
  version: 'Verzija',
  mainConsent: 'Glavna privola',
  taskCount: 'Broj zadataka',
  statusNote: 'Bilješka status',
  statusTimestamp: 'Datum statusa',
  consent: 'Privola',
  user_thumbnail: 'Nastavnici i suradnici',
  agreement: 'Sporazum',
  sourceId: 'Izvor',
  user_email: 'E-mail',
  user_personalIdNumber: 'OIB',
  role: 'Rola',
  estimated: 'Procijenjeno',
  unitPrice: 'Jedinična cijena',
  total: 'Total',
  mainBranch: 'Glavna grana',
  isBillable: 'Naplativo',
  owner: 'Vlasnik',
  module: 'Modul',
  access: 'Pristup',
  exportQuestion: 'Želite li izvesti trenutnu stranicu ili sve stranice',
  checkedItems: 'Označene stavke',
  currentPage: 'Trenutnu stranicu',
  allPages: 'Sve stranice',
  exportTo: 'Izvezi u',
  csv: '.csv',
  xlsx: '.xlsx',
  exportItems: 'Izvezi stavke',
  filename: 'Ime datoteke',
  enable: 'Omogući',
  disable: 'Onemogući',
  name_thumbnail: '',
  createdBy_thumbnail: '',
  owner_image: 'Owner',

  //AUTH

  usernameEmail: 'Korisnicko ime/Email',
  changePassword: 'Promijeni lozinku',
  oldPassword: 'Stara lozinka',
  newPassword: 'Nova lozinka',
  confirmPassword: 'Potvrdi lozinku',
  forgotPassword: 'Zaboravio sam lozinku',
  resetPassword: 'Promjena lozinke',

  //fileUpload

  maxFileSizeWarn: 'Prevelika datoteka',
  maxFileSizeError: 'Premašena maksimalna veličina datoteke',

  //Video

  noVideoUploaded: 'Video nije učitan',

  //BreadCrumbs

  materialsexternalservices: 'Materijali i vanjske usluge',
  newmaterial: 'Novi materijal',
  editmaterial: 'Uredi materijal',
  home: 'Početna',
  company: 'Tvrtka',
  companies: 'Tvrtke',
  details: 'Detalji',
  contacts: 'Kontakti',
  webusers: 'Web korisnici',
  branches: 'Poslovnice',
  projects: 'Projekti',
  newproduct: 'Novi Proizvod',
  editproduct: 'Uredi Proizvod',
  newuser: 'Novi Korisnik',
  edituser: 'Uredi Korisnika',
  newbranch: 'Nova Poslovnica',
  editbranch: 'Uredi Poslovnicu',
  newcompany: 'Nova Tvrtka',
  editcompany: 'Uredi Tvrtku',
  analytics: 'Analitika',
  sessions: 'Sesije',
  ui: 'UI',
  info: 'Info',
  admin: 'Administrator',
  general: 'Generalno',
  lists: 'Liste',
  overview: 'Pregled',
  conversions: 'Konverzije',
  alerts: 'Obavjesti',
  documents: 'Dokumenti',
  courses: 'Tečajevi',
  products: 'Proizvodi',
  materials: 'Materijali',
  productsmaterials: 'Proizvodi i materijali',
  list: 'Lista',
  implementationplans: 'Izvedbeni planovi',
  evnascrumb: 'Evnas',
  elearning: 'E-učenje',
  participants: 'Sudionici',
  virtualclassroom: 'Virtualna učionica',
  ecommerce: 'E-commerce',
  paymentterms: 'Uvjeti Plaćanja',
  deliveryterms: 'Uvjeti Dostave',
  lead: 'Leadovi',
  opportunity: 'Prilika',
  newOpportunity: 'Nova prilika',
  insurancesales: 'Prodaja osiguranja',
  mailbox: 'Pošta',
  inbox: 'Spremnik',
  sent: 'Poslano',
  drafts: 'Skice',
  trash: 'Otpad',
  archives: 'Arhiva',
  reports: 'Izvještaji',
  gdpr: 'Zaštita podataka',
  consents: 'Privole',
  newconsent: 'Nova privola',
  editconsent: 'Uredi privolu',
  consentlogs: 'Unosi privola',
  agreements: 'Sporazumi',
  newagreement: 'Novi sporazum',
  editagreement: 'Uredi sporazum',
  newconsentlog: 'Novi unos privole',
  editconsentlog: 'Uredi unos privole',
  consentLog: 'Unos privole',
  departments: 'Odjeli',
  newdepartment: 'Novi odjel',
  editdepartment: 'Uredi odjel',
  newcourse: 'Novi tečaj',
  editcourse: 'Uredi tečaj',
  attributes: 'Atributi',
  newattribute: 'Novi atribut',
  editattribute: 'Uredi atribut',
  attributesets: 'Setovi atributa',
  newattributeset: 'Novi set atributa',
  editattributeset: 'Uredi set atributa',
  productsservices: 'Proizvodi i usluge',
  newservice: 'Nova usluga',
  editservice: 'Uredi uslugu',
  externalservices: 'Vanjske usluge',
  newexternalservice: 'Nova vanjska usluga',
  editexternalservice: 'Uredi vanjsku uslugu',
  newclass: 'Nova lekcija',
  editclass: 'Uredi lekciju',
  classes: 'Lekcije',
  classdetails: 'Detalji lekcije',
  discussiondetails: 'Detalji diskusije',
  editdiscussion: 'Uredi diskusiju',
  newdiscussion: 'Nova diskusija',
  newclassroom: 'Nova učionica',
  editclassroom: 'Uredi učionicu',
  newwarehouse: 'Novo skladište',
  editwarehouse: 'Uredi skladište',
  newproject: 'Novi projekt',
  editproject: 'Uredi projekt',
  newtask: 'Novi zadatak',
  edittask: 'Uredi zadatak',
  timeexpenses: 'Vrijeme i troškovi',
  kanban: 'Kanban',
  humanresources: 'Ljudski resursi',
  attendance: 'Prisutnost',
  workstatus: 'Radni status',
  absenceschedule: 'Raspored odutnosti',
  healthandsafety: 'Zdravlje i sigurnost',
  bins: 'Police',
  newbin: 'Nova polica',
  editbin: 'Uredi policu',
  newasset: 'Nova imovina',
  editasset: 'Uredi imovinu',
  assets: 'Imovina',
  report: 'Report',
  newcategory: 'Nova kategorija',
  editcategory: 'Uredi kategoriju',
  managecategories: 'Upavljanje kategorijama',
  exams: 'Ispiti',
  exam: 'Ispit',
  examslist: 'Lista ispita',
  newexam: 'Novi ispit',
  editexam: 'Uredi ispit',
  cms: 'CMS',
  stores: 'Trgovine',
  commercialprofiles: 'Komercijalni profili',
  newcommercialprofile: 'Novi komercijalni profil',
  editcommercialprofile: 'Uredi komercijalni profil',
  commercialprofile: 'Komercijalni profil',
  couponsets: 'Kupon setovi',
  gaussDrive: 'Storage',
  projectgeneralreport: 'Projektni opći izvještaj',
  outgoinginvoicereport: 'Izlazni računi izvještaj',
  inboundinvoicereport: 'Ulazni računi izvještaj',
  contractreport: 'Ugovori izvještaj',
  inboundorderreport: 'Ulazne narudžbenice izvještaj',
  outgoingorderreport: 'Izlazna narudžbenice izvještaj',
  projectloggedtimereport: 'Projekti logirano vrijeme izvještaj',
  stock: 'Zaliha',
  stockmovement: 'Kretanje zaliha',
  appearance: 'Appearance',  // TOMO
  blogposts: 'Postovi',
  comments: 'Komentari',
  forms: 'Forme',
  pages: 'Stranice',
  addnewpage: 'Dodavanje nove stranice',
  editpage: 'Ureživanje stranice',
  addnewpost: 'Dodavanje novog posta',
  editpost: 'Uređivanje posta',
  addnewcategory: 'Dodavanje nove kategorije',
  addnewtag: 'Dodavanje novog taga',
  edittag: 'Uređivanje taga',
  employeeworkloadreport: 'Izvještaj – Radna opterećenost zaposlenika',
  navigation: 'Navigacija',
  editlink: 'Uređivanje poveznice',
  projectportfolioreport: 'Izvještaj portfelja projekata',
  projectstatusreport: 'Izvještaj - status projekta',
  rolelist: 'Popis rola',
  newrole: 'Nova rola',
  editrole: 'Uredi rolu',
  generalSettings: 'Opće postavke',
  gaussdrive: 'Gauss drive',
  system: 'Sustav',
  catalog: 'Katalog',
  invoices: 'Računi',
  orders: 'Narudžbe',
  deliverymethods: 'Načini dostave',
  paymentmethods: 'Načini plaćanja',
  redirects: 'Redirects',
  trackingtools: 'Tracking tools',
  productpages: 'Stranice proizvoda',
  defaultAllowComments: 'Komentiranje postova dopušteno po defaultu',
  defaultAllowReviews: 'Recenziranje postova dopušteno po defaultu',
  requireRatingForComment: 'Obavezno ocjenjivanje kod komentiranja',
  requireCommentForRating: 'Obavezno komentiranje kod ocjenjivanja',
  numberOfStars: 'Navjeća moguća ocjena',
  canCommentOnBlog: 'Može komentirati blog',
  canAddReviews: 'Može recenzirati proizvod',
  registeredUsers: 'Registrirani korisnici',
  anyone: 'Bilo tko',
  academicyear: 'Akademska godina',
  studyprogram: 'Studijski program',
  implementationplan: 'Izvedbeni plan',
  coursecrumb: 'Kolegij',
  registry: 'Referada',
  createactivity: 'Dodaj aktivnost',
  editactivity: 'Uredi aktivnost',
  activitylogs: 'Korisničke aktivnosti',

  //Activity

  recentActivity: 'Nedavna aktivnost',
  recentActivities: 'Nedavne aktivnosti',
  modified: 'Promijenjeno',
  logged_in: 'Logiran',
  moved_to_archive: 'Prebačeno u arhivu',
  restored_from_archive: 'Vraćeno iz arhive',
  reopened: 'Ponovno otvoren',
  uploaded: 'Učitan',
  workgroup: 'radna grupa',
  cashRegister: 'blagajna',
  product: 'Produkt',
  simple_product: 'jednostavni proizvod',
  complex_product: 'složeni proizvod',
  eProduct_product: 'e-Proizvod',
  service: 'Usluga',
  simple_service: 'jednostavna usluga',
  complex_service: 'složena usluga',
  eProduct_service: 'e-Usluga',
  material: 'Sirovina',
  externalService: 'vanjska usluga',
  attribute: 'atribut',
  attributeSet: 'Atribut set',
  course: 'Tečaj',
  courseExam: 'Ispit',
  class: 'Lekcija',
  discussion: 'Diskusija',
  comment: 'komentar',
  projectDiscussion: 'Diskusija',
  removed_from_stock: 'uklonjeno sa stanja',
  added_to_stock: 'dodano na stanje',
  noCheckedItems: 'Nema označenih stavki',
  findFreeRooms: 'Pronađi slobodne prostorije',
  freeRooms: 'Slobodne prostorije',

  //Layout

  language: 'Jezik',
  updateprofile: 'Uredi profil',
  changepassword: 'Promijeni lozinku',
  logout: 'Odjava',
  default: 'Standardna',
  custom: 'Prilagođena',
  business: 'Poslovna',
  clientcompany: 'Klijent tvrtka',
  productsAndMaterials: 'Proizvodi i materijali',
  NoCompanyWarningHeader: 'Nema registrirane tvrtke',
  NoCompanyWarningText: 'Nemate registriranu tvrtku, molimo da ju kreirate kako biste koristili aplikaciju',
  error: 'Greška',
  colorscheme: 'Boja',
  addnewuser: 'Dodaj novog korisnika',
  addnewcompany: 'Dodaj novu tvrtku',
  addnewbranch: 'Dodaj novu poslovnicu',
  addnewdepartment: 'Dodaj novi odjel',

  //User

  iban: 'IBAN',
  savechanges: 'Spremi promjene',
  firstname: 'Ime',
  lastname: 'Prezime',
  companybranch: 'Poslovnica',
  phonenumber: 'Telefon',
  oib: 'OIB',
  birthdate: 'Datum rođenja',
  placeofbirth: 'Mjesto rođenja',
  addresses: 'Adrese',
  streetaddress: 'Ulica',
  postalcode: 'Poštanski broj',
  stateprovince: 'Općina/Naselje',
  hasaccess: 'Ima pristup',
  selectpermission: 'Odaberi ovlaštenje',
  weak: 'Slabo',
  medium: 'Srednje',
  strong: 'Jako',
  enterPassword: 'Unesite lozinku',
  male: 'Muško',
  female: 'Žensko',
  usercreated: 'Korisnik je kreiran',
  userupdated: 'Korisnik je uređen',
  userarchived: 'Korisnik je arhiviran',
  userrestored: 'korisnik je vraćen',
  sendmail: 'Pošalji mail',
  selecttype: 'Odaberite tip',
  dateofbirth: 'Datum rođenja',
  individuaalinformation: 'Osobni podatci',
  occupationinformation: 'Podatci o firmi',
  companyname: 'Naziv tvrtke',
  companyaddress: 'Adresa tvrtke',
  profileImageRemoved: 'Profilna slika je uklonjena',
  identify: 'Identificiraj',
  isAnonimysed: 'Anonizimiran',
  webuser: 'Web korisnik',
  contactPersonFirstName: 'Ime kontakt osobe',
  contactPersonLastName: 'Prezime kontakt osobe',
  contactPersonPhone: 'Telefonski broj kontakt osobe',

  //Company

  addnew: 'Dodaj',
  shortname: 'Kratki naziv',
  industrytype: 'Tip djelatnosti',
  companyidnumber: 'OIB',
  vatnumber: 'Porezni broj',
  housenumber: 'Kućni broj',
  selectimage: 'Odaberi sliku',
  hasaccesstooltip: 'Klijent tvrtka',
  contactcheckboxtooltip: 'Vaš glavni kontakt',
  companycreated: 'Nova tvrtka je kreirana',
  companyupdated: 'Tvrtka je uređena',
  companyarchived: 'Tvrtka je arhivirana',
  companyrestored: 'Tvrtka je vraćena',
  selectcompany: 'Odaberi tvrtka',
  selectcompanytext: 'Molimo odaberite tvrtku',
  departmentcreated: 'Katedra kreirana',
  departmentupdated: 'Katedra uređena',
  departmentarchived: 'Katedra arhivirana',
  companyLogo: 'Logo tvrtke',
  priceList: 'Cjenik',
  contact: 'Kontakt',

  //Branch

  noteofbusinessarea: 'Oznaka poslovnog prostora',
  noteofbusinessareainfo: 'Obavezno ako poslovnica izdaje račune',
  workhours: 'Radno vrijeme',
  zipcode: 'Poštanski broj',
  branchcreated: 'Poslovnica je kreirana',
  branchupdated: 'Poslovnica je uređena',
  brancharchived: 'Poslovnica je arhivirana',
  branchRestored: 'Poslovnica je otvorena',
  cashregisters: 'Blagajne',
  newdevice: 'Nova blagajna',
  editdevice: 'Uređivanje blagajne',
  devicetype: 'Vrsta blagajne',
  retail: 'Maloprodajna',
  cashregister: 'Blagajna ',
  invoicestartnumber: 'Početni broj računa',
  cashregistercreated: 'Blagajna kreirana',

  //Admin

  listsettings: 'Postavke listi',
  generalsettings: 'Općenite postavke',
  settingssave: 'Postavke promijenjene',
  hourUnits: 'Sati',
  minutes: 'Minute',
  days: 'Dani',
  defaultTheme: 'Standardna',
  customTheme: 'Prilagođena',
  businessTheme: 'Poslovna',
  attributeSets: 'Atribut setovi',
  attributeSetCreated: 'Atribut set je kreiran',
  attributeSetUpdated: 'Atribut set je uređen',
  attributeSetArchived: 'Atribut set je arhiviran',
  attributeSetRestored: 'Atribut set je vraćen',
  uploadHeader: 'Učitaj zaglavlje',
  uploadFooter: 'Učitaj podnožje',
  defaultType: 'Dodjela osnovnog tipa',
  defaultTypeNote: 'Prikaži tipove dokumenata specifičnim odjelima, radnim grupama ili osobama.',
  enableMemo: 'Omogući memorandum tvrtke',
  documentTraceability: 'Slijedivost dokumenata',
  documentTraceabilityNote: 'Definiraj red kojim se dokumenti mogu kreirati.',
  documentAuthorisation: 'Autorizacija dokumenata',
  documentAuthorisationNote: 'Postavi osobe za autorizaciju za tipove dokumenata.',
  documentSettings: 'Postavke dokumenata',
  defaultLanguage: 'Osnovni jezik',
  defaultColorTheme: 'Osnovna tema',
  mainCompanyCurrency: 'Valuta tvrtke',
  currencyFormat: 'Format valute',
  numberFormat: 'Format broja',
  dateAndTime: 'Datum i vrijeme',
  timeFormat: 'Format vremena',
  dateFormat: 'Format datuma',
  timeZone: 'Vremenska zona',
  twoStepAuth: 'Autentifikacija prijave u dva koraka',
  daylightSavings: 'Ljetno računanje vremena',
  entityType: 'Vrsta entiteta',
  createdOn: 'Vrijeme stvaranja',
  url: 'Url',
  apiKeyToken: 'Api key token',
  copyToken: 'Kopiraj token',
  tokenCopied: 'api key token kopiran',
  addDomain: 'Dodaj domenu',
  editDomain: 'Uredi domenu',
  domain: 'Domena',
  activeDomains: 'Aktivne domene',
  users: 'Nastavnici i korisnici',
  eLearning: 'E-učenje',
  inbound: 'Ulazni',
  outgoing: 'Izlazni',
  internal: 'Interni',
  subtask: 'Podzadatci',
  profileName: 'Naziv profila',
  selectProfile: 'Odaberi profil',
  emailSettings: 'E-mail postavke',
  accountSettings: 'Postavke računa',
  incomingSettings: 'Dolazne postavke',
  outgoingSettings: 'Odlazne postavke',
  emailAccountType: 'Vrsta računa e-pošte',
  protocol: 'Protokol',
  hostname: 'Hostname',
  port: 'Port',
  encryption: 'Šifriran',
  authentication: 'Ovjera',
  normalPassword: 'Normalna lozinka',
  encryptedPassword: 'Šifriran password',
  kerberosGSSAPI: 'Kerberos / GSSAPI',
  ntlm: 'NTLM',
  oAuth: 'OAuth',
  languageError: 'Prijevod na odabranom jeziku postoji',
  fileSizeError: 'Iznos uploadanih podataka je veči od dupoštenog',
  templateContent: 'Sadržaj',
  disableCommentEditingAfter: 'Onemogući uređivanje komentara nakon',
  'inbound-inquiry': 'Ulazni upit',
  'inbound-invoice': 'Izlazni upit',
  'inbound-letter': 'Ulazni dopis',
  'inbound-offer': 'Ulazna ponuda',
  'inbound-order': 'Izlazna narudžbenica',
  'internal-assethandoutform': 'Asset handover',
  'internal-assetreturnform': 'Asset return',
  'internal-deliverynote': 'Otpremnica',
  'internal-goodsreceipt': 'Primka',
  'internal-issuenote': 'Izdatnica',
  'internal-letter': 'Interni dopis',
  'internal-requisitionwarrant': 'Zahtjevnica',
  'internal-warehousetransfer': 'Međuskladišnica',
  'outgoing-inquiry': 'Izlazni upit',
  'outgoing-invoice': 'Izlazni raćun',
  'outgoing-letter': 'Izlazni dopis',
  'outgoing-offer': 'Izlazna ponuda',
  'outgoing-order': 'Izlazna narudžbenica',
  'special-contract': 'Ugovor',
  projectsSettingsSaved: 'Postave projekata spremljene',
  elearningSettingsSaved: 'Postavke E-learninga spremljene',
  permissionGroup: 'Permission group',
  permissionGroups: 'Permission groups',
  assetNumberPattern: 'Model broja imovine',
  warehouseSettingsSaved: 'Postavke skladišta spremljene',
  legend: 'Legenda',
  year: 'Godina',
  counter: 'Brojač',

  //Products

  retailPrice1: 'Maloprodajna cijena',
  newProduct: 'Novi proizvod',
  services: 'Usluge',
  text: 'Tekst',
  textArea: 'Tekst dio',
  confirmation: 'Da/Ne',
  selectField: 'Odaberi',
  multiSelectField: 'Višestruki odabir',
  attributeFields: 'Atribut polja',
  values: 'Vrijednosti',
  advanced: 'Napredno',
  defaultValue: 'Osnovna vrijednost',
  setDefaultValue: 'Postavi kao osnovno',
  required: 'Neophodno',
  code: 'Šifra',
  uniqueValue: 'Jedinstvena vrijednost',
  unique: 'Jedinstven',
  addToColumn: 'Dodaj stupcu',
  useInFilter: 'Koristi u filteru',
  useInSearch: 'Koristi u tražilici',
  inputType: 'Tip unosa',
  attributeCreated: 'Atribut kreiran',
  attributeUpdated: 'Atribut uređen',
  attributeDeleted: 'Atribut obrisan',
  attributeRestored: 'Atribut vraćen',
  longDescription: 'Dugi opis',
  breakAge: 'Rastep, kalo, lom',
  hasWeight1: 'Ima težinu',
  weight: 'Težina',
  weightUnit: 'Jedinica težine',
  productCreated: 'Proizvod kreiran',
  productUpdated: 'Proizvod uređen',
  productArchived: 'Proizvod arhiviran',
  productRestored: 'Proizvod vraćen',
  serviceCreated: 'Usluga kreirana',
  serviceUpdated: 'Usluga uređena',
  serviceArchived: 'Usluga arhivirana',
  serviceRestored: 'Usluga vraćena',
  parentCategory: 'Nadređena kategorija',
  'external-service': 'vanjska usluga',
  format: 'Format',
  materialCreated: 'Materijal kreiran',
  materialUpdated: 'Materijal uređen',
  materialArchived: 'Materijal arhiviran',
  materialRestored: 'Materijal vraćen',
  subType: 'Podtip',
  pleaseCreateCategoryProduct: 'Proizvodi i usluge trbeaju biti dodjeljene na kategoriju, molimo vas prvo kreirajte kategoriju',
  simple: 'Jednostavan',
  complex: 'Složen',
  eProduct: 'eProizvod',
  rawMaterials: 'Sirovine',
  spareParts: 'Rezervni dijelovi',
  beverages: 'Pića',
  tradingGoods: 'Trgovačka roba',
  smallInventory: 'Sitan inventar',
  tires: 'Gume',
  packaging: 'Ambalaža',
  waste: 'Otpadni materijal',
  useDefault: 'Koristi zadano',
  store: 'Trgovina',
  commercialProfile: 'Komercijalni profil',
  commercialProfiles: 'Komercijalni profili',
  couponSets: 'Kupon setovi',
  newCommercialProfile: 'Novi komercijalni profile',
  editCommercialProfile: 'Uredi komercijalni profil',
  newCouponSet: 'Novi kupon set',
  editCouponSet: 'Uredi kupon set',
  isEnabled: 'Omogućeno',
  isStackable: 'Is stackable',
  discountType: 'Tip popusta',
  shippingDiscount: 'Popust na dostavu',
  discountTarget: 'Meta dostave',
  priority: 'Prioritet',
  applyForStore: 'Dodaj na trgovinu',
  profileDescription: 'Opis profila',
  profileApplication: 'Primjena',
  always: 'Uvijek',
  viaCouponCodes: 'Uz kupon kodove',
  viaReferrals: 'Uz referrale',
  relative: 'Relativan',
  absolute: 'Apsolutan',
  singleUnit: 'Jedna jedinica',
  viaContacts: 'Uz kontakte',
  assignedProducts: 'Dodani proizvodi',
  assignedClients: 'Dodani klijenti',
  allProducts: 'Svi proizvodi',
  commercialProfileCreated: 'Komercijalni profil stvoren',
  commercialProfileUpdated: 'Komercijalni profil uređen',
  commercialProfileArchived: 'Komercijalni profil arhiviran',
  commercialProfileRestored: 'Komercijalni profil vraćen',
  profileType: 'Tip profila',
  relatedProducts: 'Povezani proizvodi',
  vendor: 'Dobavljač',
  vendors: 'Dobavljači',
  manufacturers: 'Proizviđači',
  brand: 'Robna marka',
  brands: 'Robne marke',
  page: 'Page',
  productToPage: 'Proizvod dodan na stranicu',
  addToEcommerce: 'Dodaj u e-commerce',
  addedToEcommerce: 'Staavke dodane u e-commerce',
  removeFromStore: 'Ukloni sa trgovine',
  removedFromStore: 'Stavke su uklonjene sa trgovine',
  forRegOnly: 'Samo za registrirane korisnike',
  applyCartRules: 'Primijeni pravila kolica',
  couponCodeNum: 'Broj kupon kodova',
  manageReferrals: 'Upravljaj referalima',
  manageCouponCodes: 'Upravljak kupon kodovima',
  noTimeLimit: 'Bez vremenskog ograničenja',
  spanInDays: 'Trajanje u danima',
  timeInterval: 'Vremenski interval',
  noCouponCodes: 'Bez kodova (koristi samo referale)',
  singleCouponCode: 'Jedan kod',
  multipleCouponCodes: 'Više kodova',
  couponSetCreated: 'Kupon set stvoren',
  couponSetUpdated: 'Kupon set uređen',
  couponSetArchived: 'Kupon set arhiviran',
  couponSetRestored: 'Kupon set vraćen',
  couponCodes: 'Kupon kodovi',
  mustSaveProfile: 'Da bi ste upravljali kupon kodovima komercijalni profil mora biti pohranjen. Pohraniti trenutni komercijalni profil?',
  codePrefix: 'Code prefix',
  codeSuffix: 'Code sufix',
  dashEveryXDigits: 'Dash every x digits',
  noOfCodes: 'Number of codes',
  usesPerReferralLink: 'User per referral link',
  usesPerUser: 'Uses per user',
  noOfProductUnits: 'Number of product units',
  combineCoupons: 'Combine coupons',
  forRegisterUsers: 'For registered users',
  validFrom: 'Vrijedi od',
  expiryDate: 'Datum isteka',
  couponsGenerated: 'Kuponi stvoreni',
  couponCode: 'Kupon kod',
  couponEdited: 'Kupon uređen',
  specialPrice: 'Aktivna posebna cijena',
  currentSpecialPrice: 'Trenutna posebna cijena',
  specialPrices: 'Posebne cijene',
  specialPriceCreate: 'Stvori posebnu cijenu',
  specialPriceEdit: 'Uredi posebnu cijenu',
  specialPriceCreated: 'Posebna cijena je stvorena',
  specialPriceUpdated: 'Posebna cijena je uređena',
  manageSpecialPrices: 'Upravljaj posebnim cijenama',
  specialPriceArchived: 'Posebna cijena arhivirana',
  specialPriceRestored: 'Posebna cijena vraćena',
  timePeriod: 'Vremenski period',

  //E-learning

  newCourse: 'Novi tečaj',
  editCourse: 'Uredi tečaj',
  virtualClassroom: 'Virtualna učionica',
  virtualClassrooms: 'Virtualne učionice',
  educator: 'Predavač',
  educators: 'Predavači',
  findEducator: 'Pronađi predavača',
  findEducators: 'Pronađi predavače',
  educatorsOnCourse: 'Predavači na tečaju',
  student: 'Polaznik',
  students: 'Polaznici',
  findStudent: 'Pronađi polaznika',
  findStudents: 'Pronađi polaznike',
  studentsOnCourse: 'Polaznici na tečaju',
  addParticipant: 'Dodaj polaznika',
  addParticipants: 'Dodaj polaznike',
  upload: 'Učitaj',
  assignee: 'Dodijeljena osoba',
  assignees: 'Dodijeljene osobe',
  participant: 'Sudionik',
  classroom: 'Učionica',
  repeat: 'Ponavljanje',
  courseCreated: 'Tečaj je kreiran',
  courseUpdated: 'Tečaj je uređen',
  courseArchived: 'Tečaj je arhiviran',
  courseRestored: 'Tečaj je vraćen',
  participantsUpdated: 'Sudionici su uređeni',
  moderator: 'Moderator',
  moderators: 'Moderatori',
  moderatorsOnCourse: 'Moderatori na tečaju',
  moderatorsUpdated: 'Moderatori su uređeni',
  addNewClass: 'Nova lekcija',
  editClass: 'Uredi lekciju',
  liveStreaming: 'Prijenos uživo',
  subscriptions: 'Pretplate',
  classCreated: 'Lekcija kreirana',
  classUpdated: 'Lekcija uređena',
  classArchived: 'Lekcija arhivirana',
  classRestored: 'Lekcija vraćena',
  projectLeader: 'Nositelj projekta',
  commentCreated: 'Komentar kreiran',
  commentUpdated: 'Komentar uređen',
  commentDeleted: 'Komentar obrisan',
  createDiscussion: 'Kreiraj diskusiju',
  editDiscussion: 'Uredi diskusiju',
  discussionCreated: 'Diskusija kreirana',
  discussionUpdated: 'Diskusija uređena',
  discussionArchived: 'Diskusija arhivirana',
  discussionRestored: 'Diskusija vraćena',
  createClassroom: 'Nova učionica',
  editClassroom: 'Uredi učionicu',
  classroomCreated: 'Učionica kreirana',
  classroomUpdated: 'Učionica uređena',
  classroomArchived: 'Učionica arhivirana',
  classroomRestored: 'Učionica vraćena',
  passingPoints: 'Bodovi za prolaz',
  enableTimeLimit: 'Test na vrijeme',
  examName: 'Naziv ispita',
  publishExam: 'Objavi ispit',
  addQuestion: 'Dodaj pitanje',
  examCreated: 'Ispit kreiran',
  examUpdated: 'Ispit editiran',
  examDeleted: 'Ispit obrisan',
  examRestored: 'Ispit vraćen',
  timeLimit: 'Vremensko ograničenje',
  newExam: 'Novi ispit',
  saveExam: 'Spremi ispit',
  examType: 'Vrsta ispita',
  editExam: 'Uredi ispit',
  previewExam: 'Pregled ispita',
  submitExam: 'Predaj ispit',
  resetExam: 'Resetiraj ispit',
  examSubmitted: 'Ispit predan',
  examReset: 'Ispit je vraćen na početno stanje',
  allowContinue: 'Dozvoli nastavljanje',
  disqualify: 'Diskvalificiraj',
  question: 'Pitanje',
  questionType: 'Tip pitanja',
  answer: 'Odgovor',
  addAnswer: 'Dodaj odgovor',
  points: 'Bodovi',
  penalty: 'Negativni bodovi',
  typeAnswer: 'Unesi odgovor',
  closePreview: 'Zatvori pregled',
  result: 'Rezultat',
  singleTypeText: 'Jednostruki odgovor',
  multiTypeText: 'Višestruki odgovor',
  boolTypeText: 'Točno/netočno',
  gapsTypeText: 'Popuni praznine',
  matchTypeText: 'Spoji parove',
  essayTypeText: 'Esejsko pitanje',
  shortTypeText: 'Kratak odgovor',
  passed: 'Prošli',
  failed: 'Pali',
  takeExam: 'Riješi ispit',
  continueExam: 'Nastavi ispit',
  viewResults: 'Pregledaj rezultate',
  timeLeft: 'Preostalo vrijeme',
  gradeExam: 'Ocijeni ispit',
  gradeExams: 'Ocijeni ispite',
  examGraded: 'Ispit ocijenjen',
  examsGraded: 'Ispiti ocijenjeni',
  viewExam: 'Pregledaj ispit',
  pointsUpdated: 'Bodovi ažurirani',

  //Project

  projectCreated: 'Projekt kreiran',
  projectUpdated: 'Projekt uređen',
  projectArchived: 'Projekt arhiviran',
  projectRestored: 'Projekt vraćen',
  newProject: 'Novi projekt',
  editProject: 'Uredi projekt',
  firstTask: 'Kreiraj prvi zadatak',
  estimationUnit: 'Jedinica procjene',
  estimationUnits: 'Jedinice procjene',
  estimated1: 'Kol. jed. proc.',
  unitPrice1: 'Cijena jedinice',
  total1: 'Ukupno',
  observers: 'Promatrači',
  notBillable: 'Nenaplativo',
  newTask: 'Novi zadatak',
  editTask: 'Uredi zadatak',
  taskCreated: 'Zadatak kreiran',
  taskUpdated: 'Zadatak uređen',
  taskArchived: 'Zadatak arhiviran',
  taskRestored: 'Zadatak vraćen',
  employees: 'Zaposlenici',
  clients: 'Klijenti',
  pleaseSelectEstimatedBy: 'Molim odaberite jedinicu procjene',
  subscribersUpdated: 'Pretplatnici uređen',
  addSubTask: 'Dodaj podzadatak',
  subTask: 'Podzadatak',
  subTasks: 'Podzadatci',
  showCompleted: 'Prikaži završene',
  assignedTo: 'Dodjeljeno na',
  timeAndExpenses: 'Vrijeme i troškovi',
  logCreated: 'Upis kreiran',
  logUpdated: 'Upis uređen',
  logDeleted: 'Upis obrisan',
  logTime: 'Upiši vrijeme',
  logActivity: 'Upiši aktivnost',
  logExpense: 'Upiši trošak',
  thisWeek: 'Ovaj tjedan',
  previousMonth: 'Ovaj mjesec',
  loggedTime: 'Upisano vrijeme',
  loggedExpense: 'Upisani troškovi',
  loggedActivity: 'Upisane aktivnosti',
  log: 'Upiši',
  time: 'Vrijeme',
  totalInHours: 'Ukupno u satima',
  hourPrice: 'Cijena po satu',
  estimatedInHours: 'Procjena po satima',
  cantUpdateKanban: 'Nemate ovlasti za uređivanje kanbana',
  sortByLabel: 'Sortiraj po oznaci',
  sortByMilestone: 'Sortiraj po milestone',
  refreshTasks: 'Osvježi zadatke',
  logTypes: 'Tipovi upisa',
  timeFrameFrom: 'Vrijeme od',
  timeFrameTo: 'Vrijeme do',
  groupBy: 'Grupiraj',
  loggedBy: 'Logirano po',
  responsiblePerson: 'Odgovorna osoba',
  exportProject: 'Izvoz podataka o projektu',
  importProject: 'Uvezi projekt',
  noTasksSelected: 'Nije odabran nijedan zadatak',
  noDiscussionsSelected: 'Nije odabrana nijedna diskusija',
  nothingSelected: 'Ništa nije odabrano',
  importProjectCategories: 'Uvezi kategorije projekta',
  importProjectLabels: 'Uvezi oznake projekta',
  importTaskCategories: 'Uvezi kategorije zadataka',
  importTaskLabels: 'Uvezi oznake zadataka',
  importDiscussionCategories: 'Uvezi kategorije diskusija',
  importDiscussionLabels: 'Uvezi oznake diskusija',
  internalProject: 'Interni projekt',
  projectImported: 'Projekt uvezen',

  //Warehouse

  warehouseCreated: 'Skladište kreirano',
  warehouseUpdated: 'Skladište uređeno',
  warehouseDeleted: 'Skladište obrisano',
  warehouseArchived: 'Skladište arhivirano',
  warehouseRestored: 'Skladište vraćeno',
  newWarehouse: 'Novo skladište',
  editWarehouse: 'Uredi skladište',
  binCreated: 'Polica kreirana',
  binUpdated: 'Polica uređena',
  binDeleted: 'Polica obrisana',
  binRestored: 'Polica vraćena',
  newBin: 'Nova polica',
  editBin: 'Uredi policu',
  assetCreated: 'Imovina kreirana',
  assetUpdated: 'Imovina uređena',
  assetDeleted: 'Imovina obrisana',
  assetRestored: 'Imovina vraćena',
  newAsset: 'Nova imovina',
  editAsset: 'Uredi imovinu',
  warehouseList: 'Lista skladišta',
  stockValuationMethod: 'Metoda vrednovanja zalihe',
  dispatchOrderType: 'Redoslijed izdavanja',
  workers: 'Djelatnici',
  parentBranch: 'Nadređena poslovnica',
  linkedBranches: 'Povezane poslovnice',
  linkedWarehouses: 'Povezana skladišta',
  stockMovement: 'Kretanje zaliha',
  transferSchedule: 'Raspored transfera',
  maxLoad: 'Max nosivost (kg)',
  semiProducts: 'Poluproizvodi',
  mixed: 'Mješano',
  warrantyDue: 'Istek jamstva',
  long: 'Dugoročno',
  short: 'Kratkoročno',
  belowMin: 'Ispod min',
  aboveMax: 'Iznad max',
  minimumStock: 'Minimalna količina',
  maximumStock: 'Maksimalna količina',
  uom: 'UOM',
  qty: 'Kol',
  totalQty: 'Ukupna Kol',
  reservedQty: 'Rezervirana Kol',
  availableQty: 'Dostupna Kol',
  issuedQty: 'Izdana Kol',
  receivedQty: 'Zaprimljena Kol',
  receivegoods: 'Zaprimi robu',
  viewbins: 'Pogledaj police',
  dispatch: 'Izlaz',
  'write-off': 'Otpis',
  notAvailable: 'Zaduženo',
  warranty: 'Jamstvo',
  assignTo: 'Zaduži na',
  assign: 'Zaduži',
  return: 'Razduži',
  selectUser: 'odaberi korisnika',
  assetAssignedMsg: 'Imovina zadužena na ',
  assetReturnedMsg: 'Imovina razdužena',
  assetAlreadyAssigned: 'Ova imovina je već zadužena.',
  internalCode: 'Interni kod',
  itemCode: 'Kod',
  autoGenerateSku: 'Automatski generiraj SKU',

  //Reports

  advancedProjectSearchAndFiltering: 'Napredno pretraživanje i filtriranje projekata',
  advancedDocumentSearchAndFiltering: 'Napredno pretraživanje i filtriranje dokumenata',
  lastMonth: 'Prošli mjesec',
  lastWeek: 'Prošli tjedan',
  totalTime: 'Ukupno vrijeme',
  issuedOn: 'Datum izdavanja',
  dueDate: 'Datum dospijeća',
  projectGeneralReport: 'Projekti opći izvještaji',
  projectLoggedTimeReport: 'Upisano vrijeme',
  outgoingInvoiceReport: 'Izlazni računi izvještaj',
  inboundInvoiceReport: 'Ulazni računi izvještaj',
  outgoingOrderReport: 'Izlazne ponude izvještaj',
  contractReport: 'Ugovori izvještaj',
  contracts: 'Ugovori',
  loggedTimeReport: 'Upisano vrijeme izvještaj',
  issuedFromBranch: 'Poslovnica izdavanja',
  issuedFromDevice: 'Blagajna izavanja',
  issuedOnStartDate: 'Datum izdavanja od',
  issuedOnEndDate: 'Datum izdavanja do',
  dueDateFrom: 'Datum dospijeća od',
  dueDateTo: 'Datum dospijeća do',
  documentLabel: 'Oznaka dokumenta',
  validUntilFrom: 'Rok valjanosti od',
  validUntilTo: 'Rok valjanosti do',
  validUntil: 'Rok valjanosti',
  startDateFrom: 'Datum početka od',
  startDateTo: 'Datum početka do',
  endDateFrom: 'Datum završetka od',
  endDateTo: 'Datum završetka do',
  estimatedBudgetFrom: 'Estimirani budžet od',
  estimatedBudgetTo: 'Estimirani budžet do',
  when: 'Kada je',
  changed: 'Uređen',
  assigned: 'dodijeljen',
  removed: 'uklonjen',
  commented: 'komentiran',
  newAssignees: 'dodana nova dodijeljena osoba',
  newParticipants: 'dodan novi sudionik',
  newModerators: 'dodani su novi moderatori',
  assignedModerator: 'dodijeljen je moderator',
  removedModerator: 'moderator je uklonjen',
  newObservers: 'dodani su novi promatrači',
  assignedObserver: 'dodan je novi promatrač',
  removedObserver: 'promatrač je uklonjen',

  assignedModerators: 'Moderatori su dodjeljeni',
  removedModerators: 'Moderatori su uklonjeni',
  removedObservers: 'Promatrači su uklonjeni',
  assignedSubscribers: 'dodijeljena osoba dodana',
  removedSubscribers: 'dodijeljena osoba maknuta',
  removedParticipants: 'sudionik uklonjen',
  removedAssignees: ' uklonjena dodijeljena osoba',

  outgoingoffer: 'Izlazna ponuda',
  outgoinginquiry: 'Izlazni upit',
  outgoinginvoice: 'Izlazni račun',
  outgoingorder: 'Izlazna ponuda',
  outgoingletter: 'Izlazni dopis',
  inboundoffer: 'Ulazna ponuda',
  inboundinvoice: 'Ulazni račun',
  inboundinquiry: 'Ulazni upit',
  inboundorder: 'Ulazna narudžba',
  inboundletter: 'Ulazni dopis',
  internaloffer: 'Interna ponuda',
  internalinquiry: 'Interni upit',
  internalinvoice: 'Interni račun',
  internalorder: 'Interna narudžbenica',
  internalletter: 'Interni dopis',
  portfolioReport: 'Izvještaj portfelja',
  projectStatusReport: 'Izvještaj - status projekta',
  employeeWorkload: 'Izvještaj – Radna opterećenost zaposlenika',
  timeExpensesReport: 'Izvješće o vremenu i troškovima',

  //gdpr

  newConsent: 'Nova privola',
  editConsent: 'Uredi privolu',
  providerName: 'Naziv pružatelja',
  consentName: 'Naziv privole',
  source: 'Izvor',
  setBy: 'Postavio',
  displayName1: 'Tekst prikaza',
  purposesText: 'Tekst svrhe',
  uniqueNumber: 'Poseban broj',
  consentCreated: 'Privola stvorena',
  consentEdited: 'Privola uređena',
  newAgreement: 'Novi sporazum',
  editAgreement: 'Uredi sporazum',
  agreementName: 'Naziv sporazuma',
  agreementEdited: 'Sporazum uređen',
  consentLogs: 'Unosi privola',
  newConsentLog: 'Novi unos privole',
  editConsentLog: 'Uredi unos privole',
  logDate: 'Datum unosa',
  consentGiver: 'Davatelj privole',
  files: 'Datoteke',

  //Documents

  offer: 'Ponuda',
  order: 'Narudžbenica',
  contract: 'Ugovor',
  assethandover: 'Obrazac za zaduženje imovine',
  assetreturn: 'Obrazac za razduženje imovine',
  warehousetransfer: 'Međuskladišnica',
  'internal-travelwarrant': 'Putni nalog',
  //TRAVEL WARRANT
  updatetw: 'Uredi',
  vehicle: 'Vozilo',
  selectvehicle: '- Odaberi vozilo -',
  paymentinadvance: 'Predujam',
  selectemployee: '- Odaberi zaposlenika -',
  enterpaymentinadvance: 'Unos predujma...',
  locations: 'Lokacije',
  selectlocations: 'Unesi lokacije...',
  traveltask: 'Razlog putovanja',
  entertraveltask: 'Unesi razlog putovanja...',
  travelwarrant: 'Putni nalog',
  documentnumber: 'Broj dokumenta',
  selectissuerperson: 'Odaberi izdavatelja',
  dailyallowanceexpense: 'Obračun dnevnica',
  removesection: 'Ukloni sekciju',
  calcstart: 'Početak obračuna',
  calcend: 'Završetak obračuna',
  hourstotal: 'Ukupno sati',
  allowancetotal: 'Ukupno dnevnica',
  allowanceamount: 'Iznos dnevnice',
  countrynotselected: 'Zemlja nije odabrana',
  exchangerate: 'Tečaj',
  totalSum: 'Ukupno',
  addallowance: 'Dodaj dnevnicu',
  totalallowance: 'Dnevnice ukupno',
  travelexpense: 'Obračun putnih troškova',
  relationfrom: 'Relacija od',
  relationto: 'Relacija do',
  transporttype: 'Vrsta prijevoznog sredstva',
  selecttransporttype: '- Odaberi tip prijevoza -',
  startingkms: 'Početno stanje kilometar sata (km)',
  endkms: 'Završno stanje kilometar sata (km)',
  distance: 'Udaljenost',
  addtransportexpense: 'Dodaj prijevozni trošak',
  totaltransport: 'Prijevoz ukupno',
  otherexpense: 'Ostali troškovi',
  expensedescription: 'Opis troška',
  expese: 'Trošak',
  addother: 'Dodaj ostalo',
  totalother: 'Ostalo ukupno',
  finalizedate: 'Datum zaključivanja',
  totaltravelexpense: 'Ukupni putni trošak',
  payedinadvance: 'Isplačeni predujam',
  forpayment: 'Za isplatu',
  manageallowances: 'Uredi dnevnice država',
  occupationplaceholder: 'Unos ili odabrat zaposlenika',
  fromplaceholder: 'Od...',
  toplaceholder: 'Do...',
  milagestartplaceholder: 'Početna kilometraža...',
  milageendplaceholder: 'Završna kilometraža...',
  distanceplaceholder: 'Udaljenost...',
  selectcountry: '- Odaberi državu -',
  vehicleregistrationplaceholder: 'Registracija vozila',
  transporttypeplaceholder: 'Tip prijevoza',


  detailsBalance: 'Saldo',
  invoicetype: 'Tip računa',
  paymenttype: 'Tip plaćanja',
  travelWarrantCreate: 'Izradi iz putnog naloga',
  paymentterm: 'Uvjeti plaćanja',
  validuntil: 'Rok valjanosti',
  branch: 'Poslovnica',
  selectbranch: 'odaberi poslovnicu',
  selectinvtype: 'odaberi tip računa',
  selectptype: 'odaberi tip plaćanja',
  selectpterm: 'odaberi uvjet plaćanja',
  selectincoterms: 'odaberi incoterms',
  entercompany: 'Unesi tvrtku',
  entercontact: 'Unesi kontakt',
  inquirynumber: 'Broj upita',
  contactperson: 'Kontakt osoba',
  itemdetails: 'Detalji stavke',
  deliverydetails: 'Detalji isporuke',
  selectcontact: 'Odaberi kontakt',
  deliverynotes: 'Otpremnica',
  saleswarrant: 'Prodajni nalog',
  devices: 'Blagajne',
  selectdevices: 'odaberi blagajnu',
  nodevices: 'Nema blagajni',
  referentdocument: 'Referentni dokument',
  linkeddocument: 'Vezani dokument',
  offernumber: 'Broj ponude',
  warehousereceivingassets: 'Skladište primatelj imovinu',
  personreceivingassets: 'Osoba primatelj imovine',
  personreturningassets: 'Osoba izdavatelj imovine',
  issuedby: 'Izdavatelj',
  issuedbranch: 'Odjel izdavatelj',
  issuerwarehouse: 'Skladište izdavatelj',
  issuerbranch: 'Poslovnica izdavatelj',
  issuerperson: 'Osoba izdavatelj',
  deliverycompany: 'Tvrtka koja dostavlja',
  deliveryperson: 'Osoba koja dostavlja',
  deliveryvehiclereg: 'Registracijske oznake vozila',
  receivedon: 'Vrijeme zaprimanja',
  receiverwarehouse: 'Skladište zaprimatelj',
  receiverperson: 'Osoba zaprimatelj',
  itemtype: 'Vrsta stavke',
  receptiontobins: 'Zaprimanje na police',
  //DOCUMENTS LIST
  issuedfrom: 'Izdavatelj',
  issuedto: 'Primatelj',
  issuedon: 'Datum izdavanja',
  duedate: 'Datum dospjeća',
  grandtotal: 'Ukupan iznos',
  exporttopdf: 'Izradi PDF',
  receive: 'Zaprimi',
  //ITEMS
  travelwarrantdetails: 'Detalji putnog naloga',
  unit: 'Jedinica',
  deliverydate: 'Datum isporuke',
  unitprice: 'Jedinična cijena',
  discount: 'Popust',
  tax: 'Porez',
  netprice: 'Neto cijena',
  nettotal: 'Neto ukupna',
  itemsnettotal: 'Ukupan neto iznos',
  pretaxtotal: 'Ukupno (bez PDV-a)',
  taxrate: 'Por. stopa',
  taxbase: 'Por. osn.',
  taxamount: 'Iznos poreza',
  referencenumber: 'Referentni broj',
  //NOTE
  note: 'Bilješka',
  writepublicnote: 'Unesi bilješku',
  notewarn: 'Ova bilješka neće bit prikazivana klijentu niti uključena u dokumentu',
  choose: 'Odaberi',
  fromtemplate: 'iz predloške',
  internalnote: 'Interna bilješka',
  selectlabel: 'odaberi oznaku',
  contacttitle: 'Naziv ugovora',
  contractnetamount: 'Neto iznos ugovora',
  contractgrandtotal: 'Ukupni ugovoreni iznos',
  //Missing
  'asset-handover': 'Obrazac za zaduženje imovine',
  'asset-return': 'Obrazac za razduženje imovine',
  issuenote: 'Izdatnica',
  goodsreceipt: 'Primka',
  'warehouse-transfer': 'Međuskladišnica',
  //ASSETS
  warrantydue: 'Garancija do',
  serialnumber: 'Serijski broj',
  assetnumber: 'Broj imovine',
  assetname: 'Naziv imovine',
  // WAREHOUSE RECIEPT
  warehousereceipt: 'Primka',
  selectwarehouse: 'odaberi skladište',
  selectsupplier: 'Odaberi dobavljača',
  selectorder: 'Odaberi narudžbenicu',
  deliverynotenumber: 'Broj otpremnice',
  entersupplier: 'Unesi dobavljača',
  recieve: 'Zaprimi',
  ordernumber: 'Broj narudžbenice',
  orderitem: 'Ord. item',
  orderqty: 'Kol. nar.',
  avaliableqty: 'Dostupna kol.',
  recieveqty: 'Zaprimljena kol.',
  selectbin: 'odaberi policu',
  addbin: 'Dodoj',
  warehouse: 'Skladište',
  department: 'Katedra',
  nocontact: 'Nema kontakata za odabranu poslovnicu',
  requisitionwarrant: 'Zahtjev za nabavu',
  requisitionwarrantslip: 'Izdatnica',
  warehousereciept: 'Primka',
  receivedby: 'Zaprimio',
  deliverydue: 'Delivery due',
  purpose: 'Purpose',
  reqwarquantity: 'Req.war.qty',
  issuedqty: 'Issued qty',
  selectwarrant: 'Select warrant',
  //LETTER
  lettertitle: 'Naslov dopisa',
  lettercontents: 'Sadržaj dopisa',
  selectdepartment: 'odaberi odjel',
  selectissuerbranch: 'Odaberi poslovnicu izdavanja',
  selectissuerwarehouse: 'Odaberi skladište izdavanja',
  selectreciever: 'Odaberi primatelja',
  selectrecievertype: 'Odaberi tip primatelja',
  selectrecieverbranch: 'Odaberi poslovnicu primatelja',
  selectrecieverwarehouse: 'Odaberi skladište primatelja',
  selectrecieverdepartment: 'Odaberi odjel primatelja',
  goodsrecievedby: 'Zaprima',
  goodsissuedby: 'Izdaje',
  purchaserequisition: 'Purchase requisition',
  deliverynote: 'Otpremnica',
  onstock: 'Na stanju',
  storno: 'Storniraj',
  createstorno: 'Kreiraj storno',
  shippingmethod: 'Dostavna metoda',
  legacydocument: 'Legacy dokument',
  draft: 'Nacrt',
  documentTitle: 'Naziv dokumenta',
  selectcategory: 'Odaberi kategoriju',
  relatedAssets: 'Vezana imovina',

  //MailboxTranslation

  compose: 'Novi e-mail',

  //VALIDATIONS
  DigitError: 'Mora sadržavat barem jedan broj',
  UppercaseError: 'Mora sadržavat barem jedno veliko slovo',
  LowercaseError: 'Mora sadržavat barem jedno malo slovo',
  SpecCharError: 'Mora sadržavat barem jedan specijalni znak',
  LengthError: 'Mora sadržavat barem 8 znakova',

  //ECommerce

  paymentTerms: 'Uvjeti plaćanja',
  deliveryTerms: 'Uvjeti dostave',
  paymentTermsUpdated: 'Uvjeti plaćanja ažurirani!',
  deliveryTermsUpdated: 'Uvjeti dostave ažurirani!',
  generalSettingsUpdated: 'Opće postavke ažurirane!',
  storeName: 'Naziv trgovine',
  addStore: 'Dodaj trgovinu',
  editStore: 'Uredi trgovinu',
  isDefault: 'Postavi kao zadanu trgovinu',
  manageWebsites: 'Upravljaj website-ovima',
  websiteName: 'Ime website-a',
  contactEmail: 'Kontaktni e-mail',
  contactPhone: 'Kontaktni telefon',
  paymentMethods: 'Načini plaćanja',
  deliveryMethods: 'Načini dostave',
  exitEcommerce: 'Napusti Ecommerce',
  changeLabel: 'Promijeni oznaku',
  addProducts: 'Dodaj proizvode',
  productsAdded: 'Proizvodi dodani',

  //Referrals
  referral: 'Referral',
  referrals: 'Referali',
  addReferrals: 'Dodaj referale',
  referralsCreated: 'Referali stvoreni',

  //cmc

  editPage: 'Uređivanje stranice',
  newPage: 'Dodavanje stranice',
  enterTitle: 'Ovdje unesite naslov',
  chooseLanguage: 'Izaberite jezik',
  saveDraft: 'Spremiti nacrt',  // TOMO
  preview: 'Pregled',
  published: 'Objavljeno',
  publishImmediately: 'Objavi',
  toTrash: 'Baci u smeće',
  pageAttributes: 'Atributi stranice',
  parent: 'Roditelj',
  noParent: '(nema roditelja)',
  template: 'Predložak',
  defaultTemplate: 'Zadani predložak',  // TOMO
  mainTitle: 'Glavni naslov',
  enterMainTitle: 'Unesite glavni naslov',
  commentsOn: 'Uključeno',
  commentsOff: 'Isključeno',
  pageSaved: 'Stranica spremljena',
  changeLanguageSure: 'Jeste li sigurni da želite promijeniti jezik? Svi podaci o stranici povezani s tim jezikom, a da nisu spremljeni biti će izgubljeni!',
  deletePage: 'Briši',
  archivePageSure: 'Jeste li sigurni da želite arhivirati stranicu?',
  posts: 'Postovi',
  addCategories: 'Dodaj kategorije',
  languages: 'Jezici',
  addLanguage: 'Dodaj jezik',
  categorySaved: 'Kategorija spremljena',
  newTag: 'Dodavanje taga',
  editTag: 'Uređivanje taga',
  deleteTranslationSure: 'Jeste li sigurni da želite obrisati ovaj prijevod?',
  deleteTagSure: 'Jeste li sigurni da želite obrisati ovaj tag?',
  archivePostSure: 'Jeste li sigurni da želite arhivirati ovaj post?',
  deleteCategorySure: 'Jeste li sigurni da želite obrisati ovu kategoriju?',
  pageArchived: 'Stranica arhivirana',
  postArchived: 'Post arhiviran',
  pagePublished: 'Stranica objavljena',
  postPublished: 'Post objavljen',
  pageUnpublished: 'Odjavi',  // TOMO
  postUnpublished: 'Objava nije objavljena',  // TOMO
  categoryDeleted: 'Kategorija obrisana',
  tagDeleted: 'Tag obrisan',
  translationDeleted: 'Prijevod obrisan',
  addTags: 'Dodaj tagove',
  tagUpdated: 'Tag ažuriran',
  editPost: 'Uredi post',
  newPost: 'Novi post',
  postSaved: 'Post spremljen',
  tagSaved: 'Tag spremljen',
  postUpdated: 'Post ažuriran',
  pageUpdated: 'Stranica ažurirana',
  translation: 'Prijevod',
  pageInfo: 'Informacije o stranici',
  postInfo: 'Informacije o postu',
  categoryInfo: 'Informacije o kategoriji',
  tagInfo: 'Informacije o tagu',
  navigationSaved: 'Navigacija spremljena',
  linksAdded: 'Poveznice dodane na navigaciju',
  navigationOrderSaved: 'Spremljen poredak navigacije',
  linkDeleted: 'Poveznica obrisana',
  deleteLinkSure: 'Jeste li sigurni da želite obrisati poveznicu i sve njezine podpoveznice?',
  affectsAllLanguages: 'Promjene će utjecati na sve jezike',
  willLostChanges: 'Sve promjene koje nisu spremljene biti će izgubljene. Jeste li sigurni da želite nastaviti?',
  deleteNavigationBranchSure: 'Jeste li sigurni da želite ukloniti poveznicu iz navigacije?',
  homePage: 'Početna stranica',
  optionsSaved: 'Opcije spremljene',
  emailsToSend: 'Emailovi za kontakt formu',
  emailsForGdpr: 'Emailovi za zaštitu podataka',
  chooseDomain: 'Izaberite domenu',
  chooseProduct: 'Izaberite proizvod',
  noProductChosen: 'Nema',
  exitCms: 'Napusti CMS',
  chooseNavigation: 'Izaberi navigaciju',
  welcome: 'Dobro došli u Gauss Box CMS',
  welcomeEcommerce: 'Dobro došli u Gauss Box E-commerce',
  addDomainToAccess: 'Potrebno je dodati barem jednu domenu kako bi se pristupilo CMS-u',
  chooseType: 'Izaberite tip',
  chooseStatus: 'Izaberite status',
  enterSlug: 'Ovdje unesite slug',
  manageExternalLinks: 'Upravljanje eksternim poveznicama',
  externalLinkTitle: 'Naziv',
  backgroundColor: 'Boja pozadine',
  metaDescription: 'Meta opis',
  keywords: 'Ključne riječi',
  allowedLanguages: 'Dozvoljeni jezici',
  navigationType: 'Tip navigacije',
  addNavigation: 'Dodaj navigaciju',
  navigationAdded: 'Navigacija dodana',
  navigations: 'Navigacije',
  navigationDeleted: 'Navigacija uklonjena',
  productCategories: 'Kategorije proizvoda',
  externalLink: 'Vanjska poveznica',
  externalLinks: 'Vanjske poveznice',
  newNavigation: 'Nova navigacija',
  linklessItem: 'Stavka bez poveznice',
  soldProductsCount: 'Broj prodanih proizvoda',
  copyrightText: 'Copyright tekst',
  createRedirect: 'Kreiraj redirect',
  redirectCreated: 'Redirect kreiran',
  redirectUpdated: 'Redirect uređen',
  redirectDeleted: 'Redirect obrisan',
  newRedirect: 'Novi redirect',
  editRedirect: 'Uredi redirect',
  redirectFrom: 'Redirect sa',
  redirectTo: 'Redirect prema',
  statusCode: 'Status kod',
  code301: '301 Moved Permanently',
  code302: '302 Found',
  code307: '307 Temporary redirect',
  code410: '410 Content deleted',
  seo: 'SEO',
  robots: 'Robots',
  sitemap: 'Site map',
  robotsSaved: 'robots.txt saved',
  siteMapLastmod: 'Forsiraj lastmod na sve url-ove',
  siteMapHreflang: 'Dodaj prevedene postove u sitemap',
  siteMapImages: 'Dodaj slike u site map',
  siteMapGenerated: 'Site map generiran',
  changeFreq: 'Frekvencija promjene',
  hourly: 'Po satu',
  daily: 'Dnevno',
  weekly: 'Tjedno',
  monthly: 'Mjesečno',
  yearly: 'Godišnje',
  never: 'Nikada',
  postCount: 'Broj postova',
  seoFollow: 'Prati poveznice na ovoj stranici',
  seoIndex: 'Index post',
  canonicalPost: 'Kanonski post',
  canonicalPage: 'Kanonska stranica',
  trackingTools: 'Alati za praćenje',
  customHeader: 'Custom header',
  customFooter: 'Custom footer',
  googleAnalyticsIdentifier: 'Google analytics identifier',
  googleSearchConsoleIdentifier: 'Google search console identifier',
  googleTagManagerIdentifier: 'Google tag manager identifier',
  googleSearchPreview: 'Prikaz Google pretraživanja:',
  postSchedules: 'Vremenski rasporedi',
  createPostSchedule: 'Stvori vremenski raspored',
  postScheduleCreated: 'Vremenski raspored stvoren',
  scheduledDate: 'Datum primjene',
  manageStatusSchedule: 'Upravljaj rasporedom statusa',
  productPages: 'Stranice proizvoda',

  //Drive

  createDirectory: 'Stvori mapu',
  copy: 'Kopiraj',
  cut: 'Izreži',
  paste: 'Zalijepi',
  usedIn: 'Koristi se na',
  notInUse: 'Datoteka se ne koristi nigdje',
  size: 'Veličina',
  versionHistory: 'Povijest verzija',
  uploadNewVersion: 'Učitaj novu verziju',
  internalLink: 'Internal link',
  sharing: 'Dijeljenje',
  accessPermissions: 'Pristupne dozvole',
  accessPermissionsSaved: 'Pristupne dozvole spremljene',
  publicLink: 'Javni link',
  editItem: 'Uredi stavku',
  minorVersion: 'Minor verzija',
  majorVersion: 'Major verzija',
  versionUploadQuestion: 'Koju verziju želite učitati',
  moveTo: 'Premjesti u',
  copyTo: 'Kopiraj u',
  read: 'Pregled',
  readWrite: 'Pregled, Uređivanje',
  readWriteDelete: 'Pregled, Uređivanje, Brisanje',
  permissionLevel: 'Razina ovlasti',
  fileArchived: 'Datoteka arhivirana',
  fileRestored: 'Datoteka vraćena',
  uploadFromDisc: 'Učitaj sa diska',
  makeActive: 'Učini aktivnom verzijom',
  activeVersionUpdated: 'Aktivna verzija datoteke uređena',


  //pagination

  previous: 'Prethodna',
  next: 'Sljedeća',
  show: 'Prikaži',
  entries: 'rezultata',
  of: 'od',
  to: 'Do',

  //filter

  filters: 'Filteri',
  apply: 'Primjeni',
  industry: 'Industrija',
  inputtype: 'Tip polja',
  startdate: 'Datum početka',
  enddate: 'Datum završetka',
  createdat: 'Datum kreiranja',
  loggedby: 'Upisano od',
  flowtype: 'Tip toka',
  selectTwoDates: 'Odaberite dva datuma',
  removezerostock: 'Ukloni nultu zalihu',
  subjecttype: 'Tip subjekta',
  statustimestamp: 'Datum loga',

  //archive

  showUsers: 'Prikaži Korisnike',
  clientcompanies: 'Prikaži klijent tvrtke',

  //SearchTranslations

  beginSearch: 'Pretraži...',

  //forbidden

  forbidden: 'Zabranjeno',
  goback: 'Vrati se natrag',

  //internalServerError

  titleInternal: 'Interna greška na serveru',

  //serviceUnavailable

  title1: 'Interna greška na serveru',
  text1: 'Server trenutno nije u mogućnosti obraditi ovaj zahtjev. Molimo pokušajte ponovno',

  //labelModal

  statuses: 'Statusi',
  statusName: 'Ime Statusa',
  manageLabels: 'Upravljaj oznakama',
  labelNamePlaceholder: 'Ime oznake',

  //categoryModal

  manageCategories: 'Upravljaj kategorijama',
  categoryNamePlaceholder: 'Naziv kategorije',

  //occupationModal

  manageocupation: 'Upravljaj zanimanjima',
  occupationNamePlaceholder: 'Naziv zanimanja',

  //workGroupModal

  manageworkgroup: 'Upravljaj radnim grupama',
  workGroupNamePlaceholder: 'Naziv radne grupe',

  //participantModal

  educatorsOn: 'Predavači na tečaju',
  studentsOn: 'Polaznici na tečaju',
  employeesOn: 'Djelatnici na projektu',
  clientsOn: 'Klijenti na projektu',

  //groupModal

  manageGroups: 'Upravljaj grupama',
  groupNamePlaceholder: 'Ime grupe',

  //countries

  abw: 'Aruba',
  afg: 'Afganistan',
  ago: 'Angola',
  aia: 'Anguila',
  alb: 'Albanija',
  and: 'Andora',
  are: 'Ujedinjeni Arapski Emirati ',
  arg: 'Argentina',
  arm: 'Armenija',
  asm: 'Američka Samoa',
  ata: 'Antarktika',
  atf: 'Južni Francuski Teritoriji',
  atg: 'Antigua i Barbuda',
  aus: 'Australija',
  aut: 'Austrija',
  aze: 'Azerbajdžan',
  bdi: 'Burundi',
  bel: 'Belgija',
  ben: 'Benin',
  bes: 'Bonaire',
  bfa: 'Burkina Faso',
  bgd: 'Bangladeš',
  bgr: 'Bugarska',
  bhr: 'Bahrein',
  bhs: 'Bahami',
  bih: 'Bosna i Hercegovina',
  blm: 'Sveti Bartolomej',
  blr: 'Bjelorusija',
  blz: 'Belize',
  bmu: 'Bermuda',
  bol: 'Bolivija',
  bra: 'Brazila',
  brb: 'Barbados',
  brn: 'Bruneji',
  btn: 'Butan',
  bvt: 'Otok Bouvet',
  bwa: 'Bocvana',
  caf: 'Srednjoafrička Republika',
  can: 'Kanada',
  cck: 'Keeling Otoci',
  che: 'Švicarska',
  chl: 'Čile',
  chn: 'Kina',
  civ: 'Obala Bjelokosti',
  cmr: 'Kameron',
  cod: 'Demokratska Republika Kongo',
  cog: 'Kongo',
  cok: 'Cookovi Otoci',
  col: 'Kolumbija',
  com: 'Komori',
  cpv: 'Zelenortska Republika',
  cri: 'Kostarika',
  cub: 'Kuba',
  cuw: 'Curacao',
  cxr: 'Božićni Otoci',
  cym: 'Kajmanski Otoci',
  cyp: 'Cipar',
  cze: 'Češka',
  deu: 'Njemačka',
  dji: 'Džibuti',
  dma: 'Dominika',
  dnk: 'Danska',
  dom: 'Dominikanska Republika',
  dza: 'Alžir',
  ecu: 'Ekvador',
  egy: 'Egipat',
  eri: 'Eritreja',
  esh: 'Zapadna Sahara',
  esp: 'Španjolska',
  est: 'Estonija',
  eth: 'Etiopija',
  fin: 'Finska',
  fji: 'Fidži',
  flk: 'Falkandski Otoci',
  fra: 'Francuska',
  fro: 'Farski Otoci',
  fsm: 'Mikronezija',
  gab: 'Gabon',
  gbr: 'Ujedinjeno Kraljevstvo',
  geo: 'Gruzija',
  ggy: 'Guernsey',
  gha: 'Gana',
  gib: 'Gibraltar',
  gin: 'Gvineja',
  glp: 'Guadelupe',
  gmb: 'Gambija',
  gnb: 'Gvineja Bisau',
  gnq: 'Ekvatorijalna Gvineja',
  grc: 'Grčka',
  grd: 'Grenada',
  grl: 'Grenland',
  gtm: 'Guatemala',
  guf: 'Francuska Gvajana',
  gum: 'Guam',
  guy: 'Gvajana',
  hkg: 'Hong Kong',
  hmd: 'Otok Heard i McDonald Otoci',
  hnd: 'Honduras',
  hrv: 'Hrvatska',
  hti: 'Haiti',
  hun: 'Mađarska',
  idn: 'Indonezija',
  imn: 'Isle of Man',
  ind: 'Indija',
  iot: 'Britanski teritoriji U Indijskom oceanu',
  irl: 'Irska',
  irn: 'Iran',
  irq: 'Irak',
  isl: 'Island',
  isr: 'Izrael',
  ita: 'Italija',
  jam: 'Jamajka',
  jey: 'Jersey',
  jor: 'Jordan',
  jpn: 'Japan',
  kaz: 'Kazahstan',
  ken: 'Kenija',
  kgz: 'Krigistan',
  khm: 'Kambodža',
  kir: 'Kiribati',
  kna: 'Sveti Kitts i Nevis',
  kor: 'Rebublika Koreja',
  kwt: 'Kuvajt',
  lao: 'Laos',
  lbn: 'Libanon',
  lbr: 'Liberija',
  lby: 'Libija',
  lca: 'Sveta Lucija',
  lie: 'Lihtenštajn',
  lka: 'Šri Lanka',
  lso: 'Lesoto',
  ltu: 'Litva',
  lux: 'Luksmburg',
  lva: 'Latvija',
  mac: 'Makao',
  maf: 'Sveti Martin (fr.dio)',
  mar: 'Maroko',
  mco: 'Monako',
  mda: 'Moldavija',
  mdg: 'Madagaskar',
  mdv: 'Maldivi',
  mex: 'Meksiko',
  mhl: 'Maršalovi',
  mkd: 'Makedonija',
  mli: 'Mali',
  mlt: 'Malta',
  mmr: 'Mjanmar',
  mne: 'Crna Gora',
  mng: 'Mongolija',
  mnp: 'Sjeverni Marijanski Otoci',
  moz: 'Mozambik',
  mrt: 'Mauritanija',
  msr: 'Monteserat',
  mtq: 'Martinik',
  mus: 'Mauricius',
  mwi: 'Malavi',
  mys: 'Malezija',
  myt: 'Mayotte',
  nam: 'Namibija',
  ncl: 'Nova Kaledonija',
  ner: 'Niger',
  nfk: 'Norfolk',
  nga: 'Nigerija',
  nic: 'Nikaragva',
  niu: 'Niue',
  nld: 'Nizozemska',
  nor: 'Norveška',
  npl: 'Nepal',
  nru: 'Nauru',
  nzl: 'Novi Zeland',
  omn: 'Oman',
  pak: 'Pakistan',
  pan: 'Panama',
  pcn: 'Pitcairn',
  per: 'Peru',
  phl: 'Filipini',
  plw: 'Palau',
  png: 'Papua Nova Gvineja',
  pol: 'Poljska',
  pri: 'Portoriko',
  prk: 'DNR Koreja',
  prt: 'Portugal',
  pry: 'Paragvaj',
  pse: 'Palestina',
  pyf: 'Francuska Polinezija',
  qat: 'Katar',
  reu: 'Reunion',
  rou: 'Rumunjska',
  rus: 'Ruska Federacija',
  rwa: 'Ruanda',
  sau: 'Saudijska Arabija',
  sdn: 'Sudan',
  sen: 'Senegal',
  sgp: 'Singapur',
  sgs: 'Južna Georgia i južni Sandwich Otoci',
  shn: 'Sveta Helena',
  sjm: 'Svalvard i Jan Mayen',
  slb: 'Salomoski Otoci',
  sle: 'Siera Leone',
  slv: 'Salvador',
  smr: 'San Marino',
  som: 'Somalija',
  spm: 'Sveti Petar i Miquelon',
  srb: 'Srbija',
  ssd: 'Južni Sudan',
  stp: 'Sveti Toma i Princip',
  sur: 'Surinam',
  svk: 'Slovačka',
  svn: 'Slovenija',
  swe: 'Švedska',
  swz: 'Swaziland',
  sxm: 'Sveti Martin (niz.dio)',
  syc: 'Sejšeli',
  syr: 'Sirija',
  tca: 'Otoci Turks i Caicos',
  tcd: 'Čad',
  tgo: 'Togo',
  tha: 'Tajland',
  tjk: 'Tadžikistan',
  tkl: 'Tokelau',
  tkm: 'Turkmenistan',
  tls: 'Istočni Timor',
  ton: 'Tonga',
  tto: 'Trinidad i Tobago',
  tun: 'Tunis',
  tur: 'Turska',
  tuv: 'Tuvalu',
  twn: 'Tajvan',
  tza: 'Tanaznija',
  uga: 'Uganda',
  ukr: 'Ukrajina',
  ury: 'Urugvaj',
  usa: 'Sjedinjenje Američke Države',
  uzb: 'Uzbekistan',
  vat: 'Vatikan',
  vct: 'Sveti Vincent i Grenadini',
  ven: 'Venezuela',
  vgb: 'Britanski Djevičanski Otoci',
  vir: 'Američki Djevičanski Otoci',
  vnm: 'Vietnam',
  vut: 'Vanuatu',
  wlf: 'Wallis i Futuna',
  wsm: 'Samoa',
  yem: 'Jemen',
  zaf: 'Južna Afrika',
  zmb: 'Zambija',
  zwe: 'Zimbabve',

  //industryTypes

  banking: 'Bankarstvo',
  construction: 'Građevina',
  consulting: 'Konzalting',
  delivery: 'Dostava',
  education: 'Edukacija',
  entertainment: 'Zabava',
  finance: 'Financijski sektor',
  government: 'Državna služba',
  it: 'Informacijske tehnologije',
  manufacturing: 'Proizvodnja',
  nonprofit: 'Neprofitni sektor',
  sales: 'Prodajne djelatnosti',
  telecommunication: 'Telekomunikacije',

  //contactTypes

  'e-mail': 'Email',

  //addressTypes

  billing: 'Adresa za dostavu računa',
  shipping: 'Adresa isporuke',

  //userTypes

  employee: 'Zaposlenik',
  customer: 'Kupac',
  externalAssociate: 'Vanjski suradnik',

  //salutation

  Mr: 'g',
  Ms: 'gđa',
  Sir: 'g',
  Dr: 'dr',

  //confirm

  header: 'Potvrda',
  messageConfirm: 'Jeste li sigurni da želite izvršiti ovu radnju?',
  acceptlabel: 'Da',
  rejectlabel: 'Ne',

  //error message

  requiredError: 'Ovo polje je obavezno',
  min: 'Vrijednost ne može biti manja od',
  max: 'Vrijednost ne može biti veća od',
  emailError: 'Email nije validan',
  characters: 'znakova',
  minLength: 'Polje mora imati min',
  maxLength: 'Polje može imati max',
  validateOib: 'OIB nije ispravan',
  validPhone: 'Ovo polje može sadržavati samo brojeve i + znak',
  validPassword: 'Mora imati veliko slovo, znak i broj.',
  mailFormat: 'Email nije ispravan',
  salutationFormat: 'Titula može biti prazna, g, gđa...',
  shippingAddressMax: 'Ne može imati 2 adrese za isporuku roba',
  billingAddressMax: 'Ne može imati 2 adresa za dostavu računa',
  mainAddressMax: 'Ne može imati 2 glavne adrese',
  isOfAge: 'Korisnik treba biti najmanje 18 godina',
  maxCheckedItems: 'Možete odabrati max 5 stavci',
  spaceNotAllowed: 'Razmak nije dozvoljen',
  passwordNotMatch: 'Lozinke se ne podudaraju',
  notAvailableUsername: 'Korisničko ime je zazueto, prijedlog:',
  notAvailableEmail: 'Email je zazuzet',
  startDateGreaterThenEndDate: 'Start date can not be greater then end date',
  endDateLesserThenStartDate: 'End date can not be lesser then start date',
  timeRegExp: 'Dozvoljeni su samo brojevi, točka i dvotočka',
  numberFirst: 'Prvi znak mora biti broj',
  maxTagLength: 'Tag ne može biti duži od',
  passingPointsNegative: 'Bodovi za prolaz ne mogu biti negativni',
  passingPointsMoreThanTotal: 'Bodovi za prolaz ne mogu biti preko ukupnog broja bodova',
  atLeastOneQuestion: 'Ispit mora imati barem jedno pitanje',
  atLeastOneCorrectAnswer: 'Pitanje mora imati barem jedan točan odgovor',
  atLeastTwoAnswers: 'Pitanje mora imati barem dva ponuđena odgovora',
  sameAnswerText: 'Dva odgovora ne mogu imati jednaki tekst',
  atLeastOneAnswer: 'Pitanje mora imati barem jednu odgovor',
  numAnswerGaps: 'Broj odgovora mora odgovarati broju praznina u tekstu pitanja',
  atLeastTwoPairs: 'Pitanje mora imati barem dva para odgovora',
  minNotBiggerThenMaxStock: 'Minimalna zaliha ne može biti manja od maksimanle',
  usernameSpecialChars: 'Korisničko ime može sadržavati samo slova, brojeve - i _',
  startEvent: 'Početak događaja',
  endEvent: 'Kraj događaja',
  endRecurring: 'Kraj ponavljanja događaja',
  cantBeGreaterThen: 'ne može biti veći od',
  cantBeValue: 'Ne može biti',
  atLeastOneRequired: 'Barem jedno od navedenih polja je obavezno',
  notInPastDate: 'Ne smije biti u prijašnjem datumu',
  invalidSlug: 'Neispravan slug',
  slugTaken: 'Slug već postoji',
  isUrl: 'Unost mora bit valjan URL',
  mustBePsotiveInteger: 'Unos mora biti pozitivan cijeli broj',
  isSlug: 'Unos mora biti valjan slug',


  //monthNumbers

  1: 'Siječanj',
  2: 'Veljača',
  3: 'Ožujak',
  4: 'Travanj',
  5: 'Svibanj',
  6: 'Lipanj',
  7: 'Srpanj',
  8: 'Kolovoz',
  9: 'Rujan',
  10: 'Listopad',
  11: 'Studeni',
  12: 'Prosinac',

  //datePicker

  firstDayOfWeek: 1,
  dayNames: ['Nedjelja', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'],
  dayNamesShort: ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'],
  dayNamesMin: ['Ne', 'Po', 'Ut', 'Sri', 'Če', 'Pt', 'Su'],
  monthNames: ['siječanj', 'veljača', 'ožujak', 'travanj', 'svibanj', 'lipanj', 'srpanj', 'kolovoz', 'rujan', 'listopad', 'studeni', 'prosinac'],
  monthNamesShort: ['sij', 'velj', 'ožu', 'tra', 'svi', 'lip', 'srp', 'kol', 'ruj', 'lis', 'stu', 'pro'],

  //Oni koji nisu imali prijevod

  courseDiscussions: 'Rasprave',
  totalBasicPrice: 'Ukupna osnovna cijena',
  totalTax: 'Ukupni porez',
  totalDiscount: 'Ukupni popust',
  discountAmount: 'Iznos popusta',
  taxAmount: 'Iznos poreza',
  finalNetPrice: 'Konačna neto cijena',
  amountApsolute: 'Apsolutni iznos',
  amountPercentage: 'Postotak iznosa',
  leads: 'Vodi',
  opportunities: 'Mogućnosti',
  rating: 'Ocjena',
  sourceType: 'Vrsta izvora',
  poi: 'POI',
  noOfEmployees: 'Broj zaposlenih',
  nationality: 'Nacionalnost',
  newLead: 'Novi voditelj',
  editLead: 'Uredi voditelja',
  manageRatings: 'Upravljanje ocjenama',
  ratingName: 'Naziv ocjene',
  cmsSite: 'CMS stranica',
  employeeReferral: 'Referal zaposlenik',
  externalReferral: 'Vanjski referal',
  wordOfMouth: 'Usmena predaja',
  externalWebSite: 'Vanjske web-lokacije',
  partner: 'Partner',
  callIncome: 'Prihod od poziva',
  annualBudget: 'Godišnji proračun',
  uidNumber: 'UDI broj',
  contactPerson: 'Kontakt osoba',
  sourceTypes: 'Vrste izvora ',
  editSourceType: 'Uredi izvorni tip',
  createSourceType: 'Napravi izvorni tip',
  contactPersonRole: 'Uloga kontakt osobe',
  manageContactPersonRoles: 'Upravljanje ulogama osoba',
  previousOwners: 'Prethodni vlasnici',
  event: 'Događaj',
  call: 'Poziv',
  meeting: 'Sastanak',
  phoneNumber: 'Tel. broj',
  newContact: 'Novi kontakt',
  newAddress: 'Nova adresa',
  contactType: 'Vrsta kontakta',
  addEvent: 'Dodaj događajn',
  addTask: 'Dodaj zadatak',
  newComment: 'Novi komentar',
  sendMail: 'Pošalji mail',
  scheduledActivity: 'Zakazana aktivnost',
  pastActivity: 'Past activity',
  birthday: 'Rođendan',
  leadmanagement: 'Upravljanje vodstvom',
  newlead: 'Novo vodstvo',
  editlead: 'Uredi vodstvo',
  asset: 'Imovina',
  workGroup: 'Radna grupa',
  useranonymised: 'Korisnik je bio anoniman',
  useridentified: 'Korisnik je identificiran',
  estimationLogCreate: 'Procjena dnevnika',
  estimationLogEdit: 'Uređivanje procjene',
  complete: 'Završeno',
  projectFilters: 'Projektni filtri',
  clientType: 'Odaberite klijenta',
  selectClient: 'Select client',
  projectLabel: 'Oznaka projekta',
  projectCategory: 'Kategorija projekta',
  projectName: 'Naziv projekta',
  taskFilters: 'Vrsta zadatka',
  taskType: 'Task type',
  taskLabel: 'Oznaka zadatka',
  logFilters: 'Filtri zapisnika',
  logType: 'Vrsta zapisnika',
  logStatus: 'Status zapisnika',
  loggedDate: 'Datum zapisivanja',
  activated: 'aktivirati',
  agreementCreated: 'Sporazum je stvoren',
  recievedqty: 'Primljena količina',
  publishPageSure: 'Jeste li sigurni da želite objaviti ovu stranicu?',
  unpublishPageSure: 'Jeste li sigurni da želite poništiti objavljivanje ove stranice?',
  archiveTagSure: 'Jeste li sigurni da želite izbrisati ovaj tag?',
  publishPostSure: 'Jeste li sigurni da želite objaviti ovaj post?',
  unpublishPostSure: 'Jeste li sigurni da želite poništiti objavljivanje tog posta?',
  navigationUpdated: 'Navigacija je ažurirana',
  createdby: 'Napravio',
  sourcetype: 'Vrsta izvora',

  //tableHeaders
  detailsDisplayName: 'Naziv',
  contactsPhone: 'Telefon',
  contactsMobile: 'Mobitel',
  companyName: 'Naziv kompanije',
  detailsFirstName: 'Ime',
  detailsLastName: 'Prezime',
  detailsGender: 'Spol',
  detailsSalutation: 'Pozdrav',
  detailsVatNumber: 'Porezni broj',
  detailsPersonalIdNumber: 'OIB',
  detailsBirthDate: 'Datum rođenja',
  detailsPlaceOfBirth: 'Mjesto rođenja',
  detailsNotes: 'Bilješke',
  addressesMain: 'Glavna adresa',
  addressesShipping: 'Adresa isporuke',
  contactsEmail: 'Email',
  addressesBilling: 'Adresa za dostavu računa',
  contactsFax: 'Fax',
  contactsSkype: 'Skype',
  detailsCompanyIdNumber: 'ID broj tvrtke',
  detailsLogo: '',
  detailsGrandTotal: 'Ukupno',
  contactsValue: 'Kontakt',
  supervisor_thumbnail: '',
  mainAgreement: 'Osnovni sporazum',
  statusSourceIdentifier: 'Status source identifier',
  published_at: 'Datum objave',
  created_at: 'Datum kreiranja',
  background: 'Pozadina',
  navType: 'Tip',
  language_code: 'Jezik',
  use_archive_bar: 'Koristiti arhivu',
  email_type_option: 'Vrsta email-a',
  double_optin: 'Opcija',
  marketing_option: 'Marketing opcija',
  ects: 'ECTS',
  courseName: 'Kolegij',
  activityTitle: 'Nastavna cjelina',
  action: 'Akcija',

  // table settings header titles
  tableSettingsDetailsProfileImage: 'Profilna slika',
  tableSettingsDetailsLogo: 'Logo',
  tableSettingsUserImage: 'Korisnička fotografija',
  tableSettingsIcon: 'Ikona',
  tableSettingsLevel: 'Stupanj',
  tableSettingsSupervisor_thumbnail: 'Supervizorova slika',
  tableSettingsCreatedBy_thumbnail: 'Korisnikova slika',
  ganttTrans: {
    date: {
      month_full: ['January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'],
      month_short: ['Jan', 'bla', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'],
      day_full: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday',
        'Saturday'],
      day_short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    },
    labels: {
      new_task: 'New task',
      icon_save: 'Save',
      icon_cancel: 'Cancel',
      icon_details: 'Details',
      icon_edit: 'Edit',
      icon_delete: 'Delete',
      confirm_closing: '',//Your changes will be lost, are you sure ?
      confirm_deleting: 'Task will be deleted permanently, are you sure?',

      section_description: 'Description',
      section_time: 'Time period',

      /* link confirmation */

      confirm_link_deleting: 'Dependency will be deleted permanently, are you sure?',
      link_from: 'From',
      link_to: 'To',
      link_start: 'Start',
      link_end: 'End',

      minutes: 'Minutes',
      hours: 'Hours',
      days: 'Days',
      weeks: 'Week',
      months: 'Months',
      years: 'Years'
    }
  },
  profileImage: 'Profilna slika',
  subscribed: 'Pretplaćen',
  unsubscribed: 'Otkazana pretplata',
  cleaned: 'Neisporučivo',
  pending: 'Na čekanju',
  transactional: 'Transakcijski',

  //EVNAS specific keys
  evnas: {
    plannedExams: 'Planirani ispitni rokovi',
    studyYearStudentAmounts: 'Broj studenata po godinama studija',
    addStudyYear: 'Dodaj godinu studija',
    studentNumberLocked: 'Zaključan broj studenata',
    lessonTypes: 'Tipovi nastava',
    activityGroupsUpdated: 'Teme uređene',
    normHours: 'Norma sati',
    createOccupation: 'Kreiraj zanimanje',
    editOccupation: 'Uredi zanimanje',
    createUser: 'Kreiraj korisnika',
    editUser: 'Uredi korisnika',
    archiveUser: 'Korisnik arhiviran',
    deletedUser: 'Korisnik obrisan',
    restoreUser: 'Korisnik je vraćen',
    ects: 'ECTS bodovi',
    createCourse: 'Kreiraj kolegij',
    editCourse: 'Uredi kolegij',
    department: 'Katedra',
    academicYear: 'Akademska godina',
    course: 'Kolegij',
    from: 'Od',
    to: 'Do',
    createAcademicYear: 'Kreiraj akademsku godinu',
    editAcademicYear: 'Uredi akademsku godinu',
    room: 'Prostorije',
    createDepartment: 'Kreiraj katedru',
    editDepartment: 'Uredi katedru',
    createRoom: 'Kreiraj prostoriju',
    editRoom: 'Uredi prostoriju',
    room_open: 'Prostorija se može više puta zauzeti',
    user_open: 'Korisnik se može više puta zauzeti',
    group_open: 'Grupa se može više puta zauzeti',
    archiveRoom: 'Prostorija arhivirana',
    restoreRoom: 'Prostorija je vraćena',
    hasImplementationPlans: 'Izvedbeni plan dostupan',
    implementationPlan: 'Izvedbeni plan',
    implementationPlans: 'Izvedbeni planovi',
    createImplementationPlan: 'Kreiraj izvedbeni plan',
    editImplementationPlan: 'Uredi izvedbeni plan',
    studyProgram: 'Studijski program',
    logs: 'Studijski program',
    location: 'Lokacija',
    createStudyProgram: 'Kreiraj studijski program',
    editStudyProgram: 'Uredi studijski program',
    studyKind: 'Vrsta studija',
    studyType: 'Tip studija',
    studyTypeName: 'Ime tipa studija',
    studyYear: 'Godina studija',
    buildings: 'Zgrade',
    createBuilding: 'Kreiraj zgradu',
    editBuilding: 'Uredi zgradu',
    archiveBuilding: 'Zgrada arhivirana',
    restoreBuilding: 'Zgrada je vraćena',
    activityGroup: 'Tema',
    createActivityGroup: 'Kreiraj temu',
    editActivityGroup: 'Uredi temu',
    plannedHours: 'Planirano sati',
    lessonType: 'Tip nastave',
    numberOfGroups: 'Broj grupa',
    numberOfStudents: 'Broj studenata',
    createActivity: 'Nova nastavna cjelina',
    editActivity: 'Uredi aktivnost',
    activityList: 'Popis nastavnih cjelina',
    activityGroupList: 'Lista tema',
    newActivity: 'Nova nastavna cjelina',
    deletedActivity: 'Nastavna cjelina obrisana',
    newActivityGroup: 'Nova tema',
    classType: 'Tip nastave',
    lesson: 'Lekcija',
    lecture: 'Predavanja',
    exercise: 'Vježba',
    seminar: 'Seminar',
    exam: 'Ispit',
    reference: 'Napomena',
    groupNumber: 'Broj grupe',
    primaryRepetitive: 'Primarna / Repetitivna',
    institute: 'Matična ustanova',
    employment: 'Zaposlenje',
    leader: 'Nositelj',
    activities: 'Aktivnosti',
    coefficient: 'Koeficijent',
    member: 'Suradnik',

    // layout/component translation
    admin: {
      department: {
        supervisor: 'Predsjednik'
      },
      study_program: {
        supervisor: 'Voditelj'
      }
    }
  },
  primaryRepetitive: 'Primarna / Repetitivna',
  primary: 'Primarna',
  repetitive: 'Repetitivna',
  reservationTime: 'Vrijeme rezervacije',
  lessonType: 'Tip nastave',
  academicYear: 'Akademska godina',
  numberOfStudents: 'Broj studenata',
  newactivity: 'Nova nastavna cjelina',
  buildings: 'Zgrade i prostorije',
  buildingslist: 'Zgrade',
  rooms: 'Prostorije',
  errors: 'Greške',
  studyProgramme: 'Studijski program',

  from: 'Od',
  studyProgrammeName: 'Studijski program',
  implementationsPlansAvailable: 'Izvedbeni plan',
  studyType: 'Vrsta studija',
  studyTypeName: 'Ime vrste studija',
  location: 'Lokacija',
  academicYearDescription: 'Opis',
  occupationName: 'Radno mjesto',
  employmentPercentage: 'Postotak zaposlenosti',
  maximumNormHours: 'Maximum norma sati',
  normHours: 'Norma sati',

  hide: 'Sakrij',
  hasErrors: 'Ima greške',

  last_occupation_date: 'Izbor u zadnje zvanje',
  start_of_work: 'Početak radnog staža',
  supervisors: 'Voditelji',
  manageStudyTypesTitle: 'Uredi tipove studija',
  manageStudyKindsTitle: 'Uredi vrste studija',
  studyTypePlaceholder: 'Ime tipa studija',
  confirmStudyKindArchiveText: 'Jeste li sigurni da zelite arhivirati ovu vrstu studija?',
  confirmStudyTypeArchive: 'Jeste li sigurni da zelite arhivirati ovaj tip studija?',
  codeStudyProgram: 'Šifra',
  courseWithThisCodeAlreadyExists: 'Kolegij sa ovom šifrom već postoji',
  codeAlreadyExists: 'Kolegij s tom šifrom već postoji',
  mustDefineAcademicYearAndStudyProgramFilters: 'Morate definirati akademsku godinu i studijski program u filteru.',
};
