export const Guidelines = {
 text:`
 # Frontend development rules
 ---
 &nbsp;
 
 ### Make sure to checkout and use - __[NgPrime](https://www.primefaces.org/primeng/#/)__ - will make life easier
 
  &nbsp;
 
 ### Naming
 ---
 **variables:** camel case (userProfile)
 
 **files:** kebab case (user-details.component.ts, user-profile.ts)
 
 &nbsp;
    
 ### Route naming
 ---
 + **/users** -> list
   
 + **/user/:id** -> single (children /user/:id/details)
   
 + **/users/new** -> create page
   
 + **/users/edit/:id** -> update page

 
  &nbsp;
  
 **Each route must have data object and resolver with hasAccess**
   \`\`\`
    {
        path: 'edit/:id',
        component: UserFormComponent,
        data: {
          breadcrumb: 'EditUser',
          permission: 'user_update'
        },
        resolve: {
          hasAccess: HasPermissionResolver,
        }
     }
   \`\`\`
   
   ### Global service and global module
   
   &nbsp;
   
   **Global service**
   
   Global service contains, form validation functions, string, date manipulation
   
   Lodash mixins for creating custom lodash functions
   
   &nbsp;
   
   **Global module**
   
   Global module contains all services, modules and components that are used globally across the project
   
   Also contains NgPrime modules
   
   
   &nbsp;
   
   ### CommonData is a variable that contains all common data across the app, and common data for each module
   
   ### Each module has his own common resolver
   
   &nbsp;
   
   &nbsp;
   
    **Classes (Modals)**
    - Everything must have a class.
    - Everything that comes from the backend must go trough that class and its constructor, and that goes back to backend must go trough toJSON() method inside that class
    - All data formatting that is sent to backed is done withing the class
    - Check user.ts in user module in classes folder for example
  `
};


