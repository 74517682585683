import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {FilterData} from '../../../../classes/filter-data';
import * as _ from 'lodash';
import {GlobalService} from '../../../../services/global.service';
import {UserService} from '../user.service';
import {CompanyService} from '../../company/company.service';
import {AuthService} from '../../../../services/auth.service';
import {Observable} from 'rxjs';
import {User} from '../classes/user';
import {ApiService} from '../../../../services/api.service';

@Injectable()
export class EmployeesGetResolver implements Resolve<any> {

  constructor(private _user: UserService,
              private _company: CompanyService,
              private _auth: AuthService,
              private _api: ApiService,
              private _global: GlobalService) {
  }

  resolve(): Observable<User[]> {
    return this._api.handleRequest(this._user.getUsers(new FilterData({
      filters: [
        {key: 'type', value: _.find(this._global.commonData.userTypes, ['slug', 'employee']).id},
        {key: 'archived', value: false}
      ],
      select: ['details.firstName', 'details.lastName'],
      sort: {param: 'details.lastName', order: 'asc'}
    }, true)));
  }

}
