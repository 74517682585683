import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { Label } from '../../../interfaces/common/label';
import { LocalizationService } from '../../../services/localization.service';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-select-labels-modal',
  templateUrl: './select-labels-modal.component.html',
  styleUrls: ['./select-labels-modal.component.scss']
})
export class SelectLabelsModalComponent implements OnInit {

  @Input() labels: Label[] = [];
  @Input() isDisabled:boolean = true;
  @Output() choice: EventEmitter<string> = new EventEmitter<string>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  public form: FormGroup;

  constructor(
    public _locale: LocalizationService,
    private _fb: FormBuilder,
    private _global: GlobalService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    this.form = this._fb.group({
      labels: [[], Validators.required],
    });
  }

  submit(): void {
    if (!this.form.valid) return this._global.checkFormErrors(this.form);
    this.choice.emit(this.form.value.labels);
    this.close.emit();
  }

}
