import {throwError as observableThrowError, Observable, BehaviorSubject} from 'rxjs';

import {take, filter, catchError, switchMap, finalize, tap} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpErrorResponse
} from '@angular/common/http';

import * as _ from 'lodash';
import {analyticsUrls} from '../modules/layout/analytics/analytics.urls';

import {AuthService} from '../services/auth.service';
import {GlobalService} from '../services/global.service';
import {LocalizationService} from '../services/localization.service';
import {LoadingBarService} from '@ngx-loading-bar/core';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private isRefreshingTokens: boolean = false;
  private tokenSubject: BehaviorSubject<{ accessToken: string, mediaToken: string }> = new BehaviorSubject<{ accessToken: string, mediaToken: string }>(null);

  constructor(private injector: Injector) {
  }


  private applyCredentials(req, accessToken: string, refreshToken: string) {
    let headers = {
      // 'Content-type': 'application/json',
      // Setting header type in interceptors overrides the setted request headers
      // Problem: when uploading files the header need to be form-data, the line above overrides them and sends them as json which results in Bad Request
      // Solution: Dont set the header type in here, set it at request preparation
      'accessToken': accessToken || 'at',
      'refreshToken': refreshToken || 'rt',
    };
    if (_.includes(_.map(analyticsUrls, route => route), req.url)) return req.clone();
    if (req.url.search('mailchimp') !== -1) Object.assign(headers, this.returnMailChimpHeaders());
    return req.clone({
      setHeaders: headers
    });
  };

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const authService = this.injector.get(AuthService);

    return <any>next.handle(this.applyCredentials(req, authService.getAuthToken(), authService.getRefreshToken())).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {

          if ((<HttpErrorResponse>error).status == 0) return observableThrowError(error);

          const global = this.injector.get(GlobalService);
          const locale = this.injector.get(LocalizationService);
          const lb = this.injector.get(LoadingBarService);
          lb.complete(); //If error happens just turn off the loading bar

          switch ((<HttpErrorResponse>error).status) {
            case 400:
            case 404:
            case 403:
            case 500:
            case 503:
              global.pushAppMessage('error', locale.trans.error, error.error.message);
              return observableThrowError(error);
            case 401:
              return this.handle401Error(req, next);
            case 405:
              global.saleError.emit();
              return observableThrowError(error);
            case 406:
              authService.logOut();
              return observableThrowError('');
          }
        } else return observableThrowError(error);
      }));
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    const authService = this.injector.get(AuthService);
    if (!this.isRefreshingTokens) {
      this.isRefreshingTokens = true;
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return authService.refreshTheTokens().pipe(
        catchError(error => {
          authService.logOut();
          return observableThrowError('');
        }),
        switchMap((newTokens: { accessToken: string, mediaToken: string }) => {
          if (newTokens.accessToken == '') {
            authService.logOut();
            return observableThrowError('');
          } // If we don't get a new token, we are in trouble so logout.
          this.tokenSubject.next(newTokens);
          return next.handle(this.applyCredentials(req, newTokens.accessToken, authService.getRefreshToken()));
        }),
        finalize(() => {
          this.isRefreshingTokens = false
        }));
    } else {
      return this.tokenSubject.pipe(
        filter((tokens: { accessToken: string, mediaToken: string }) => tokens && tokens.accessToken != null),
        take(1),
        switchMap((tokens: { accessToken: string, mediaToken: string }) => {
          return next.handle(this.applyCredentials(req, tokens.accessToken, authService.getRefreshToken()));
        }));
    }
  }

  private returnMailChimpHeaders(): object {
    const global = this.injector.get(GlobalService);
    return {Authorization: `Basic ${btoa(`${global.mailChimpParams.username}:${global.mailChimpParams.apiKey}`)}`};
  }
}
