import { environment } from './../environments/environment';
import { NgModule, ErrorHandler } from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgxElectronModule} from 'ngx-electron';
import {ApiInterceptor} from './interceptors/api.interceptor';
import {ApiService} from './services/api.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GlobalModule} from './modules/global.module';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {GrowlModule} from 'primeng/growl';
import {GuidelinesModule} from './components/guidelines/guidelines.module';
import {LocalizationService} from './services/localization.service';
import {GetLoggedUserResolver} from "./resolvers/get-logged-user.resolver";
import { LoadingBarService } from '@ngx-loading-bar/core';
import {PasswordModule} from "primeng/primeng";
import {AuthComponent} from "./components/auth/auth.component";
import {ForgotPasswordComponent} from "./components/auth/forgot-password/forgot-password.component";
import {ResetPasswordComponent} from "./components/auth/reset-password/reset-password.component";
import {NotFoundComponent} from "./components/errors/not-found/not-found.component";
import {InternalServerErrorComponent} from "./components/errors/internal-server-error/internal-server-error.component";
import {ForbiddenComponent} from "./components/errors/forbidden/forbidden.component";
import {ServiceUnavailableComponent} from "./components/errors/service-unavailable/service-unavailable.component";



@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NotFoundComponent,
    InternalServerErrorComponent,
    ForbiddenComponent,
    ServiceUnavailableComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    GrowlModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxElectronModule,
    LoadingBarHttpClientModule,
    PasswordModule,
    GuidelinesModule,
    GlobalModule.forRoot(),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    // {provide: ErrorHandler, useClass: environment.envName === 'development' ? CustomErrorHandler : ErrorHandler},
    ApiService,
    LocalizationService,
    LoadingBarService,
    GetLoggedUserResolver,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
