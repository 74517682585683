export const GermanTranslations = {
  optionsSelected: 'options selected',
  lessonTypes: 'Lesson types',
  lessonType: 'Lesson type',
  createOpportunity: 'Create opportunity',
  update_audience_info: 'Audience successfully updated',
  update_member_info: 'Member successfully updated',
  update_campaign_info: 'Campaign successfully updated',
  delete_campaign_info: 'Campaign successfully deleted',
  delete_audience: 'Audience successfully deleted',
  campaigns: 'Campaings',
  audience_title: 'Audience',
  create_audience_sucessfull: 'New Audience has been created',
  send_campaign_info: 'Campaign successfully sent, check you email',
  add_campaign: 'New campaign added',
  campaign_title: 'Campaign',
  delete_member: 'Member deleted',
  add_new_member: 'New member added',
  member_info: 'Member',
  marketing_permissions: 'Marketing permissions',
  marketing_permissions_details: 'Whether or not the list has marketing permissions (eg. GDPR) enabled',
  use_archive_bar_details: 'Whether campaigns for this list use the Archive Bar in archives by default',
  email_type_option_details: 'Whether the list supports multiple formats for emails',
  double_optin_details: 'Whether or not to require the subscriber to confirm subscription via email',
  create_audience_error: 'You already have the max amount of audiences allowed in your plan, please delete audience first',
  email_subscribe: 'the email to send subscribe notification to',
  email_unsubcribe: 'the email to send unsubscribe notification to',
  permission_reminder: 'Permmission reminder',
  email_address: 'Email address',
  campaing_type: 'Campaign type',
  preview_text: 'Preview text',
  campaign_name: 'Campaign name',
  choose_audience: 'Choose a audience',
  campaignType: 'Campaign type',
  from_email: 'From email',
  from_name: 'From name',
  notify_on_unsubscribe: 'Notify on unsubscribe',
  notify_on_subscribe: 'Notify on subscribe',
  permmision_reminder: 'Permission reminder',
  addr1: 'Address 1',
  addr2: 'Address 2',
  double_optinMailchinp: 'This person gave me permission to email them',
  double_optinMailchinpDescritption: 'This person will not receive a confirmation email from Mailchimp. Subscribers added manually won\'t have an opt-in IP address or date in your records.',
  marketing_permissionsMailchimp: 'If this person is already on my list, update their profile',
  unsubscribe: 'Unsubscribe',
  email_sent: 'Emails sent',
  email_marketing: 'Email Marketing',
  send_campaign: 'Send campaign',
  all_campaigns: 'All campaigns',
  subscribers: 'Subscribers',
  subscriber: 'Subscriber',
  delete_campaign: 'Delete',
  edit_campaign: 'Edit',
  create_campaign: 'Create Campaign',
  edit_member: 'Edit a subscriber',
  add_member: 'Add a subscriber',
  edit_audience: 'Edit audience',
  all_audiences: 'All audiences',
  campaign: 'Campaign',
  editCampaign: 'Edit campaign',
  createCampaign: 'Create campaign',
  subscribe: 'Subscribe',
  create_audience: 'Create audience',
  email_type: 'Email type',
  visbility: 'Visibility',
  audiences: 'Audiences',
  new_audience: 'New Audience',
  audience: 'Audience',
  mailchimp: 'Mailchimp',
  mailchimpFreeAccAudienceHeaderText: 'You\'ve hit your audience limit',
  mailchimpFreeAccAudienceBodyText: 'You already have the max amount of audiences allowed in your plan, but you can add more contacts to an existing audience and organize them using tags. If you want to add another audience, you\'ll need to upgrade your plan.',
  leave: 'Leave',
  channelInfo : ' Channel Info:',
  idle : 'Idle',
  busy: 'Busy',
  ganttchart: 'Gantt chart',
  gantt: 'Gantt',
  addedToCms: 'Surveys added to cms',
  removeFromCmsModal: 'Remove from Cms',
  addToCMS: 'Add to CMS',
  removeFromCms: 'Survey remove from CMS',
  sites: 'Sites',
  quote: 'Quote',
  react: 'React',
  signatureText: 'Signature text',
  yourPassword: 'Your password',
  login: 'Login',
  won: 'Won',
  lost: 'Lost',
  qualified: 'Qualified',
  unqualified: 'Unqualified',
  percentage: 'Percentage',
  examPoints: 'Exam points',
  passPoints: 'Pass points',
  isPassed: 'Passed',
  participated: 'Participated',
  gallery: 'Gallery',
  convertlead: 'Convert lead',
  convertLeadQuestion: 'Do you want to convert the lead?',
  addExistingAddress: 'Add existing address',
  insurance: 'Insurance',
  insuranceSubscribers: 'Insurance subscribers',
  insuranceActivity: 'Insurance activity',
  additionalFields: 'Additional fields',
  leadConvertedSuccessfully: 'Lead converted successfully',
  quickAddMember: 'Quick add member',
  addFamilyMember: 'Add family member',
  selectOrCreateFamily: 'Select or create family',
  convert: 'Convert',
  productCategory: 'Product category',
  selectManufacturerAndProducts: 'Please select a manufacturer and products',
  decisionmaker: 'Decision maker',
  decisionMaker: 'Decision maker',
  family: 'Family',
  families: 'Families',
  newfamily: 'New family',
  editfamily: 'Edit family',
  vise: 'Visa',
  managePolicyStatuses: 'Manage policy statuses',
  policyStart: 'Policy start',
  policyEnd: 'Policy end',
  policyStatus: 'Policy status',
  approvedValue: 'Approved value',
  leadPerson: 'Lead person',
  leadCompany: 'Lead company',
  insurancesales: 'Insurance sales',
  tableView: 'Table view',
  gridView: 'Grid view',
  createdAt: 'Created',
  opportunityActivity: 'Opportunity activity',
  leadActivity: 'Lead activity',
  leadSubscribers: 'Lead subscribers',
  opportunitySubscribers: 'Opportunity subscribers',
  coverImage: 'Cover image',
  member: 'Member',
  members: 'Members',
  uploadCoverImage: 'Upload cover image',
  newteam: 'New team',
  editteam: 'Edit team',
  teams: 'Teams',
  team: 'Team',
  sendOffer: 'Send offer',
  noAddresses: 'No addresses',
  noHistory: 'No history',
  bcc: 'BCC',
  newOpportunity: 'New opportunity',
  editOpportunity: 'Edit opportunity',
  stage: 'Stage',
  stages: 'Stages',
  estimatedTime: 'Estimated time',
  estimatedValue: 'Estimated value',
  newopportunity: 'New opportunity',
  editopportunity: 'Edit opportunity',
  editedBy: 'Edited by',
  archivedBy: 'Archived by',
  restoredBy: 'Restored by',
  completedBy: 'Completed by',
  reassignedTo: 'Reassigned to',
  showHistory: 'Show history',
  history: 'History',
  messageConfirmConfirm: 'Are you sure ?',
  reassign: 'Reassign',
  reassignActivity: 'Reassign activity',
  onlyThisActivity: 'Only this activity',
  allActivitiesOfThisType: 'All activities of this type',
  allActivities: 'All activities',
  totals: 'Totals',
  notExportFile: 'Please upload the project export file',
  reply: 'Reply',
  forward: 'Forward',
  toTask: 'Convert to task',
  work: 'Work',
  travel: 'Travel',
  newGroup: 'New group',
  sendMessage: 'Send message',
  attachments: 'Attachments',
  deleteForever: 'Delete forever',
  nn: 'NN',
  dueTime: 'Due time',
  estimateBy: 'Estimate by',
  cc: 'CC',
  marketing: 'Marketing',
  customerExperienceResearch: 'Customer experience research',
  createPollsSurvey: 'Create polls, surveys and many more',
  apiKey: 'Apikey',
  requestUrl: 'Requesturl',
  newsletter: 'Newsletter',
  mailchimpsettings: 'Mailchimp settings',
  audienceCreated: 'Audience created',
  audienceUpdated: 'Audience updated',
  audienceDeleted: 'Audience deleted',
  audienceList: 'Audience list',
  audiencelist: 'Audience list',
  newAudience: 'New Audience',
  newaudience: 'New Audience',
  editAudience: 'Edit Audience',
  audienceRestored: 'Audience restored',
  previewAudience: 'Preview Audience',
  audienceName: 'Audience Name',
  viewAudience: 'View Audience',
  takeAudience: 'Take Audience',
  takeaudience: 'Take Audience',
  submitAudience: 'Submit Audience',
  audienceSubmitted: 'Audience submitted',
  resultsaudience: 'Results',
  audienceReset: 'Audience has been reset',
  campaignsCreated: 'Campaigns created',
  campaignsUpdated: 'Campaigns updated',
  campaignsDeleted: 'Campaigns deleted',
  campaignsList: 'Campaigns list',
  campaignslist: 'Campaigns list',
  newCampaigns: 'New Campaigns',
  newcampaigns: 'New Campaigns',
  editCampaigns: 'Edit Campaigns',
  campaignsRestored: 'Campaigns restored',
  previewCampaigns: 'Preview Campaigns',
  campaignsName: 'Campaigns Name',
  viewCampigns: 'View Campaigns',
  takeCampigns: 'Take Campaigns',
  takecampaigns: 'Take Campaigns',
  submitCampaigns: 'Submit Campaigns',
  campaignsSubmitted: 'Campaigns submitted',
  campaignsReset: 'Campaigns has been reset',
  mailchimpCreated: 'Mailchimp created',
  mailchimpUpdated: 'Mailchimp updated',
  mailchimpDeleted: 'Mailchimp deleted',
  mailchimpList: 'Mailchimp list',
  mailchimplist: 'Mailchimp list',
  newMailchimp: 'New Mailchimp',
  newmailchimp: 'New Mailchimp',
  editMailchimp: 'Edit Mailchimp',
  mailchimpRestored: 'Mailchimp restored',
  previewMailchimp: 'Preview Mailchimp',
  mailchimpName: 'Mailchimp Name',
  viewMailchimp: 'View Mailchimp',
  takeMailchimp: 'Take Mailchimp',
  takemailchimp: 'Take Mailchimp',
  submitMailchimp: 'Submit Mailchimp',
  mailchimpSubmitted: 'Mailchimp submitted',
  mailchimpReset: 'Mailchimp has been reset',
  mailchimpcreated: 'New mailchimp has been created',
  newsletterCreated: 'Newsletter created',
  newsletterUpdated: 'Newsletter updated',
  newsletterDeleted: 'Newsletter deleted',
  mnewsletterpList: 'Newsletter list',
  newsletterlist: 'Newsletter list',
  newNewsletter: 'New Newsletter',
  editNewsletter: 'Edit Newsletter',
  newsletterRestored: 'Newsletter restored',
  previewNewsletter: 'Preview Newsletter',
  newsletterName: 'Newsletter Name',
  viewNewsletter: 'View Newsletter',
  takeNewsletter: 'Take Newsletter',
  takenewsletter: 'Take Newsletter',
  submitNewsletter: 'Submit Newsletter',
  newsletterSubmitted: 'Newsletter submitted',
  newsletterReset: 'Newsletter has been reset',
  templates: 'Templates',
  subscribeMonthly: 'Subscribe to our monthly newsletter',
  sms: 'SMS',
  sendViewSms: 'Send and view all your SMS messages',
  survey: 'Survey',
  surveyList: 'Survey list',
  surveylist: 'Survey list',
  newSurvey: 'New Survey',
  newsurvey: 'New Survey',
  previewSurvey: 'Preview survey',
  surveyName: 'Survey Name',
  forClient: 'For client',
  editsurvey: 'Edit Survey',
  editSurvey: 'Edit Survey',
  prizeType: 'Prize type',
  prize: 'Prize',
  publishSurvey: 'Publish survey',
  anonimous: 'Anonimous',
  surveyReset: 'Survey has been reset',
  surveySubmitted: 'Survey submitted',
  surveyDeleted: 'Survey deleted',
  surveyRestored: 'Survey restored',
  gradeSurvey: 'Grade Survey',
  viewSurvey: 'View Survey',
  takeSurvey: 'Take Survey',
  takesurvey: 'Take Survey',
  submitSurvey: 'Submit Survey',
  resultssurvey: 'Results',
  chat: 'Chat',
  createNewChanel: 'Create new Channel',
  channel: 'Channel',
  directMessages: 'Direct Messages',
  cannotEmptyMessage: 'You cannot send an empty message!',
  userInfo: 'User Info',
  //Global

  myProfile: 'My profile',
  move: 'Move',
  selectAll: 'Select all',
  deselectAll: 'Deselect all',
  showRows: 'Show rows',
  showGrid: 'Show grid',
  add: 'Add',
  remove: 'Remove',
  create: 'Create',
  save: 'Save',
  saveChanges: 'Save changes',
  view: 'View',
  edit: 'Edit',
  archive: 'Archive',
  manageProductStores: 'Manage stores',
  archives: 'Archive',
  managePages: 'Manage pages',
  editProductPage: 'Edit product page',
  restore: 'Restore',
  cancel: 'Cancel',
  delete: 'Delete',
  search: 'Search...',
  select: '- Select -',
  back: 'Back',
  success: 'Success',
  yes: 'Yes',
  no: 'No',
  ok: 'Ok',
  new: 'New',
  clear: 'Clear',
  download: 'Download',
  downloads: 'Downloads',
  category: 'Category',
  categories: 'Categories',
  label: 'Label',
  labels: 'Labels',
  activity: 'Activity',
  activities: 'Activities',
  calendar: 'Calendar',
  calendars: 'Calendars',
  manage: 'Manage',
  generalData: 'General data',
  enabled: 'Enabled',
  disabled: 'Disabled',
  image: 'Image',
  images: 'Images',
  newCategory: 'New category',
  editCategory: 'Edit category',
  categoryCreated: 'Category created',
  categoryUpdated: 'Category updated',
  setDefault: 'Set default',
  startTime: 'Start time',
  endTime: 'End time',
  tag: 'Tag',
  tags: 'Tags',

  description: 'Description',
  showMore: 'Show more',
  showLess: 'Show less',
  uploadVideo: 'Upload video',
  uploadFile: 'Upload file',
  uploadImage: 'Upload image',
  reUploadImage: 'Reupload image',
  uploadSeoImage: 'Upload SEO image',
  reUploadSeoImage: 'Reupload SEO image',
  all: 'All',
  none: 'None',
  online: 'Online',
  offline: 'Offline',
  by: 'By',
  created: 'Created',
  viewAll: 'View all',
  goTo: 'Go to',
  createComment: 'Leave a comment',
  addNew: 'Add new',
  milestone: 'Milestone',
  milestones: 'Milestones',
  warning: 'Warning',
  completed: 'Completed',
  message: 'Message',
  viewProfile: 'View profile',
  supervisor: 'Supervisor',
  group: 'Group',
  street: 'Street address',
  houseNumber: 'House number',
  zipCode: 'Postal code',
  city: 'City',
  region: 'Region',
  country: 'Country',
  active: 'Active',
  type: 'Type',
  projectDiscussions: 'Project discussions',
  classroom: 'Classrooms',
  courseDiscussions: 'Course discussions',
  close: 'Close',
  hours: 'Hours',
  expense: 'Expense',
  saved: 'Saved',
  date: 'Date',
  notifications: 'Notifications',
  notification: 'Notification',
  ibans: 'IBAN',
  bank: 'Bank',
  accountNo: 'Account number',
  pricelist: 'Price list',
  name: 'Name',
  item: 'Item',
  currency: 'Currency',
  vatClass: 'VAT',
  updated: 'Updated',
  deleted: 'Deleted',
  validTo: 'Valid to',
  number: 'Number',
  start: 'Start',
  end: 'End',
  leader: 'Leader',
  createdBy: 'Created by',
  open: 'Open',
  closed: 'Closed',
  withCategories: 'Categories',
  project: 'Project',
  task: 'Task',
  person: 'Person',
  entry: 'Entry',
  noRecords: 'No records found.',
  anonymise: 'Anonymise',
  indentify: 'Indentify',
  notSet: 'NOT SET',
  shippingMethods: 'Shipping methods',
  taxes: 'Taxes',
  domains: 'Domains',
  estimatedBudget: 'Estimated budget',
  client: 'Client',
  barCode: 'Bar code',
  pdf: 'Export to PDF',
  convertOfferToInvoice: 'Convert offer to invoice',
  offer_outgoing: 'Outgoing offer',
  offer_inbound: 'Inbound offer',
  invoice_outgoing: 'Outgoing invoice',
  invoice_inbound: 'Inbound invoice',
  order_inbound: 'Inbound order',
  order_outgoing: 'Outgoing order',
  pattern: 'Pattern',
  firstNumber: 'First number',
  newValue: 'New value',
  oldValue: 'Old value',
  incoterms: 'Incoterms',
  update: 'Update',
  outRefNumber: 'Ref. number',
  paymentType: 'Payment type',
  confirm: 'Confirm',
  externalImageLink: 'External image link',
  itemsUpdated: 'Items updated',
  slugEditWarning: 'Updating slug can cause existing link to stop working',

  ///Translate

  totalBasicPrice: 'Total basic price',
  grandTotal: 'Grand total',
  totalTax: 'Total tax',
  totalDiscount: 'Total discount',
  discountAmount: 'Discount amount',
  taxAmount: 'Tax amount',
  netPrice: 'Netto price',
  finalNetPrice: 'Final net price',
  amountApsolute: 'Aps. amount',
  amountPercentage: 'Perc. amount',
  note: 'Note',
  archived: 'Archived',
  restored: 'Restored',
  export: 'Export',
  import: 'Import',
  items: 'Items',
  issuedFrom: 'Issued from',
  issuedTo: 'Issued to',
  issuedFromContact: 'Contact person',
  issuedFromContactValue: 'Contact',
  issuedToContact: 'Contact person',
  issuedToContactValue: 'Contact',
  gmail: 'Gmail',
  other: 'Other',
  email: 'E-mail',
  password: 'Password',
  pop3: 'POP 3',
  imap: 'IMAP',
  smtp: 'SMTP',
  documentDiscount: 'Document discount',
  documentDiscountAmount: 'Doc. discount amount',
  media: 'Media',
  content: 'Content',
  intRefNumber: 'Int. ref. number',
  intRefNumberId: 'Int. ref. number ID',
  intRefNumberDate: 'Int. ref. number date',
  outRefNumberId: 'Out. ref. number ID',
  outRefNumberDate: 'Out. ref. number date',
  lnkRefNumber: 'Lnk. ref. number',
  lnkRefNumberId: 'Lnk. ref. number ID',
  lnkRefNumberDate: 'Lnk. ref number date',
  deliveryDetails: 'Delivery details',
  deliveryCompany: 'Delivery company',
  deliveryPerson: 'Delivery person',
  deliveryVehicleReg: 'Vehicle registration',
  externalReceivedBy: 'Received by',
  shippingMethod: 'Shipping method',
  transportCost: 'Transport cost',
  detailsBalance: 'Balance',
  "internal-travelwarrant": 'Travel warrant',
  travelwarrantdetails: 'Travel warrant details',
  selectemployee: '- Select employee -',
  vehicle: 'Vehicle',
  selectvehicle: '- Select vehicle -',
  paymentinadvance: 'Payment in advance',
  enterpaymentinadvance: 'Enter payment in advance...',
  locations: 'Locations',
  selectlocations: 'Enter locations...',
  updatetw: 'Update',
  traveltask: 'Task',
  entertraveltask: 'Enter task...',
  travelwarrant: 'Travel warrant',
  documentnumber: 'Document number',
  selectissuerperson: 'Select issuer',
  dailyallowanceexpense: 'Daily allowances',
  removesection: 'Remove section',
  calcstart: 'Calculation start',
  calcend: 'Calculation end',
  hourstotal: 'Hours',
  allowancetotal: 'Allowance total',
  allowanceamount: 'Allowance amount',
  countrynotselected: 'Country is not selected',
  exchangerate: 'Exchange rate',
  totalSum: 'Total',
  addallowance: 'Add allowance',
  totalallowance: 'Allowance total',
  travelexpense: 'Travel expenses',
  relationfrom: 'Relation from',
  relationto: 'Relation to',
  transporttype: 'Transport type',
  selecttransporttype: '- Select transport type -',
  startingkms: 'Start state (km)',
  endkms: 'End state (km)',
  distance: 'Distance',
  addtransportexpense: 'Add transport expense',
  totaltransport: 'Total transport expense',
  otherexpense: 'Other expense',
  expensedescription: 'Description',
  expese: 'Expense',
  addother: 'Add other',
  totalother: 'Other expense total',
  finalizedate: 'Finalization date',
  totaltravelexpense: 'Total travel expense',
  payedinadvance: 'Payed in advance',
  forpayment: 'For payment',
  manageallowances: 'Manage country allowances',
  occupationplaceholder: 'Enter or select an employee',
  fromplaceholder: 'From...',
  toplaceholder: 'To...',
  milagestartplaceholder: 'Milage start...',
  milageendplaceholder: 'Milage end...',
  distanceplaceholder: 'Distance...',
  selectcountry: '- Select country -',
  vehicleregistrationplaceholder: 'Vehicle registation',
  transporttypeplaceholder: 'Transport type',


  paymentTerm: 'Payment terms',
  invoiceType: 'Invoice type',
  device: 'Device',
  internalNote: 'Internal note',
  authorize: 'Authorize',
  authorizeDate: 'Authorize date',
  documentTitle: 'Document title',
  cancelled: 'Cancelled',
  cancelledRelation: 'Cancelled relation',
  isLegacy: 'Legacy',
  branch: 'Branch',
  createdByType: 'Created by type',
  status: 'Status',
  documentTax: 'Documen tax',
  supplier: 'Supplier',
  supplierType: 'Supplier type',
  phase: 'Phase',
  pdfTemplate: 'PDF template',
  bin: 'Bin',
  quantity: 'Quantity',
  basicPrice: 'Basic price',
  itemNote: 'Item note',
  deliveryDate: 'Delivery date',
  itemSku: 'SKU',
  unit: 'Unit',
  taxClassValue: 'Tax class value',
  originDocument: 'Origin document',
  originDocumentItem: 'Origin document item',
  itemType: 'Item type',
  taxClass: 'Tax class',
  shippingCost: 'Shipping cost',
  shippingTax: 'Shipping tax',
  invalidFile: 'Invalid file',
  filter: 'Filter',
  discussions: 'Discussions',
  tasks: 'Tasks',
  user: 'User',
  available: 'Available',
  selected: 'Selected',
  get: 'Get',
  logs: 'Logs',
  roles: 'Roles',
  role: 'Role',
  projectParticipants: 'Participants',
  discussionSubscribers: 'Discussion subscribers',
  taskAssignees: 'Task assignees',
  showArchive: 'Show archive',
  showActive: 'Show active',
  noRoles: 'No roles, please create a role',
  visibility: 'Visibility',
  settings: 'Settings',
  generate: 'Generate',
  invoice: 'Invoice',
  lead: 'Lead',
  leads: 'Leads',
  opportunities: 'Opportunities',
  company: 'Company',
  owner: 'Owner',
  rating: 'Rating',
  sourceType: 'Source type',
  source: 'Source',
  products: 'Products',
  poi: 'POI',
  notes: 'Notes',
  birthDate: 'Birth date',
  noOfFamilyMembers: 'No of family members',
  noOfEmployees: 'No of employees',
  nationality: 'Nationality',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  newLead: 'New lead',
  editLead: 'Edit lead',
  manageRatings: 'Manage ratings',
  ratingName: 'Rating name',
  cmsSite: 'CMS site',
  employeeReferral: 'Employee referral',
  externalReferral: 'External referral',
  externalWebSite: 'External web site',
  partner: 'Partner',
  wordOfMouth: 'Word of mouth',
  callIncome: 'Call income',
  annualBudget: 'Annual budget',
  uidNumber: 'UID number',
  contactPerson: 'Contact person',
  industryType: 'Industry type',
  general: 'General',
  sourceTypes: 'Source types',
  editSourceType: 'Edit source type',
  createSourceType: 'Create source type',
  contactPersonRole: 'Contact person role',
  manageContactPersonRoles: 'Manage contact person roles',
  vatNumber: 'VAT number',
  previousOwners: 'Previous owners',
  address: 'Address',
  phone: 'Phone',
  subscriptions: 'Subscriptions',
  comment: 'Comment',
  event: 'Event',
  assignedTo: 'Assigned to',
  call: 'Call',
  meeting: 'Meeting',
  phoneNumber: 'Phone number',
  newContact: 'New contact',
  newAddress: 'New address',
  contactType: 'Contact type',
  subject: 'Subject',
  addresses: 'Addresses',
  addEvent: 'Add event',
  addTask: 'Add task',
  newComment: 'New comment',
  sendMail: 'Send mail',
  scheduledActivity: 'Scheduled activity',
  pastActivity: 'Past activity',
  homeWelcomeMsg: 'Welcome to Gauss Box.',
  assethandoutform_internal: 'Asset handout',
  assetreturnform_internal: 'Asset return',
  contract_special: 'Contact',
  deliverynote_internal: 'Deliery note',
  goodsreceipt_internal: 'Goods receipt',
  inquiry_inbound: 'Inbound inquiry',
  inquiry_outgoing: 'Outgoing inquiry',
  issuenote_internal: 'Issue note',
  letter_inbound: 'Inbound letter',
  letter_internal: 'Internal letter',
  letter_outgoing: 'Outgoing letter',
  requisitionwarrant_internal: 'Requisition warrant',
  warehousetransfer_internal: 'Warehouse transfer',
  publish: 'Publish',
  unpublish: 'Unpublish',
  changeDomainSure: 'All unsaved changes made in current domain will be lost. Are you sure that you want to change current domain?',
  campainName: 'Campain Name',
  recipientList: 'Recepient List',
  senderName: 'Sender Name',
  senderEmail: 'Sender Email',
  previweText: 'Previwe Text',


  //HOME

  revenueMonthly: 'Revenue monthly',
  inboundVsOutgoingInv: 'Inbound vs outgoing invoices',
  invoicesByDueDate: 'Invoices by due date',
  totalDocuments: 'Total documents',
  topSellingProdServ: 'Top selling products & services',
  top6customers: 'Top 6 customers',
  revenue: 'Revenue',
  revenueWeekly: 'REVENUE WEEKLY',
  revenueDaily: 'REVENUE DAILY',

  //KALENDAR

  createEvent: 'Create event',
  eventName: 'Event name',
  eventStartTime: 'Event start time',
  eventEndTime: 'Event end time',
  recurring: 'Recurring',
  recurrenceEndTime: 'Recurrence end time',
  editThisEvent: 'Edit this event',
  editRecurringSeries: 'Edit recurring series',
  deleteRecurringSeries: 'Delete recurring series',
  deleteEvent: 'Delete event',
  editSeries: 'Edit series',
  editEvent: 'Edit event',
  today: 'Today',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  private: 'Private',
  public: 'Public',
  eventupdated: 'Event updated!',
  eventdeleted: 'Event deleted!',
  list: 'List',

  //LIST

  contactName: 'Contact name',
  mobile: 'Mobile',
  occupation: 'Occupation',
  retailPrice: 'Retail price',
  value: 'Value',
  billable: 'Billable',
  taskCategory: 'Task category',
  summary: 'Summary',
  loggedBy: 'Logged by',
  capacity: 'Capacity',
  timeElapsed: 'Time elapsed',
  submittedOn: 'Submitted on',
  score: 'Score',
  results: 'Results',
  totalPrice: 'Total price',
  vat1: 'Vat',
  sku: 'SKU',
  displayName: 'Name',
  title: 'Title',
  personalIdNumber: 'Personal id number',
  permission: 'Permission',
  site: 'Site',
  website: 'Website',
  hasaccess: 'Has access',
  firstName: 'First name',
  lastName: 'Last name',
  salutation: 'Salutation',
  placeOfBirth: 'Place of birth',
  main: 'Main',
  billing: 'Billing address',
  shipping: 'Shipping address',
  fax: 'Fax',
  workgroups: 'Work groups',
  username: 'Username',
  shortName: 'Short name',
  companyIdNumber: 'Company id number',
  logo: 'Logo',
  uniqueNumber: 'Unique number',
  barcode: 'Barcode',
  ean: 'Ean',
  qrCode: 'QR code',
  tariffNumber: 'Tariff number',
  breakage: 'Breakage',
  shortDescription: 'Short description',
  featured: 'Featured',
  hasWeight: 'Weight',
  issuedOn: 'Issued on',
  dueDate: 'Due date',
  documentType: 'Document type',
  documentFlow: 'Document flow',
  leaderImage: 'Leader image',
  createdByImage: 'Created by image',
  budget: 'Budget',
  totalProjects: 'Total projects',
  pageTitle: 'Title',
  author: 'Author',
  readability: 'Readability',
  slug: 'Slug',
  termTitle: 'Title',
  countOfTasks: 'Broj taskova',
  completedTasks: 'Završeni taskovi',
  completedTasksPercentage: 'Completed task %',
  estimatedHoursInTotal: 'Estimated hours total',
  totalHours: 'Total hours',
  buyDate: 'Buy date',
  model: 'Model',
  warehouse: 'Warehouse',
  document: 'Document',
  invoiceNumber: 'Invoice number',
  manufacturer: 'Manufacturer',
  serialNo: 'Serial number',
  assignType: 'Assign type',
  warrantyDue: 'Warranty due',
  coordinates: 'Coordinates',
  reserved: 'Reserved',
  version: 'Version',
  mainConsent: 'Main consent',
  taskCount: 'Task count',
  statusNote: 'Status note',
  statusTimestamp: 'Status date',
  consent: 'Consent',
  user_thumbnail: 'User thumbnail',
  agreement: 'Agreement',
  sourceId: 'Source',
  user_email: 'E-mail',
  user_personalIdNumber: 'Personal id number',
  estimated: 'Estimated',
  estimatedInHours: 'Estimated in hours',
  unitPrice: 'Unit price',
  hourPrice: 'Hour price',
  total: 'Total',
  totalInHours: 'Total in hours',
  mainBranch: 'Main branch',
  stockValuationMethod: 'Stock valuation method',
  isBillable: 'Is billable',
  module: 'Module',
  access: 'Access',
  birthday: 'Birthday',
  exportQuestion: 'Do you want to export the current page or all pages',
  checkedItems: 'Checked items',
  currentPage: 'Current page',
  allPages: 'All pages',
  exportTo: 'Export to',
  csv: '.csv',
  xlsx: '.xlsx',
  exportItems: 'Export items',
  filename: 'Filename',
  enable: 'Enable',
  disable: 'Disable',
  name_thumbnail: '',
  createdBy_thumbnail: '',
  owner_image: 'Owner',

  //AUTH

  usernameEmail: 'Username/Email',
  changePassword: 'Change password',
  oldPassword: 'Old password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  forgotPassword: 'Forgot password',
  resetPassword: 'Reset password',

  //fileUpload

  maxFileSizeWarn: 'UploadedFile to big',
  maxFileSizeError: 'Max file size is',

  //Video

  noVideoUploaded: 'No video uploaded',

  //breadCrumbs

  home: 'Home',
  companies: 'Companies',
  users: 'Users',
  details: 'Details',
  contacts: 'Contacts',
  webusers: 'Web users',
  branches: 'Branches',
  projects: 'Projects',
  newproduct: 'New Product',
  editproduct: 'Edit Product',
  newuser: 'New User',
  edituser: 'Edit User',
  newbranch: 'New Branch',
  editbranch: 'Edit Branch',
  newcompany: 'New Company',
  editcompany: 'Edit Company',
  analytics: 'Analytics',
  sessions: 'Sessions',
  ui: 'UI',
  info: 'Info',
  admin: 'Admin',
  lists: 'Lists',
  overview: 'Overview',
  conversions: 'Conversions',
  alerts: 'Alerts',
  documents: 'Documents',
  courses: 'Courses',
  materials: 'Materials',
  productsmaterials: 'Products and materials',
  elearning: 'E-learning',
  participants: 'Participants',
  virtualclassroom: 'Virtual Classroom',
  ecommerce: 'Ecommerce',
  paymentterms: 'Payment Terms',
  deliveryterms: 'Delivery Terms',
  opportunity: 'Opportunity',
  mailbox: 'Mailbox',
  inbox: 'Inbox',
  sent: 'Sent',
  drafts: 'Drafts',
  trash: 'Trash',
  reports: 'Reports',
  gdpr: 'Data protection',
  consents: 'Consents',
  newconsent: 'New consent',
  editconsent: 'Edit consent',
  consentlogs: 'Consent logs',
  agreements: 'Agreements',
  newagreement: 'New agreement',
  editagreement: 'Edit agreement',
  newconsentlog: 'New consent log',
  editconsentlog: 'Edit consent log',
  consentLog: 'Consent log',
  departments: 'Departments',
  newdepartment: 'New department',
  editdepartment: 'Edit department',
  newcourse: 'New course',
  editcourse: 'Edit course',
  attributes: 'Attributes',
  newattribute: 'New attribute',
  editattribute: 'Edit attribute',
  attributesets: 'Attribute sets',
  newattributeset: 'New attribute set',
  editattributeset: 'Edit attribute set',
  productsservices: 'Products and services',
  newservice: 'New service',
  editservice: 'Edit service',
  materialsexternalservices: 'Materials and external services',
  newmaterial: 'New material',
  editmaterial: 'Edit material',
  externalservices: 'External services',
  newexternalservice: 'New external service',
  editexternalservice: 'Edit external service',
  newclass: 'New class',
  editclass: 'Edit class',
  classes: 'Classes',
  classdetails: 'Class details',
  discussiondetails: 'Discussion details',
  editdiscussion: 'Edit discussion',
  newdiscussion: 'New discussion',
  classrooms: 'Classrooms',
  newclassroom: 'New classroom',
  editclassroom: 'Edit classroom',
  newwarehouse: 'New warehouse',
  editwarehouse: 'Edit warehouse',
  newproject: 'New project',
  editproject: 'Edit project',
  newtask: 'New task',
  edittask: 'Edit task',
  timeexpenses: 'Time & Expenses',
  kanban: 'Kanban',
  humanresources: 'Human resources',
  attendance: 'Attendance',
  workstatus: 'Work status',
  absenceschedule: 'Absence schedule',
  healthandsafety: 'Health & Safety',
  bins: 'Bins',
  newbin: 'New bin',
  editbin: 'Edit bin',
  newasset: 'New asset',
  editasset: 'Edit asset',
  assets: 'Assets',
  report: 'Report',
  newcategory: 'New category',
  editcategory: 'Edit category',
  managecategories: 'Manage categories',
  exams: 'Exams',
  exam: 'Exam',
  examslist: 'Exam list',
  newexam: 'New exam',
  editexam: 'Edit exam',
  cms: 'CMS',
  stores: 'Stores',
  commercialprofiles: 'Commercial profiles',
  newcommercialprofile: 'New commercial profile',
  editcommercialprofile: 'Edit Commercial profile',
  commercialprofile: 'Commercial profile',
  couponsets: 'Coupon sets',
  gaussDrive: 'Storage',
  inboundinvoicereport: 'Outgoing invoice report',
  contractreport: 'Contract report',
  inboundorderreport: 'Inbound order report',
  outgoingorderreport: 'Outgoing order report',
  projectgeneralreport: 'Project general report',
  projectloggedtimereport: 'Project logged time report',
  stock: 'Stock',
  stockmovement: 'Stock movement',
  projectportfolioreport: 'Project Portfolio Report',
  appearance: 'Appearance',
  blogposts: 'Blog Posts',
  comments: 'Comments',
  forms: 'Forms',
  pages: 'Pages',
  addnewpage: 'Add new page',
  editpage: 'Edit page',
  addnewpost: 'Add new post',
  editpost: 'Edit post',
  addnewcategory: 'Add new category',
  addnewtag: 'Add new tag',
  edittag: 'Edit tag',
  projectstatusreport: 'Project Status Report',
  employeeworkloadreport: 'Employee Workload Report',
  navigation: 'Navigation',
  editlink: 'Edit link',
  rolelist: 'Role list',
  newrole: 'New role',
  editrole: 'Edit role',
  generalSettings: 'General settings',
  leadmanagement: 'Lead management',
  newlead: 'New lead',
  editlead: 'Edit lead',
  gaussdrive: 'Gauss drive',
  system: 'System',
  catalog: 'Catalog',
  invoices: 'Invoices',
  orders: 'Orders',
  deliverymethods: 'Delivery methods',
  paymentmethods: 'Payment methods',
  redirects: 'Redirects',
  trackingtools: 'Tracking tools',
  productpages: 'Product pages',

  //Activity

  recentActivity: 'Recent activity',
  recentActivities: 'Recent activities',
  modified: 'Modified',
  logged_in: 'Logged in',
  moved_to_archive: 'Moved to archive',
  restored_from_archive: 'Restored from archive',
  reopened: 'Responded',
  uploaded: 'Uploaded',
  cashRegister: 'Cash register',
  department: 'Department',
  product: 'Product',
  simple_product: 'Simple product',
  complex_product: 'Complex product',
  eProduct_product: 'e-Product',
  service: 'Service',
  simple_service: 'Simple service',
  complex_service: 'Complex service',
  eProduct_service: 'e-Service',
  material: 'Material',
  externalService: 'External service',
  attribute: 'Attribute',
  attributeSet: 'Attribute set',
  course: 'Course',
  courseExam: 'Exam',
  class: 'Class',
  discussion: 'Discussion',
  asset: 'Asset',
  projectDiscussion: 'discussion',
  removed_from_stock: 'removed from stock',
  added_to_stock: 'added to stock',
  noCheckedItems: 'There are no checked items',
  findFreeRooms: 'Find free rooms',
  freeRooms: 'Free rooms',

  //Layout

  language: 'Language',
  updateprofile: 'Update profile',
  changepassword: 'Change password',
  logout: 'Log out',
  default: 'Default',
  custom: 'Custom',
  business: 'Business',
  productsAndMaterials: 'Products & materials',
  NoCompanyWarningHeader: 'No company registered',
  NoCompanyWarningText: 'You do not have a company registered, please create a company in order to use the application',
  error: 'Error',
  colorscheme: 'Color scheme',
  addnewuser: 'Add new user',
  addnewcompany: 'Add new company',
  addnewbranch: 'Add new branch',
  addnewdepartment: 'Add new department',

  //User

  iban: 'IBAN',
  savechanges: 'Save changes',
  firstname: 'First name',
  lastname: 'Last name',
  companybranch: 'Company branch',
  phonenumber: 'Phone number',
  oib: 'Personal ID Number',
  birthdate: 'Birth date',
  placeofbirth: 'Place of birth',
  streetaddress: 'Street address',
  postalcode: 'Postal code',
  stateprovince: 'State/Province',
  selectpermission: 'Select permission',
  workGroup: 'Workgroup',
  weak: 'Weak',
  medium: 'Medium',
  strong: 'Strong',
  enterPassword: 'Enter password',
  usercreated: 'User was created',
  userupdated: 'User was updated',
  userarchived: 'User was archived',
  userrestored: 'User was restored',
  useranonymised: 'User was anonymised',
  useridentified: 'User was anonymised',
  sendmail: 'Send email',
  selecttype: 'Please select type',
  dateofbirth: 'Date of birth',
  individuaalinformation: 'Individual information',
  occupationinformation: 'Occupation information',
  companyname: 'Company name',
  companyaddress: 'Company address',
  profileImageRemoved: 'Profile image removed',
  identify: 'Identify',
  isAnonimysed: 'Anonymize',
  webuser: 'Web user',
  contactPersonFirstName: 'Contact person first name',
  contactPersonLastName: 'Contact person last name',
  contactPersonPhone: 'Contact person phone',

  //Company

  addnew: 'Add new',
  shortname: 'Short name',
  clientcompany: 'Client company',
  industrytype: 'Industry type',
  companyidnumber: 'Company ID Number',
  vatnumber: 'Vat number',
  housenumber: 'House number',
  selectimage: 'Select image',
  hasaccesstooltip: 'Client company is a fictive company',
  contactcheckboxtooltip: 'Your main contact',
  companycreated: 'New company has been created',
  companyupdated: 'Company has been updated',
  companyarchived: 'Company archived',
  companyrestored: 'Company restored',
  selectcompany: 'Select company',
  selectcompanytext: 'Please select a company',
  parent: 'Parent',
  departmentcreated: 'Department created',
  departmentupdated: 'Department updated',
  departmentarchived: 'Department archived',
  companyLogo: 'Company logo',
  priceList: 'Price list',
  contact: 'Contact',

  //Branch

  noteofbusinessarea: 'Note of business area',
  noteofbusinessareainfo: 'Obligatory if branch is issuing invoices',
  workhours: 'Work hours',
  zipcode: 'Zip code',
  branchcreated: 'Branch created',
  branchupdated: 'Branch updated',
  brancharchived: 'Branch archived',
  branchRestored: 'Branch restored',
  cashregisters: 'Cash registers',
  newdevice: 'New device',
  editdevice: 'Edit device',
  devicetype: 'Type',
  retail: 'Retail',
  cashregister: 'Cash register ',
  invoicestartnumber: 'Invoice start number',
  cashregistercreated: 'Cash register created',

  //Admin

  projectsSettings: 'Projects settings',
  eLearningSettings: 'E-learning settings',
  listsettings: 'List settings',
  generalsettings: 'General settings',
  settingssave: 'Settings save',
  hourUnits: 'Hours',
  minutes: 'Minutes',
  days: 'Days',
  defaultTheme: 'Default',
  customTheme: 'Custom',
  businessTheme: 'Business',
  attributeSets: 'Attribute sets',
  attributeSetCreated: 'Attribute set created',
  attributeSetUpdated: 'Attribute set updated',
  attributeSetArchived: 'Attribute set archived',
  attributeSetRestored: 'Attribute set restored',
  uploadHeader: 'Upload header',
  uploadFooter: 'Upload footer',
  defaultType: 'Default type assignation',
  defaultTypeNote: 'Show document types to specific departments, workgroups or people.',
  enableMemo: 'Enable company memo',
  documentTraceability: 'Document traceability',
  documentTraceabilityNote: 'Define order in which documents can be created..',
  documentAuthorisation: 'Document authorisation',
  documentAuthorisationNote: 'Set authorisation persons for document types.',
  documentSettings: 'Document settings',
  defaultLanguage: 'Default language',
  defaultColorTheme: 'Default color theme',
  mainCompanyCurrency: 'Main company currency',
  currencyFormat: 'Currency format',
  numberFormat: 'Number format',
  dateAndTime: 'Date & Time',
  timeFormat: 'Time format',
  dateFormat: 'Date format',
  timeZone: 'Time zone',
  twoStepAuth: 'Two-step login authentication',
  daylightSavings: 'Daylight savings',
  entityType: 'Entity type',
  createdOn: 'Created on',
  url: 'Url',
  apiKeyToken: 'Api key token',
  copyToken: 'Copy token',
  tokenCopied: 'api key token copied',
  addDomain: 'Add domain',
  editDomain: 'Edit domain',
  domain: 'Domain',
  activeDomains: 'Active domains',
  eLearning: 'E-learning',
  inbound: 'Inbound',
  outgoing: 'Outgoing',
  internal: 'Internal',
  subtask: 'Sutbtask',
  profileName: 'Profile name',
  selectProfile: 'Select profile',
  emailSettings: 'E-mail settings',
  accountSettings: 'Account settings',
  incomingSettings: 'Incoming settings',
  outgoingSettings: 'Outgoing settings',
  emailAccountType: 'Email account type',
  protocol: 'Protocol',
  hostname: 'Hostname',
  port: 'Port',
  encryption: 'Encryption',
  authentication: 'Authentication',
  normalPassword: 'Normal password',
  encryptedPassword: 'Encrypted password',
  kerberosGSSAPI: 'Kerberos / GSSAPI',
  ntlm: 'NTLM',
  oAuth: 'OAuth',
  languageError: 'Content with the selected language already exists',
  fileSizeError: 'Uploaded file size exceeds the limit',
  templateContent: 'Content',
  disableCommentEditingAfter: 'Disable comment editing after',
  projectsSettingsSaved: 'Projects settings saved',
  eLearningSettingsSaved: 'E-learning settings saved',
  permissionGroup: 'Permission group',
  permissionGroups: 'Permission groups',
  assetNumberPattern: 'Asset number pattern',
  warehouseSettingsSaved: 'Warehouse setting saved',
  legend: 'Legend',
  year: 'Year',
  counter: 'Counter',

  //Products

  newProduct: 'New product',
  services: 'Services',
  text: 'Text',
  textArea: 'Text area',
  confirmation: 'Yes/No',
  selectField: 'Select',
  multiSelectField: 'Multi select',
  attributeFields: 'Attribute fields',
  values: 'Values',
  advanced: 'Advanced',
  defaultValue: 'Default value',
  setDefaultValue: 'Set default',
  required: 'Required',
  code: 'Code',
  uniqueValue: 'Unique value',
  unique: 'Unique',
  addToColumn: 'Add to column',
  useInFilter: 'Use in filter',
  useInSearch: 'Use in search',
  inputType: 'Input type',
  attributeCreated: 'Attribute created',
  attributeUpdated: 'Attribute updated',
  attributeDeleted: 'Attribute deleted',
  attributeRestored: 'Attribute restored',
  state: 'State',
  longDescription: 'Long description',
  breakAge: 'Breakage',
  weight: 'Weight',
  weightUnit: 'Weight unit',
  productCreated: 'Product created',
  productUpdated: 'Product updated',
  productArchived: 'Product archived',
  productRestored: 'Product restored',
  serviceCreated: 'Service created',
  serviceUpdated: 'Service updated',
  serviceArchived: 'Service archived',
  serviceRestored: 'Service restored',
  parentCategory: 'Parent Category',
  format: 'Format',
  materialCreated: 'Material created',
  materialUpdated: 'Material updated',
  materialArchived: 'Material archived',
  materialRestored: 'Material restored',
  'external-service': 'external service',
  subType: 'Sub type',
  pleaseCreateCategoryProduct: 'Products and services need to be assigned to category, please create a category first',
  simple: 'Simple',
  complex: 'Complex',
  eProduct: 'eProduct',
  rawMaterials: 'Raw materials',
  spareParts: 'Spare parts',
  beverages: 'Beverages',
  tradingGoods: 'Trading goods',
  smallInventory: 'Small inventory',
  tires: 'Tires',
  packaging: 'Packaging',
  waste: 'Waste',
  useDefault: 'Use default',
  store: 'Store',
  commercialProfiles: 'Commercial profiles',
  couponSets: 'Coupon sets',
  commercialProfile: 'Commercial profile',
  newCommercialProfile: 'New commercial profile',
  editCommercialProfile: 'Edit commercial profile',
  newCouponSet: 'New coupon set',
  editCouponSet: 'Edit coupon set',
  isEnabled: 'Is enabled',
  isStackable: 'Is stackable',
  discountType: 'Discount type',
  shippingDiscount: 'Shipping amount',
  discountTarget: 'Discount target',
  priority: 'Priority',
  startDate: 'Start date',
  endDate: 'End date',
  applyForStore: 'Apply for store',
  profileDescription: 'Profile description',
  profileApplication: 'Application',
  always: 'Always',
  viaCouponCodes: 'Via coupon codes',
  viaReferrals: 'Via referrals',
  relative: 'Relative',
  absolute: 'Absolute',
  singleUnit: 'Single unit',
  viaContacts: 'Via contacts',
  assignedProducts: 'Assigned products',
  assignedClients: 'Assigned clients',
  allProducts: 'All products',
  commercialProfileCreated: 'Commercial profile created',
  commercialProfileUpdated: 'Commercial profile updated',
  commercialProfileArchived: 'Commercial profile archived',
  commercialProfileRestored: 'Commercial profile restored',
  profileType: 'Profile type',
  relatedProducts: 'Related products',
  vendor: 'Vendor',
  vendors: 'Vendors',
  manufacturers: 'Manufacturers',
  brand: 'Brand',
  brands: 'Brands',
  page: 'Page',
  productToPage: 'Product assigned to page',
  addToEcommerce: 'Add to e-commerce',
  addedToEcommerce: 'Items added to e-commerce',
  removeFromStore: 'Remove from store',
  removedFromStore: 'Items removed from the store',
  retailPrice1: 'Retail price',
  hasWeight1: 'Has weight',
  forRegOnly: 'For registered users only',
  applyCartRules: 'Apply cart rules',
  couponCodeNum: 'Number of coupon codes',
  manageReferrals: 'Manage referrals',
  manageCouponCodes: 'Manage coupon codes',
  noTimeLimit: 'No time limit',
  spanInDays: 'Lifespan in days',
  timeInterval: 'Time interval',
  noCouponCodes: 'No codes (use referrals only)',
  singleCouponCode: 'Single code',
  multipleCouponCodes: 'Multiple codes',
  couponSetCreated: 'Coupon set created',
  couponSetUpdated: 'Coupon set updated',
  couponSetArchived: 'Coupon set archived',
  couponSetRestored: 'Coupon set restored',
  couponCodes: 'Coupon codes',
  mustSaveProfile: 'In order to manage coupon codes, commercial profile has to be saved. Save current commercial profile?',
  codePrefix: 'Code prefix',
  codeSuffix: 'Code sufix',
  dashEveryXDigits: 'Dash every x digits',
  noOfCodes: 'Number of codes',
  usesPerReferralLink: 'User per referral link',
  usesPerUser: 'Uses per user',
  noOfProductUnits: 'Number of product units',
  combineCoupons: 'Combine coupons',
  forRegisterUsers: 'For registered users',
  validFrom: 'Valid from',
  expiryDate: 'Expiry date',
  couponsGenerated: 'Coupons generated',
  couponCode: 'Coupon code',
  couponEdited: 'Coupon edited',
  specialPrice: 'Active special price',
  currentSpecialPrice: 'Current special price',
  specialPrices: 'Special prices',
  specialPriceCreate: 'Create special price',
  specialPriceEdit: 'Edit special price',
  specialPriceCreated: 'Special price created',
  specialPriceUpdated: 'Special price updated',
  manageSpecialPrices: 'Manage special prices',
  specialPriceArchived: 'Special price archived',
  specialPriceRestored: 'Special price restored',
  timePeriod: 'Time period',

  //E-learnigs

  newCourse: 'New course',
  editCourse: 'Edit course',
  virtualClassroom: 'Virtual classroom',
  virtualClassrooms: 'Virtual classrooms',
  educator: 'Educator',
  educators: 'Educators',
  findEducator: 'Find educator',
  findEducators: 'Find educators',
  educatorsOnCourse: 'Educators already on course',
  student: 'Student',
  students: 'Students',
  findStudent: 'Find student',
  findStudents: 'Find students',
  studentsOnCourse: 'Students already on course',
  addParticipant: 'Add participant',
  addParticipants: 'Add participants',
  upload: 'Upload',
  assignee: 'Assignee',
  assignees: 'Assignees',
  participant: 'Participant',
  repeat: 'Repeat',
  courseCreated: 'Course created',
  courseUpdated: 'Course updated',
  courseArchived: 'Course archived',
  courseRestored: 'Course restored',
  participantsUpdated: 'Participants updated',
  moderator: 'Moderator',
  moderators: 'Moderators',
  moderatorsOnCourse: 'Moderators already on course',
  moderatorsUpdated: 'Moderators updated',
  addNewClass: 'New class',
  editClass: 'Edit class',
  liveStreaming: 'Live streaming',
  classCreated: 'Class created',
  classUpdated: 'Class updated',
  classArchived: 'Class archived',
  classRestored: 'Class restored',
  projectLeader: 'Project leader',
  commentCreated: 'Comment created',
  commentUpdated: 'Comment updated',
  commentDeleted: 'Comment deleted',
  createDiscussion: 'Create discussion',
  editDiscussion: 'Edit discussion',
  discussionCreated: 'Discussion created',
  discussionUpdated: 'Discussion updated',
  discussionArchived: 'Discussion archived',
  discussionRestored: 'Discussion restored',
  createClassroom: 'New classroom',
  editClassroom: 'Edit classroom',
  classroomCreated: 'Classroom created',
  classroomUpdated: 'Classroom updated',
  classroomArchived: 'Classroom deleted',
  classroomRestored: 'Classroom restored',
  passingPoints: 'Passing points',
  enableTimeLimit: 'Enable time limit',
  examName: 'Exam name',
  publishExam: 'Publish exam',
  addQuestion: 'Add question',
  examCreated: 'Exam created',
  examUpdated: 'Exam updated',
  examDeleted: 'Exam deleted',
  examRestored: 'Exam restored',
  timeLimit: 'Time limit',
  newExam: 'New exam',
  saveExam: 'Save exam',
  examType: 'Exam type',
  editExam: 'Edit exam',
  previewExam: 'Preview exam',
  submitExam: 'Submit exam',
  resetExam: 'Reset exam',
  examSubmitted: 'Exam submitted',
  examReset: 'Exam has been reset',
  allowContinue: 'Allow continue',
  disqualify: 'Disqualify',
  question: 'Question',
  questionType: 'Question type',
  answer: 'Answer',
  addAnswer: 'Add answer',
  points: 'Points',
  penalty: 'Penalty points',
  typeAnswer: 'Type answer',
  closePreview: 'Close preview',
  result: 'Result',
  singleTypeText: 'Single choice',
  multiTypeText: 'Multiple choice',
  boolTypeText: 'True/False question',
  gapsTypeText: 'Fill in the gaps',
  matchTypeText: 'Match pairs',
  essayTypeText: 'Essay question',
  shortTypeText: 'Short answer',
  passed: 'Passed',
  failed: 'Failed',
  takeExam: 'Take exam',
  continueExam: 'Continue exam',
  viewResults: 'View results',
  timeLeft: 'Time left',
  gradeExam: 'Grade exam',
  gradeExams: 'Grade exams',
  examGraded: 'Exam graded',
  examsGraded: 'Exams graded',
  viewExam: 'View exam',
  pointsUpdated: 'Points updated',

  //Project

  estimated1: 'Est. unit quantity',
  unitPrice1: 'Est. unit price',
  total1: 'Unit total',
  projectCreated: 'Project created',
  projectUpdated: 'Project updated',
  projectArchived: 'Project archived',
  projectRestored: 'Project restored',
  newProject: 'New project',
  editProject: 'Edit project',
  estimationUnit: 'Estimation unit',
  estimationUnits: 'Estimation units',
  estimationLogCreate: 'Log estimation',
  estimationLogEdit: 'Edit estimation',
  observers: 'Observers',
  notBillable: 'Not-billable',
  firstTask: 'Create first task',
  newTask: 'New task',
  editTask: 'Edit task',
  taskCreated: 'Task created',
  taskUpdated: 'Task updated',
  taskArchived: 'Task archived',
  taskRestored: 'Task restored',
  employees: 'Employees',
  clients: 'Clients',
  pleaseSelectEstimatedBy: 'Please select estimated by',
  subscribersUpdated: 'Subscribers updated',
  addSubTask: 'Add subtask',
  subTask: 'Subtask',
  subTasks: 'Subtasks',
  showCompleted: 'Show completed',
  timeAndExpenses: 'Time & Expenses',
  logCreated: 'Log created',
  logUpdated: 'Log updated',
  logDeleted: 'log deleted',
  logTime: 'Log time',
  logActivity: 'Log activity',
  logExpense: 'Log expense',
  thisWeek: 'This week',
  previousMonth: 'This month',
  loggedTime: 'Log time',
  loggedExpense: 'Log expense',
  loggedActivity: 'Log activity',
  log: 'Log',
  time: 'Time',
  cantUpdateKanban: 'Yout do not have permissions to update kanban',
  complete: 'Complete',
  sortByLabel: 'Sort by label',
  sortByMilestone: 'Sort by milestone',
  refreshTasks: 'Refresh tasks',
  logTypes: 'Log types',
  timeFrameFrom: 'Timeframe from',
  timeFrameTo: 'Timeframe to',
  groupBy: 'Group by',
  responsiblePerson: 'Responsible person',
  exportProject: 'Export project data',
  importProject: 'Import project',
  noTasksSelected: 'No tasks selected',
  noDiscussionsSelected: 'No discussions selected',
  nothingSelected: 'Nothing selected',
  importProjectCategories: 'Import project categories',
  importProjectLabels: 'Import project labels',
  importTaskCategories: 'Import task categories',
  importTaskLabels: 'Import task labels',
  importDiscussionCategories: 'Import discussion categories',
  importDiscussionLabels: 'Import discussion labels',
  internalProject: 'Internal project',
  projectImported: 'Project imported',

  //Warehouse

  warehouseCreated: 'Warehouse created',
  warehouseUpdated: 'Warehouse updated',
  warehouseDeleted: 'Warehouse deleted',
  warehouseArchived: 'Warehouse archived',
  warehouseRestored: 'Warehouse restored',
  newWarehouse: 'New warehouse',
  editWarehouse: 'Edit warehouse',
  binCreated: 'Bin created',
  binUpdated: 'Bin updated',
  binDeleted: 'Bin archived',
  binRestored: 'Bin restored',
  newBin: 'New bin',
  editBin: 'Edit bit',
  assetCreated: 'Asset created',
  assetUpdated: 'Asset updated',
  assetDeleted: 'Asset archived',
  assetRestored: 'Asset restored',
  newAsset: 'New asset',
  editAsset: 'Edit asset',
  warehouseList: 'Warehouse list',
  dispatchOrderType: 'Dispatch order type',
  workers: 'Workers',
  parentBranch: 'Parent branch',
  linkedBranches: 'Linked branches',
  linkedWarehouses: 'Linked warehouses',
  stockMovement: 'Stock movement',
  transferSchedule: 'Transfer schedule',
  maxLoad: 'Max load (kg)',
  semiProducts: 'Semi products',
  mixed: 'Mixed',
  long: 'Long',
  short: 'Short',
  belowMin: 'Below min',
  aboveMax: 'Above max',
  minimumStock: 'Minimum stock',
  maximumStock: 'Maximum stock',
  uom: 'UOM',
  qty: 'Qty',
  totalQty: 'Total Qty',
  reservedQty: 'Reserved Qty',
  availableQty: 'Available Qty',
  issuedQty: 'Issued Qty',
  receivedQty: 'Received Qty',
  receivegoods: 'Receive goods',
  viewbins: 'View bins',
  dispatch: 'Dispatch',
  'write-off': 'Write-off',
  assigned: 'Assigned',
  warranty: 'Warranty',
  assignTo: 'Assign to',
  assign: 'Assign',
  return: 'Return',
  selectUser: 'select user',
  assetAssignedMsg: 'Asset assigned to ',
  assetReturnedMsg: 'Asset returned',
  assetAlreadyAssigned: 'This asset is already assigned.',
  internalCode: 'Internal code',
  itemCode: 'Item code',
  autoGenerateSku: 'Auto generate sku',

  //Reports

  advancedProjectSearchAndFiltering: ' Advanced project search and filtering',
  advancedDocumentSearchAndFiltering: 'Advanced document search and filtering',
  lastMonth: 'Last month',
  lastWeek: 'Last week',
  totalTime: 'Total time',
  projectGeneralReport: 'Project general report',
  projectLoggedTimeReport: 'Project logged time report',
  outgoingInvoiceReport: ' Outgoing invoice report',
  inboundInvoiceReport: 'Inbound invoice report',
  outgoingOrderReport: 'Outgoing order report',
  contractReport: 'Contract report',
  contracts: 'Contracts',
  loggedTimeReport: 'Logged time report',
  timeExpensesReport: 'Time & expenses report',
  issuedFromBranch: 'Issued from branch',
  issuedFromDevice: 'Issued from device',
  issuedOnStartDate: 'Issued on start date',
  issuedOnEndDate: 'Issued on end date',
  dueDateFrom: 'Due date from',
  dueDateTo: 'Due date to',
  documentLabel: 'Document label',
  validUntilFrom: 'Valid until from',
  validUntilTo: 'Valid until to',
  validUntil: 'Valid until',
  startDateFrom: 'Start date from',
  startDateTo: 'Start date to',
  endDateFrom: 'End date from',
  endDateTo: 'End date to',
  estimatedBudgetFrom: 'Estimated budget from',
  estimatedBudgetTo: 'Estimated budget to',
  projectFilters: 'Project filters',
  clientType: 'Client type',
  selectClient: 'Select client',
  projectLabel: 'Project label',
  projectCategory: 'Project category',
  projectName: 'Project name',
  taskFilters: 'Task filters',
  taskType: 'Task type',
  taskLabel: 'Task label',
  logFilters: 'Log filters',
  logType: 'Log type',
  logStatus: 'Log status',
  loggedDate: 'Logged date',
  when: 'When',
  changed: 'Changed',
  removed: 'removed',
  commented: 'commented',
  activated: 'activated',
  newAssignees: 'new assignees added',
  removedAssignees: 'assignees where removed',
  newParticipants: 'new participants added',
  removedParticipants: 'participants where removed',
  newModerators: 'new moderators added',
  assignedModerator: 'moderator was assigned',
  assignedModerators: 'moderator where assigned',
  removedModerator: 'moderator was removed',
  removedModerators: 'moderators where removed',
  newObservers: 'new observers added',
  assignedObserver: 'observer was assigned',
  removedObserver: 'observer was removed',
  removedObservers: 'observers where removed',
  assignedSubscribers: 'subscribers where assigned',
  removedSubscribers: 'subscribers where removed',
  outgoingoffer: 'Outgoing offer',
  outgoinginquiry: 'Outgoing inquiry',
  outgoinginvoice: 'Outgoing invoice',
  outgoingorder: 'Outgoing order',
  outgoingletter: 'Outgoing letter',
  inboundoffer: 'Inbound offer',
  inboundinvoice: 'Inbound invoice',
  inboundinquiry: 'Inbound inquiry',
  inboundorder: 'Inbound order',
  inboundletter: 'Inbound letter',
  internaloffer: 'Internal offer',
  internalinquiry: 'Internal inquiry',
  internalinvoice: 'Internal invoice',
  internalorder: 'internal order',
  internalletter: 'Internal letter',
  portfolioReport: 'Portfolio report',
  projectStatusReport: 'Project status report',
  employeeWorkload: 'Employee workload report',
  //gdpr

  displayName1: 'Display name',
  newConsent: 'New consent',
  editConsent: 'Edit consent',
  providerName: 'Provider name',
  consentName: 'Consent name',
  setBy: 'Set by',
  purposesText: 'Purposes text',
  consentCreated: 'Consent created',
  consentEdited: 'Consent edited',
  newAgreement: 'New agreement',
  editAgreement: 'Edit agreement',
  agreementName: 'Agreement name',
  agreementCreated: 'Agreement created',
  agreementEdited: 'Agreement edited',
  consentLogs: 'Consent logs',
  newConsentLog: 'New consent log',
  editConsentLog: 'Edit consent log',
  logDate: 'Log date',
  consentGiver: 'Consent giver',
  files: 'Files',

  //Documents

  offer: 'Offer',
  order: 'Order',
  contract: 'Contract',
  referentdocument: 'Referent document',
  linkeddocument: 'Linked document',
  contacttitle: 'Contract title',
  contractnetamount: 'Contact net amount',
  contractgrandtotal: 'Contract grand total',
  assethandover: 'Asset handover',
  assetreturn: 'Asset return',
  warehousetransfer: 'Warehouse transfer',
  //DOCUMENTS SINGLICA
  invoicetype: 'Invoice type',
  paymenttype: 'Payment type',
  paymentterm: 'Payment term',
  validuntil: 'Valid until',
  selectbranch: 'select branch',
  selectptype: 'select payment type',
  selectpterm: 'select payment term',
  selectincoterms: 'select incoterms',
  entercompany: 'Enter company',
  selectcontact: 'Select contact',
  entercontact: 'Enter contact',
  inquirynumber: 'Inquiry number',
  contactperson: 'Contact person',
  itemdetails: 'Item details',
  deliverydetails: 'Delivery details',
  deliverynotes: 'Delivery notes',
  saleswarrant: 'Sales warrant',
  devices: 'Devices',
  selectdevices: 'select devices',
  nodevices: 'No devices',
  warehousereceivingassets: 'Warehouse receiving assets',
  personreceivingassets: 'Person receiving assets',
  personreturningassets: 'Person returning assets',
  issuedby: 'Issued by',
  issuerbranch: 'Issuer branch',
  issuerwarehouse: 'Issuer warehouse',
  issuerperson: 'Issuer person',
  deliverycompany: 'Delivery company',
  deliveryperson: 'Delivery person',
  deliveryvehiclereg: 'Delivery vehicle registration',
  receivedon: 'Received on',
  receiverwarehouse: 'Receiver warehouse',
  receiverperson: 'Receiver person',
  itemtype: 'Item type',
  receivedby: 'Received by',
  //DOCUMENTS LIST
  issuedfrom: 'Issued from',
  issuedto: 'Issued to',
  issuedon: 'Issued on',
  duedate: 'Due date',
  grandtotal: 'Grand total',
  exporttopdf: 'Export to PDF',
  selectinvtype: 'select invoice type',
  requisitionwarrant: 'Requisition warrant',
  requisitionwarrantslip: 'Issue note',
  warehousereciept: 'Warehouse reciept',
  receive: 'Receive',
  deliverydate: 'Delivery date',
  unitprice: 'Unit price',
  discount: 'Discount',
  tax: 'Tax',
  netprice: 'Net price',
  nettotal: 'Net total',
  itemsnettotal: 'Items net total',
  pretaxtotal: 'Pre-tax total',
  travelWarrantCreate: 'Create from existing',
  taxrate: 'Tax rate',
  taxbase: 'Tax base',
  taxamount: 'Tax amount',
  notewarn: 'This note is never displayed to the client or included in the document.',
  writepublicnote: 'Write public note',
  choose: 'Choose',
  internalnote: 'Internal note',
  selectlabel: 'select label',
  referencenumber: 'Reference number',
  offernumber: 'Offer number',
  "inbound-inquiry": "Inquiry inbound",
  "inbound-invoice": "Invoice inbound",
  "inbound-letter": "Letter inbound",
  "inbound-offer": "Offer inbound",
  "inbound-order": "Order inbound",
  "internal-assethandoutform": "Asset handover",
  "internal-assetreturnform": "Asset return",
  "internal-deliverynote": "Delivery note",
  "internal-goodsreceipt": "Goods receipt",
  "internal-issuenote": "Issue note",
  "internal-letter": "Letter internal",
  "internal-requisitionwarrant": "Requisition warrant ",
  "internal-warehousetransfer": "Warehouse transfer",
  "outgoing-inquiry": "Inquiry outgoing",
  "outgoing-invoice": "Invoice outgoing",
  "outgoing-letter": "Letter outgoing",
  "outgoing-offer": "Offer outgoing",
  "outgoing-order": "Order outgoing",
  "special-contract": "Contract",
  //Missing
  "asset-handover": "Asset handover",
  "asset-return": "Asset return",
  issuenote: "Issue note",
  goodsreceipt: 'Goods receipt',
  "warehouse-transfer": "Warehouse transfer",
  //ASSETS
  warrantydue: 'Warranty due',
  serialnumber: 'Serial number',
  assetnumber: 'Asset number',
  assetname: 'Asset name',
  //WAREHOUSE
  warehousereceipt: 'Warehouse receipt',
  selectwarehouse: 'select warehouse',
  selectsupplier: 'Select supplier',
  selectorder: 'Select order',
  deliverynotenumber: 'Delivery note number',
  entersupplier: 'Enter supplier',
  recieve: 'Recieve',
  ordernumber: `Buyers order number`,
  orderitem: 'Ord. item',
  orderqty: 'Ord. qty.',
  avaliableqty: 'Available qty',
  recieveqty: 'Recieved qty',
  selectbin: 'select bin',
  addbin: 'Add bin',
  nocontact: 'No contacts for current branch',
  deliverydue: 'Delivery due',
  purpose: 'Purpose',
  reqwarquantity: 'Req.war.qty',
  issuedqty: 'Issued qty',
  recievedqty: 'Recieved qty',
  receptiontobins: 'Reception to bins',
  //LETTER
  lettertitle: 'Letter title',
  lettercontents: 'Letter contents',
  selectwarrant: 'select warrant',
  selectdepartment: 'select department',
  ////
  selectissuerbranch: 'Select issuer branch',
  selectissuerwarehouse: 'Select issuer warehouse',
  selectreciever: 'Select reciever',
  selectrecievertype: 'Select reciever type',
  selectrecieverbranch: 'Select reciever branch',
  selectrecieverwarehouse: 'Select reciever warehouse',
  selectrecieverdepartment: 'Select reciever department',
  goodsrecievedby: 'Goods recieved by',
  goodsissuedby: 'Goods issued by',
  purchaserequisition: 'Purchase requisition',
  deliverynote: 'Delivery note',
  onstock: 'On stock',
  storno: 'Issue cancellation',
  createstorno: 'Create cancellation',
  shippingmethod: 'Shipping method',
  legacydocument: 'Legacy document',
  draft: 'Draft',
  selectcategory: 'Select category',
  relatedAssets: 'Related assets',

  //MailboxTranslation

  compose: 'Compose',

  //ECommerce

  paymentTerms: 'Payment terms',
  deliveryTerms: 'Delivery terms',
  paymentTermsUpdated: 'Payment terms updated!',
  deliveryTermsUpdated: 'Delivery terms updated!',
  generalSettingsUpdated: 'General settings updated!',
  storeName: 'Store name',
  addStore: 'Add store',
  editStore: 'Edit store',
  isDefault: 'Set as default store',
  manageWebsites: 'Manage websites',
  websiteName: 'Website name',
  contactEmail: 'Contact e-mail',
  contactPhone: 'Contact phone',
  paymentMethods: 'Payment methods',
  deliveryMethods: 'Delivery methods',
  exitEcommerce: 'Exit Ecommerce',
  changeLabel: 'Change label',
  addProducts: 'Add products',
  productsAdded: 'Products added',

  //Referrals
  referral: 'Referal',
  referrals: 'Referrals',
  addReferrals: 'Add referrals',
  referralsCreated: 'Referrals created',

  //cms

  editPage: 'Edit page',
  newPage: 'Add new page',
  enterTitle: 'Enter title here',
  chooseLanguage: 'Choose language',
  saveDraft: 'Save draft',
  preview: 'Preview',
  published: 'Published',
  publishImmediately: 'Publish',
  notAvailable: 'Not available',
  toTrash: 'Move to trash',
  pageAttributes: 'Page Attributes',
  noParent: '(no parent)',
  template: 'Template',
  defaultTemplate: 'Default template',
  mainTitle: 'Main title',
  enterMainTitle: 'Enter main title',
  commentsOn: 'On',
  commentsOff: 'Off',
  pageSaved: 'Page saved',
  changeLanguageSure: 'Are you sure that you want change language? All unsaved page data related with this language will be lost.',
  deletePage: 'Delete',
  archivePageSure: 'Are you sure that you want to archive this page?',
  publishPageSure: 'Are you sure that you want to publish this page?',
  unpublishPageSure: 'Are you sure that you want to unpublish this page?',
  posts: 'Posts',
  addCategories: 'Add categories',
  languages: 'Languages',
  addLanguage: 'Add language',
  categorySaved: 'Category saved',
  newTag: 'Add new tag',
  editTag: 'Edit tag',
  deleteTranslationSure: 'Are you sure that you want to delete this translation?',
  archiveTagSure: 'Are you sure that you want to delete this tag?',
  archivePostSure: 'Are you sure that you want to archive this post?',
  publishPostSure: 'Are you sure that you want to publish this post?',
  unpublishPostSure: 'Are you sure that you want to unpublish this post?',
  deleteCategorySure: 'Are you sure that you want to delete this category?',
  pageArchived: 'Page archived',
  postArchived: 'Post archived',
  pagePublished: 'Page published',
  postPublished: 'Post published',
  pageUnpublished: 'Page unpublished',
  postUnpublished: 'Post unpublished',
  categoryDeleted: 'Category deleted',
  tagDeleted: 'Tag deleted',
  translationDeleted: 'Translation deleted',
  addTags: 'Add tags',
  tagUpdated: 'Tag updated',
  editPost: 'Edit post',
  newPost: 'Add new post',
  postSaved: 'Post saved',
  tagSaved: 'Tag saved',
  postUpdated: 'Post updated',
  pageUpdated: 'Page updated',
  translation: 'Translation',
  pageInfo: 'Page information',
  postInfo: 'Post information',
  categoryInfo: 'Category info',
  tagInfo: 'Tag info',
  navigationSaved: 'Navigation saved',
  navigationUpdated: 'Navigation updated',
  linksAdded: 'Links added to navigation',
  navigationOrderSaved: 'Navigation order saved',
  linkDeleted: 'Link deleted',
  deleteLinkSure: 'Are you sure that you want to delete this link and all his children?',
  affectsAllLanguages: 'Changes will affect all languages',
  willLostChanges: 'All unsaved changes will be lost. Are you sure you want to continue',
  deleteNavigationBranchSure: 'Are you sure that you want to remove link from navigation?',
  homePage: 'Home page',
  optionsSaved: 'Options saved',
  emailsToSend: 'Contact form emails',
  emailsForGdpr: 'Data protection emails',
  chooseDomain: 'Choose domain',
  chooseProduct: 'Choose product',
  noProductChosen: 'None',
  exitCms: 'Exit CMS',
  chooseNavigation: 'Choose navigation',
  welcome: 'Welcome to Gauss Box CMS',
  welcomeEcommerce: 'Welcome to Gauss Box E-commerce',
  addDomainToAccess: 'You need to add at least one domain to access CMS',
  chooseType: 'Choose type',
  chooseStatus: 'Choose status',
  enterSlug: 'Enter slug here',
  manageExternalLinks: 'Manage external links',
  externalLinkTitle: 'Title',
  backgroundColor: 'Background color',
  metaDescription: 'Meta description',
  seoInfo: 'SEO info',
  keywords: 'Keywords',
  allowedLanguages: 'Allowed languages',
  navigationType: 'Navigation type',
  addNavigation: 'Add navigation',
  navigationAdded: 'Navigation added',
  navigations: 'Navigations',
  navigationDeleted: 'Navigation deleted',
  productCategories: 'Product categories',
  externalLink: 'External link',
  externalLinks: 'External links',
  newNavigation: 'New  navigation',
  linklessItem: 'Linkless item',
  soldProductsCount: 'Sold products count',
  copyrightText: 'Copyright text',
  createRedirect: 'Create redirect',  //TODO redirection??
  redirectCreated: 'Redirect created',
  redirectUpdated: 'Redirect updated',
  redirectDeleted: 'Redirect deleted',
  newRedirect: 'New redirect',
  editRedirect: 'Edit redirect',
  redirectFrom: 'Redirect from',
  redirectTo: 'Redirect to',
  statusCode: 'Status code',
  code301: '301 Moved Permanently',
  code302: '302 Found',
  code307: '307 Temporary redirect',
  code410: '410 Content deleted',
  seo: 'SEO',
  robots: 'Robots',
  sitemap: 'Site map',
  robotsSaved: 'robots.txt saved',
  siteMapLastmod: 'Forsiraj lastmod na sve url-ove',
  siteMapHreflang: 'Dodaj prevedene postove u sitemap',
  siteMapImages: 'Dodaj slike u site map',
  siteMapGenerated: 'Site map generiran',
  changeFreq: 'Frekvencija promjene',
  hourly: 'Hourly',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
  never: 'Never',
  postCount: 'Post count',
  seoFollow: 'Follow links on this page',
  seoIndex: 'Index post',
  canonicalPost: 'Canonical post',
  canonicalPage: 'Canonical page',
  trackingTools: 'Tracking tools',
  customHeader: 'Custom header',
  customFooter: 'Custom footer',
  googleAnalyticsIdentifier: 'Google analytics identifier',
  googleSearchConsoleIdentifier: 'Google search console identifier',
  googleTagManagerIdentifier: 'Google tag manager identifier',
  googleSearchPreview: 'Google search preview',
  postSchedules: 'Schedules',
  createPostSchedule: 'Create schedule',
  postScheduleCreated: 'Schedule created',
  scheduledDate: 'Scheduled date',
  manageStatusSchedule: 'Manage status schedule',
  productPages: 'Product pages',
  defaultAllowComments: 'Post comments allowed by default',
  defaultAllowReviews: 'Post reviews allowed by default',
  requireRatingForComment: 'Require rating for comment',
  requireCommentForRating: 'Require comment for rating',
  numberOfStars: 'Maximum rating stars',
  canCommentOnBlog: 'Can comment on blog',
  canAddReviews: 'Can review the product',
  registeredUsers: 'Registered users',
  anyone: 'Anyone',

  //drive

  createDirectory: 'Create folder',
  copy: 'Copy',
  cut: 'Cut',
  paste: 'Paste',
  usedIn: 'Used in',
  notInUse: 'The file is not in use anywhere',
  size: 'Size',
  versionHistory: 'Version history',
  uploadNewVersion: 'Upload new version',
  internalLink: 'Internal link',
  sharing: 'Sharing',
  accessPermissions: 'Access permissions',
  accessPermissionsSaved: 'Access permissions saved',
  publicLink: 'Public link',
  editItem: 'Edit item',
  minorVersion: 'Minor version',
  majorVersion: 'Major version',
  versionUploadQuestion: 'Which version do you want to upload',
  moveTo: 'Move to',
  copyTo: 'Copy to',
  read: 'Read',
  readWrite: 'Read, Write',
  readWriteDelete: 'Read, Write, Delete',
  permissionLevel: 'Permission level',
  fileArchived: 'File archived',
  fileRestored: 'File restored',
  uploadFromDisc: 'Upload from disc',
  makeActive: 'Make active',
  activeVersionUpdated: 'File active version updated',

  //pagination

  previous: 'Previous',
  next: 'Next',
  plural: 's',
  show: 'Show',
  entries: 'entries',
  of: 'of',
  to: 'to',

  //filter

  filters: 'Filters',
  apply: 'Apply',
  workgroup: 'Workgroup',
  industry: 'Industry',
  inputtype: 'Input type',
  startdate: 'Start date',
  enddate: 'End date',
  createdat: 'Created at',
  createdby: 'Created by',
  loggedby: 'Logged by',
  flowtype: 'Flow type',
  selectTwoDates: 'Select two dates',
  removezerostock: 'Remove zero stock',
  subjecttype: 'Subject type',
  statustimestamp: 'Log date',
  sourcetype: 'Source type',

  //archive

  showUsers: 'Show Users',
  clientcompanies: 'Show client companies',

  //SearchTranslations

  beginSearch: 'Search...',

  //forbidden

  forbidden: 'Forbidden',
  goback: 'Go back',

  //internalServerError

  titleInternal: 'Internal Server Error',

  //serviceUnavailable

  title1: 'Service unavailable',
  text1: 'The server is temporarily unable to service your request due to maintenance downtime or capacity problems. Please try again later.',

  //labelModal

  statuses: 'Statuses',
  statusName: 'Status name',
  manageLabels: 'Manage labels',
  labelNamePlaceholder: 'Label name',

  //categoryModal

  manageCategories: 'Manage categories',
  categoryNamePlaceholder: 'Category name',

  //occupationModal

  manageocupation: 'Manage Occupations',
  occupationNamePlaceholder: 'Occupation name',

  //workGroupModal

  manageworkgroup: 'Manage workgroups',
  workGroupNamePlaceholder: 'Work group name',

  //participantModal

  educatorsOn: 'Educators on course',
  studentsOn: 'Students on course',
  employeesOn: 'Employees on project',
  clientsOn: 'Clients on project',

  //groupModal

  manageGroups: 'Manage groups',
  groupNamePlaceholder: 'Group name',

  //countries

  abw: 'Aruba',
  afg: 'Afghanistan',
  ago: 'Angola',
  aia: 'Anguilla',
  alb: 'Albania',
  and: 'Andorra',
  are: 'United Arab Emirates',
  arg: 'Argentina',
  arm: 'Armenia',
  asm: 'American Samoa',
  ata: 'Antarctica',
  atf: 'French Southern Territories',
  atg: 'Antigua and Barbuda',
  aus: 'Australia',
  aut: 'Austria',
  aze: 'Azerbaijan',
  bdi: 'Burundi',
  bel: 'Belgium',
  ben: 'Benin',
  bes: 'Bonaire',
  bfa: 'Burkina Faso',
  bgd: 'Bangladesh',
  bgr: 'Bulgaria',
  bhr: 'Bahrain',
  bhs: 'Bahamas',
  bih: 'Bosnia and Herzegovina',
  blm: 'Saint Barthelemy',
  blr: 'Belarus',
  blz: 'Belize',
  bmu: 'Bermuda',
  bol: 'Bolivia',
  bra: 'Brazil',
  brb: 'Barbados',
  brn: 'Brunei Darussalam',
  btn: 'Bhutan',
  bvt: 'Bouvet Island',
  bwa: 'Botswana',
  caf: 'Central African Republic',
  can: 'Canada',
  cck: 'Cocos (Keeling) Islands',
  che: 'Switzerland',
  chl: 'Chile',
  chn: 'China',
  civ: 'Cote d\'Ivoire',
  cmr: 'Cameroon',
  cod: 'Democratic Republic of the Congo',
  cog: 'Congo',
  cok: 'Cook Islands',
  col: 'Colombia',
  com: 'Comoros',
  cpv: 'Cape Verde',
  cri: 'Costa Rica',
  cub: 'Cuba',
  cuw: 'Curacao',
  cxr: 'Christmas Island',
  cym: 'Cayman Islands',
  cyp: 'Cyprus',
  cze: 'Czech Republic',
  deu: 'Germany',
  dji: 'Djibouti',
  dma: 'Dominica',
  dnk: 'Denmark',
  dom: 'Dominican Republic',
  dza: 'Algeria',
  ecu: 'Ecuador',
  egy: 'Egypt',
  eri: 'Eritrea',
  esh: 'Western Sahara',
  esp: 'Spain',
  est: 'Estonia',
  eth: 'Ethiopia',
  fin: 'Finland',
  fji: 'Fiji',
  flk: 'Falkland Islands (Malvinas)',
  fra: 'France',
  fro: 'Faroe Islands',
  fsm: 'Micronesia, Federated States of',
  gab: 'Gabon',
  gbr: 'United Kingdom',
  geo: 'Georgia',
  ggy: 'Guernsey',
  gha: 'Ghana',
  gib: 'Gibraltar',
  gin: 'Guinea',
  glp: 'Guadeloupe',
  gmb: 'Gambia',
  gnb: 'Guinea-Bissau',
  gnq: 'Equatorial Guinea',
  grc: 'Greece',
  grd: 'Grenada',
  grl: 'Greenland',
  gtm: 'Guatemala',
  guf: 'French Guiana',
  gum: 'Guam',
  guy: 'Guyana',
  hkg: 'Hong Kong',
  hmd: 'Heard Island and McDonald Islands',
  hnd: 'Honduras',
  hrv: 'Croatia',
  hti: 'Haiti',
  hun: 'Hungary',
  idn: 'Indonesia',
  imn: 'Isle of Man',
  ind: 'India',
  iot: 'British Indian Ocean Territory',
  irl: 'Ireland',
  irn: 'Iran, Islamic Republic of',
  irq: 'Iraq',
  isl: 'Iceland',
  isr: 'Israel',
  ita: 'Italy',
  jam: 'Jamaica',
  jey: 'Jersey',
  jor: 'Jordan',
  jpn: 'Japan',
  kaz: 'Kazakhstan',
  ken: 'Kenya',
  kgz: 'Kyrgyzstan',
  khm: 'Cambodia',
  kir: 'Kiribati',
  kna: 'Saint Kitts and Nevis',
  kor: 'Korea, Republic of',
  kwt: 'Kuwait',
  lao: 'Lao People\'s Democratic Republic',
  lbn: 'Lebanon',
  lbr: 'Liberia',
  lby: 'Libya',
  lca: 'Saint Lucia',
  lie: 'Liechtenstein',
  lka: 'Sri Lanka',
  lso: 'Lesotho',
  ltu: 'Lithuania',
  lux: 'Luxembourg',
  lva: 'Latvia',
  mac: 'Macao',
  maf: 'Saint Martin (French part)',
  mar: 'Morocco',
  mco: 'Monaco',
  mda: 'Moldova, Republic of',
  mdg: 'Madagascar',
  mdv: 'Maldives',
  mex: 'Mexico',
  mhl: 'Marshall Islands',
  mkd: 'Macedonia, the Former Yugoslav Republic of',
  mli: 'Mali',
  mlt: 'Malta',
  mmr: 'Myanmar',
  mne: 'Montenegro',
  mng: 'Mongolia',
  mnp: 'Northern Mariana Islands',
  moz: 'Mozambique',
  mrt: 'Mauritania',
  msr: 'Montserrat',
  mtq: 'Martinique',
  mus: 'Mauritius',
  mwi: 'Malawi',
  mys: 'Malaysia',
  myt: 'Mayotte',
  nam: 'Namibia',
  ncl: 'New Caledonia',
  ner: 'Niger',
  nfk: 'Norfolk Island',
  nga: 'Nigeria',
  nic: 'Nicaragua',
  niu: 'Niue',
  nld: 'Netherlands',
  nor: 'Norway',
  npl: 'Nepal',
  nru: 'Nauru',
  nzl: 'New Zealand',
  omn: 'Oman',
  pak: 'Pakistan',
  pan: 'Panama',
  pcn: 'Pitcairn',
  per: 'Peru',
  phl: 'Philippines',
  plw: 'Palau',
  png: 'Papua New Guinea',
  pol: 'Poland',
  pri: 'Puerto Rico',
  prk: 'Korea, Democratic People\'s Republic of',
  prt: 'Portugal',
  pry: 'Paraguay',
  pse: 'Palestine, State of',
  pyf: 'French Polynesia',
  qat: 'Qatar',
  reu: 'Reunion',
  rou: 'Romania',
  rus: 'Russian Federation',
  rwa: 'Rwanda',
  sau: 'Saudi Arabia',
  sdn: 'Sudan',
  sen: 'Senegal',
  sgp: 'Singapore',
  sgs: 'South Georgia and the South Sandwich Islands',
  shn: 'Saint Helena',
  sjm: 'Svalbard and Jan Mayen',
  slb: 'Solomon Islands',
  sle: 'Sierra Leone',
  slv: 'El Salvador',
  smr: 'San Marino',
  som: 'Somalia',
  spm: 'Saint Pierre and Miquelon',
  srb: 'Serbia',
  ssd: 'South Sudan',
  stp: 'Sao Tome and Principe',
  sur: 'Suriname',
  svk: 'Slovakia',
  svn: 'Slovenia',
  swe: 'Sweden',
  swz: 'Swaziland',
  sxm: 'Sint Maarten (Dutch part)',
  syc: 'Seychelles',
  syr: 'Syrian Arab Republic',
  tca: 'Turks and Caicos Islands',
  tcd: 'Chad',
  tgo: 'Togo',
  tha: 'Thailand',
  tjk: 'Tajikistan',
  tkl: 'Tokelau',
  tkm: 'Turkmenistan',
  tls: 'Timor-Leste',
  ton: 'Tonga',
  tto: 'Trinidad and Tobago',
  tun: 'Tunisia',
  tur: 'Turkey',
  tuv: 'Tuvalu',
  twn: 'Taiwan, Province of China',
  tza: 'United Republic of Tanzania',
  uga: 'Uganda',
  ukr: 'Ukraine',
  ury: 'Uruguay',
  usa: 'United States',
  uzb: 'Uzbekistan',
  vat: 'Holy See (Vatican City State)',
  vct: 'Saint Vincent and the Grenadines',
  ven: 'Venezuela',
  vgb: 'British Virgin Islands',
  vir: 'US Virgin Islands',
  vnm: 'Viet Nam',
  vut: 'Vanuatu',
  wlf: 'Wallis and Futuna',
  wsm: 'Samoa',
  yem: 'Yemen',
  zaf: 'South Africa',
  zmb: 'Zambia',
  zwe: 'Zimbabwe',

  //industryTypes

  banking: 'Banking Services',
  construction: 'Construction',
  consulting: 'Consulting',
  delivery: 'Delivery',
  education: 'Education',
  entertainment: 'Entertainment',
  finance: 'Finance',
  government: 'Government',
  it: 'Information Technology',
  manufacturing: 'Manufacturing',
  nonprofit: 'Non-profit',
  sales: 'Sales',
  telecommunication: 'Telecommunication',

  //contactTypes

  'e-mail': 'Email',
  skype: 'Skype',

  //userTypes

  employee: 'Employee',
  customer: 'Customer',
  externalAssociate: 'External associate',

  //salutation

  Mr: 'Mr',
  Ms: 'Ms',
  Sir: 'Sir',
  Dr: 'Dr',

  //confirm

  header: 'Confirmation',
  messageConfirm: 'Are you sure that you want to perform this action?',
  acceptlabel: 'Yes',
  rejectlabel: 'No',

  //error message

  requiredError: 'This field is required',
  min: 'Value cannot be lower than',
  max: 'Value cannot be higher than',
  emailError: 'Email is not valid',
  characters: 'characters',
  minLength: 'Field can have minimum of',
  maxLength: 'Field can have maximum of',
  validateOib: 'This OIB is not valid',
  validPhone: 'This field can only use numbers and a + character',
  validPassword: 'Must have uppercase letter, symbol and a number',
  mailFormat: 'Email is not valid',
  salutationFormat: 'Salutation can be Empty, Mr, Mrs, Sir...',
  shippingAddressMax: 'Cant have 2 shipping addresses',
  billingAddressMax: 'Cant have 2 billing addresses',
  mainAddressMax: 'Cant have 2 main addresses',
  isOfAge: 'User should be at least 18 years old',
  maxCheckedItems: 'You can only select 5 items',
  spaceNotAllowed: 'Space is not allowed',
  passwordNotMatch: 'Password does not match',
  notAvailableUsername: '`Username is taken, suggested:',
  notAvailableEmail: 'Email is taken',
  startDateGreaterThenEndDate: 'Start date can not be greater then end date',
  endDateLesserThenStartDate: 'End date can not be lesser then start date',
  timeRegExp: 'Only numbers, point and colon are allowed',
  numberFirst: 'Number must be a first character',
  maxTagLength: 'Max tag length is',
  passingPointsNegative: 'Passing points cannot be nagative',
  passingPointsMoreThanTotal: 'Passing points cannot be more than point total',
  atLeastOneQuestion: 'Exam must have at least one question',
  atLeastOneQuestion1: 'Survey must have at least one question',
  atLeastOneCorrectAnswer: 'Question must have at least one correct answer',
  atLeastTwoAnswers: 'Question must have at least two answer options',
  sameAnswerText: 'Two answers cannot have the same text',
  atLeastOneAnswer: 'Question must have at least one answer',
  numAnswerGaps: 'Number of answers must match the number of gaps in question text',
  atLeastTwoPairs: 'Question must have at least two answer pairs',
  minNotBiggerThenMaxStock: 'Minimum stock can not be lesser then maximum stock',
  usernameSpecialChars: 'Username can only have letters numbers - and _ ',
  startEvent: 'Event start time',
  endEvent: 'Event end time',
  endRecurring: 'Recurrence end time',
  cantBeGreaterThen: 'can not be greater then',
  cantBeValue: 'Cannot be',
  atLeastOneRequired: 'At least one of the fields is required',
  notInPastDate: 'Must not be in the past date',
  invalidSlug: 'Invalid slug',
  slugTaken: 'Slug already exists',
  isUrl: 'Must be valid URL',
  mustBePositiveInteger: 'Positive integer required',
  isSlug: 'Must be a valid slug',

  //monthNumbers

  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',

  //datePicker

  firstDayOfWeek: 1,
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  //tableHeaders
  detailsDisplayName: 'Name',
  contactsPhone: 'Phone',
  contactsMobile: 'Mobile',
  companyName: 'Company name',
  detailsFirstName: 'First name',
  detailsLastName: 'Last name',
  detailsGender: 'Gender',
  detailsSalutation: 'Salutation',
  detailsVatNumber: 'VAT number',
  detailsPersonalIdNumber: 'Personal id number',
  detailsBirthDate: 'Birth date',
  detailsPlaceOfBirth: 'Place of birth',
  detailsNotes: 'Notes',
  addressesMain: 'Main address',
  addressesShipping: 'Shipping address',
  contactsEmail: 'Email',
  addressesBilling: 'Billing address',
  contactsFax: 'Fax',
  contactsSkype: 'Skype',
  detailsCompanyIdNumber: 'Company id number',
  detailsLogo: '',
  detailsGrandTotal: 'Grand total',
  contactsValue: 'Contact',
  supervisor_thumbnail: '',
  mainAgreement: 'Main agreement',
  statusSourceIdentifier: 'Status source identifier',
  published_at: 'Published at',
  created_at: 'Created at',
  background: 'Background',
  navType: 'Type',
  language_code: 'Language',
  use_archive_bar: 'Use archive bar',
  email_type_option: 'Email type option',
  double_optin: 'Double option',
  marketing_option: 'Marketing option',

  //VALIDATIONS
  DigitError:'Needs to have alteast 1 digit',
  UppercaseError:'Needs to have atleast 1 uppercase letter',
  LowercaseError:'Needs to have atleast 1 lowercase letter',
  SpecCharError:'Needs to have atleast 1 special character',
  LengthError:'Needs to have atleast 8 characters',
  // table settings header titles
  tableSettingsDetailsProfileImage: 'Profile image',
  tableSettingsDetailsLogo: 'Logo',
  tableSettingsUserImage: 'User image',
  tableSettingsIcon: 'Icon',
  tableSettingsLevel: 'Level',
  tableSettingsSupervisor_thumbnail: 'Supervisor image',
  tableSettingsCreatedBy_thumbnail: 'User image',
  profileImage: 'Profile image',
  subscribed: 'Subscribed',
  unsubscribed: 'Unsubscribed',
  cleaned: 'Cleaned',
  pending: 'Pending',
  transactional: 'Transactional',
  ganttTrans: {
    date: {
      month_full: ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"],
      month_short: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep",
        "Oct", "Nov", "Dec"],
      day_full: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday",
        "Saturday"],
      day_short: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    },
    labels: {
      new_task: "New task",
      icon_save: "Save",
      icon_cancel: "Cancel",
      icon_details: "Details",
      icon_edit: "Edit",
      icon_delete: "Delete",
      confirm_closing: "",//Your changes will be lost, are you sure ?
      confirm_deleting: "Task will be deleted permanently, are you sure?",

      section_description: "Description",
      section_time: "Time period",

      /* link confirmation */

      confirm_link_deleting: "Dependency will be deleted permanently, are you sure?",
      link_from: "From",
      link_to: "To",
      link_start: "Start",
      link_end: "End",

      minutes: "Minutes",
      hours: "Hours",
      days: "Days",
      weeks: "Week",
      months: "Months",
      years: "Years"
    }
  },
};
