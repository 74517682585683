import {environment} from '../../../../environments/environment';

export const WarehouseRoutes = {
  bin: {
    commons: {
      category: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/category/filter`
        },
        get: {
          method: 'Get',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/category/get`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/category/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/category/delete`
        },
      },
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/label/filter`
        },
        get: {
          method: 'Get',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/label/get`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/label/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/label/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/label/delete`
        },
      },
      group: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/group/filter`
        },
        get: {
          method: 'Get',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/group/get`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/group/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/group/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/group/delete`
        },
      }
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/get`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/update`
    },
    restore: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/restore`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/bin/delete`
    },
  },
  asset: {
    commons: {
      category: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/category/filter`
        },
        get: {
          method: 'Get',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/category/get`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/category/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/category/delete`
        },
      },
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/get`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/delete`
    },
    restore: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/restore`
    },
    user: {
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/user`
      }
    },
    manage: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/asset/manage`
    }
  },
  commons: {
    category: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/category/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/category/get`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/category/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/category/update`
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/category/delete`
      },
    },
    label: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/label/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/label/get`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/label/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/label/update`
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/label/delete`
      },
    },
    group: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/group/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/group/get`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/group/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/group/update`
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/warehouse/group/delete`
      },
    }
  },
  filter: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/warehouse/filter`
  },
  get: {
    method: 'Get',
    url: `${environment.baseUrl}/api/${environment.version}/warehouse/get`
  },
  create: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/warehouse/create`
  },
  update: {
    method: 'Patch',
    url: `${environment.baseUrl}/api/${environment.version}/warehouse/update`
  },
  delete: {
    method: 'Delete',
    url: `${environment.baseUrl}/api/${environment.version}/warehouse/delete`
  },
  restore: {
    method: 'Patch',
    url: `${environment.baseUrl}/api/${environment.version}/warehouse/restore`
  },
  stock:{
    filter: {
      method:'Post',
      url:`${environment.baseUrl}/api/${environment.version}/warehouse/stock/filter`
    },
    manage: {
      method:'Post',
      url:`${environment.baseUrl}/api/${environment.version}/warehouse/stock/manage/items`
    },
    getStockItems: {
      method:'Post',
      url:`${environment.baseUrl}/api/${environment.version}/warehouse/stock/manage/list`
    }
  },
  stockTransfers: {
    filter: {
      method:'Post',
      url:`${environment.baseUrl}/api/${environment.version}/warehouse/stock/transfer/filter`
    },
  },
  binState:{
    method:'Post',
    url:`${environment.baseUrl}/api/${environment.version}/warehouse/stock/bins/items`
  },
  itemStockState:{
    method:'Post',
    url:`${environment.baseUrl}/api/${environment.version}/warehouse/stock/state/items`
  },
  manufacturer: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/manufacturer/get`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/manufacturer/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/manufacturer/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/manufacturer/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/warehouse/manufacturer/delete`
    },
  },
};
