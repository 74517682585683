import {Component, Input, OnInit} from '@angular/core';
import {LocalizationService} from '../../services/localization.service';

@Component({
  selector: 'app-error-msg',
  templateUrl: './error-msg.component.html',
  styleUrls: ['./error-msg.component.scss']
})
export class ErrorMsgComponent implements OnInit {

  @Input() object: any;

  public messages: object = {};

  constructor(private _locale: LocalizationService) {
  }

  ngOnInit() {
    this.messages = {
      required: () => {
        return this._locale.trans.requiredError;
      },
      min: (values): string => {
        return `${this._locale.trans.min} ${values.min}`;
      },
      max: (values): string => {
        return `${this._locale.trans.max} ${values.max}`;
      },
      cantBeValue: (value): string => {
        return `${this._locale.trans.cantBeValue} ${value.value}`;
      },
      email: (): string => {
        return this._locale.trans.emailError;
      },
      minlength: (value): string => {
        return `${this._locale.trans.minLength} ${value.requiredLength} ${this._locale.trans.characters}`;
      },
      maxlength: (value): string => {
        return `${this._locale.trans.maxLength} ${value.requiredLength} ${this._locale.trans.characters}`;
      },
      validateOib: (): string => {
        return this._locale.trans.validateOib;
      },
      validPhone: (): string => {
        return this._locale.trans.validPhone;
      },
      validPassword: (): string => {
        return this._locale.trans.validPassword;
      },
      mailFormat: (): string => {
        return this._locale.trans.mailFormat;
      },
      salutationFormat: (): string => {
        return this._locale.trans.salutationFormat;
      },
      shippingAddressMax: (): string => {
        return this._locale.trans.shippingAddressMax;
      },
      billingAddressMax: (): string => {
        return this._locale.trans.billingAddressMax;
      },
      mainAddressMax: (): string => {
        return this._locale.trans.mainAddressMax;
      },
      isOfAge: (): string => {
        return this._locale.trans.isOfAge;
      },
      maxCheckedItems: (): string => {
        return this._locale.trans.maxCheckedItems;
      },
      spaceNotAllowed: (): string => {
        return this._locale.trans.spaceNotAllowed;
      },
      passwordNotMatch: (): string => {
        return this._locale.trans.passwordNotMatch;
      },
      notAvailableUsername: (suggested?: string[]): string => {
        return `${this._locale.trans.notAvailableUsername} ${suggested.toString()}`;
      },
      notAvailableEmail: (): string => {
        return this._locale.trans.notAvailableEmail;
      },
      dateStartEndGreater: (dates: string[]): string => {
        if (!dates) return this._locale.trans.startDateGreaterThenEndDate;
        return `Datum završetka ne može prethoditi datumu početka`;
      },
      dateStartEndLesser: (): string => {
        return this._locale.trans.endDateLesserThenStartDate;
      },
      timeRegExp: (): string => {
        return this._locale.trans.timeRegExp;
      },
      numberFirst: (): string => {
        return this._locale.trans.numberFirst;
      },
      maxTagLength: (err: any): string => {
        return `${this._locale.trans.maxTagLength} ${err.max} ${this._locale.trans.characters}`;
      },
      passingPointsNegative: (err: any): string => {
        return this._locale.trans.passingPointsNegative;
      },
      passingPointsMoreThanTotal: (err: any): string => {
        return this._locale.trans.passingPointsMoreThanTotal;
      },
      atLeastOneQuestion: (err: any): string => {
        return this._locale.trans.atLeastOneQuestion;
      },
      atLeastOneQuestion1: (err: any): string => {
        return this._locale.trans.atLeastOneQuestion1;
      },
      atLeastOneCorrectAnswer: (err: any): string => {
        return this._locale.trans.atLeastOneCorrectAnswer;
      },
      atLeastTwoAnswers: (err: any): string => {
        return this._locale.trans.atLeastTwoAnswers;
      },
      sameAnswerText: (err: any): string => {
        return this._locale.trans.sameAnswerText;
      },
      atLeastOneAnswer: (err: any): string => {
        return this._locale.trans.atLeastOneAnswer;
      },
      numAnswerGaps: (err: any): string => {
        return this._locale.trans.numAnswerGaps;
      },
      atLeastTwoPairs: (err: any): string => {
        return this._locale.trans.atLeastTwoPairs;
      },
      minNotBiggerThenMaxStock: (): string => {
        return this._locale.trans.minNotBiggerThenMaxStock;
      },
      usernameSpecialChars: (): string => {
        return this._locale.trans.usernameSpecialChars;
      },
      atLeastOneRequired: (err: any): string => {
        let controls: string = err['controls'].map((control: string) => { return this._locale.trans[control] ? this._locale.trans[control] : control}).join(', ');
        return this._locale.trans.atLeastOneRequired + ': ' + controls;
      },
      notInPastDate: (): string => {
        return this._locale.trans.notInPastDate;
      },
      invalidSlug: (): string => {
        return this._locale.trans.invalidSlug;
      },
      slugTaken: (): string => {
        return this._locale.trans.slugTaken;
      },
      isUrl: (): string => {
        return this._locale.trans.isUrl;
      },
      maxLengthWithoutHTML: (err): string => {
        return `${this._locale.trans.maxLength} ${err.maxLength} ${this._locale.trans.characters}`;
      },
      isNotPositiveInt: (): string => {
        return this._locale.trans.mustBePositiveInteger;
      },
      isSlug: (): string => {
        return this._locale.trans.isSlug;
      },
      passowrdError:(val) => {
        return this._locale.trans[val]
      },
      codeAlreadyExists: (): string => {
        return this._locale.trans.codeAlreadyExists;
      }
    };
  }

  returnErrors() {
    return this.object.errors ? Object.keys(this.object.errors) : [];
  }

  returnErrorMessage(key) {
    return this.messages[key](this.object.errors[key]);
  }

}
