import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiselectSearch'
})
export class MultiselectSearchPipe implements PipeTransform {

  transform(value: any, searchKey: string, searchValue: string, byPhraseBeginning: boolean = false): any {
    if(!value || !searchValue) return value;
    if(byPhraseBeginning) return value.filter(option => option[searchKey].toLowerCase().indexOf(searchValue.toLowerCase()) === 0);
    return value.filter(option => option[searchKey].toLowerCase().indexOf(searchValue.toLowerCase()) != -1);
  }

}
