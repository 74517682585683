import {GlobalService} from './../../../services/global.service';
import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {ElectronService} from 'ngx-electron';
import {LocalizationService} from '../../../services/localization.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {


  constructor(public _router: Router,
              public _locale: LocalizationService,
              public _electronService: ElectronService,
              public _global: GlobalService) {

    _global.saleError.subscribe(() => {
      this.back();
    });
  }

  back(): Promise<boolean> {
    return this._router.navigate(['/home']);
  }
}
