import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NoRecordsComponent} from './no-records.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
  ],
  declarations: [
    NoRecordsComponent
  ],
  exports: [
    NoRecordsComponent
  ]
})
export class NoRecordsModule {
}
