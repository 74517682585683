import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Location, PlatformLocation} from '@angular/common';
import {PasswordValidator} from '../../../validators/password.validator';
import {LocalizationService} from '../../../services/localization.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../services/api.service';
import {urlValues} from '../../../configs/url.values';
import {GlobalService} from '../../../services/global.service';
import {StorageService} from '../../../services/storage.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  public form: FormGroup;
  private platformLocation: string = null;

  constructor(private _fb: FormBuilder,
              public _location: Location,
              public _locale: LocalizationService,
              private _global: GlobalService,
              private _api: ApiService,
              private _route: ActivatedRoute,
              private _storage: StorageService,
              private _router: Router,
              private _platformLocation: PlatformLocation) {

    //Set platform location, for domain checking
    this.platformLocation = this._platformLocation['location'].origin;

    this.form = this._fb.group({
      password: ['', Validators.compose([Validators.required, PasswordValidator.isValidFormat])],
      passwordConfirm: ['', Validators.required],
      subdomain: [this.platformLocation.includes('localhost') ? 'gaussdev' : this._platformLocation['location'].host.split('.')[0]],
      token: this._route.snapshot.params.token
    }, {validator: PasswordValidator.MatchPassword});
  }

  send(formData: object): void {
    delete formData['passwordConfirm'];
    this._api.send(urlValues.auth.setNewPassword.method, urlValues.auth.setNewPassword.url, formData).subscribe(res => {
      this._global.pushAppMessage('success', 'Success', 'Password reset');
      this._storage.clear(); // Clear local storage
      this._router.navigate(['auth']); //Send back to login page
    });
  }
}
