import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GuidelinesRoutingModule } from './guidelines-routing.module';
import { GuidelinesComponent } from './guidelines.component';
import {NgxMdModule} from 'ngx-md';

@NgModule({
  imports: [
    CommonModule,
    GuidelinesRoutingModule,
    NgxMdModule.forRoot()
  ],
  declarations: [GuidelinesComponent]
})
export class GuidelinesModule { }
