import {Injectable} from '@angular/core';
import {urlValues} from '../configs/url.values';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from './api.service';
import {UploadedFile} from '../interfaces/uploaded-file';
import {AuthService} from './auth.service';
import {GlobalService} from './global.service';
import * as _ from 'lodash';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class FileManagementService {

  public uploaded: boolean = false; //Just a boolean that marks that file upload has been finished
  public urlValues: any = urlValues;

  public uploadUrl: string = urlValues.file.uploadv2;
  public uploadv2Url: string = urlValues.file.uploadv2;
  public progress: number = null;

  //<editor-fold desc="Allowed sizes">
  public profileImageSize: number = 1048576; //1mb
  //</editor-fold>

  public onUploadComplete: BehaviorSubject<{
    module: string,
    files: UploadedFile[]
  }> = new BehaviorSubject<{
    module: string,
    files: UploadedFile[]
  }>(null);

  constructor(private _auth: AuthService,
              private _api: ApiService,
              private _http: HttpClient,
              private _global: GlobalService) {

  }

  //Get file for user and return that file to observable
  getFile(id: number): Observable<UploadedFile> {
    return new Observable<UploadedFile>(obs => {
      this._api.send(urlValues.file.get.method, `${urlValues.file.get.url}/${id}`).subscribe(res => {
        obs.next(res['data']);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  //Get all files by hashes
  getFiles(hashes: string[]): Observable<UploadedFile[]> {
    return new Observable<UploadedFile[]>(obs => {
      this._api.send(urlValues.file.getAll.method, urlValues.file.getAll.url, {hashes: hashes}).subscribe(res => {
        let files = res['data'];
        obs.next(files);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  //Delete file from server and send boolean true if file was deleted successfully
  deleteFile(hash: string): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this._api.send(urlValues.file.delete.method, `${urlValues.file.delete.url}/${hash}`).subscribe(res => {
        obs.next(true);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
  }

  onBeforeSend(event: any): void { //Callback to invoke before file upload begins to customize the request such as post parameters before the files.
    //Set header params before sending a file to backend
    event.xhr.setRequestHeader('accessToken', this._auth.accessToken);
  }

  onBeforeUpload(event: any, uploader: any): void {
  }

  onProgress(event: any): void | number {
    this.progress = event.progress; //Display file upload progress as percentage
  }

  onSelected(event) { //Callback to invoke when files are selected.
    // console.log('SELECTED');
    // console.log(event);
  }

  onRemove(file) { //Callback to invoke when a file is removed without uploading using clear button of a file.
    // console.log('REMOVED FILE');
    // console.log(file);
  }

  onUpload(event) { //Callback to invoke when file upload is complete.
    this.progress = null;
  }

  onError(event: any) { //Callback to invoke if file upload fails.
    //Catch error from file upload, and create new message
    if (event.xhr.response) {
      let response = JSON.parse(event.xhr.response);
      this._global.pushAppMessage('error', `${event.xhr.status} ${event.xhr.statusText}`, response.message, {life: 5000});
    }
    this.progress = null;
    this.uploaded = false;
  }

  getMediaExtensionPlaceholder(file: UploadedFile): string {
    if (!file) return urlValues.placeholderPath('blank'); //If no file, return blank placeholder
    if (file.type.search('image') != -1) return this.getMediaPath(file.media);
    else return urlValues.placeholderPath(file.originalName.split('.').pop());
  }

  getMediaPath(hash: string, size: 'SmallSize' | 'MediumSize' | 'LargeSize' | 'ThumbnailSize' = 'MediumSize'): string { //Adds base app path to url and returns new url with base path added
    return `${this.urlValues.base}/${this.urlValues.version}/media/download/1/active?size=${size}&hash=${hash}`;
  }

  openFile(file: UploadedFile): void {
    window.open(this.getMediaPath(file.media, 'LargeSize'));
  }

  sortFiles(files: UploadedFile[], order: string): UploadedFile[] {
    return _.orderBy(files, ['originalName'], [order.toLowerCase()]);
  }

  groupFiles(files: UploadedFile[], extension: string): UploadedFile[] {
    //Orders true array are files that have that extension
    //Orders false array are files that do not have that extension
    let orders = _.groupBy(files, file => (file.originalName.includes(extension)));
    return _.concat(orders.true, orders.false); //Concat orders.true and orders.false so the true ones are at the beginning
  }

  public static getListOfExtensions(files): string[] {
    let extensions = _.map(files, file => {
      let extension = file.originalName.split('.');
      return extension[extension.length - 1];
    });
    return _.uniq(extensions);
  }

  public static downloadJsonFile(file: string, fileName: string): void {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(file));
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  exportData(data) {
    this._api.send(urlValues.file.export.method, urlValues.file.export.url, data).subscribe(res => {
      console.log(res);
    }, err => {
      console.log(err);
    });
  }
}

