import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CalendarSheetComponent} from './calendar-sheet.component';
import {ScheduleModule} from "primeng/primeng";

@NgModule({
  imports: [
    CommonModule,
    ScheduleModule
  ],
  providers: [
  ],
  declarations: [
    CalendarSheetComponent
  ],
  exports: [
    CalendarSheetComponent
  ]
})
export class CalendarSheetModule {
}
