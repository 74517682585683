import {CompanyDetails} from '../interfaces/company-details';
import {ContactType} from '../../../../interfaces/common/contact-type';
import {Country} from '../../../../interfaces/common/country';
import {Iban} from '../../../../interfaces/iban';
import {Categorizable} from "../../../../interfaces/categorizable";
import {Contact} from "../../../../classes/contact";
import {Address} from "../../../../classes/address";

export class Company implements Categorizable {
  id?: number;
  name: string;
  shortName: string;
  isClient: boolean;
  industryType: number;
  archived: boolean;
  details: CompanyDetails;
  addresses: Address[] = [];
  contacts: Contact[] = [];
  ibans: Iban[];
  label?: number[];
  category?: number;
  iconColor: string = '#BF5783';

  constructor(init: any) {
    if (init.id) this.id = init.id;
    if (init.name) this.name = init.name;
    if (init.shortName) this.shortName = init.shortName;
    if (init.label) this.label = init.label || null;
    if (init.category) this.category = init.category || null;
    if (init.isClient) this.isClient = init.isClient;
    if (init.archived) this.archived = init.archived;
    if (init.industryType) this.industryType = init.industryType;
    if (init.iconColor) this.iconColor = init.iconColor;
    if (init.details) this.details = init.details;
    if (init && init.contacts && init.contacts.length) this.contacts = init.contacts.map(contact => new Contact(contact));
    if (init && init.addresses && init.addresses.length) this.addresses = init.addresses.map(address => new Address(address));
    if (init.ibans) this.ibans = init.ibans;
  }


  toJson() { //Return JSON object (always use this function before sending to backend)
    let json = {
      id: this.id,
      name: this.name || '',
      shortName: this.shortName || '',
      isClient: this.isClient,
      archived: this.archived,
      industryType: this.industryType || null,
      details: this.details,
      addresses: this.addresses,
      contacts: this.contacts,
      label: this.label || null,
      category: this.category || null,
    };

    if (this.ibans) json['ibans'] = this.ibans;
    if (this.details && this.details.logo) json['details']['logo'] = this.details.logo['media']; //Get logo id
    return json;
  }

  public static mapCompaniesForSelect(companies: Company[], clientType?: string): Array<{
    id: number,
    name: string,
    isClient: boolean,
    clientType?: string
  }> {
    return companies.map(company => {
      let json = {
        id: company.id,
        name: company.name,
        isClient: company.isClient
      };
      if (clientType) json['clientType'] = clientType;
      return json;
    });
  }

  public static mapCompanyForSelect(company: Company, clientType?: string): {
    id: number,
    name: string,
    clientType?: string
  } {
    let json = {
      id: company.id,
      name: company.name,
    };
    if (clientType) json['clientType'] = clientType;
    return json;
  }

  getAddress(countries: Country[], translations: any) {
    if (!this.addresses || this.addresses.length < 1) return '-'; //If no addresses at all return -
    const address = this.addresses.find(address => address.type == 'main'); //find main address

    if (!address) return '-'; //If did not found address with type main return -

    const country = countries.find(country => country.id == address.country); //find the country

    let street = address.street ? `${address.street}` : '';
    let houseNumber = address.houseNumber ? `${address.houseNumber}` : '';
    let zipCode = address.zipCode ? address.zipCode : '';
    let city = address.city ? `${address.city},` : '';
    let region = address.region ? `${address.region},` : '';

    if (street !== '' && houseNumber !== '') {
      street = `${street} ${houseNumber},`;
      houseNumber = '';
    }

    return `${street} ${houseNumber} ${street || houseNumber ? '<br />' : ''}
            ${zipCode} ${city} ${zipCode || city ? '<br />' : ''}
            ${region} ${region ? '<br />' : ''}
            ${translations[country.code.toLowerCase()]}`;
  }

  getEmail(contacts: ContactType[]): string {
    if (!this.contacts || !this.contacts.length) return '';
    const type = contacts.find(c => c.slug == 'email');
    const contact = this.contacts.find(contact => contact.type == type.id);
    return contact ? contact.value : '';
  }

  getPhone(contacts: ContactType[]): string {
    if (!this.contacts || !this.contacts.length) return '';
    const type = contacts.find(c => c.slug == 'phone');
    const contact = this.contacts.find(contact => contact.type == type.id);
    return contact ? contact.value : '';
  }

  getMobile(contacts: ContactType[]): string {
    if (!this.contacts || !this.contacts.length) return '';
    const type = contacts.find(c => c.slug == 'mobile');
    const contact = this.contacts.find(contact => contact.type == type.id);
    return contact ? contact.value : '';
  }

  getFax(contacts: ContactType[]): string {
    if (!this.contacts || !this.contacts.length) return '';
    const type = contacts.find(c => c.slug == 'fax');
    const contact = this.contacts.find(contact => contact.type == type.id);
    return contact ? contact.value : '';
  }
}
