import {environment} from '../../../../environments/environment';

export const HumanResourcesRoutes = {
  occupation: {

    create: {
      url: `${environment.baseUrl}/api/${environment.version}/hr/occupation/create`,
      method: 'Post'
    },

    filter: {
      url: `${environment.baseUrl}/api/${environment.version}/hr/occupation/filter`,
      method: 'Post'
    },

    get: id => ({
      url: `${environment.baseUrl}/api/${environment.version}/hr/occupation/get/${id}`,
      method: 'Get'
    }),

    update: id => ({
      url: `${environment.baseUrl}/api/${environment.version}/user/occupation/update/${id}`,
      method: 'Patch'
    }),

    delete: id => ({
      url: `${environment.baseUrl}/api/${environment.version}/user/occupation/delete/${id}`,
      method: 'Delete'
    })
  }
};
