import {Component} from '@angular/core';
import {Guidelines} from '../../configs/guidelines';
import {Location} from '@angular/common';

@Component({
  selector: 'app-guidelines',
  templateUrl: './guidelines.component.html',
  styleUrls: ['./guidelines.component.scss']
})
export class GuidelinesComponent  {

  public markdown: string = null;

  constructor(public _location: Location) {
    this.markdown = Guidelines.text;
  }

}
