import { SelectLabelsModalModule } from '../components/modals/select-lables-modal/select-labels-modal.module';
import {NgModule} from '@angular/core';
import {GlobalService} from '../services/global.service';
import {StorageService} from '../services/storage.service';
import {ToggleService} from '../services/toggle.service';
import {ApiService} from '../services/api.service';
import {ErrorMsgModule} from '../components/error-msg/error-msg.module';
import {ThemeService} from '../services/theme.service';


import {ScheduleModule} from 'primeng/schedule';
import {UserService} from './layout/user/user.service';
import {
  AccordionModule, CalendarModule,
  DragDropModule,
  FileUploadModule,
  InputMaskModule, KeyFilterModule,
  OrganizationChartModule,
  RadioButtonModule, ScrollPanelModule,
  TooltipModule
} from 'primeng/primeng';
import {FileManagementService} from '../services/file-management.service';
import {MessageService} from 'primeng/components/common/messageservice';
import {NoRecordsModule} from '../components/no-records/no-records.module';
import {LabelModalModule} from '../components/modals/label-modal/label-modal.module';
import {CategoryModalModule} from '../components/modals/category-modal/category-modal.module';
import {OccupationModalModule} from '../components/modals/occupation-modal/occupation-modal.module';
import {WorkgroupModalModule} from '../components/modals/workgroup-modal/workgroup-modal.module';
import {ChipsModule} from 'primeng/chips';
import {BreadcrumbService} from '../services/breadcrumb.service';
import {CompanyService} from './layout/company/company.service';
import {CompanyGetResolver} from './layout/company/resolvers/company-get.resolver';
import {MultiselectModule} from '../components/form-elements/multiselect/multiselect.module';
import {CompanyAllResolver} from './layout/company/resolvers/company-all.resolver';
import {UserGetResolver} from './layout/user/resolvers/user-get.resolver';
import {UserSidebarModule} from './layout/user/modals/user-sidebar/user-sidebar.module';
import {UserCommonsResolver} from './layout/user/resolvers/user-commons.resolver';
import {ToHumanReadablePipe} from '../pipes/to-human-readable.pipe';
import {UserAllResolver} from './layout/user/resolvers/user-all.resolver';
import {EmployeesGetResolver} from './layout/user/resolvers/employees-get.resolver';
import {HasPermissionResolver} from '../resolvers/has-permission.resolver';
import {AuthService} from '../services/auth.service';
import {TreeModule} from 'primeng/tree';
import {ActivityService} from '../services/activity.service';
import {ActivityModule} from '../components/activity/activity.module';
import {ClientsGetResolver} from './layout/user/resolvers/clients-get.resolver';
import { TruncatePipe } from '../pipes/truncate.pipe';
import {GroupModalModule} from '../components/modals/group-modal/group-modal.module';
import {SafeHtmlPipe} from '../pipes/safe-html.pipe';
import {CalendarSheetModule} from "../components/calendar-sheet/calendar-sheet.module";
import {RemoveCharPipe} from '../pipes/remove-char.pipe';
import {WebUserGetResolver} from "./layout/user/resolvers/web-user-get.resolver";
import {NavigationService} from '../services/navigation.service';
import {TinyMceService} from "../services/tiny-mce.service";
import {GeneralOptionsService} from "../services/general-options.service";
import { SocketService } from '../services/socket.service';
import {ListPageMemoryService} from '../services/list-page-memory.service';
import {SessionStorageService} from '../services/session-storage.service';
import {FilterDataResolver} from '../resolvers/filter-data.resolver';
import {FilterDataService} from '../services/filter-data.service';

@NgModule({
  declarations: [
    ToHumanReadablePipe,
    TruncatePipe,
    SafeHtmlPipe,
    RemoveCharPipe,
  ],
  exports: [
    ErrorMsgModule,
    SelectLabelsModalModule,
    NoRecordsModule,
    LabelModalModule,
    CategoryModalModule,
    OccupationModalModule,
    GroupModalModule,
    WorkgroupModalModule,
    MultiselectModule,
    UserSidebarModule,
    TreeModule,
    ActivityModule,
    //<editor-fold desc="NGPrime Modules">
    ScheduleModule,
    CalendarModule,
    FileUploadModule,
    InputMaskModule,
    TooltipModule,
    ChipsModule,
    RadioButtonModule,
    DragDropModule,
    AccordionModule,
    OrganizationChartModule,
    ScrollPanelModule,
    KeyFilterModule,
    //</editor-fold>
    //<editor-fold desc="Pipes">
    ToHumanReadablePipe,
    TruncatePipe,
    SafeHtmlPipe,
    RemoveCharPipe,
    //</editor-fold>
    CalendarSheetModule
  ]
})
export class GlobalModule {

  static forRoot() {
    return {
      ngModule: GlobalModule,
      providers: [
        //<editor-fold desc="Services">
        ApiService,
        GlobalService,
        BreadcrumbService,
        UserService,
        CompanyService,
        MessageService,
        FileManagementService,
        StorageService,
        SocketService,
        ToggleService,
        ThemeService,
        ActivityService,
        //</editor-fold>
        //<editor-fold desc="Resolvers">
        HasPermissionResolver,
        CompanyGetResolver,
        CompanyAllResolver,
        UserGetResolver,
        WebUserGetResolver,
        UserAllResolver,
        EmployeesGetResolver,
        ClientsGetResolver,
        UserCommonsResolver, //Also imported in layout module
        //</editor-fold>
        ToHumanReadablePipe,
        TruncatePipe,
        RemoveCharPipe,
        ListPageMemoryService,
        SessionStorageService,
        NavigationService,
        TinyMceService,
        FilterDataService,
        FilterDataResolver,
      ]
    };
  }

}
