import {FilterData as GlobalFilterData} from '../../../../classes/filter-data';
import {Params} from '@angular/router';

export class FilterData extends GlobalFilterData {
    customConfig: {
        hideSidebar: boolean;
        defaultEventType: string;
        defaultEventSubType: string;
    };

    constructor(init: any, removePagination?: boolean) {
        super(init);
        this.customConfig = init.customConfig ? {
            hideSidebar: init.customConfig.hideSidebar ? init.customConfig.hideSidebar : false,
            defaultEventType: init.customConfig.defaultEventType ? init.customConfig.defaultEventType : null,
            defaultEventSubType: init.customConfig.defaultEventSubType ? init.customConfig.defaultEventSubType : null,
        } : {
            hideSidebar: false,
            defaultEventType: null,
            defaultEventSubType: null
        };
    }

    getSaveableData(): Params {
        // filter all savable filters
        const onlySaveableFilters = this.filters.filter((filter) => {
            if (!filter.notSavable) return filter;
        });

        return {
            name: this.name,
            view: this.view as string,
            filters: onlySaveableFilters,
            customConfig: this.customConfig
        };
    }
}
