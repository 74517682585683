import {BaseClass} from "../../../../classes/base-class";
import {ChatUser} from './chat-user';

export class Chat extends BaseClass {
  id?: number;
  title?: string;
  created_at: string;
  updated_at: string;
  is_private: boolean;
  last_message_at: string;
  me: ChatUser[] = [];
  displayName: string;
  users: ChatUser[] = [];
  length: any;
  description: string;
  isTyping: boolean;

  constructor(init: any) {
    super();
    this.id = init.id;
    if (init && init.title) this.title = init.title;
    if (init && init.created_at) this.created_at = init.created_at;
    if (init && init.updated_at) this.updated_at = init.updated_at;
    if (init && init.last_message_at) this.last_message_at = init.last_message_at;
    if (init && init.me) this.me = init.me;
    if (init && init.is_private) this.is_private = init.is_private == 1;
    if (init && init.isTyping) this.isTyping = init.isTyping == 1;
    if (init && init.displayName) this.displayName = init.displayName;
    if (init && init.length) this.length = init.length;
    if (init && init.description) this.description = init.description;
    if (init && init.users) this.users = init.users.map(user => new ChatUser(user));
  }

  toJson(): Object {
    return Chat.clearJson({
      id: this.id ? this.id : undefined,
      title: this.title,
      created_at: this.created_at,
      updated_at: this.updated_at,
      last_message_at: this.last_message_at,
      length: this.length,
      is_private: this.is_private?1:0,
      isTyping: this.isTyping?1:0,
      me: this.me,
      description: this.description,
      displayName: this.displayName,
      users: this.users,
    });
  }
}

