export class Label {
  id?: number | string = null;
  name: string = 'NOT SET';
  color?: string = '#4294E6';
  isDefault?: boolean = false;
  description?: string;

  constructor(init?: any) {
    if (init && init.id) this.id = init.id;
    if (init && init.name) this.name = Label.capitalizeFirstLetter(init.name);
    if (init && init.color) this.color = init.color;
    if (init && init.isDefault) this.isDefault = init.isDefault;
    if (init && init.description) this.description = init.description;
  }


  private static capitalizeFirstLetter(string: string): string {
    if (!string) return;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
