export const MailChimpRoutes = {
  accountData: {
    method: 'Get',
    url: '/'
  },
  audience: {
    lists: {
      method: 'Get',
      url: '/lists'
    },
    list: {
      method: 'Get',
      url: '/lists' // /:id
    },
    new_list: {
      method: 'Post',
      url: '/lists'
    },
    sub_unsub: {
      method: 'Post',
      url: '/lists/' // /:list_id
    },
    delete_list: {
      method: 'Delete',
      url: 'lists/' // /:list_id
    },
    patch_audience: {
      method: 'Patch',
      url: 'lists/' // /:list_id
    },
    add_subscriber: {
      method: 'Post',
      url: 'lists/' // /:list_id/members
    },
    audience_sub: {
      method: 'Get',
      url: 'lists/' // /:list_id/members
    },
    audience_single_sub: {
      method: 'Get',
      url: 'lists/' // /:list_id/members/:subscriber_hash
    },
    batch_subscriber: {
      method: 'Post',
      url: 'lists/'  // /:list_id
    },
    delete_single_sub: {
      method: 'Delete',
      url: 'lists/'  // /:list_id/members/:subscriber_hash
    },
    edit_sub: {
      method: 'Patch',
      url: 'lists/' // /:list_id/members/:subscriber_hash
    }
  },
  campaigns: {
    lists: {
      method: 'Get',
      url: '/campaigns'
    },
    get_campaign: {
      method: 'Get',
      url: '/campaigns' // /:campaign_id
    },

    create_campaign: {
      method: 'Post',
      url: '/campaigns'
    },
    send_campaign: {
      method: 'Post',
      url: '/campaigns' // /:campaign_id/actions/send
    },
    update_campaign: {
      method: 'Patch',
      url: '/campaigns' // /campaign_id:
    },
    delete_campaign: {
      method: 'Delete',
      url: '/campaigns' // /campaign_id
    },

    campaign_content: {
      method: 'Get',
      url: '/campaigns' // /campaign_id/content
    }

  },
  templates: {
    getTemplates: {
      method: 'Get',
      url: '/templates'
    }
  }
};
