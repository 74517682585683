import {environment} from '../../../../environments/environment';

export const CompanyRoutes = {
  //<editor-fold desc="Company">
  get: {
    url: `${environment.baseUrl}/api/${environment.version}/company/get`, // /:id -> company id
    method: 'Get'
  },
  filter: {
    url: `${environment.baseUrl}/api/${environment.version}/company/filter`,
    method: 'Post'
  },
  create: {
    url: `${environment.baseUrl}/api/${environment.version}/company/create`,
    method: 'Post'
  },
  update: {
    url: `${environment.baseUrl}/api/${environment.version}/company/update`, // /:id -> company id
    method: 'Patch'
  },
  archive: {
    url: `${environment.baseUrl}/api/${environment.version}/company/delete`, // /:id -> company id,
    method: 'Delete'
  },
  restore: {
    url: `${environment.baseUrl}/api/${environment.version}/company/restore`, // /:id -> company id
    method: 'Patch'
  },
  createMain: {
    url: `${environment.baseUrl}/api/${environment.version}/company/main/create`,
    method: 'Post'
  },
  updateMain: {
    url: `${environment.baseUrl}/api/${environment.version}/company/main/update`, // /:id -> company id
    method: 'Patch'
  },
  commons: {
    label: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/company/label/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/company/label/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/company/label/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/company/label/delete` // /:id
      },
    },
    category: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/company/category/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/company/category/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/company/category/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/company/category/delete` // /:id
      },
    }
  },
  //</editor-fold>
  //<editor-fold desc="Branches">
  branch: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/company/branch/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/company/branch/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/company/branch/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/company/branch/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/company/branch/delete` // /:id
    },
    cashRegister: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/company/cashRegister/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/company/cashRegister/create`
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/company/cashRegister/delete`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/company/cashRegister/update` // :id
      },
    }
  },
  //</editor-fold>
  //<editor-fold desc="Departments">
  department: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/company/department/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/company/department/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/company/department/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/company/department/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/evnas/department/archive`
    }
  },
  //</editor-fold>
  //<editor-fold desc="Price list">
  priceList: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/company/priceList/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/company/priceList/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/company/priceList/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/company/priceList/delete` // /:id
    }
  },
  //</editor-fold>
  documents: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/document/singleCompanyDocument`
  }
};
