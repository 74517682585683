import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OccupationModalComponent} from './occupation-modal.component';
import {BaseModalModule} from "../base-modal/base-modal.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BaseModalModule,
  ],
  providers: [
  ],
  declarations: [
   OccupationModalComponent
  ],
  exports: [
    OccupationModalComponent
  ]
})
export class OccupationModalModule {
}
