import {HumanResourcesRoutes} from './human-resources/human-resources.routes';
import {AdminRoutes} from './admin/admin-routes';
import { BuildingsRoutes } from './buildings/buildings.routes';
import {environment} from '../../../../environments/environment';
const root: string = `${environment.baseUrl}/api/${environment.version}`;

export const EvnasRoutes = {

  humanResources: HumanResourcesRoutes,
  admin: AdminRoutes,
  buildings: BuildingsRoutes,
  studyTypes: {
    create: {
      url: `${root}/evnas/studyProgrammeType/create`,
      method: 'Post'
    },

    delete: {
      url: `${root}/evnas/studyProgrammeType/archive/`,
      method: 'Delete'
    },

    get: {
      url: `${root}/evnas/studyProgrammeType/get/`,
      method: 'Get'
    },

    filter: {
      url: `${root}/evnas/studyProgrammeType/filterStandard`,
      method: 'Post'
    },
    update: {
      url: `${root}/evnas/studyProgrammeType/update/`,
      method: 'Patch'
    },
  },

  studyKinds: {
    create: {
      url: `${root}/evnas/studyType/create`,
      method: 'Post'
    },

    delete: {
      url: `${root}/evnas/studyType/archive/`,
      method: 'Delete'
    },

    get: {
      url: `${root}/evnas/studyType/get/`,
      method: 'Get'
    },

    filter: {
      url: `${root}/evnas/studyType/filterStandard`,
      method: 'Post'
    },
    update: {
      url: `${root}/evnas/studyType/update/`,
      method: 'Patch'
    },
  },

  transferWord: {
    url: `${environment.transferUrl}/v1/transfer/evnas/word`,
    method: 'Get'
  }
};
