import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {urlValues} from '../configs/url.values';
import * as moment from 'moment';
import * as _ from 'lodash';
import {LocalizationService} from './localization.service';
import {Message} from 'primeng/api';
import {FormGroup} from '@angular/forms';
import {AppMessageSettings} from '../classes/app-message-settings';
import {CommonData} from '../interfaces/common/common-data';
import {Settings} from '../interfaces/settings/settings';
import {environment} from '../../environments/environment';
import {ApiService} from './api.service';
import {ThemeService} from './theme.service';
import * as numeral from 'numeral';
import {EstimationLog} from '../modules/layout/projects/classes/estimation-log';
import {FilterData} from '../classes/filter-data';
import {map} from 'rxjs/operators';
import * as CryptoJS from "crypto-js";
import {LayoutData} from '../components/layout-menu/layout-menu.component';
import {EnglishTranslation} from "../localizations/languages/eng";
import {CroatianTranslations} from "../localizations/languages/cro";
import {SerbianTranslations} from "../localizations/languages/srp";

@Injectable()
export class GlobalService {

  private cryptoKey: string = 'GBCrypt1134!*';
  //<editor-fold desc="App messages variables">
  public appMessages: Message[] = [];
  public appMessageSettings: BehaviorSubject<AppMessageSettings> = new BehaviorSubject<AppMessageSettings>(null);
  //</editor-fold>

  public urlValues: any = urlValues;

  public commonData: CommonData = null; //common.resolver for commons across app, and each module has its own common resolver
  public settings: Settings = null;
  public documentCommons = {}; // documentCommons.resolver for common data on document module (only used on documents for now)

  //Loading -> show / hide spinner div
  public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  //405 error event
  public saleError: EventEmitter<any> = new EventEmitter<any>();
  //<editor-fold desc="Date, time settings">
  public initTime: object = moment().set({hours: 0, minutes: 0}).toDate();
  public today: object = new Date();
  //</editor-fold>
  public env: object;
  public taxes: any[] = [];
  public shippingMethods: any[] = [{id: null, shippingName: 'Select'}];
  public source: string = '';
  public projectToInvoice;
  public offerToConvert;
  public eLearningToInvoice;
  public createTraveWarrantWithData: number = null;

  public mainSidebarData: LayoutData[];

  public tinyMceSettings = {
    inline: false,
    statusbar: false,
    browser_spellcheck: true,
    height: 320,
    paste_data_images: true,
    //plugins: 'powerpaste preview fullpage searchreplace autolink directionality advcode visualblocks visualchars fullscreen image link media codesample table charmap hr pagebreak nonbreaking advlist lists wordcount tinymcespellchecker a11ychecker imagetools textpattern',
    //toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | forecolor | backcolor | fontsizeselect',
    plugins: 'print preview fullpage powerpaste searchreplace autolink directionality advcode visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount tinymcespellchecker a11ychecker imagetools mediaembed  linkchecker contextmenu colorpicker textpattern help',
    toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | fontsizeselect',
    content_css: [],
    video_template_callback: function (data) {
      return '<video width="' + data.width + '" height="' + data.height + '"' + (data.poster ? ' poster="' + data.poster + '"' : '') + ' controls="controls">\n' + '<source src="' + data.source1 + '"' + (data.source1mime ? ' type="' + data.source1mime + '"' : '') + ' />\n' + (data.source2 ? '<source src="' + data.source2 + '"' + (data.source2mime ? ' type="' + data.source2mime + '"' : '') + ' />\n' : '') + '</video>';
    }
  };

  //<editor-fold desc="Datepicker vars">
  public dateFormat: string;
  public yearRange: string = '1950:2050';
  //</editor-fold>

  public mailChimpParams: {
    apiKey: string,
    username: string,
    requestUrl: string
  } = {
    apiKey: '',
    username: '',
    requestUrl: `https://cors-anywhere.herokuapp.com/https://us20.api.mailchimp.com/3.0`,
  };

  constructor(
    public _locale: LocalizationService,
    public _router: Router,
    private _api: ApiService,
    private _theme: ThemeService) {

    this.appMessages = []; //Set all errorMessages to empty
    this.createMixins(); //Create custom lodash functions
    this.env = environment;
  }

  //Languages can be cro, srp
  //Compares them with eng translations and finds the ones that are missing
  public getMissingTranslations(language: 'cro' | 'srp'): any {
    let languages = {
      eng: EnglishTranslation,
      cro: CroatianTranslations,
      srp: SerbianTranslations
    };
    return _.filter(_.map(languages[language], (value, index) => {
      if (!languages.eng[index]) return {[index]: value}
    }), value => value !== undefined);
  }

  //<editor-fold desc="Date time manipulations">
  //Converts timestamp to string format DD.MM.YYYY
  timestampToDate(timestamp: number): string {
    if (typeof timestamp !== 'number') timestamp = parseInt(timestamp);
    if (!timestamp) return '-';
    let date = moment(timestamp);
    let dateFormat = _.find(this.settings.global, {key: 'dateFormat'});
    return dateFormat ? date.format(dateFormat.value.value.toUpperCase()) : date.format('DD.MM.YYYY');
  }

  convertToDate(data: string | number): string {
    if (!data) return '-';
    let date = moment(data);
    if (!date.isValid()) return '-';
    let dateFormat = _.find(this.settings.global, {key: 'dateFormat'});
    return dateFormat ? date.format(dateFormat.value.value.toUpperCase()) : date.format('DD.MM.YYYY');
  }

  //Accepts JS date object and returns timestamp
  dateToTimestamp(date: Date, format?: string) {
    return moment(date, format || 'DD.MM.YYYY HH:mm').valueOf();
  }

  formatDateObject(date: Date, format?: string): Date | string {
    if (!date) return '-';
    let newDate = moment(date);
    let dateFormat = _.find(this.settings.global, {key: 'dateFormat'});
    return dateFormat ? newDate.format(dateFormat.value.value.toUpperCase()) : date;
  };

  reformatDateString(date: string, format?: string): string {
    if (!format) {
      let defaultFormat: string = 'MM. DD. YYYY.';
      let dateFormat = _.find(this.settings.global, {key: 'dateFormat'}).value.value;
      format = `${dateFormat ? dateFormat.toUpperCase() : defaultFormat}`;
    }
    return moment(date).format(format);
  }

  getTimeFromTimestamp(timestamp: number): string {
    let timeFormat = _.find(this.settings.global, {key: 'timeFormat'});
    return timestamp ? moment(timestamp).format(timeFormat ? timeFormat.value.value : 'HH:mm') : '-';
  }

  timeToString(seconds: number): string {
    if (!seconds) return '00:00';
    return this.zeroPad(Math.floor(seconds / 3600)) + ':' + this.zeroPad(Math.floor(seconds / 60) % 60) + ':' + this.zeroPad(seconds % 60);
  }

  formatDate(date: Date, format: string): string {
    return moment(date).format(format);
  }

  public checkGracePeriod(settings: any, updatedAt: number, moduleName: string): boolean {
    const setting = settings[moduleName].find(setting => setting.key === 'allowCommentEditing');
    if (!setting) return false;
    const values = JSON.parse(setting.value);
    if (values.value < 0) return false;
    let gracePeriod = moment().subtract(values.value, values.unit);
    //check if grace period is over - negative value means it is still active
    return (gracePeriod.valueOf() - moment(updatedAt).valueOf()) < 0;
  }

  //</editor-fold>

  getTaxes(): Observable<any> {
    return this._api.send(urlValues.admin.taxes.filter.method, urlValues.admin.taxes.filter.url, new FilterData()).pipe(map(res => res['data'].records));
  }

  zeroPad(number: number): string {
    let numberString = number.toString();
    return numberString.length > 1 ? numberString : '0' + numberString;
  }


  //<editor-fold desc="CryptoJS">
  encryptJSON(data: object): string { //Returns encrypted string
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.cryptoKey).toString();
  }

  decryptJSON(text: any): any { //Accepts encrypted string and returns JSON
    let bytes = CryptoJS.AES.decrypt(text, this.cryptoKey);
    try {
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (e) {
      return null;
    }
  }

  //</editor-fold>

  //<editor-fold desc="String manipulations">
  capitalizeFirstLetter(string: string): string {
    if (!string) return;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  toLowerCase(word: string): string {
    return word.toLowerCase();
  }

  removeLastChar(str: string): string {
    return str.substring(0, str.length - 1);
  }

  removeHtmlFromString(text: string): string {
    let tmp = document.createElement('DIV'); //Create tmp div
    tmp.innerHTML = text.substring(0, 100).length > 40 ? `${text.substring(0, 100)}...` : text.substring(0, 100); //Set his inner html, get only first 100 characters and add ... at the end
    return tmp.textContent || tmp.innerText || '';
  }

  slugify(text: string, replaceChar?: string): string {
    return text.toString().toLowerCase()
      .replace(/\s+/g, replaceChar ? replaceChar : '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, replaceChar ? replaceChar : '-') // Remove all non-word chars
      .replace(/\-\-+/g, replaceChar ? replaceChar : '-')  // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  public static toCamelCase(str: string, separator: string = '.'): string {
    let frags = str.split(separator);
    for (let i = 0; i < frags.length; i++) frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    return frags.join('').charAt(0).toLowerCase() + frags.join('').slice(1);
  }

  replaceAt(string: string, index: number, replacement: string): string {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length);
  }

  objectToQuery(data: object) {
    const queryArray = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) queryArray.push(`${key}=${data[key]}`)
    }
    return queryArray.length ? `?${queryArray.join('&')}` : '';
  }

  //</editor-fold>

  //<editor-fold desc="App messages">
  //Add messages to the application messages and display them on page
  //Available types : success, info, warn, error
  pushAppMessage(type: 'success' | 'info' | 'warn' | 'error', headerText: string, bodyText: string, settings?: AppMessageSettings): void {
    this.appMessages = [];
    this.appMessageSettings.next((settings) ? settings : new AppMessageSettings());
    this.appMessages.push({severity: type, summary: headerText, detail: bodyText});
  }

  updateAppMessage(index: number, data: object) {
    if (index >= this.appMessages.length) return;
    for (let key in data) {
      this.appMessages[index][key] = data[key];
    }
  }

  clearAppMessages() {
    this.appMessages = [];
  }

  onCloseAppMessage() {
    this.appMessageSettings.next(null);
    this.appMessages = [];
  }

  getDefaultCurrency(): number {
    return this.settings.global.find(e => e.key === 'defaultCurrency').value.id
  }

  getCurrencyCode(id: number): string {
    try {
      let currencyId: number
      if (typeof id === 'string') {
        currencyId = parseInt(id);
      } else {
        currencyId = id
      }
      return this.commonData.currencies.find(e => e.id === id).code
    } catch (e) {
      console.error(e);
      return ''
    }
  }

  //</editor-fold>

  //<editor-fold desc="Currency & Number Formats">
  //TODO only works with current currency formats
  formatCurrency(number: number, removeSymbol?: boolean): string {
    let currencyFormat = _.find(this.settings['global'], {key: 'currencyFormat'});
    let currencyFormatValue = currencyFormat ? currencyFormat.value.value : '0.00';
    let defaultCurrency = _.find(this.settings['global'], {key: 'defaultCurrency'});
    let currencySymbol = defaultCurrency ? defaultCurrency.value.symbol : '';
    let dotIndex = currencyFormatValue.indexOf('.');
    let commaIndex = currencyFormatValue.indexOf(',');
    if (!~dotIndex || !~commaIndex) { //just in case
      currencyFormatValue = '0.00';
    } else if (dotIndex < commaIndex) {
      currencyFormatValue = currencyFormatValue.replace(",", "*").replace(".", ",").replace("*", ".");
      let allDotsFormated = numeral(number).format(currencyFormatValue).replace(/,/g, '.');
      let lastDot = allDotsFormated.lastIndexOf('.');
      if (removeSymbol) return (allDotsFormated.slice(0, lastDot) + ',' + allDotsFormated.slice(lastDot + 1)).replace('$', '');
      return (allDotsFormated.slice(0, lastDot) + ',' + allDotsFormated.slice(lastDot + 1)).replace('$', currencySymbol);
    }
    if (removeSymbol) return numeral(number).format(currencyFormatValue).replace('$', '');
    return numeral(number).format(currencyFormatValue).replace('$', currencySymbol);
  }

  findDelimiter(currencyString: string): string {
    for (let i = currencyString.length - 1; i > 0; i--) {
      if (isNaN(+currencyString[i])) {
        if (currencyString[i] === ',' || currencyString[i] === '.') {
          return currencyString[i];
        }
      }
    }
  }

  formatFromCurToNum(currencyString: string): number {
    if (!currencyString) return 0;
    let delimiter = this.findDelimiter(currencyString)
    let splitedValue = currencyString.split(delimiter);
    splitedValue[0] = splitedValue[0].replace(/\D/g, '');
    let joinedValue = splitedValue.join('.');

    return parseFloat(joinedValue)
  }

  formatNumber(number: number): string {
    let currency = _.find(this.settings['global'], {key: 'defaultCurrency'});

    let customNumeral = {
      delimiters: {
        thousands: '.',
        decimal: ','
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
      },
      ordinal: function (num) {
        return '';
      },
      currency: {
        symbol: currency.value.symbol
      }
    };
    numeral.register('locale', 'custom', customNumeral);
    numeral.locale('custom');


    let format = _.find(this.settings['global'], {key: 'numberFormat'});
    return numeral(number).format(format.value.value);
  }

  //</editor-fold>

  //<editor-fold desc="Form Data manipulations">
  getChangedValues(formValues, selectedValue) { //formValues = new values, selectedValue = old values to compare with
    let changedKeys = _.keys(formValues).filter(key => !_.isEqual(selectedValue[key], formValues[key]));

    return _.zipObject(changedKeys, changedKeys.map(field => {
      return (_.isObject(formValues[field]) && !_.isArray(formValues[field])) ? this.getChangedValues(formValues[field], selectedValue[field]) : formValues[field];
    }));
  }

  //Accepts fromGroup, iterates trough it and marks controls as touched
  //Depends on error-msg component which displays errors
  checkFormErrors(formGroup: FormGroup): void {
    _.each(formGroup.controls, control => {
      control.markAsTouched();

      if (control.controls) _.each(control.controls, c => {
        c.markAsTouched();
        this.checkFormErrors(c);
      });
    });
  }

  //</editor-fold>

  //<editor-fold desc="Random functions">
  getRandomColor(id?: number): string {
    let colors = ['#BF5783', '#1DBFBF', '#6F80C7', '#EFA02A', '#ED6C76'];
    if (!id) _.sample(colors); //Return random color
    else return colors[id % colors.length]; //If id then bind color to that id
  }

  getCountryNameWithId(id: number): string {
    if (typeof id !== 'number') throw Error('Id is not a number');
    let country = this.commonData.countries.find(country => country.id === id);
     return country ? country.name : '';
  }

  updateCommonData(module: string, key: string, value: object, method: 'create' | 'update' | 'delete'): void {
    if (!this.commonData[module]) this.commonData[module] = [];
    if (!this.commonData[module][key]) this.commonData[module][key] = []; //If it does not exists create anew array
    switch (method) {
      case 'create': //Pushes a new label that has been created to labels array
        this.commonData[module][key].push(value);
        break;
      case 'update': //Splices the old label with the new one
        let index = _.findIndex(this.commonData[module][key], {id: value['id']});
        this.commonData[module][key].splice(index, 1, value);
        break;
      case 'delete': //Removes the label from labels array
        _.remove(this.commonData[module][key], ['id', value['id']]);
        break;
      default:
        return;
    }
  }

  setGridPercentages(headers: object): string { //Returns string of grid widths (5% 16% 12% ....)
    return _.map(headers, header => header.width).join(' ');
  }

  isEmptyObject(obj: object): boolean {
    if (!obj) return;
    return (Object.keys(obj).length === 0 && obj.constructor === Object);
  }

  isAllowedKeyCode(event): boolean {
    if ((event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40)) return false;
    else return true;
  }

  //</editor-fold>

  getUserSettings(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this._api.send(urlValues.user.settings.get.method, urlValues.user.settings.get.url).subscribe(res => {
        this.settings = res['data'];
        _.each(this.settings.lists, (list) => list.values = _.orderBy(list.values, ['orderNo'])); //Sort list values by orderNumber
        //Set default language in localization service
        let lang = _.find(res['data'].global, ['key', 'language']);
        this._locale.set(lang ? lang.value.code : 'eng');
        //Set default color scheme for app
        let scheme = _.find(res['data'].global, ['key', 'colorScheme']);
        this._theme.colorScheme = scheme ? scheme.value : 'defaultTheme';
        // //Find the default date from settings and set ti to global service variable
        let defaultDateSettings = _.find(this.settings.global, {key: 'dateFormat'});
        this.dateFormat = defaultDateSettings ? defaultDateSettings.value.value : 'dd.mm.yy';
        obs.next(true);
        obs.complete();
      });
    });
  }

  getLanguageCodeWithId(id: number): string {
    let languageId = typeof id === 'number' ? id : parseInt(id);
    let language = this.commonData.languages.find(e => e.id === languageId);
    return language ? language.code.toUpperCase() : '';
  }

  getUnit(id: number): Object {
    let unitId = typeof id === 'number' ? id : parseInt(id);
    let unit = this.commonData.units.find(e => e.id === unitId);
    return unit;
  }

  createMixins(): void {//Create a bunch of custom lodash functions
    _.mixin({
      'mapFilterFields': (collection) => {
        return _.map(collection, item => {
          return {name: item.name, sendValue: item.id, childs: item.childs ? item.childs : null};
        });
      },
      'mapEmployeesSelect': (users, type) => {
        return _.map(_.filter(users, user => user.type == type), employee => {
          return {
            id: employee.id,
            name: `${employee.details.firstName} ${employee.details.lastName}`,
            initials: `${employee.details.firstName[0]}${employee.details.lastName[0]}`,
            profileImage: employee.details.profileImage ? employee.details.profileImage.path : null,
            iconColor: this.getRandomColor(employee.id)
          };
        });
      },
      'mapUsersSelect': (users, forFilter?: boolean, clientType?: string) => {
        return _.map(users, user => {
          let json = {
            id: user.id,
            name: `${user.details.firstName ? user.details.firstName : ''} ${user.details.lastName ? user.details.lastName : ''}`,
            initials: `${user.details.firstName ? user.details.firstName[0] : ''}${user.details.lastName ? user.details.lastName[0] : ''}`,
            profileImage: user.details.profileImage ? user.details.profileImage.media : null,
            iconColor: this.getRandomColor(user.id),
            type: user.type,
            role: user.role ? user.role.id : null
          };
          if (clientType) json['clientType'] = clientType;
          forFilter ? json['sendValue'] = user.id : json['id'] = user.id;
          return json;
        });
      },
      'mapPriceListItems': (items: any) => {
        return _.map(items, item => {
          return {
            id: item.id,
            itemName: item.name,
            netPrice: item.retailPrice,
            totalPrice: item.retailPrice,
            unit: item.unit,
            vatClass: item.taxClass,
            currency: item.currency ? item.currency : null
          };
        });
      },
      'mapPriceListItem': (item: any) => {
        return {
          id: item.id,
          itemName: item.name,
          netPrice: item.retailPrice,
          totalPrice: item.retailPrice,
          unit: item.unit,
          vatClass: item.taxClass,
          currency: item.currency ? item.currency : null
        };
      },
      'mapSingleUsersSelect': (user) => {
        if (!user) return;
        return {
          id: user.id,
          name: `${user.details.firstName} ${user.details.lastName}`,
          initials: `${user.details.firstName[0]}${user.details.lastName[0]}`,
          profileImage: user.details.profileImage ? user.details.profileImage.path : null,
          iconColor: this.getRandomColor(user.id),
          type: user.type
        };
      },
      'mapUsersListItemSelect': (users) => {
        if (!users) return;
        return _.map(users, user => {
          return {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            initials: `${user.firstName[0]}${user.lastName[0]}`,
            profileImage: user.profileImage ? user.profileImage.path : null,
            iconColor: this.getRandomColor(user.id),
            type: user.type
          };
        });
      },
      'mapSingleUsersListItemSelect': (user) => {
        if (!user) return;
        return {
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          initials: `${user.firstName[0]}${user.lastName[0]}`,
          profileImage: user.profileImage ? user.profileImage.path : null,
          iconColor: this.getRandomColor(user.id),
          type: user.type
        };
      },
      'mapUserForActivityLogFilter': (users) => {
        if (!users) return;
        return _.map(users, user => {
          return {
            sendValue: user.id,
            name: `${user.firstName} ${user.lastName}`
          };
        });
      },
      'mapCalendarEvent': (objects: object[], type: string) => {
        return _.map(objects, object => {
          let data: any = {
            title: object.uniqueNumber ? `${object.uniqueNumber}: ${object.name}` : object.title,
            eventTitle: object.uniqueNumber ? `${object.uniqueNumber}: ${object.name}` : object.title,
            start: moment(object.startDate || object.start || object.startEvent).toDate(),
            end: moment(object.endDate || object.start || object.endEvent).toDate(),
            // id: object.id,
            eventId: object.id,
            type: type,
            recurring: type === 'customEvent' ? object.recurring : false,
            className: `calendar-label-${type}`
          };
          if (type === 'customEvent') {
            data.startEvent = moment(object.startEvent).toDate();
            data.endEvent = moment(object.endEvent).toDate();
            data.startRecurring = object.startRecurring ? moment(object.startRecurring).toDate() : null;
            data.endRecurring = object.endRecurring ? moment(object.endRecurring).toDate() : null;
            data.recurringType = object.recurringType;
            data.private = object.private;
            data.note = object.note;
          }
          return data;
        });
      },
      'move': (array: any[], from: number, to: number) => {
        array.splice(to, 0, array.splice(from, 1)[0]);
        return array;
      },
      'mapLoggedDays': (logs: EstimationLog[]) => {
        return _.map(logs, log => {
          return moment(log.date).format('DD-MM-YYYY');
        });
      }
    });
  }

  invoiceInitWithExtData(extModule: 'project' | 'offer' | 'eLearning' | '',
                         /*
                           items - array of items that will be displayed in the 'Items details' section
                           note - string that will be displayed in 'Note' section
                           timeframe - string of 2 concated dates ( from and to )
                           note and timeframe will be merged togather
                         */
                         data: { items: any[], note: string, timeframe: string }
  ): void {
    this.source = extModule;
    this[`${extModule}ToInvoice`] = data;
    this._router.navigate(['documents', 'outgoing-invoice', 'create']);
  }
}
