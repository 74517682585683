import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CategoryModalComponent} from './category-modal.component';
import {BaseModalModule} from "../base-modal/base-modal.module";
import {ErrorMsgModule} from "../../error-msg/error-msg.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BaseModalModule,
    ErrorMsgModule
  ],
  providers: [
  ],
  declarations: [
    CategoryModalComponent
  ],
  exports: [
    CategoryModalComponent
  ]
})
export class CategoryModalModule {
}
