import {BaseClass} from "../../../../classes/base-class";
import {User} from '../../user/classes/user';
import {Reaction} from './reaction';
import {Links} from './links';
import {UploadedFile} from "../../../../interfaces/uploaded-file";
import {QuotedMessage} from "./quoted-message";
import {Chat} from "./chat";

export class ChatMessage extends BaseClass {
  id?: number;
  type: string;
  body: string;
  chat_id: number;
  additional: string;
  created_at: string;
  updated_at: string;
  links: Links[] = [];
  user_id?: User;
  user?: User;
  chat?: Chat;
  quotedMessage: QuotedMessage;
  medias?: UploadedFile;
  limit?: number;
  reactions: Reaction [] = [];
  quoted_message_id: number;
  showEmojiPicker = false;

  constructor(init: any) {
    super();
    this.id = init.id;
    if (init && init.type) this.type = init.type;
    if (init && init.body) this.body = init.body;
    if (init && init.chat_id) this.chat_id = init.chat_id;
    if (init && init.additional) this.additional = init.additional;
    if (init && init.created_at) this.created_at = init.created_at;
    if (init && init.updated_at) this.updated_at = init.updated_at;
    if (init && init.user) this.user = new User(init.user);
    if (init && init.chat) this.chat = new Chat(init.chat);
    if (init && init.quotedMessage) this.quotedMessage = new QuotedMessage(init.quotedMessage);
    if (init.links && init.links.length) this.links = init.links.map(links => new Links(links));
    if (init.reactions && init.reactions.length) this.reactions = init.reactions.map(reactions => new Reaction(reactions));
    if (init && init.medias) this.medias = init.medias;
    if (init && init.quoted_message_id) this.quoted_message_id = init.quoted_message_id;
    if (init && init.limit) this.limit = init.limit;
    this.user_id = init.user_id;
  }

  toJson(): Object {
    return ChatMessage.clearJson({
      id: this.id ? this.id : undefined,
      type: this.type,
      body: this.body,
      chat_id: this.chat_id,
      additional: this.additional,
      created_at: this.created_at,
      updated_at: this.updated_at,
      user_id: this.user_id,
      user: this.user,
      chat: this.chat,
      quotedMessage: this.quotedMessage,
      reactions: this.reactions,
      medias: this.medias,
      links: this.links,
      quoted_message_id: this.quoted_message_id,
      limit: this.limit,
    });
  }

  public static convertToSystemDeletedMsg(message: ChatMessage): ChatMessage {
    return Object.assign(message, {
      type: 'system',
      body: 'deletedMessage'
    });
  }

  public static updateMsgBody(message: ChatMessage, body: string): ChatMessage {
    return Object.assign(message, {body: body});
  }

  public static generateQuoteText(header: string, body: string): string {
    return `${header}: ${body}`
  }

  public static checkIfFiveMinutesPassed(createdAt: number): boolean {
    let diff = Math.abs(Math.round((createdAt - Date.now()) / 1000 / 60));
    return diff >= 5;
  }


}

