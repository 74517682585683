import {EventEmitter, Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {PlatformLocation} from "@angular/common";
import {environment} from "../../environments/environment";
import Ws from '@adonisjs/websocket-client';
import {InAppNotification} from "../components/notifications/classes/in-app-notification";
import {Chat} from "../modules/layout/chat/classes/chat";
import {BehaviorSubject} from "rxjs";
import {ChatMessage} from "../modules/layout/chat/classes/chat-message";

export interface ChatSocket {
  chatCreated?: any;
  chatUpdate?: any;
  chatStatus?: any;
  chatLeave?: any;
  seenChat?: any;
  messageCreated?: any;
  messageUpdated?: any;
  messageDelete?: any;
  messageReact?: any;
}

@Injectable()
export class SocketService {

  readonly webSocketUrl: string;
  private ws;
  public channel: any;
  public notifications: InAppNotification[] = [];

  public showInAppNotifications: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public chatSocket: EventEmitter<ChatSocket> = new EventEmitter<ChatSocket>();

  constructor(private _auth: AuthService,
              private _pl: PlatformLocation) {

    if(this._auth.loggedUser) this.connect();
  }

  public connect() {
  }

  public close() {
    this.ws.close();
  }

  private static generateWebSocketUrl(origin: string): string {
    return origin.search('http://') !== -1 ? environment.httpSocketUrl : environment.httpsSocketUrl;
  }

  private chatSubscriptions(subscription: any): void {
    subscription.on('createChat', (data) => this.chatSocket.emit({chatCreated: new Chat(data)}));
    subscription.on('updateChat', (data) => this.chatSocket.emit({chatUpdate: new Chat(data)}));
    subscription.on('setChatStatus', (data) => this.chatSocket.emit({chatStatus: new Chat(data)}));
    subscription.on('leaveChat', (data) => this.chatSocket.emit({chatLeave: new Chat(data)}));
    subscription.on('seenChat', (data) => this.chatSocket.emit({seenChat: new Chat(data)}));

    subscription.on('messageCreated', (data) => this.chatSocket.emit({messageCreated: new ChatMessage(data)}));
    subscription.on('messageUpdated', (data) => this.chatSocket.emit({messageUpdated: new ChatMessage(data)}));
    subscription.on('messageDelete', (data) => this.chatSocket.emit({messageDelete: new ChatMessage(data)}));
    subscription.on('reactToMessage', (data) => this.chatSocket.emit({messageReact: new ChatMessage(data)}));
3
    subscription.on('typing', (data) => {
      // @ts-ignore
      // this.chatTyping().emit(new Chat(data));
    });
  }
}


