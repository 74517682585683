import { GlobalModule } from '../../../modules/global.module';
import { SelectLabelsModalComponent } from './select-labels-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ClickOutsideModule} from '../../../directives/clickOutside/clickOutside.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiselectModule } from '../../form-elements/multiselect/multiselect.module';
import { ErrorMsgModule } from '../../error-msg/error-msg.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MultiselectModule,
    ErrorMsgModule,
  ],
  declarations: [SelectLabelsModalComponent],
  exports: [SelectLabelsModalComponent]
})
export class SelectLabelsModalModule { }
