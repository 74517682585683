import {Params} from "@angular/router";

export interface FilterValue {
  key: string;
  subType?: boolean | number | string | number[] | string[] | {
    [logicalOperator: string]: boolean | number | string | number[] | string[] //Operator, != ....
  },
  value: boolean | number | string | number[] | string[] | {
    [logicalOperator: string]: boolean | number | string | number[] | string[] //Operator, != ....
  };
  name?: string | string[]; // for showing active filter name
  //'default' is misleading, not to be confused with default filters added in component list. It should be smth like 'keep (when filtering/clearing)'
  // default filters on ListComponent-s are added if saved filter values dont exist but they may be removed/edited when filtering (if not marked 'default')
  // permanent filters on ListComponent's will override even saved filters (filter values) if permanent saved filters are changed
  default?: boolean; //If true that is a default filter and it will never be removed from filters array
  notSavable?: boolean;
}

export class FilterData {
  name: string;
  view: 'list' | 'grid' | 'kanban' | string;
  parentId: number; //id of an item the list belongs to (eg. tasks belong to a project)
  keywords: string;
  filters: FilterValue[] = [];
  page: number = 1;
  offset?: number = 0;
  limit: number = 10;
  noPaginate?: boolean;
  total: number;
  sort: {
    param?: string,
    order?: string // ASC, DESC
  };
  list?: boolean = false; //Boolean if backend will return a list json or normal standard json
  report: boolean = false; //Only used in report module
  select: string[];
  urlQuery?: string; //params for url
  details: boolean; //For exams
  tree?: boolean;
  storeID?: number;
  project?: number;
  estimation?: number;
  clientUsers?: boolean;
  currentTimestamp?: number;
  customConfig?: any = {}

  constructor(init?: any, removePagination?: boolean) {
    if(init) {
      if (init.name) this.name = init.name;
      if (init.view) this.view = init.view;
      this.parentId = init.parentId;
      this.project = init.project;
      if (init.keywords) this.keywords = init.keywords;
      if (init.filters) this.filters = init.filters;
      if (init.page) this.page = init.page;
      if (init.offset) this.offset = init.offset;
      if (init.limit) this.limit = init.limit;
      if (init.noPaginate) this.noPaginate = init.noPaginate;
      if (init.total) this.total = init.total;
      if (init.sort) this.sort = init.sort;
      if (init.list) this.list = init.list;
      if (init.report) this.report = init.report;
      if (init.select) this.select = init.select;
      if (init.details) this.details = init.details;
      if (init.urlQuery) this.urlQuery = init.urlQuery;
      if (init.tree) this.tree = init.tree;
      if (init.clientUsers) this.clientUsers = init.clientUsers;
      if (init.currentTimestamp) this.currentTimestamp = init.currentTimestamp;
      this.customConfig = init.customConfig || {};
    }

    if (removePagination) { //Remove pagination params if removePagination is set to TRUE
      this.removePagination();
    }
    //Remove everything that is set to false or that is undefined
    Object.keys(this).forEach(key => (this[key] === undefined || this[key] === false) && delete this[key]);
  }

  removePagination(): void {
    if(typeof this.limit !== 'undefined') delete this.limit;
    if(typeof this.page !== 'undefined') delete this.page;
    if(typeof this.total !== 'undefined') delete this.total;
  }

  getSaveableData(): Params {
    // filter all savable filters
    const onlySaveableFilters = this.filters.filter((filter) => {
      if (!filter.notSavable) return filter;
    });

    return {
      name: this.name,
      view: this.view as string,
      // limit: this.limit, //TODO maknuti?
      limit: this.getPossibleLimit(),
      sort: this.sort,
      filters: onlySaveableFilters,
      parentId: this.parentId,
      project: this.project,
      customConfig: this.customConfig,
    }
  }

  getPossibleLimit(): number {
    if (this.limit > 20) {
      return 20;
    }
    return this.limit;
  }

}
