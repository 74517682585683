import {environment} from '../../../../../environments/environment';

const gBoxList = '?gBoxList=true';
const root: string = `${environment.baseUrl}/api/${environment.version}`;

export const AdminRoutes = {

  academicYear: {

    create: {
      url: `${root}/evnas/academicYear/create`,
      method: 'Post'
    },

    delete: {
      url: `${root}/evnas/academicYear/archive/`,
      method: 'Delete'
    },

    get: {
      url: `${root}/evnas/academicYear/get/`,
      method: 'Get'
    },

    filter: {
      url: `${root}/evnas/academicYear/filterStandard${gBoxList}`,
      method: 'Post'
    },

    getAll: {
      url: `${root}/evnas/academicYear/filterStandard`,
      method: 'Post'
    },

    update: {
      url: `${root}/evnas/academicYear/update/`,
      method: 'Patch'
    },
    copy: {
      url: `${root}/evnas/academicYear/copy`,
      method: 'Post'
    }
  },

  course: {
    checkCode: {
      url: `${root}/evnas/course/checkCode`,
      method: 'Post'
    },
    create: {
      url: `${root}/evnas/course/create`,
      method: 'Post'
    },

    delete: id => ({
      url: `${root}/evnas/course/archive/${id}`,
      method: 'Delete'
    }),

    get: id => ({
      url: `${root}/evnas/course/get/${id}`,
      method: 'Get'
    }),

    filter: {
      url: `${root}/evnas/course/filterStandard${gBoxList}`,
      method: 'Post'
    },

    getAll: {
      url: `${root}/evnas/course/filterStandard`,
      method: 'Post'
    },

    update: id => ({
      url: `${root}/evnas/course/update/${id}`,
      method: 'Patch'
    })

  },

  department: {

    filter: {
      url: `${root}/evnas/department/filterStandard${gBoxList}`,
      method: 'Post'
    },

    getAll: {
      url: `${root}/evnas/department/filterStandard`,
      method: 'Post'
    }

  },

  room: {

    create: {
      url: `${root}/company/room/create`,
      method: 'Post'
    },

    filter: {
      url: `${root}/company/room/filter`,
      method: 'Post'
    },

    filterFree: {
      url: `${root}/company/room/filter`,
      method: 'Post'
    },

    delete: id => ({
      url: `${root}/company/room/delete/${id}`,
      method: 'Delete'
    }),

    get: id => ({
      url: `${root}/company/room/get/${id}`,
      method: 'Get'
    }),

    edit: id => ({
      url: `${root}/company/room/update/${id}`,
      method: 'Patch'
    })
  },

  building: {

    create: {
      url: `${root}/company/building/create`,
      method: 'Post'
    },

    edit: id => ({
      url: `${root}/company/building/update/${id}`,
      method: 'Patch'
    }),

    filter: {
      url: `${root}/company/building/filter`,
      method: 'Post'
    },

    delete: id => ({
      url: `${root}/company/building/delete/${id}`,
      method: 'Delete'
    }),

    get: id => ({
      url: `${root}/company/building/get/${id}`,
      method: 'Get'
    })
  },

  implementationPlan: {
    copy: {
      url: `${root}/evnas/implementationPlan/copy`,
      method: 'Post'
    },

    copyAdmin: {
      url: `${root}/evnas/implementationPlan/copyAdmin`,
      method: 'Post'
    },

    copyYear: {
      url: `${root}/evnas/implementationPlan/copyToYear`,
      method: 'Post'
    },

    create: {
      url: `${root}/evnas/implementationPlan/create`,
      method: 'Post'
    },

    delete: id => ({
      url: `${root}/evnas/implementationPlan/delete/${id}`,
      method: 'Delete'
    }),

    get: (id) => ({
      url: `${root}/evnas/implementationPlan/get/${id}`,
      method: 'Get'
    }),

    filter: {
      url: `${root}/evnas/implementationPlan/filterStandard${gBoxList}`,
      method: 'Post'
    },

    filterNoGbox: {
      url: `${root}/evnas/implementationPlan/filterStandard`,
      method: 'Post'
    },

    filterAdmin: {
      url: `${root}/evnas/implementationPlan/filterStandardAdmin${gBoxList}`,
      method: 'Post'
    },

    filterAdminNoGbox: {
      url: `${root}/evnas/implementationPlan/filterStandardAdmin`,
      method: 'Post'
    },

    update: id => ({
      url: `${root}/evnas/implementationPlan/update/${id}`,
      method: 'Patch'
    }),

    updateAdmin: id => ({
      url: `${root}/evnas/implementationPlan/updateAdmin/${id}`,
      method: 'Patch'
    }),

    getAllAdmin: {
      url: `${root}/evnas/implementationPlan/filterStandardAdmin`,
      method: 'Post'
    },

    getAll: {
      url: `${root}/evnas/implementationPlan/filterStandard`,
      method: 'Post'
    },

    adminMember: id => ({
      url: `${root}/evnas/implementationPlan/${id}/addMemberAdmin`,
      method: 'Post'
    }),

    adminUpdateMember: (planId, memberId) => ({
      url: `${root}/evnas/implementationPlan/${planId}/updateMemberAdmin/${memberId}`,
      method: 'Patch'
    }),

    adminRemoveMember: (planId, memberId) => ({
      url: `${root}/evnas/implementationPlan/${planId}/deleteMemberAdmin/${memberId}`,
      method: 'Delete'
    }),

    addMember: id => ({
      url: `${root}/evnas/implementationPlan/${id}/addMember`,
      method: 'Post'
    }),

    updateMember: (planId, memberId) => ({
      url: `${root}/evnas/implementationPlan/${planId}/updateMember/${memberId}`,
      method: 'Patch'
    }),

    removeMember: (planId, memberId) => ({
      url: `${root}/evnas/implementationPlan/${planId}/deleteMember/${memberId}`,
      method: 'Delete'
    })
  },

  lessonType: {
    filter: {
      url: `${root}/evnas/lessonType/filter`,
      method: 'Get'
    },

    getStudentNumber: {
      url: `${root}/evnas/lessonType/getNumberOfStudents`,
      method: 'Post'
    }
  },

  reservation: {
    create: {
      url: `${root}/company/reservation/create`,
      method: 'Post'
    },
    delete: id => ({
      url: `${root}/company/reservation/delete/${id}`,
      method: 'Delete'
    }),
    filter: {
      url: `${root}/company/reservation/filter`,
      method: 'Post'
    },
    get: id => ({
      url: `${root}/company/reservation/get/${id}`,
      method: 'Get'
    }),
    update: id => ({
      url: `${root}/company/reservation/update/${id}`,
      method: 'Patch'
    }),
  },

  activity: {
    filter: {
      url: `${root}/evnas/activity/filterStandard${gBoxList}`,
      method: 'Post'
    },
    filter2: {
      url: `${root}/evnas/activity/filterStandard`,
      method: 'Post'
    },
    create: planId => ({
      url: `${root}/evnas/activity/${planId}/create`,
      method: 'Post'
    }),
    createAdmin: planId => ({
      url: `${root}/evnas/activity/${planId}/createAdmin`,
      method: 'Post'
    }),
    createDummy: {
      url: `${root}/evnas/activity/addDummy`,
      method: 'Post'
    },
    get: id => ({
      url: `${root}/evnas/activity/get/${id}`,
      method: 'Get'
    }),
    update: id => ({
      url: `${root}/evnas/activity/update/${id}`,
      method: 'Patch'
    }),
    updateAdmin: id => ({
      url: `${root}/evnas/activity/updateAdmin/${id}`,
      method: 'Patch'
    }),
    updateDummy: id => ({
      url: `${root}/evnas/activity/updateDummy/${id}`,
      method: 'Patch'
    }),
    delete: id => ({
      url: `${root}/evnas/activity/delete/${id}`,
      method: 'Delete'
    }),
    deleteDummy: id => ({
      url: `${root}/evnas/activity/deleteDummy/${id}`,
      method: 'Delete'
    }),
    deleteAdmin: id => ({
      url: `${root}/evnas/activity/deleteAdmin/${id}`,
      method: 'Delete'
    }),
    filterFreeRooms: {
      url: `${root}/evnas/activity/getFreeRooms`,
      method: 'Post'
    }
  },

  studyProgram: {
    create: {
      url: `${root}/evnas/studyProgramme/create`,
      method: 'Post'
    },

    delete: id => ({
      url: `${root}/evnas/studyProgramme/archive/${id}`,
      method: 'Delete'
    }),

    get: id => ({
      url: `${root}/evnas/studyProgramme/get/${id}`,
      method: 'Get'
    }),

    filter: {
      url: `${root}/evnas/studyProgramme/filterStandard${gBoxList}`,
      method: 'Post'
    },

    getAll: {
      url: `${root}/evnas/studyProgramme/filterStandard`,
      method: 'Post'
    },

    update: id => ({
      url: `${root}/evnas/studyProgramme/update/${id}`,
      method: 'Patch'
    })
  },

  activityGroup: {
    create: planId => ({
      url: `${root}/evnas/activityGroup/${planId}/create`,
      method: 'Post'
    }),

    delete: id => ({
      url: `${root}/evnas/activityGroup/delete/${id}`,
      method: 'Delete'
    }),

    get: id => ({
      url: `${root}/evnas/activityGroup/get/${id}`,
      method: 'Get'
    }),

    filter: {
      url: `${root}/evnas/activityGroup/filterStandard${gBoxList}`,
      method: 'Post'
    },

    update: id => ({
      url: `${root}/evnas/activityGroup/update/${id}`,
      method: 'Patch'
    })
  },

  log: {

    filter: {
      url: `${root}/evnas/log/filterStandard${gBoxList}`,
      method: 'Post'
    },

  },

};
