import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs/Observable";
// import {FilterData} from "../classes/filter-data";
import {FilterData as GlobalFilterData} from '../classes/filter-data';
import {FilterData as CalendarFilterData} from '../modules/layout/calendar/classes/filter-data';
import {urlValues} from "../configs/url.values";
import {Params} from "@angular/router";

@Injectable()
export class FilterDataService {

    constructor(
        private _api: ApiService
    ) { }

    get(name: string, parentId?: number): Observable<GlobalFilterData> {
        return new Observable<GlobalFilterData>(obs => {
            this._api.send(urlValues.filterData.get.method, urlValues.filterData.get.url(name, parentId)).subscribe(res => {
                let filterData: GlobalFilterData;
                if(!Array.isArray(res['data']) && res['data'] !== null) {
                    filterData = new GlobalFilterData(res['data']);
                    filterData.list = true;
                } else filterData = null;
                obs.next(filterData);
                obs.complete();
            }, err => {
                obs.error(err);
                obs.complete();
            });
        });
    }

    calendarGet(name: string, parentId?: number): Observable<CalendarFilterData> {
        return new Observable<CalendarFilterData>(obs => {
            this._api.send(urlValues.filterData.get.method, urlValues.filterData.get.url(name, parentId)).subscribe(res => {
                let filterData: CalendarFilterData;
                if(!Array.isArray(res['data']) && res['data'] !== null) {
                    filterData = new CalendarFilterData(res['data']);
                    filterData.list = true;
                } else filterData = null;
                obs.next(filterData);
                obs.complete();
            }, err => {
                obs.error(err);
                obs.complete();
            });
        });
    }

    save(data: Params): Observable<void> {
        return new Observable<void>(obs => {
            this._api.send(urlValues.filterData.save.method, urlValues.filterData.save.url, data).subscribe(() => {
                obs.next();
                obs.complete();
            }, err => {
                obs.error(err);
                obs.complete();
            });
        });
    }

    clear(listName: string, listParentId?: number): Observable<void> {
        return new Observable<void>(obs => {
            this._api.send(urlValues.filterData.delete.method, urlValues.filterData.delete.url, {name: listName, parentId: listParentId}).subscribe(() => {
                obs.next();
                obs.complete();
            }, err => {
                obs.error(err);
                obs.complete();
            });
        });
    }

}
