import {environment} from '../../../../environments/environment';

export const MarketingRoutes = {
  survey: {
    common: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/label`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/label/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/label/delete` // /:id
        },
      },
      category: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/category/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/category/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/category/delete` // /:id
        },
      }
    },

    filter: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/filter`
    },
    create: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/create`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/delete` // :id
    },
    details: {
      method: 'GET',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/get` // :id
    },
    statistics: {
      method: 'GET',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/statistics` // :id
    },
    take: {
      method: 'GET',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/get` // :id
    },
    reset: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/reset`
    },
    submitSurvey: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/submit`
    },
    results: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/results/get`
    },
    getAnswers: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/check`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/update`
    },
    addToSite: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/apiKeyScope/addPollsToSites`
    },
    surveyDomains: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/apiKeyScope/getPollSites`   // :pollId
    },
    removeFromSite: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/apiKeyScope/removeSitesToPoll`
    },
    answers: {
      get: {
        method: 'GET',
        url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/answers/get` // /:id
      },

      update: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/results/update` // /:id
      },
      getAnswers: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/check`
      },
    },

    comment: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/comment/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/comment/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/comment/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/comment/update`
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/comment/delete`
      },
    },

    candidates: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/participants/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/marketing/survey/participants/manage`
      },
    },

  },

  audience: {
    common: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/label`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/label/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/label/delete` // /:id
        },
      },
      category: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/category/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/category/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/category/delete` // /:id
        },
      },
      statuses: {
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/audience/status/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/audience/status/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/audience/status/delete`
        },
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/audience/status/filter`
        }
      },
    },

    filter: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/filter`
    },
    create: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/create`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/delete` // :id
    },
    details: {
      method: 'GET',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/get` // :id
    },
    reset: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/reset`
    },
    update: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/audience/update`
    },
  },
  campaigns: {
    common: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/label`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/label/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/label/delete` // /:id
        },
      },
      category: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/category/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/category/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/category/delete` // /:id
        },
      },
      statuses: {
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/campaigns/status/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/campaigns/status/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/campaigns/status/delete`
        },
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/campaigns/status/filter`
        }
      },
    },

    filter: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/filter`
    },
    create: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/create`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/delete` // :id
    },
    details: {
      method: 'GET',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/get` // :id
    },
    reset: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/reset`
    },
    update: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/campaigns/update`
    },
  },
  newsletter: {
    common: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/label`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/label/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/label/delete` // /:id
        },
      },
      category: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/category/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/category/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/category/delete` // /:id
        },
      },
      statuses: {
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/newsletter/status/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/newsletter/status/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/newsletter/status/delete`
        },
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/newsletter/status/filter`
        }
      },
    },

    filter: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/filter`
    },
    create: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/create`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/delete` // :id
    },
    details: {
      method: 'GET',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/get` // :id
    },
    reset: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/reset`
    },
    update: {
      method: 'POST',
      url: `${environment.baseUrl}/api/${environment.version}/marketing/newsletter/update`
    },
  },
};
