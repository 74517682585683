import {AbstractControl} from '@angular/forms';


export class PasswordValidator {

  static MatchPassword(c: AbstractControl) {
    let password: string;

    if (c.get('newPassword')) {
      password = c.get('newPassword').value;
    } else if (c.get('password')) {
      password = c.get('password').value;
    }

    if (password != c.get('passwordConfirm').value) return {passwordNotMatch: {valid: false}};
    return null;
  }

  static isValidFormat(c: AbstractControl) {
    let val = c.value

    //let PASS_REGEXP = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_-])[A-Za-z\d$@$!%*?&_-]{8,10}/);
    ///^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&-_+.:;,<>#])[A-Za-z\d$@$!%*?&-_+.:;,<>#]{8,10}/
    let NUM_RGX = /(?=.*[0-9])/;
    let L_LETTER_RGX = /(?=.*[a-z])/;
    let U_LETTER_RGX = /(?=.*[A-Z])/;
    //let SPEC_CHAR_RGX = /[$@$!%*?&-_+.:;,<>#]/;
    let SPEC_CHAR_RGX = /[^A-Za-z0-9]+/
    let length = /(?=.{8,})/;
    //if (c.value != '' && !PASS_REGEXP.test(c.value)) return {validPassword: {valid: true}};

    let num_test = NUM_RGX.test(val);
    let l_letter_test = L_LETTER_RGX.test(val);
    let u_letter_test = U_LETTER_RGX.test(val);
    let spec_char_test = SPEC_CHAR_RGX.test(val);
    let legth_test = length.test(val)


    if(!num_test) return {passowrdError:'DigitError'}
    if(!l_letter_test) return {passowrdError:'LowercaseError'}
    if(!u_letter_test) return {passowrdError:'UppercaseError'}
    if(!spec_char_test) return {passowrdError:'SpecCharError'}
    if(!legth_test) return {passowrdError:'LengthError'}
    
    return null;
  };
}
