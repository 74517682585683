import {environment} from '../../../../environments/environment';

export const CmsRoutes = {
  post: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/post/filterProtectedStandard`,
    },
    filterDataTable: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/post/filterProtectedStandard`,
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/post/getProtected`,
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/post/create`,
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/cms/post/update`,
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/cms/post/delete`,
    },
    slugOk: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/post/slugOk`,
    },
    updateBulk: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/cms/post/updateBulk`,
    }
  },
  postSchedule: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/postSchedules/filter`,
    },
    create: {
      method: 'Post',
      url: (postSlug: string) => `${environment.baseUrl}/api/${environment.version}/cms/postSchedules/${postSlug}/create`,
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/cms/postSchedules/delete`,
    }
  },
  term: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/term/filterStandard`,
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/term/get`,
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/term/create`,
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/cms/term/update`,
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/cms/term/delete`,
    },
    slugOk: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/term/slugOk`,
    },
  },
  navigation: {
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigation/create`,
    },
    filterStandard: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigation/filterStandard`,
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigation/get/`,
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigation/delete`,
    },
    getTreeDirect: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigation/treeDirect/`,
    },
    getNavigations: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigation/filter`,
    },
    getTree: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigation/tree/`,
    },
    reorder: {
      method: 'Put',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigation/reorder`,
    },
    getPossibleLinks: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/finder/searchSlugs`,
    }
  },
  navigationLink: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigationLink/filterStandard`,
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigationLink/get`,
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigationLink/create`,
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigationLink/update`,
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/cms/navigationLink/delete/`,
    }
  },
  translation: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/cms/translate/create`,
  },
  options: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/option/filter`,
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/option/create`,
    },
    createBulk: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/option/createBulk`,
    },

  },
  optionsSlugs: {
    getByType: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/finder/searchSlugs`,
    }
  },
  externalLinks: {
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/externalLink/create`,
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/cms/externalLink/delete/`,
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/externalLink/get/`,
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/externalLink/filter`,
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/cms/externalLink/update/`,
    },
  },
  domains: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/domain/filter`,
    }
  },
  meta: {
    filter: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/meta/filter`,
    }
  },
  redirect: {
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/redirect/create`,
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/cms/redirect/delete/`,
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/redirect/get/`,
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/redirect/filter`,
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/cms/redirect/update/`,
    },
  },
  robots: {
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/robots/create`,
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/cms/robots/get`,
    },
  },
  siteMap: {
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/cms/site/create`,
    },
  }
};
