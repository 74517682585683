import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Calendar} from "fullcalendar";
import {GlobalService} from "../../services/global.service";
import {ConfirmationService} from "primeng/api";
import {LocalizationService} from "../../services/localization.service";
import {CalendarEvent} from "../../modules/layout/calendar/interface/calendar-event";
import * as _ from 'lodash';

@Component({
  selector: 'app-calendar-sheet',
  templateUrl: './calendar-sheet.component.html',
  styleUrls: ['./calendar-sheet.component.scss']
})
export class CalendarSheetComponent implements OnInit, OnChanges {

  protected calendar: Calendar;
  @ViewChild('calendar') calendarEl;
  @Input() events: CalendarEvent[];
  @Output() eventClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() datesRender: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _global: GlobalService,
              private _confirm: ConfirmationService,
              private _locale: LocalizationService) {
  }

  ngOnInit() {
    this.calendar = new Calendar(this.calendarEl.nativeElement, {
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      buttonText: {
        today: this._locale.trans.today,
        month: this._locale.trans.month,
        week: this._locale.trans.week,
        day: this._locale.trans.day,
        list: this._locale.trans.list
      },
      locale: this.getLocale(),
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
      },
      editable: false,
      selectable: false,
      allDaySlot: false,
      eventLimit: 4,
      eventClick: (data) => this.eventClick.emit(data.event),
      datesRender: (data) => this.datesRender.emit(data),
    });
    this.calendar.render();
  }

  ngOnChanges(changes: SimpleChanges) {
    const events: SimpleChange = changes.events;
    if (this.calendar && events.currentValue) {
      this.calendar.removeAllEvents();
      this.calendar.addEventSource(events.currentValue);
    }
  }

  private getLocale(): string {
    if (_.find(this._global.settings.global, ['key', 'language'])) {
      let locale = this._global.removeLastChar(_.find(this._global.settings.global, ['key', 'language']).value.code.toLowerCase());
      if (locale == 'sr') locale = 'sr-Latn';
      return locale;
    }
    return 'en';
  }

}
