import {environment} from '../../../../environments/environment';

export const DriveRoutes = {
  directory: {
    // filter: {
    //   method: 'Post',
    //   url: `${environment.baseUrl}/api/${environment.version}/media/directory/filter`
    // },
    store: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/media/directory/create`
    }
  },
  directoryItem: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/media/directoryItem/filter`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/media/directoryItem/update`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/media/directoryItem/get`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/media/directoryItem/delete`
    },
    ancestorsInfo: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/media/directoryItem/parents`
    },
    copy: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/media/directoryItem/copy`
    },
    cut: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/media/directoryItem/cut`
    }
  },
  userFile: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/media/userFile/get`
    },
    downloadVersion: {
      method: 'Get',
      url: `${environment.baseUrl}/${environment.version}/media/download`
    },
    setActiveVersion: {
      method: 'Patch',
      url: (fileId: number) => `${environment.baseUrl}/api/${environment.version}/media/userFile/${fileId}/setActiveVersion`
    },
    // sharing: {
    //   get: {
    //     method: 'Get',
    //     url: `${environment.baseUrl}/${environment.version}/media/userFile/sharing`
    //   },
    //   update: {
    //     method: 'Patch',
    //     url: `${environment.baseUrl}/${environment.version}/media/userFile/sharing`
    //   }
    // },
    access: {
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/media/userFileAccess/get`
      },
      update: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/media/userFileAccess/create`
      }
    }
  }
};
