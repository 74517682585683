import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterComponent} from './filter.component';
import {ClickOutsideModule} from '../../directives/clickOutside/clickOutside.module';
import {FormsModule} from '@angular/forms';
import {MultiselectModule} from '../form-elements/multiselect/multiselect.module';
import {ChipsModule} from 'primeng/chips';
import {CalendarModule} from 'primeng/primeng';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MultiselectModule,
    ClickOutsideModule,
    ChipsModule,
    CalendarModule
  ],
  providers: [
  ],
  declarations: [
    FilterComponent,
  ],
  exports: [
    FilterComponent
  ]
})
export class FilterModule {
}
