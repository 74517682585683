import {environment} from "../../../../environments/environment";

export const InsuranceSalesRoutes = {
  insurance: {
    user: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/person/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/person/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/person/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/person/update` // /:id
      },
      archive: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/person/archive` // /:id
      },
      restore: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/person/restore` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/person/delete` // /:id
      }
    },
    company: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/company/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/company/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/company/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/company/update` // /:id
      },
      archive: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/company/archive` // /:id
      },
      restore: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/company/restore` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/company/delete` // /:id
      }
    },
    commons: {
      categories: {
        user: {
          filter: {
            method: 'Post',
            url: `${environment.baseUrl}/api/${environment.version}/insurance/person/category/filter`
          },
          create: {
            method: 'Post',
            url: `${environment.baseUrl}/api/${environment.version}/insurance/person/category/create`
          },
          update: {
            method: 'Patch',
            url: `${environment.baseUrl}/api/${environment.version}/insurance/person/category/update`
          },
          delete: {
            method: 'Delete',
            url: `${environment.baseUrl}/api/${environment.version}/insurance/person/category/delete`
          }
        },
        company: {
          filter: {
            method: 'Post',
            url: `${environment.baseUrl}/api/${environment.version}/insurance/company/category/filter`
          },
          create: {
            method: 'Post',
            url: `${environment.baseUrl}/api/${environment.version}/insurance/company/category/create`
          },
          update: {
            method: 'Patch',
            url: `${environment.baseUrl}/api/${environment.version}/insurance/company/category/update`
          },
          delete: {
            method: 'Delete',
            url: `${environment.baseUrl}/api/${environment.version}/insurance/company/category/delete`
          }
        }
      },
      statuses: {
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/status/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/status/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/status/delete`
        },
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/status/filter`
        }
      },
      ratings: {
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/rating/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/rating/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/rating/delete`
        },
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/rating/filter`
        },
      }
    },
    sourceType: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/source/type/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/source/type/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/source/type/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/source/type/update` // /:id
      },
      archive: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/source/type/delete` // /:id
      },
    },
    contactPersonRole: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/company/contact/role/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/company/contact/role/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/company/contact/role/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/company/contact/role/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/company/contact/role/delete` // /:id
      },
    },
    activity: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/label/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/label/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/label/delete`
        },
        get: {
          method: 'Get',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/label/get` // /:id
        },
      },
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/update` // /:id
      },
      archive: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/archive` // /:id
      },
      restore: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/restore` // /:id
      },
      complete: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/complete` // /:id
      },
      reassign: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/reassign`
      },
      history: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/activity/history` // /:activityId
      },
    },
    subscribers: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/subscribers/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/subscribers/manage`
      },
    },
    convert: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/convert`
    }
  },
  opportunity: {
    commons: {
      categories: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/category/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/category/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/category/delete`
        }
      },
      stages: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/stage/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/stage/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/stage/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/stage/delete`
        }
      }
    },
    sourceType: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/source/type/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/source/type/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/source/type/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/source/type/update` // /:id
      },
      archive: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/source/type/delete` // /:id
      },
    },
    policyStatus: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/policy/status/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/policy/status/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/policy/status/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/policy/status/update` // /:id
      },
      archive: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/policy/status/delete` // /:id
      },
    },
    subscribers: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/subscribers/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/subscribers/manage`
      },
    },
    activity: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/label/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/label/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/label/delete`
        },
        get: {
          method: 'Get',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/label/get` // /:id
        },
      },
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/update` // /:id
      },
      archive: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/archive` // /:id
      },
      restore: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/restore` // /:id
      },
      complete: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/complete` // /:id
      },
      reassign: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/reassign`
      },
      history: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/activity/history` // /:activityId
      },
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/update` // /:id
    },
    archive: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/archive` // /:id
    },
    restore: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/opportunity/restore` // /:id
    },
  },
  team: {
    commons: {
      categories: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/team/category/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/team/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/team/category/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/insurance/team/category/delete`
        }
      }
    },
    members: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/team/members/filter`
      },
      get: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/team/members/manage`
      },
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/team/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/team/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/team/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/team/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/team/delete` // /:id
    },
    getLeaders: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/team/getLeaders`
    }
  },
  family: {
    members: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/family/members/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/insurance/family/members/manage`
      }
    },
    decisionMakers: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/family/getDecisionMakers`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/family/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/family/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/family/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/family/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/insurance/family/delete` // /:id
    }
  },
  userRole: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/insurance/role/get`
  }
};
