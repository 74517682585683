import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserService} from '../user.service';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from '../classes/user';
import {ApiService} from '../../../../services/api.service';
import {EvnasUserService} from '../../evnas/services/evnas-user.service';
import {HumanResourcesService} from '../../evnas/human-resources/human-resources.service';

@Injectable()
export class UserGetResolver implements Resolve<any> {

  constructor(
    private _user: UserService,
    private _api: ApiService,
    private _evnasUser: EvnasUserService,
    private _hr: HumanResourcesService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const id = parseInt(route.params.id, 10);
    const evnasRequest = this._evnasUser.getEvnasUser(id);
    return this._api.handleRequest(evnasRequest).flatMap(res => {
      const userId = res.user_id;
      // return this._api.handleRequest(this._user.getUser(userId));
      const requests = [];
      requests.push(this._api.handleRequest(this._user.getUser(userId)));
      requests.push(this._api.handleRequest(this._hr.getHrUser(userId)));
      return forkJoin(requests).pipe(map(([gbUser, hr]) => {
        // console.log(gbUser, hr, res);
        return {...hr, ...gbUser, ...res, id: userId};
      }));
    });
  }
}
