import {environment} from '../../environments/environment';

export const SettingsRoutes = {
  user: {
    method: 'Get',
    url: `${environment.baseUrl}/api/${environment.version}/settings/general/user`
  },
  save: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/settings/general/save/apiKey` //By api key
  }
};
