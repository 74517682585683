import {Injectable} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {LocalizationService} from './localization.service';
import {GlobalService} from './global.service';
import {LayoutData} from '../components/layout-menu/layout-menu.component';
import {AuthService} from './auth.service';
import {ToggleService} from './toggle.service';

@Injectable()
export class NavigationService {

  private history = [];

  constructor(
    private router: Router,
    private _locale: LocalizationService,
    private _global: GlobalService,
    private _auth: AuthService,
    private _toggle: ToggleService,
  ) {
    this.loadRouting();
  }

  public loadRouting(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.history = [...this.history, event.urlAfterRedirects];
        this.setLayoutData();
      }
    });

    this._locale.main.subscribe((lang: string) => {
      if (!lang) return;
      this.setLayoutData();
    });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/index';
  }

  public getCurrentUrl(): string {
    return this.history[this.history.length - 1] || '/index';
  }

  public setLayoutData(): void {
    this._toggle.showProfile = false;
    const currentUrlName = this.getCurrentUrl().split('/')[1];
    const previousUrlName = this.getPreviousUrl().split('/')[1];
    if (currentUrlName !== previousUrlName) {
      this._global.mainSidebarData = this.generateNavigation(currentUrlName);
    }
  }

  generateNavigation(routeName?: string): LayoutData[] {
    switch (routeName) {
      case 'cms': {
        return this.generateCmsNavigation();
      }
      case 'e-commerce': {
        return this.generateECommerceNavigation();
      }
      default: {
        return this.generateMainNavigation();
      }
    }
  }

  generateMainNavigation(): LayoutData[] {
    const nav = [];
    this._auth.checkPermission('user_create') && nav.push({
      name: 'Kadrovska',
      iconName: 'icon-human-resources',
      url: '/evnas/human-resources',
      permission: 'company'
    });
    this._auth.checkPermission('building_create') && nav.push({
      name: 'Zgrade i prostorije',
      iconName: 'icon-warehouse',
      url: '/evnas/buildings-and-rooms',
      permission: 'company'
    });
    nav.push({
      name: 'Izvedbeni planovi',
      iconName: 'icon-writing-board',
      url: '/evnas/implementation-plans',
      permission: 'company'
    });
    this._auth.checkPermission('implementation_plan_creation') && nav.push({
      name: this._locale.trans.admin,
      iconName: 'icon-gear',
      url: '/evnas/admin',
      permission: 'company'
    });
    this._auth.checkPermission('add_new_dummy_activity') && nav.push({
      name: 'Referada',
      iconName: 'icon-calendar',
      url: '/evnas/registry',
      permission: 'company'
    });

    return nav;
  }

  generateCmsNavigation(): LayoutData[] {
    return [
      {
        name: this._locale.trans.home,
        iconName: 'icon-cms-home',
        url: '/cms/home',
      },
      {
        name: this._locale.trans.pages,
        iconName: 'icon-cms-pages',
        url: '/cms/pages',
      },
      {
        name: this._locale.trans.posts,
        iconName: 'icon-cms-posts',
        url: '/cms/posts',
      },
      {
        name: this._locale.trans.categories,
        iconName: 'icon-cms-categories',
        url: '/cms/categories',
      },
      {
        name: this._locale.trans.tags,
        iconName: 'icon-cms-tags',
        url: '/cms/tags',
      },
      {
        name: this._locale.trans.navigation,
        iconName: 'icon-cms-navigation',
        url: '/cms/navigation',
      },
      // {
      //   name: this._locale.trans.seo,
      //   iconName: 'icon-seo',
      //   url: '/cms/seo',
      // },
      // {
      //   name: this._locale.trans.trackingTools,
      //   iconName: 'icon-seo',
      //   url: '/cms/tracking',
      // },
      {
        name: this._locale.trans.settings,
        iconName: 'icon-cms-settings',
        url: '/cms/settings',
      },
      {
        name: this._locale.trans.exitCms,
        iconName: 'icon-cms-back-to-main-menu',
        url: '/home',
      },
    ];
  }

  generateECommerceNavigation(): LayoutData[] {
    return [];
  }

  generateCustomNavigation(links: LayoutData[]) {
    this._global.mainSidebarData = links;
  }

}
