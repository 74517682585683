export const SerbianTranslations = {
  optionsSelected: 'odabrane stavke',
  lessonTypes: 'Lesson types',
  lessonType: 'Lesson type',
  createOpportunity: 'Kreiraj priliku',
  update_audience_info: 'Lista uspješno ažurirana',
  update_member_info: 'Korisnik uspješno dopunjen',
  update_campaign_info: 'Campaign uspješno dopunjena',
  delete_campaign_info: 'Kampanja je uspješno obrisana',
  delete_audience: 'List uspješno obrisana',
  campaigns: 'Kampanje',
  editCampaign: 'Uredi kampanju',
  createCampaign: 'Kreiraj kampanju',
  edit_audience: 'Editiranje liste primatelja',
  audience_title: 'Lista',
  create_audience_sucessfull: 'Nova lista je kreirana',
  send_campaign_info: 'Uspješno poslana kampanja, pogledajte mail',
  add_campaign: 'Nova kampanja dodana',
  campaign_title: 'Kampanja',
  email_type: 'Vrsta emaila',
  delete_member: 'Pretplatnik obrisan',
  add_new_member: 'Novi pretplatnik kreiran',
  member_info: 'Pretplatnik',
  marketing_permissions: 'Marketing dozvole',
  marketing_permissions_details: 'Ima li lista omogućene marketing dozvole npr. GDPR',
  use_archive_bar_details: 'Da li kampanje za ovu listu imaju zadano korištenje Archive Bar',
  email_type_option_details: 'Podržava li lista više formata za email',
  double_optin_details: 'Treba li tražiti od pretplatnika da potvrdi pretplatu putem emaila',
  create_audience_error: 'Trenutno imate maksimalni broj liste, molimo obrišite jednu listu',
  email_subscribe: 'Email notifikacija za  registirane korisnike',
  email_unsubcribe: 'Email notifikacija za  neregistirane korisnike',
  email_address: 'Email adresa',
  preview_text: 'Pregled teksta',
  campaign_name: 'Ime kampanje',
  choose_audience: 'Izaberite listu',
  edit_member: 'Ažuriraj pretplatnika',
  add_member: 'Dodaj pretplatnika',
  campaing_type: 'Tip kampanje',
  from_email: 'Email pošiljatelja',
  from_name: 'Ime pošiljatelja',
  notify_on_unsubscribe: 'Notify on unsubscribe',
  notify_on_subscribe: 'Notifikacija na prijavi',
  permission_reminder: 'Notifikacija o dopuštenju',
  addr1: 'Adresa',
  addr2: 'Dodatna adresa',
  double_optinMailchinp: 'Ova osoba je dala dopuštenje da ju kontaktiramo',
  double_optinMailchinpDescritption: 'Ova osoba neće primiti konfirmacijski mail od mailchimpa. Kako ovog primatelja unosite ručno, on neće imati opt-in IP adresu zabilježenu u vašim zapisima, tako da budite sigurni da imate dopuštenje za slanje. Prikaži više (link koji vodi na njihovu stranicu u novom tabu)',
  marketing_permissionsMailchimp: 'Ova osoba je već na popisu, uredite postojeći profil.',
  unsubscribe: 'Odjaviti se',
  subscribe: 'Pretplatiti se',
  email_sent: 'Poslani Email-ovi',
  email_marketing: 'Email Marketing',
  send_campaign: 'Pošalji kampanju',
  all_campaigns: 'Sve kampanje',
  subscribers: 'Pretplatnici',
  subscriber: 'Preplatnik',
  leave: 'Izađi',
  channelInfo : ' Channel Info:',
  idle : 'Besposlen',
  busy: 'zaposlen',
  ganttchart: 'Gantt chart',
  gantt: 'Gantt',
  addedToCms: 'Ankete dodane u cms',
  removeCms: 'Obriši iz Cms-a',
  removeToCms: 'Ankete obrisane u cms',
  addToCMS: 'Dodaj u CMS',
  react: 'React',
  quote: 'Quote',
  removeFromCms: 'Obriši iz CMS-a',
  new_audience: 'Nova lista primatelja',
  audiences: 'Liste primatelja',
  audience: 'Lista primatelja',
  mailchimp: 'Mailchimp',
  mailchimpFreeAccAudienceHeaderText: 'Dosegli ste limit lista primatelja.',
  mailchimpFreeAccAudienceBodyText: 'Već imate maksimalan broj lista primatelja dozvoljenih u vašem planu, ali možete dodati dodatne kontakte u postojeću listu i organizirati ih putem tagova. Ukoliko želite dodati novu listu pretplatnika trebate nadograditi vaš MailChimp račun.',
  sites: 'Stranice',
  yourPassword: 'Vaša lozinka',
  login: 'Prijavi se',
  qualified: 'Qualified',
  unqualified: 'Unqualified',
  percentage: 'Percentage',
  examPoints: 'Exam points',
  passPoints: 'Pass points',
  isPassed: 'Passed',
  participated: 'Participated',
  gallery: 'Galerija',
  convertlead: 'Convert lead',
  signatureText: 'Tekst potpisa',
  tableView: 'Table view',
  gridView: 'Grid view',
  coverImage: 'Cover image',
  member: 'Korisnik',
  members: 'Members',
  uploadCoverImage: 'Upload cover image',
  newteam: 'New team',
  editteam: 'Edit team',
  teams: 'Teams',
  team: 'Team',
  insurancesales: 'Prodaja osiguranja',
  sendOffer: 'Send offer',
  noAddresses: 'No addresses',
  noHistory: 'No history',
  newOpportunity: 'New opportunity',
  editOpportunity: 'Edit opportunity',
  stage: 'Stage',
  bcc: 'BCC',
  stages: 'Stages',
  estimatedTime: 'Estimated time',
  estimatedValue: 'Estimated value',
  newopportunity: 'New opportunity',
  editopportunity: 'Edit opportunity',
  editedBy: 'Edited by',
  archivedBy: 'Archived by',
  restoredBy: 'Restored by',
  completedBy: 'Completed by',
  reassignedTo: 'Reassigned to',
  showHistory: 'Show history',
  history: 'History',
  editsurvey: 'Editiraj anketu',
  editSurvey: 'Editiraj anketu',
  messageConfirmConfirm: 'Are you sure ?',
  reassign: 'Reassign',
  reassignActivity: 'Reassign activity',
  onlyThisActivity: 'Only this activity',
  allActivitiesOfThisType: 'All activities of this type',
  allActivities: 'All activities',
  totals: 'Totali',
  notExportFile: 'Please upload the project export file',
  reply: 'Odgovori',
  forward: 'Proslijedi',
  toTask: 'Pretvori u zadatak',
  work: 'Posao',
  travel: 'Putovanja',
  newGroup: 'Nova grupa',
  sendMessage: 'Pošalji poruku',
  attachments :'Prilozi',
  deleteForever: 'Obriši zauvek',
  nn: 'NN',
  dueTime:'Datum dospijeća',
  estimateBy: 'Procenite do',
  cc: 'CC',
  marketing: 'Marketing',
  customerExperienceResearch: 'Istraživanje korisničkog iskustva',
  createPollsSurvey: 'Stvorite ankete i još mnogo toga',
  apiKey: 'Apikey',
  subscribeMonthly: 'Pretplatite se na naš mesečni newsletter',
  sms: 'SMS',
  sendViewSms: 'Pošaljite i pregledajte sve svoje SMS poruke',
  surveyList: 'Lista Anketa',
  newSurvey: 'Nova Anketa',
  newsurvey: 'Nova Anketa',
  previewSurvey: 'Prošla anketa',
  surveyName: 'Naziv Ankete',
  forClient: 'Za klijenta',
  prizeType: 'Tip Nagrade',
  prize: 'Nagrada',
  publishSurvey: 'Objavi anketu',
  anonimous: 'Anonimna',
  surveyReset: 'Anketa je vraćena na početno stanje',
  surveySubmitted: 'Anketa predana',
  surveyDeleted: 'Anketa je obrisana',
  SurveyRestored: 'Anketea vraćena',
  gradeSurvey: 'Ocenite anketu',
  viewSurvey: 'Pogledaj Anketu',
  takeSurvey: 'Ispuni Survey',
  takesurvey: 'Ispuni Survey',
  submitSurvey: 'Predaj Anketu',
  resultssurvey: 'Rezultat Ankete',
  chat: 'Chat',
  createNewChanel: 'Kreiraj novi kanal',
  channel: 'Kanal',
  directMessages: 'Direktna Poruka',
  cannotEmptyMessage:'Ne mozes poslati praznu poruku!',
  userInfo: 'User Info',
  atLeastOneQuestion1 : 'Anketa mora imati barem jedno pitanje',

  //Global

  myProfile: 'Moj profil',
  move: 'Prebaci',
  selectAll: 'Označi sve',
  deselectAll: 'Odznači sve',
  showRows: 'Prikaži redove',
  showGrid: 'Prikaži kartice',
  add: 'Dodaj',
  remove: 'Ukloni',
  create: 'Kreiraj',
  save: 'Spremi',
  saveChanges: 'Spremi promene',
  view: 'Pogledaj',
  edit: 'Uredi',
  archive: 'Arhiviraj',
  manageProductStores: 'Upravljaj trgovinama',
  restore: 'Vrati',
  cancel: 'Odustani',
  delete: 'Obriši',
  search: 'Pretraži...',
  select: '- Odaberi -',
  back: 'Natrag',
  success: 'Uspeh',
  yes: 'Da',
  no: 'Ne',
  ok: 'U redu',
  new: 'Novi',
  clear: 'Očisti',
  download: 'Preuzmi',
  downloads: 'Preuzimanja',
  category: 'Kategorija',
  categories: 'Kategorije',
  label: 'Oznaka',
  labels: 'Oznake',
  activity: 'Aktivnost',
  activities: 'Aktivnosti',
  calendar: 'Kalendar',
  calendars: 'Kalendari',
  manage: 'Upravljaj',
  generalData: 'Osnovni podatci',
  enabled: 'Omogućeno',
  disabled: 'Onemogućeno',
  image: 'Slika',
  images: 'Slike',
  newCategory: 'Nova kategorija',
  editCategory: 'Uredi kategoriju',
  categoryCreated: 'Kategorija kreirana',
  categoryUpdated: 'Kategorija uređena',
  setDefault: 'Postavi kao osnovno',
  startDate: 'Datum početka',
  endDate: 'Datum završetka',
  startTime: 'Vrijeme početka',
  endTime: 'Vrijeme završetka',
  tag: 'Tag',
  tags: 'Tagovi',
  description: 'Opis',
  showMore: 'Prikaži više',
  showLess: 'Prikaži manje',
  uploadVideo: 'Učitaj video',
  uploadFile: 'Učitaj datoteku',
  uploadImage: 'Učitaj sliku',
  reUploadImage: 'Učitaj drugu sliku',
  uploadSeoImage: 'Učitaj SEO sliku',
  reUploadSeoImage: 'Učitaj drugu SEO sliku',
  all: 'Sve',
  none: 'Ništa',
  online: 'Online',
  offline: 'Offline',
  by: 'Od',
  created: 'Kreiran',
  viewAll: 'Prikaži sve',
  goTo: 'Idi na',
  createComment: 'Kreiraj komentar',
  addNew: 'Dodaj',
  milestone: 'Milestone',
  milestones: 'Milestones',
  warning: 'Upozorenje',
  completed: 'Završeno',
  message: 'Poruka',
  viewProfile: 'Pogledaj profil',
  supervisor: 'Nositelj',
  group: 'Grupa',
  street: 'Ime ulice',
  houseNumber: 'Kućni broj',
  zipCode: 'Poštanski broj',
  city: 'Grad',
  region: 'Regija',
  country: 'Država',
  active: 'Aktivan',
  deleted: 'Obrisan',
  type: 'Tip',
  projectDiscussions: 'Diskusije projekta',
  classrooms: 'Učionice',
  courseDicussions: 'Diskusije kursa',
  close: 'Zatvori',
  hours: 'Broj sati',
  expense: 'Trošak',
  saved: 'Spremljeno',
  date: 'Datum',
  notifications: 'Notifikacije',
  notification: 'Notifikacija',
  ibans: 'IBAN',
  bank: 'Banka',
  accountNo: 'Broj računa',
  pricelist: 'Cenik',
  name: 'Naziv',
  item: 'Stavka',
  currency: 'Valuta',
  vatClass: 'Porezna stopa',
  updated: 'Editiran',
  validTo: 'Validan do',
  number: 'Broj',
  start: 'Početak',
  end: 'Kraj',
  leader: 'Nositelj',
  createdBy: 'Kreirao',
  open: 'Otvoreno',
  closed: 'Zatvoreno',
  withCategories: 'Kategorijama',
  project: 'Projekat',
  task: 'Zadatak',
  person: 'Osoba',
  entry: 'Unos',
  noRecords: 'Nema pronađenih podataka.',
  anonymise: 'Anonimiziraj',
  indentify: 'Identificiranje',
  notSet: 'NIJE POSTAVLJENO',
  shippingMethods: 'Dostavne metode',
  taxes: 'Porezi',
  domains: 'Domene',
  estimatedBudget: 'Procenjeni budžet',
  client: 'Klijent',
  pdf: 'Export u PDF',
  offer_outgoing: 'Ulazna ponuda',
  offer_inbound: 'Izlazna ponuda',
  invoice_outgoing: 'Izlazni račun',
  invoice_inbound: 'Ulazni račun',
  order_inbound: 'Ulazna narudžbenica',
  order_outgoing: 'Izlazna narudžbenica',
  pattern: 'Pattern',
  firstNumber: 'Početni broj',
  convertOfferToInvoice: 'Izradi račun iz punude',
  convert: 'Pretvori',
  newValue: 'Nova vrednost',
  oldValue: 'Stara vrednost',
  incoterms: 'Incoterms',
  update: 'Ažurirano',
  outRefNumber: 'Ref. broj',
  paymentType: 'Tip plaćanja',
  confirm: 'Potvrdi',
  externalImageLink: 'Vanjska poveznica (slika)',
  itemsUpdated: 'Stavke uređene',
  slugEditWarning: 'Uređivanje slug-a može dovesti do greške u postojećoj poveznici',
  archived: 'Arhivirano',
  restored: 'Vraćeno',
  export: 'Izvezi',
  items: 'Stavke',
  issuedFromContact: 'Kontakt osoba',
  issuedFromContactValue: 'Kontakt',
  issuedToContact: 'Kontakt osoba',
  issuedToContactValue: 'Kontakt',
  projectParticipants: 'Sudionici',
  showArchive: 'Prikaži arhivu',
  showActive: 'Prikaži aktivne',
  noRoles: 'Nema uloga, molimo kreirajte ulogu',
  visibility: 'Vidljivost',
  settings: 'Postavke',
  generate: 'Generiraj',
  invoice: 'Račun',
  discussionSubscribers: 'Discusija preplatnika',
  taskAssignees: 'Zadatak naručitelja',
  pop3: 'POP 3',
  imap: 'IMAP',
  smtp: 'SMTP',
  homeWelcomeMsg: 'Dobrodošli u Gauss Box.',
  assethandoutform_internal: 'Predaja materijala',
  assetreturnform_internal: 'Povrat imovine',
  contract_special: 'Kontakt',
  deliverynote_internal: 'Informacija o dospeću',
  goodsreceipt_internal: 'Prijem robe',
  inquiry_inbound: 'Ulazni upit',
  inquiry_outgoing: 'Outgoing inquiry',
  issuenote_internal: 'Odlazni upit',
  letter_inbound: 'Ulazno pismo',
  letter_internal: 'Interno pismo',
  letter_outgoing: 'Izlazno pismo',
  requisitionwarrant_internal: 'Nalog za izdavanje',
  warehousetransfer_internal: 'Prijenos skladišta',
  publish: 'Objavi',
  unpublish: 'Poništi objavu',  // TOMO
  changeDomainSure: 'Sve nespremljene promjene na trenutnoj domeni biti će izgubljene. Jeste li sigurni da želite promijeniti trenutnu domenu?',

  //HOME

  revenueMonthly: 'Mesečni prihodi',
  inboundVsOutgoingInv: 'Ulazni i izlazni računi',
  invoicesByDueDate: 'Računi po datumu dospeća',
  totalDocuments: 'Ukupno dokumenti',
  topSellingProdServ: 'Najprodavaniji proizvodi i usluge',
  top6customers: 'Top 6 kupaca',
  revenue: 'Prihod',
  revenueWeekly: 'TJEDNI PRIHOD',
  revenueDaily: 'DNEVNI PRIHOD',

  //KALEDNDAR

  createEvent: 'Stvori događaj',
  eventName: 'Naziv događaja',
  eventStartTime: 'Početak događaja',
  eventEndTime: 'Završetak događaja',
  recurring: 'Ponavljajući',
  recurrenceEndTime: 'Završetak ponavljanja',
  notes: 'Beleške',
  editThisEvent: 'Uredi ovaj događaj',
  editRecurringSeries: 'Uredi ponavljajuću seriju',
  deleteRecurringSeries: 'Ukloni ponavljajući događaj',
  deleteEvent: 'Ukloni događaj',
  editSeries: 'Uredi seriju',
  editEvent: 'Uredi događaj',
  today: 'Danas',
  day: 'Dan',
  week: 'Nedelja',
  month: 'Mesec',
  private: 'Privatno',
  public: 'Javno',
  eventcreated: 'Događaj stvoren!',
  eventupdated: 'Događaj uređen!',
  eventdeleted: 'Događaj uklonjen!',
  list: 'Lista',

  //LISTA

  contactName: 'Ime kontakta',
  email: 'E-mail',
  mobile: 'Mobitel',
  occupation: 'Zanimanje',
  phone: 'Telefon',
  address: 'Adresa',
  state: 'Stanje',
  basicPrice: 'VPC',
  retailPrice: 'MPC',
  createdAt: 'Datum kreiranja',
  loggedUnit: 'Upisana jedinica',
  value: 'Vrednost',
  billable: 'Naplativo',
  taskCategory: 'Kategorija zad.',
  summary: 'Opis',
  loggedBy: 'Upisao',
  capacity: 'Kapacitet',
  user: 'Korisnik',
  timeElapsed: 'Proteklo vreme',
  submittedOn: 'Predan',
  score: 'Bodovi',
  results: 'Rezultat',
  company: 'Kompanija',
  netPrice: 'Neto cena',
  totalPrice: 'Ukupna cena',
  vat1: 'PDV',
  unit: 'Merna jedinica',
  sku: 'SKU',
  displayName: 'Ime i prezime',
  title: 'Titula',
  personalIdNumber: 'Pib',
  skype: 'Skype',
  permission: 'Ovlaštenje',
  vatNumber: 'Porezni broj',
  gender: 'Pol',
  birthDate: 'Datum rođenja',
  newsletter: 'Newsletter',
  site: 'Sajt',
  website: 'Websajt',
  hasAccess: 'Ima pristup',

  firstName: 'Ime',
  lastName: 'Prezime',
  salutation: 'Titula',
  placeOfBirth: 'Mjesto rođenja',
  main: 'Glavna adresa',
  fax: 'Fax',
  workgroups: 'Radne skupine',
  username: 'Korisničko ime',
  shortName: 'Kratki naziv',
  industryType: 'Tip industrije',
  companyIdNumber: 'Matični broj kompanije',
  logo: 'Logo',

  barcode: 'Barkod',
  issuedFrom: 'Izdavalac',
  issuedTo: 'Primalac',
  grandTotal: 'Ukupno',
  issuedOn: 'Datum izdavanja',
  dueDate: 'Datum dospeća',
  documentType: 'Tip dokumenta',
  documentFlow: 'Orentacija',
  ean: 'EAN',  // TOMO
  qrCode: 'QR kod',  // TOMO
  tariffNumber: 'Tarifni broj', // TOMO
  breakage: 'Lom', // TOMO
  shortDescription: 'Kratki opis', // TOMO
  featured: 'Izdvojen', // TOMO
  taxClass: 'Klasa poreza', // TOMO
  hasWeight: 'Težina', // TOMO
  leaderImage: 'Slika voditelja', // TOMO
  createdByImage: 'Slika tvorca', // TOMO
  barCode: 'Bar kod',//TOMO
  level: 'Razina',
  budget: 'Budžet',
  totalProjects: 'Ukupno projekata',
  countOfTasks: 'Broj taskova',
  completedTasks: 'Završeni taskovi',
  estimatedHours: 'Estimirani sati',
  loggedHours: 'Ulogirani sati',
  estimatedPriceTotal: 'Ukupna estimirana cena',
  completedTasksPercentage: 'Postotak završenih zadataka',
  estimatedHoursInTotal: 'Ukupno estimiranih sati',
  totalHours: 'Ukupno sati',
  buyDate: 'Datum kupovine',
  model: 'Model',
  supplier: 'Dobavljač',
  document: 'Dokument',
  invoiceNumber: 'Broj računa',
  available: 'Dostupno',
  manufacturer: 'Proizvođač',
  serialNo: 'Serijski broj',
  assignType: 'Dodijeli vrstu',
  coordinates: 'Koordinate',
  status: 'Status',
  quantity: 'Količina',
  reserved: 'Rezervirano',
  itemType: 'Vrsta stavke',
  version: 'Verzija',
  mainConsent: 'Glavna privola',
  taskCount: 'Broj zadataka',
  statusNote: 'Beleška statusa',
  statusTimestamp: 'Datum statusa',
  consent: 'Privola',
  user_thumbnail: 'Korisnik',
  agreement: 'Sporazum',
  sourceId: 'Izvor',
  user_email: 'E-mail',
  user_personalIdNumber: 'ID broj',
  role: 'Rola',
  estimated: 'Procijenjeno',
  unitPrice: 'Jedinična cijena',
  total: 'Total',
  mainBranch: 'Glavna grana\n',
  isBillable: 'Naplativo',
  owner: 'Vlasnik',
  module: 'Modul',
  access: 'Pristup',
  exportQuestion: 'Želite li da izvezete trenutnu stranicu ili sve stranice',
  checkedItems: 'Označene stavke',
  currentPage: 'Trenutnu stranicu',
  allPages: 'Sve stranice',
  exportTo: 'Izvezi u',
  csv: '.csv',
  xlsx: '.xlsx',
  media: 'Media',
  exportItems: 'Izvezi stavke',
  filename: 'Ime datoteke',
  enable: 'Omogući',
  disable: 'Onemogući',
  name_thumbnail: '',
  createdBy_thumbnail: '',
  owner_image: 'Owner',

  //AUTH

  usernameEmail: 'Korisnicko ime/Email',
  changePassword: 'Promeeni lozinku',
  oldPassword: 'Stara lozinka',
  newPassword: 'Nova lozinka',
  confirmPassword: 'Potvrdi lozinku',
  forgotPassword: 'Zaboravio sam lozinku',
  resetPassword: 'Promena lozinke',

  //fileUpload

  maxFileSizeWarn: 'Preveliki fajl',
  maxFileSizeError: 'Premašena maksimalna veličina fajla',

  //Video

  noVideoUploaded: 'Video nije učitan',

  //BreadCrumbs

  materialsexternalservices: 'Materijali i vanjske usluge',
  newmaterial: 'Novi materijal',
  editmaterial: 'Uredi materijal',
  home: 'Početna',
  companies: 'Kompanija',
  details: 'Detalji',
  contacts: 'Kontakti',
  webusers: 'Web korisnici',
  branches: 'Poslovnice',
  projects: 'Projekti',
  newproduct: 'Novi Proizvod',
  editproduct: 'Uredi Proizvod',
  newuser: 'Novi Korisnik',
  edituser: 'Uredi Korisnika',
  newbranch: 'Nova Poslovnica',
  editbranch: 'Uredi Poslovnicu',
  newcompany: 'Nova Kompanija',
  editcompany: 'Uredi Kompaniju',
  analytics: 'Analitika',
  sessions: 'Sesije',
  ui: 'UI',
  info: 'Info',
  admin: 'Administrator',
  general: 'Generalno',
  lists: 'Liste',
  overview: 'Pregled',
  conversions: 'Konverzije',
  alerts: 'Obavesti',
  documents: 'Dokumenti',
  courses: 'Kursevi',
  products: 'Proizvodi',
  materials: 'Materijali',
  productsmaterials: 'Proizvodi i materijali',
  elearning: 'E-učenje',
  participants: 'Sudionici',
  virtualclassroom: 'Virtualna učionica',
  discussions: 'Diskusije',
  ecommerce: 'E-commerce',
  paymentterms: 'Uveti Plaćanja',
  deliveryterms: 'Uveti Dostave',
  lead: 'Leadovi',
  opportunity: 'Prilika',
  mailbox: 'Pošta',
  inbox: 'Spremnik',
  sent: 'Poslano',
  drafts: 'Skice',
  trash: 'Otpad',
  archives: 'Arhiva',
  reports: 'Izveštaji',
  gdpr: 'Zaštita podataka',
  departments: 'Odeljenja',
  newdepartment: 'Novo odeljenje',
  editdepartment: 'Udredi odeljenje',
  newcourse: 'Novi kurs',
  editcourse: 'Uredi kurs',
  attributes: 'Atributi',
  newattribute: 'Novi atribut',
  editattribute: 'Uredi atribut',
  attributesets: 'Setovi atributa',
  newattributeset: 'Novi set atributa',
  editattributeset: 'Uredi set atributa',
  productsservices: 'Proizvodi i usluge',
  newservice: 'Nova usluga',
  editservice: 'Uredi uslugu',
  externalservices: 'Vanjske usluge',
  newexternalservice: 'Nova vanjska usluga',
  editexternalservice: 'Uredi vanjsku uslugu',
  newclass: 'Nova lekcija',
  editclass: 'Uredi lekciju',
  classes: 'Lekcije',
  classdetails: 'Detalji lekcije',
  discussiondetails: 'Detalji diskusije',
  editdiscussion: 'Uredi diskusiju',
  newdiscussion: 'Nova diskusija',
  newclassroom: 'Nova učionica',
  editclassroom: 'Uredi učionicu',
  newwarehouse: 'Novo skladište',
  editwarehouse: 'Uredi skladište',
  newproject: 'Novi projekat',
  editproject: 'Uredi projekat',
  tasks: 'Zadatci',
  newtask: 'Novi zadatak',
  edittask: 'Uredi zadatak',
  timeexpenses: 'Vreme i troškovi',
  kanban: 'Kanban',
  humanresources: 'Ljudski resursi',
  attendance: 'Prisutnost',
  workstatus: 'Radni status',
  absenceschedule: 'Raspored odutnosti',
  healthandsafety: 'Zdravlje i sigurnost',
  bins: 'Police',
  newbin: 'Nova polica',
  editbin: 'Uredi policu',
  newasset: 'Nova imovina',
  editasset: 'Uredi imovinu',
  assets: 'Imovina',
  report: 'Report',
  newcategory: 'Nova kategorija',
  editcategory: 'Uredi kategoriju',
  managecategories: 'Upravljanje kategorijama',
  exams: 'Ispiti',
  exam: 'Ispit',
  examslist: 'Lista ispita',
  newexam: 'Novi ispit',
  editexam: 'Uredi ispit',
  cms: 'CMS',
  stores: 'Trgovine',
  commercialprofiles: 'Komercijalni profili',
  newcommercialprofile: 'Novi komercijalni profil',
  editcommercialprofile: 'Uredi komercijalni profil',
  commercialprofile: 'Komercijalni profil',
  couponsets: 'Kupon setovi',
  gaussDrive: 'Storage',
  projectgeneralreport: 'Projekti opći izveštaj',
  outgoinginvoicereport: 'Izlazni računi izveštaj',
  inboundinvoicereport: 'Ulazni računi izveštaj',
  contractreport: 'Ugovori izveštaj',
  inboundorderreport: 'Ulazne narudžbenice izveštaj',
  outgoingorderreport: 'Izlazna narudžbenice izveštaj',
  projectloggedtimereport: 'Projekti logirano vreme izveštaj',
  stock: 'Zaliha',
  stockmovement: 'Kretanje zaliha',
  projectportfolioreport: 'Izveštaj portfelja projekata',
  projectstatusreport: 'Izveštaj - status projekta',
  employeeworkloadreport: 'Izveštaj – Radna opterećenost zaposlenika',
  roles: 'Role',
  rolelist: 'Popis rola',
  newrole: 'Nova rola',
  editrole: 'Uredi rolu',
  generalSettings: 'Opće postavke',
  appearance: 'Izgled',  // TOMO
  blogposts: 'Postovi',
  comments: 'Komentari',
  forms: 'Forme',
  pages: 'Stranice',
  addnewpage: 'Dodavanje nove stranice',
  editpage: 'Uređivanje stranice',
  addnewpost: 'Dodavanje novog posta',
  editpost: 'Uređivanje posta',
  addnewcategory: 'Dodavanje nove kategorije',
  addnewtag: 'Dodavanje novog taga',
  edittag: 'Uređivanje taga',
  navigation: 'Navigacija',
  editLink: 'Uređivanje linka',
  gaussdrive: 'Gauss drive',
  system: 'Sustav',
  catalog: 'Katalog',
  invoices: 'Računi',
  orders: 'Narudžbe',
  deliverymethods: 'Načini dostave',
  paymentmethods: 'Načini plaćanja',
  redirects: 'Redirects',
  trackingtools: 'Tracking tools',
  productpages: 'Stranice proizvoda',
  defaultAllowComments: 'Komentiranje postova dopušteno po defaultu',
  defaultAllowReviews: 'Recenziranje postova dopušteno po defaultu',
  requireRatingForComment: 'Obavezno ocjenjivanje kod komentiranja',
  requireCommentForRating: 'Obavezno komentiranje kod ocjenjivanja',
  numberOfStars: 'Navjeća moguća ocjena',
  canCommentOnBlog: 'Može komentirati blog',
  canAddReviews: 'Može recenzirati proizvod',
  registeredUsers: 'Registrovani korisnici',
  anyone: 'Bilo tko',

  //Activity

  recentActivity: 'Nedavna aktivnost',
  recentActivities: 'Nedavne aktivnosti',
  modified: 'Promenjeno',
  logged_in: 'Logiran',
  moved_to_archive: 'Prebačeno u arhivu',
  restored_from_archive: 'Vraćeno iz arhive',
  reopened: 'Ponovno otvoren',
  uploaded: 'Učitan',
  workgroup: 'radna grupa',
  cashRegister: 'blagajna',
  department: 'odjel',
  product: 'Produkt',
  simple_product: 'jednostavni proizvod',
  complex_product: 'kompleksni proizvod',
  eProduct_product: 'e-Proizvod',
  service: 'Usluga',
  simple_service: 'jednostavna usluga',
  complex_service: 'kompleksni usluga',
  eProduct_service: 'e-Usluga',
  material: 'Sirovina',
  externalService: 'vanjska usluga',
  attribute: 'atribut',
  attributeSet: 'Atribut set',
  course: 'Tečaj',
  courseExam: 'Ispit',
  class: 'Lekcija',
  discussion: 'Diskusija',
  comment: 'komentar',
  bin: 'policu',
  asset: 'imovina',
  projectDiscussion: 'diskusija',
  removed_from_stock: 'uklonjeno sa stanja',
  added_to_stock: 'dodano na stanje',
  noCheckedItems: 'Nema označenih stavki',
  findFreeRooms: 'Pronađi slobodne prostorije',
  freeRooms: 'Slobodne prostorije',

  //</editor-fold>

  //Layout

  language: 'Jezik',
  updateprofile: 'Uredi profil',
  changepassword: 'Promeni lozinku',
  logout: 'Odjava',
  default: 'Standardna',
  custom: 'Prilagođena',
  business: 'Poslovna',
  clientcompany: 'Klijent kompanija',
  productsAndMaterials: 'Proizvodi i materijali',
  NoCompanyWarningHeader: 'Nema registrirane kompanije',
  NoCompanyWarningText: 'Nemate registriranu kompaniju, molimo da ju kreirate kako biste koristili aplikaciju',
  error: 'Greška',
  colorscheme: 'Boja',
  addnewuser: 'Dodaj novog korisnika',
  addnewcompany: 'Dodaj novu kompaniju',
  addnewbranch: 'Dodaj novu poslovnicu',
  addnewdepartment: 'Dodaj novo odeljenje',

  //User

  iban: 'IBAN',
  savechanges: 'Spremi promene',
  firstname: 'Ime',
  lastname: 'Prezime',
  password: 'Lozinka',
  companybranch: 'Poslovnica',
  phonenumber: 'Telefon',
  oib: 'PIB',
  birthdate: 'Datum rođenja',
  placeofbirth: 'Mesto rođenja',
  addresses: 'Adrese',
  streetaddress: 'Ulica',
  postalcode: 'Poštanski broj',
  stateprovince: 'Opština/Naselje',
  hasaccess: 'Ima pristup',
  selectpermission: 'Odaberi ovlaštenje',
  workGroup: 'Radna grupa',
  weak: 'Slabo',
  medium: 'Srednje',
  strong: 'Jako',
  enterPassword: 'Unesite lozinku',
  male: 'Muško',
  female: 'Žensko',
  usercreated: 'Korisnik je kreiran',
  userupdated: 'Korisnik je uređen',
  userarchived: 'Korisnik je arhiviran',
  userrestored: 'korisnik je vraćen',
  sendmail: 'Pošalji mail',
  selecttype: 'Odaberite tip',
  dateofbirth: 'Datum rođenja',
  individuaalinformation: 'Osobni podatci',
  occupationinformation: 'Podatci o kompaniji',
  companyname: 'Naziv kompanije',
  companyaddress: 'Adresa kompanije',
  profileImageRemoved: 'Profilna slika je uklonjena',
  identify: 'Identificiraj',
  isAnonimysed: 'Anonizimiran',
  webuser: 'Web korisnik',
  contactPersonFirstName: 'Ime kontakt osobe',
  contactPersonLastName: 'Prezime kontakt osobe',
  contactPersonPhone: 'Telefonski broj kontakt osobe',

  //Company

  addnew: 'Dodaj',
  shortname: 'Kratki naziv',
  industrytype: 'Tip delatnosti',
  companyidnumber: 'PIB',
  vatnumber: 'Matični broj',
  housenumber: 'Kućni broj',
  selectimage: 'Odaberi sliku',
  hasaccesstooltip: 'Klijent kompanija',
  contactcheckboxtooltip: 'Vaš glavni kontakt',
  companycreated: 'Nova kompanija je kreirana',
  companyupdated: 'Kompanija je uređena',
  companyarchived: 'Kompanija je arhivirana',
  companyrestored: 'Kompanija je vraćena',
  selectcompany: 'Odaberi kompaniju',
  selectcompanytext: 'Molimo odaberite kompaniju',
  departmentcreated: 'Odeljenje kreirano',
  departmentupdated: 'Odeljenje uređeno',
  departmentarchived: 'Odeljenje arhivirano',
  companyLogo: 'Logo kompanije',
  priceList: 'Cenik',
  contact: 'Kontakt',

  //Branch

  noteofbusinessarea: 'Oznaka poslovnog prostora',
  noteofbusinessareainfo: 'Obavezno ako poslovnica izdaje račune',
  workhours: 'Radno vrijeme',
  zipcode: 'Poštanski broj',
  branchcreated: 'Poslovnica je kreirana',
  branchupdated: 'Poslovnica je uređena',
  brancharchived: 'Poslovnica je arhivirana',
  branchRestored: 'Poslovnica je otvorena',
  cashregisters: 'Blagajne',
  newdevice: 'Nova blagajna',
  editdevice: 'Uređivanje blagajne',
  devicetype: 'Vrsta blagajne',
  retail: 'Maloprodajna',
  cashregister: 'Blagajna ',
  invoicestartnumber: 'Početni broj računa',
  cashregistercreated: 'Blagajna kreirana',

  //Admin

  generalsettings: 'Opće postavke',
  settingssave: 'Postavke promenjene',
  defaultTheme: 'Standardna',
  hourUnits: 'Sati',
  minutes: 'Minute',
  days: 'Dani',
  customTheme: 'Prilagođena',
  businessTheme: 'Poslovna',
  attributeSets: 'Atribut setovi',
  attributeSetCreated: 'Atribut set je kreiran',
  attributeSetUpdated: 'Atribut set je uređen',
  attributeSetArchived: 'Atribut set je arhiviran',
  attributeSetRestored: 'Atribut set je vraćen',
  uploadHeader: 'Učitaj zaglavlje',
  uploadFooter: 'Učitaj podnožje',
  defaultType: 'Dodela osnovnog tipa',
  defaultTypeNote: 'Prikaži tipove dokumenata specifičnim odeljenjima, radnim grupama ili osobama.',
  enableMemo: 'Omogući memorandum kompanije',
  documentTraceability: 'Sledivost dokumenata',
  documentTraceabilityNote: 'Definiraj red kojim se dokumenti mogu kreirati.',
  documentAuthorisation: 'Autorizacija dokumenata',
  documentAuthorisationNote: 'Postavi osobe za autorizaciju za tipove dokumenata.',
  documentSettings: 'Postavke dokumenata',
  detailsBalance:'Saldo',
  "internal-travelwarrant":'Putni nalog',
  travelwarrant:'Travel warrant',
  defaultLanguage: 'Osnovni jezik',
  defaultColorTheme: 'Osnovna tema',
  mainCompanyCurrency: 'Valuta kompanije',
  currencyFormat: 'Format valute',
  numberFormat: 'Format broja',
  dateAndTime: 'Datum i vrijeme',
  timeFormat: 'Format vremena',
  dateFormat: 'Format datuma',
  timeZone: 'Vremenska zona',
  twoStepAuth: 'Autentifikacija prijave u dva koraka',
  daylightSavings: 'Letno računanje vremena',
  entityType: 'Tip entiteta',
  createdOn: 'Vrijeme stvaranja',
  url: 'Url',
  apiKeyToken: 'Api key token',
  tokenCopied: 'api key token kopiran',
  copyToken: 'Kopiraj token',
  addDomain: 'Dodaj domenu',
  editDomain: 'Uredi domenu',
  domain: 'Domena',
  activeDomains: 'Aktivne domene',
  users: 'Korisnici',
  eLearning: 'E-učenje',
  inbound: 'Ulazni',
  outgoing: 'Izlazni',
  internal: 'Interni',
  subtask: 'Podzadatci',
  emailSettings: 'Postavke e-maila',
  languageError:'Prevod na odabranom jeziku postoji',
  fileSizeError:'Iznos uploadanih podataka je veči od dupoštenog',
  templateContent:'Sadržaj',
  disableCommentEditingAfter: 'Onemogući uređivanje komentara nakon',
  "inbound-inquiry": "Ulazni upit",
  "inbound-invoice": "Izlazni upit",
  "inbound-letter": "Ulazni dopis",
  "inbound-offer": "Ulazna ponuda",
  "inbound-order": "Izlazna narudžbenica",
  "internal-assethandoutform": "Asset handover",
  "internal-assetreturnform": "Asset return",
  "internal-deliverynote": "Otpremnica",
  "internal-goodsreceipt": "Primka",
  "internal-issuenote": "Izdatnica",
  "internal-letter": "Interni dopis",
  "internal-requisitionwarrant": "Zahtjevnica",
  "internal-warehousetransfer": "Međuskladišnica",
  "outgoing-inquiry": "Izlazni upit",
  "outgoing-invoice": "Izlazni raćun",
  "outgoing-letter": "Izlazni dopis",
  "outgoing-offer": "Izlazna ponuda",
  "outgoing-order": "Izlazna narudžbenica",
  "special-contract": "Ugovor",
  projectsSettingsSaved: 'Postave projekata spremljene',
  elearningSettingsSaved: 'Postavke E-learninga spremljene',
  permissionGroup: 'Permission group',
  permissionGroups: 'Permission groups',
  assetNumberPattern: 'Model broja imovine',
  warehouseSettingsSaved: 'Postavke skladišta spremljene',
  legend: 'Legenda',
  year: 'Godina',
  counter: 'Brojač',

  //Products

  newProduct: 'Novi proizvod',
  services: 'Usluge',
  text: 'Tekst',
  textArea: 'Tekst dio',
  confirmation: 'Da/Ne',
  selectField: 'Odaberi',
  multiSelectField: 'Višestruki odabir',
  attributeFields: 'Atribut polja',
  values: 'Vrednosti',
  advanced: 'Napredno',
  defaultValue: 'Osnovna vrednost',
  setDefaultValue: 'Postavi kao osnovno',
  required: 'Neophodno',
  code: 'Kod',
  uniqueValue: 'Jedinstvena vrednost',
  unique: 'Jedinstven',
  addToColumn: 'Dodaj stupcu',
  useInFilter: 'Koristi u filteru',
  useInSearch: 'Koristi u tražilici',
  inputType: 'Tip unosa',
  attributeCreated: 'Atribut kreiran',
  attributeUpdated: 'Atribut uređen',
  attributeDeleted: 'Atribut obrisan',
  attributeRestored: 'Atribut vraćen',
  longDescription: 'Dugi opis',
  retailPrice1: 'Maloprodajna cena',
  breakAge: 'Rastep, kalo, lom',
  hasWeight1: 'Ima težinu',
  weight: 'Težina',
  weightUnit: 'Jedinica težine',
  productCreated: 'Proizvod kreiran',
  productUpdated: 'Proizvod uređen',
  productArchived: 'Proizvod arhiviran',
  productRestored: 'Proizvod vraćen',
  serviceCreated: 'Usluga kreirana',
  serviceUpdated: 'Usluga uređena',
  serviceArchived: 'Usluga arhivirana',
  serviceRestored: 'Usluga vraćena',
  parentCategory: 'Nadređena kategorija',
  'external-service': 'vanjska usluga',
  format: 'Format',
  materialCreated: 'Materijal kreiran',
  materialUpdated: 'Materijal uređen',
  materialArchived: 'Materijal arhiviran',
  materialRestored: 'Materijal vraćen',
  subType: 'Podtip',
  pleaseCreateCategoryProduct: 'Proizvodi i usluge trbeaju biti dodeljene na kategoriju, molimo vas prvo kreirajte kategoriju',
  simple: 'Jednostavan',
  complex: 'Složen',
  eProduct: 'eProizvod',
  rawMaterials: 'Sirovine',
  spareParts: 'Rezervni delovi',
  beverages: 'Pića',
  tradingGoods: 'Trgovačka roba',
  smallInventory: 'Sitan inventar',
  tires: 'Gume',
  packaging: 'Ambalaža',
  waste: 'Otpadni materijal',
  useDefault: 'Koristi zadano',
  store: 'Trgovina',
  commercialProfile: 'Komercijalni profil',
  commercialProfiles: 'Komercijalni profili',
  couponSets: 'Kupon setovi',
  newCommercialProfile: 'Novi komercijalni profile',
  editCommercialProfile: 'Uredi komercijalni profil',
  newCouponSet: 'Novi kupon set',
  editCouponSet: 'Uredi kupon set',
  isEnabled: 'Omogućeno',
  isStackable: 'Gomila se',
  discountType: 'Tip popusta',
  shippingDiscount: 'popust na dostavu',
  discountTarget: 'Meta dostave',
  priority: 'Prioritet',
  applyForStore: 'Dodaj na trgovinu',
  profileDescription: 'Opis profila',
  profileApplication: 'Primena',
  always: 'Uvek',
  viaCouponCodes: 'Uz kupon kodove',
  viaReferrals: 'Uz referrale',
  relative: 'Relativan',
  absolute: 'Apsolutan',
  singleUnit: 'Jedna jedinica',
  viaContacts: 'Uz kontakte',
  assignedProducts: 'Dodani proizvodi',
  assignedClients: 'Dodani klijenti',
  allProducts: 'Svi proizvodi',
  commercialProfileCreated: 'Komercijalni profil stvoren',
  commercialProfileUpdated: 'Komercijalni profil uređen',
  commercialProfileArchived: 'Komercijalni profil arhiviran',
  commercialProfileRestored: 'Komercijalni profil vraćen',
  profileType: 'Tip profila',
  relatedProducts: 'Povezani proizvodi',
  vendor: 'Dobavljač',
  vendors: 'Dobavljači',
  manufacturers: 'Proizviđači',
  brand: 'Robna marka',
  brands: 'Robne marke',
  page: 'Page',
  productToPage: 'Proizvod dodan na stranicu',
  addToEcommerce: 'Dodaj u e-commerce',
  addedToEcommerce: 'Stavke dodane u e-commerce',
  removeFromStore: 'Ukloni sa trgovine',
  removedFromStore: 'Stavke su uklonjene sa trgovine',
  forRegOnly: 'Samo za registrovane korisnike',
  applyCartRules: 'Primeni pravila kolica',
  couponCodeNum: 'Broj kupon kodova',
  manageReferrals: 'Upravljaj referalima',
  manageCouponCodes: 'Upravljaj kupon kodovima',
  noTimeLimit: 'Bez vremenskog ograničenja',
  spanInDays: 'Trajanje u danima',
  timeInterval: 'Vremenski interval',
  noCouponCodes: 'Bez kodova (koristi samo referale)',
  singleCouponCode: 'Jedan kod',
  multipleCouponCodes: 'Više kodova',
  couponSetCreated: 'Kupon set stvoren',
  couponSetUpdated: 'Kupon set uređen',
  couponSetArchived: 'Kupon set arhiviran',
  couponSetRestored: 'Kupon set vraćen',
  couponCodes: 'Kupon kodovi',
  mustSaveProfile: 'Da bi ste upravljali kupon kodovima komercijalni profil mora biti pohranjen. Pohraniti trenutni komercijalni profil?',
  codePrefix: 'Code prefix',
  codeSuffix: 'Code sufix',
  dashEveryXDigits: 'Dash every x digits',
  noOfCodes: 'Number of codes',
  usesPerReferralLink: 'User per referral link',
  usesPerUser: 'Uses per user',
  noOfProductUnits: 'Number of product units',
  combineCoupons: 'Combine coupons',
  forRegisterUsers: 'For registered users',
  validFrom: 'Vredi od',
  expiryDate: 'Datum isteka',
  couponsGenerated: 'Kuponi stvoreni',
  couponCode: 'Kupon kod',
  couponEdited: 'Kupon uređen',
  specialPrice: 'Aktivna posebna cena',
  currentSpecialPrice: 'Trenutna posebna cena',
  specialPrices: 'Posebne cene',
  specialPriceCreate: 'Stvori posebnu cenu',
  specialPriceEdit: 'Uredi posebnu cenu',
  specialPriceCreated: 'Posebna cena je stvorena',
  specialPriceUpdated: 'Posebna cena je uređena',
  manageSpecialPrices: 'Upravljaj posebnim cenama',
  specialPriceArchived: 'Posebna cena arhivirana',
  specialPriceRestored: 'Posebna cena vraćena',
  timePeriod: 'Vremenski period',

  //E-learning

  newCourse: 'Novi kurs',
  editCourse: 'Uredi kurs',
  virtualClassroom: 'Virtualna učionica',
  virtualClassrooms: 'Virtualne učionice',
  educator: 'Predavač',
  educators: 'Predavači',
  findEducator: 'Pronađi predavača',
  findEducators: 'Pronađi predavače',
  educatorsOnCourse: 'Predavači na kursu',
  student: 'Polaznik',
  students: 'Polaznici',
  findStudent: 'Pronađi polaznika',
  findStudents: 'Pronađi polaznike',
  studentsOnCourse: 'Polaznici na tečaju',
  addParticipant: 'Dodaj polaznika',
  addParticipants: 'Dodaj polaznike',
  upload: 'Učitaj',
  assignee: 'Dodeljena osoba',
  assignees: 'Dodeljene osobe',
  participant: 'Sudionik',
  classroom: 'Učionica',
  repeat: 'Ponavljanje',
  courseCreated: 'Kurs je kreiran',
  courseUpdated: 'Kurs je uređen',
  courseArchived: 'Kurs je arhiviran',
  courseRestored: 'Kurs je vraćen',
  participantsUpdated: 'Sudionici su uređeni',
  moderator: 'Moderator',
  moderators: 'Moderatori',
  moderatorsOnCourse: 'Moderatori na kursu',
  moderatorsUpdated: 'Moderatori su uređeni',
  addNewClass: 'Nova lekcija',
  editClass: 'Uredi lekciju',
  liveStreaming: 'Prenos uživo',
  subscriptions: 'Pretplate',
  classCreated: 'Lekcija kreirana',
  classUpdated: 'Lekcija uređena',
  classArchived: 'Lekcija arhivirana',
  classRestored: 'Lekcija vraćena',
  projectLeader: 'Nositelj projekta',
  commentCreated: 'Komentar kreiran',
  commentUpdated: 'Komentar uređen',
  commentDeleted: 'Komentar obrisan',
  createDiscussion: 'Kreiraj diskusiju',
  editDiscussion: 'Uredi diskusiju',
  discussionCreated: 'Diskusija kreirana',
  discussionUpdated: 'Diskusija uređena',
  discussionArchived: 'Diskusija arhivirana',
  discussionRestored: 'Diskusija vraćena',
  createClassroom: 'Nova učionica',
  editClassroom: 'Uredi učionicu',
  classroomCreated: 'Učionica kreirana',
  classroomUpdated: 'Učionica uređena',
  classroomArchived: 'Učionica arhivirana',
  classroomRestored: 'Učionica vraćena',
  passingPoints: 'Bodovi za prolaz',
  enableTimeLimit: 'Test na vrijeme',
  examName: 'Naziv ispita',
  publishExam: 'Objavi ispit',
  addQuestion: 'Dodaj pitanje',
  examCreated: 'Ispit kreiran',
  examUpdated: 'Ispit editiran',
  examDeleted: 'Ispit obrisan',
  examRestored: 'Ispit vraćen',
  timeLimit: 'Vremensko ograničenje',
  newExam: 'Novi ispit',
  saveExam: 'Spremi ispit',
  examType: 'Vrsta ispita',
  editExam: 'Uredi ispit',
  previewExam: 'Pregled ispita',
  submitExam: 'Predaj ispit',
  resetExam: 'Resetiraj ispit',
  examSubmitted: 'Ispit predan',
  examReset: 'Ispit je vraćen na početno stanje',
  allowContinue: 'Dozvoli nastavljanje',
  disqualify: 'Diskvalificiraj',
  question: 'Pitanje',
  questionType: 'Tip pitanja',
  answer: 'Odgovor',
  addAnswer: 'Dodaj odgovor',
  points: 'Bodovi',
  penalty: 'Negativni bodovi',
  typeAnswer: 'Unesi odgovor',
  closePreview: 'Zatvori pregled',
  result: 'Rezultat',
  singleTypeText: 'Jednostruki odgovor',
  multiTypeText: 'Višestruki odgovor',
  boolTypeText: 'Točno/netočno',
  gapsTypeText: 'Popuni praznine',
  matchTypeText: 'Spoji parove',
  essayTypeText: 'Esejsko pitanje',
  shortTypeText: 'Kratak odgovor',
  passed: 'Prošli',
  failed: 'Pali',
  takeExam: 'Reši ispit',
  continueExam: 'Nastavi ispit',
  viewResults: 'Pregledaj rezultate',
  timeLeft: 'Preostalo vrijeme',
  gradeExam: 'Oceni ispit',
  gradeExams: 'Oceni ispite',
  examGraded: 'Ispit ocenjen',
  examsGraded: 'Ispiti ocenjeni',
  viewExam: 'Pregledaj ispit',
  pointsUpdated: 'Bodovi ažurirani',

  //Projekti

  projectCreated: 'Projekat kreiran',
  projectUpdated: 'Projekat uređen',
  projectArchived: 'Projekat arhiviran',
  projectRestored: 'Projekat vraćen',
  newProject: 'Novi projekat',
  editProject: 'Uredi projekat',
  firstTask: 'Kreiraj prvi zadatak',
  estimationUnit: 'Jedinica procene',
  estimationUnits: 'Jedinice procene',
  estimated1: 'Kol. jed. proc.',
  unitPrice1: 'Cena jedinice',
  total1: 'Ukupno',
  observers: 'Promatrači',
  notBillable: 'Nenaplativo',
  newTask: 'Novi zadatak',
  editTask: 'Uredi zadatak',
  taskCreated: 'Zadatak kreiran',
  taskUpdated: 'Zadatak uređen',
  taskArchived: 'Zadatak arhiviran',
  taskRestored: 'Zadatak vraćen',
  employees: 'Zaposlenici',
  clients: 'Klijenti',
  pleaseSelectEstimatedBy: 'Molim odaberite jedinicu procjene',
  subscribersUpdated: 'Pretplatnici uređen',
  addSubTask: 'Dodaj podzadatak',
  subTask: 'Podzadatak',
  subTasks: 'Podzadatci',
  showCompleted: 'Prikaži završene',
  assignedTo: 'Dodeljeno na',
  timeAndExpenses: 'Vreme i troškovi',
  logUpdated: 'Upis uređen',
  logDeleted: 'Upis obrisan',
  logTime: 'Upiši vreme',
  logActivity: 'Upiši aktivnost',
  logExpense: 'Upiši trošak',
  thisWeek: 'Ova nedelja',
  previousMonth: 'Ovaj mesec',
  loggedTime: 'Upisano vreme',
  loggedExpense: 'Upisani troškovi',
  loggedActivity: 'Upisane aktivnosti',
  log: 'Upiši',
  time: 'Vreme',
  totalInHours: 'Ukupno u satima',
  hourPrice: 'Cena po satu',
  estimatedInHours: 'Procena po satima',
  cantUpdateKanban: 'Nemate ovlasti za uređivanje kanbana',
  complete: 'Završi',
  sortByLabel: 'Sortiraj po oznaci',
  sortByMilestone: 'Sortiraj po milestone',
  refreshTasks: 'Osveži zadatke',
  logTypes: 'Tipovi upisa',
  timeFrameFrom: 'Vreme od',
  timeFrameTo: 'Vreme do',
  groupBy: 'Grupiraj',
  responsiblePerson: 'Odgovorna osoba',
  exportProject: 'Izvezi podatke projekta',
  importProjectCategories: 'Uvezi kategorije projekta',
  importProjectLabels: 'Uvezi oznake projekta',
  importTaskCategories: 'Uvezi kategorije zadataka',
  importTaskLabels: 'Uvezi oznake zadataka',
  importDiscussionCategories: 'Uvezi kategorije diskusija',
  importDiscussionLabels: 'Uvezi oznake diskusija',

  //Warehouse

  warehouseCreated: 'Skladište kreirano',
  warehouseUpdated: 'Skladište uređeno',
  warehouseDeleted: 'Skladište obrisano',
  warehouseArchived: 'Skladište arhivirano',
  warehouseRestored: 'Skladište vraćeno',
  newWarehouse: 'Novo skladište',
  editWarehouse: 'Uredi skladište',
  binCreated: 'Polica kreirana',
  binUpdated: 'Polica uređena',
  binDeleted: 'Polica obrisana',
  binRestored: 'Polica vraćena',
  newBin: 'Nova polica',
  editBin: 'Uredi policu',
  assetCreated: 'Imovina kreirana',
  assetUpdated: 'Imovina uređena',
  assetDeleted: 'Imovina obrisana',
  assetRestored: 'Imovina vraćena',
  newAsset: 'Nova imovina',
  editAsset: 'Uredi imovinu',
  warehouseList: 'Lista skladišta',
  stockValuationMethod: 'Metoda vrednovanja zalihe',
  dispatchOrderType: 'Redosled izdavanja',
  workers: 'Delatnici',
  parentBranch: 'Nadređena poslovnica',
  linkedBranches: 'Povezane poslovnice',
  linkedWarehouses: 'Povezana skladišta',
  stockMovement: 'Kretanje zaliha',
  transferSchedule: 'Raspored transfera',
  maxLoad: 'Max nosivost (kg)',
  semiProducts: 'Poluproizvodi',
  mixed: 'Mešano',
  warrantyDue: 'Istek jamstva',
  long: 'Dugoročno',
  short: 'Kratkoročno',
  belowMin: 'Ispod min',
  aboveMax: 'Iznad max',
  minimumStock: 'Minimalna količina',
  maximumStock: 'Maksimalna količina',
  uom: 'UOM',
  qty: 'Kol',
  totalQty: 'Ukupna Kol',
  reservedQty: 'Rezervirana Kol',
  availableQty: 'Dostupna Kol',
  issuedQty: 'Izdana Kol',
  receivedQty: 'Zaprimljena Kol',
  receivegoods: 'Zaprimi robu',
  viewbins: 'Pogledaj police',
  dispatch: 'Izlaz',
  'write-off': 'Otpis',
  notAvailable: 'Zaduženo',
  warranty: 'Jamstvo',
  assignTo: 'Zaduži na',
  assign: 'Zaduži',
  return: 'Razduži',
  selectUser: 'odaberi korisnika',
  assetAssignedMsg: 'Imovina zadužena na ',
  assetReturnedMsg: 'Imovina razdužena',
  assetAlreadyAssigned: 'Ova imovina je već zadužena.',
  internalCode: 'Interni kod',
  itemCode: 'Kod',
  autoGenerateSku: 'Automatski generiraj SKU',

  //Reports

  advancedProjectSearchAndFiltering: 'Napredno pretraživanje i filtriranje projekata',
  advancedDocumentSearchAndFiltering: 'Napredno pretraživanje i filtriranje dokumenata',
  lastMonth: 'Prošli mesec',
  lastWeek: 'Prošla nedelja',
  totalTime: 'Ukupno vreme',
  projectGeneralReport: 'Projekti opći izveštaji',
  projectLoggedTimeReport: 'Upisano vreme',
  outgoingInvoiceReport: 'Izlazni računi izveštaj',
  inboundInvoiceReport: 'Ulazni računi izveštaj',
  outgoingOrderReport: 'Izlazne ponude izveštaj',
  contractReport: 'Ugovori izveštaj',
  contracts: 'Ugovori',
  loggedTimeReport: 'Upisano vreme izveštaj',
  issuedFromBranch: 'Poslovnica izdavanja',
  issuedFromDevice: 'Blagajna izavanja',
  issuedOnStartDate: 'Datum izdavanja od',
  issuedOnEndDate: 'Datum izdavanja do',
  dueDateFrom: 'Datum dospeća od',
  dueDateTo: 'Datum dospeća do',
  documentLabel: 'Oznaka dokumenta',
  validUntilFrom: 'Rok valjanosti od',
  validUntilTo: 'Rok valjanosti do',
  validUntil: 'Rok valjanosti',
  startDateFrom: 'Datum početka od',
  startDateTo: 'Datum početka do',
  endDateFrom: 'Datum završetka od',
  endDateTo: 'Datum završetka do',
  estimatedBudgetFrom: 'Estimirani budžet od',
  estimatedBudgetTo: 'Estimirani budžet do',
  when: 'Kada je',
  changed: 'izmenjeno',
  assigned: 'dodeljen',
  removed: 'uklonjen',
  commented: 'komentiran',
  activated: 'aktiviran',
  newAssignees: 'dodani novi zastupnici',

  removedAssignees: 'assignees where removed',
  assignedModerators: 'moderator where assigned',
  removedModerators: 'moderators where removed',
  removedObservers: 'observers where removed',
  assignedSubscribers: 'subscribers where assigned',
  removedSubscribers: 'subscribers where removed',

  newParticipants: 'dodani su novi učesnici',
  removedParticipants: 'participants where removed',
  newModerators: 'dodani su novi moderatori',
  assignedModerator: 'dodeljen je moderator',
  removedModerator: 'moderator je uklonjen',
  newObservers: 'dodani su novi posmatrači',
  assignedObserver: 'dodeljen je posmatrač',
  removedObserver: 'posmatrač je uklonjen',
  outgoingoffer: 'Izlazna ponuda',
  outgoinginquiry: 'Izlazni upit',
  outgoinginvoice: 'Izlazni račun',
  outgoingorder: 'Izlazna ponuda',
  outgoingletter: 'Izlazni dopis',
  inboundoffer: 'Ulazna ponuda',
  inboundinvoice: 'Ulazni račun',
  inboundinquiry: 'Ulazni upit',
  inboundorder: 'Ulazna narudžba',
  inboundletter: 'Ulazni dopis',
  internaloffer: 'Interna ponuda',
  internalinquiry: 'Interni upit',
  internalinvoice: 'Interni račun',
  internalorder: 'Interna narudžbenica',
  internalletter: 'Interni dopis',
  portfolioReport: 'Izveštaj portfelja',
  projectStatusReport: 'Izveštaj - status projekta',
  employeeWorkload: 'Izveštaj – Radna opterećenost zaposlenika',
  timeExpensesReport: 'Izvješće o vremenu i troškovima',

  //gdpr

  consents: 'Pristanci',
  newConsent: 'Novi pristanak',
  editConsent: 'Uredi pristanak',
  providerName: 'Naziv pružatelja',
  consentName: 'Naziv pristanka',
  source: 'Izvor',
  setBy: 'Postavio',
  displayName1: 'Tekst prikaza',
  purposesText: 'Tekst svrhe',
  uniqueNumber: 'Poseban broj',
  consentCreated: 'Pristanak stvoren',
  consentEdited: 'Pristanak uređen',
  agreements: 'Sporazumi',
  newAgreement: 'Novi sporazum',
  editAgreement: 'Uredi sporazum',
  agreementName: 'Naziv sporazuma',
  agreementCreated: 'Sporazum stvoren',
  agreementEdited: 'Sporazum uređen',
  consentLogs: 'Unosi pristanaka',
  newConsentLog: 'Novi unos pristanka',
  editConsentLog: 'Uredi unos pristanka',
  logDate: 'Datum unosa',
  consentGiver: 'Davatelj pristanka',
  files: 'Datoteke',

  //Documents

  offer: 'Ponuda',
  order: 'Narudžbenica',
  contract: 'Ugovor',
  assethandover: 'Obrazac za zaduženje imovine',
  assetreturn: 'Obrazac za razduženje imovine',
  warehousetransfer: 'Međuskladišnica',
  documentnumber: 'Broj dokumenta',
  invoicetype: 'Tip računa',
  paymenttype: 'Tip plaćanja',
  paymentterm: 'Uveti plaćanja',
  validuntil: 'Rok valjanosti',
  branch: 'Poslovnica',
  selectbranch: 'odaberi poslovnicu',
  selectinvtype: 'odaberi tip računa',
  selectptype: 'odaberi tip plaćanja',
  selectpterm: 'odaberi uvjet plaćanja',
  selectincoterms: 'odaberi incoterms',
  entercompany: 'Unesi kompaniju',
  entercontact: 'Unesi kontakt',
  inquirynumber: 'Broj upita',
  contactperson: 'Kontakt osoba',
  itemdetails: 'Detalji stavke',
  deliverydetails: 'Detalji isporuke',
  selectcontact: 'Odaberi kontakt',
  saleswarrant: 'Prodajni nalog',
  devices: 'Blagajne',
  selectdevices: 'odaberi blagajnu',
  nodevices: 'Nema blagajni',
  subject: 'Subjekat',
  referentdocument: 'Referentni dokument',
  linkeddocument: 'Vezani dokument',
  offernumber: 'Broj ponude',
  warehousereceivingassets: 'Skladište primatelj imovinu',
  personreceivingassets: 'Osoba primatelj imovine',
  personreturningassets: 'Osoba izdavatelj imovine',
  issuedby: 'Izdavatelj',
  issuedbranch: 'Odeljenje izdavatelj',
  issuerwarehouse: 'Skladište izdavatelj',
  issuerbranch: 'Poslovnica izdavatelj',
  issuerperson: 'Osoba izdavatelj',
  deliverycompany: 'Kompanija koja dostavlja',
  deliveryperson: 'Osoba koja dostavlja',
  deliveryvehiclereg: 'Registracijske oznake vozila',
  receivedon: 'Vrijeme zaprimanja',
  receiverwarehouse: 'Skladište zaprimatelj',
  receiverperson: 'Osoba zaprimatelj',
  itemtype: 'Vrsta stavki',
  receptiontobins: 'Zaprimanje na police',
  issuedfrom: 'Izdavatelj',
  issuedto: 'Primatelj',
  issuedon: 'Datum izdavanja',
  duedate: 'Datum dospeća',
  grandtotal: 'Ukupan iznos',
  exporttopdf: 'Izradi PDF',
  receive: 'Zaprimi',
  deliverydate: 'Datum isporuke',
  unitprice: 'Jedinična cijena',
  discount: 'Popust',
  tax: 'Porez',
  netprice: 'Neto cena',
  nettotal: 'Neto ukupna',
  itemsnettotal: 'Ukupan neto iznos',
  pretaxtotal: 'Ukupno (bez PDV-a)',
  taxrate: 'Por. stopa',
  taxbase: 'Por. osn.',
  taxamount: 'Iznos poreza',
  referencenumber: 'Referentni broj',
  //NOTE
  note: 'Beleška',
  writepublicnote: 'Unesi belešku',
  notewarn: 'Ova beleška neće bit prikazivana klijentu niti uključena u dokumentu',
  choose: 'Odaberi',
  fromtemplate: 'iz predloška',
  internalnote: 'Interna beleška',
  selectlabel: 'odaberi oznaku',
  contacttitle: 'Naziv ugovora',
  contractnetamount: 'Neto iznos ugovora',
  contractgrandtotal: 'Ukupni ugovoreni iznos',
  //Missing
  "asset-handover":"Formular za zaduženje imovine",
  "asset-return": "Formular za razduženje imovine",
  issuenote:"Izdatnica",
  goodsreceipt:'Primka',
  "warehouse-transfer":"Međuskladišnica",
  //ASSETS
  warrantydue: 'Garancija do',
  serialnumber: 'Serijski broj',
  assetnumber: 'Broj imovine',
  assetname: 'Naziv imovine',
  // WAREHOUSE RECIEPT
  warehousereceipt: 'Primka',
  selectwarehouse: 'odaberi skladište',
  selectsupplier: 'Odaberi dobavljača',
  selectorder: 'Odaberi narudžbenicu',
  deliverynotenumber: 'Broj otpremnice',
  entersupplier: 'Unesi dobavljača',
  recieve: 'Zaprimi',
  ordernumber: 'Broj narudžbenice',
  orderitem: 'Ord. item',
  orderqty: 'Kol. nar.',
  avaliableqty: 'Dostupna kol.',
  recieveqty: 'Zaprimljena kol.',
  selectbin: 'odaberi policu',
  addbin: 'Dodaj',
  warehouse: 'Skladište',
  nocontact: 'Nema kontakata za odabranu poslovnicu',
  requisitionwarrant: 'Zahtev za nabavu',
  requisitionwarrantslip: 'Izdatnica',
  warehousereciept: 'Primka',
  receivedby: 'Zaprimio',
  deliverydue: 'Rok isporuke',
  purpose: 'Svrha',
  reqwarquantity: 'Req.war.qty',
  issuedqty: 'Izdana kol.',
  selectwarrant: 'Odaberi nalog',
  //LETTER
  lettertitle: 'Naslov dopisa',
  lettercontents: 'Sadržaj dopisa',
  selectdepartment: 'odaberi odjel',
  selectissuerbranch: 'Odaberi poslovnicu izdavanja',
  selectissuerwarehouse: 'Odaberi skladište izdavanja',
  selectreciever: 'Odaberi primatelja',
  selectrecievertype: 'Odaberi tip primatelja',
  selectrecieverbranch: 'Odaberi poslovnicu primatelja',
  selectrecieverwarehouse: 'Odaberi skladište primatelja',
  selectrecieverdepartment: 'Odaberi odeljenje primatelja',
  goodsrecievedby: 'Zaprima',
  goodsissuedby: 'Izdaje',
  purchaserequisition: 'Zahtev za nabavu',
  deliverynote: 'Otpremnica',
  onstock: 'Na stanju',
  storno: 'Storniraj',
  createstorno: 'Kreiraj storno',
  shippingmethod: 'Dostavna metoda',
  legacydocument: 'Legacy dokument',
  draft: 'Nacrt',
  documentTitle: 'Naziv',
  selectcategory: 'Odaberi kategoriju',
  relatedAssets: 'Vezana imovina',

  //MailboxTranslation

  compose: 'Novi e-mail',

  //ECommerce

  paymentTerms: 'Uslovi plaćanja',
  deliveryTerms: 'Uslovi dostave',
  paymentTermsUpdated: 'Uslovi plaćanja ažurirani!',
  deliveryTermsUpdated: 'Uslovi dostave ažurirani!',
  generalSettingsUpdated: 'Opće postavke ažurirane!',
  storeName: 'Naziv trgovine',
  addStore: 'Dodaj trgovinu',
  editStore: 'Uredi trgovinu',
  isDefault: 'Postavi kao zadanu trgovinu',
  manageWebsites: 'Upravljaj website-ovima',
  websiteName: 'Ime website-a',
  contactEmail: 'Kontaktni e-mail',
  contactPhone: 'Kontaktni telefon',
  paymentMethods: 'Načini plaćanja',
  deliveryMethods: 'Načini dostave',
  exitEcommerce: 'Napusti E-commerce',
  changeLabel: 'Promeni oznaku',
  addProducts: 'Dodaj proizvode',
  productsAdded: 'Proizvodi dodani',

  //Referrals
  referral: 'Referal',
  referrals: 'Referali',
  addReferrals: 'Dodaj referale',
  referralsCreated: 'Referali stvoreni',

  //cms

  editPage: 'Uređivanje stranice',
  newPage: 'Dodavanje stranice',
  enterTitle: 'Ovdje unesite naslov',
  chooseLanguage: 'Izaberite jezik',
  saveDraft: 'Sačuvaj skicu',  // TOMO
  preview: 'Pregled',
  published: 'Objavljeno',
  publishImmediately: 'Objavi',
  toTrash: 'Baci u smeće',
  pageAttributes: 'Atributi stranice',
  parent: 'Roditelj',
  noParent: '(nema roditelja)',
  template: 'Predložak',
  defaultTemplate: 'Zadani predložak',  // TOMO
  mainTitle: 'Glavni naslov',
  enterMainTitle: 'Unesite glavni naslov',
  commentsOn: 'Uključeno',
  commentsOff: 'Isključeno',
  pageSaved: 'Stranica spremljena',
  changeLanguageSure: 'Jeste li sigurni da želite promijeniti jezik? Svi podaci o stranici povezani s tim jezikom, a da nisu spremljeni biti će izgubljeni!',
  deletePage: 'Briši',
  archivePageSure: 'Jeste li sigurni da želite arhivirati stranicu?',
  posts: 'Postovi',
  addCategories: 'Dodaj kategorije',
  languages: 'Jezici',
  addLanguage: 'Dodaj jezik',
  categorySaved: 'Kategorija spremljena',
  newTag: 'Dodavanje taga',
  editTag: 'Uređivanje taga',
  deleteTranslationSure: 'Jeste li sigurni da želite obrisati ovaj prijevod?',
  deleteTagSure: 'Jeste li sigurni da želite obrisati ovaj tag?',
  archivePostSure: 'Jeste li sigurni da želite arhivirati ovaj post?',
  deleteCategorySure: 'Jeste li sigurni da želite obrisati ovu kategoriju?',
  pageArchived: 'Stranica arhivirana',
  postArchived: 'Post arhiviran',
  pagePublished: 'Stranica objavljena',
  postPublished: 'Post objavljen',
  pageUnpublished: 'Poništi objavu',
  postUnpublished: 'Poništi objavu',
  categoryDeleted: 'Kategorija obrisana',
  tagDeleted: 'Tag obrisan',
  translationDeleted: 'Prijevod obrisan',
  addTags: 'Dodaj tagove',
  tagUpdated: 'Tag ažuriran',
  editPost: 'Uredi post',
  newPost: 'Novi post',
  postSaved: 'Post spremljen',
  tagSaved: 'Tag spremljen',
  postUpdated: 'Post ažuriran',
  pageUpdated: 'Stranica ažurirana',
  translation: 'Prijevod',
  pageInfo: 'Informacije o stranici',
  postInfo: 'Informacije o postu',
  categoryInfo: 'Informacije o kategoriji',
  tagInfo: 'Informacije o tagu',
  navigationSaved: 'Navigacija spremljena',
  linksAdded: 'Poveznice dodane na navigaciju',
  navigationOrderSaved: 'Spremljen poredak navigacije',
  linkDeleted: 'Poveznica obrisana',
  deleteLinkSure: 'Jeste li sigurni da želite obrisati poveznicu i sve njezine podpoveznice?',
  affectsAllLanguages: 'Promjene će utjecati na sve jezike',
  willLostChanges: 'Sve promjene koje nisu spremljene biti će izgubljene. Jeste li sigurni da želite nastaviti?',
  deleteNavigationBranchSure: 'Jeste li sigurni da želite ukloniti poveznicu iz navigacije?',
  homePage: 'Početna stranica',
  optionsSaved: 'Opcije spremljene',
  emailsToSend: 'Emailovi za kontakt formu',
  emailsForGdpr: 'Emailovi za zaštitu podataka',
  chooseDomain: 'Izaberite domenu',
  chooseProduct: 'Izaberite proizvod',
  noProductChosen: 'Nema',
  exitCms: 'Napusti CMS',
  chooseNavigation: 'Izaberi navigaciju',
  welcome: 'Dobro došli u Gauss Box CMS',
  welcomeEcommerce: 'Dobro došli u Gauss Box E-commerce',
  addDomainToAccess: 'Potrebno je dodati barem jednu domenu kako bi se pristupilo CMS-u',
  chooseType: 'Izaberite tip',
  chooseStatus: 'Izaberite status',
  enterSlug: 'Ovdje unesite slug',
  manageExternalLinks: 'Upravljanje eksternim poveznicama',
  externalLinkTitle: 'Naziv',
  backgroundColor: 'Boja pozadine',
  metaDescription: 'Meta opis',
  keywords: 'Ključne reči',
  allowedLanguages: 'Dozvoljeni jezici',
  navigationType: 'Tip navigacije',
  addNavigation: 'Dodaj navigaciju',
  navigationAdded: 'Navigacija dodana',
  navigations: 'Navigacije',
  navigationDeleted: 'Navigacija uklonjena',
  productCategories: 'Kategorije proizvoda',
  externalLink: 'Vanjska poveznica',
  externalLinks: 'Vanjske poveznice',
  newNavigation: 'Nova navigacija',
  linklessItem: 'Stavka bez poveznice',
  soldProductsCount: 'Broj prodanih proizvoda',
  copyrightText: 'Copyright tekst',
  createRedirect: 'Kreiraj redirect',
  redirectCreated: 'Redirect kreiran',
  redirectUpdated: 'Redirect uređen',
  redirectDeleted: 'Redirect obrisan',
  newRedirect: 'Novi redirect',
  editRedirect: 'Uredi redirect',
  redirectFrom: 'Redirect sa',
  redirectTo: 'Redirect prema',
  statusCode: 'Status kod',
  code301: '301 Moved Permanently',
  code302: '302 Found',
  code307: '307 Temporary redirect',
  code410: '410 Content deleted',
  seo: 'SEO',
  robots: 'Robots',
  sitemap: 'Site map',
  robotsSaved: 'robots.txt saved',
  siteMapLastmod: 'Forsiraj lastmod na sve url-ove',
  siteMapHreflang: 'Dodaj prevedene postove u sitemap',
  siteMapImages: 'Dodaj slike u site map',
  siteMapGenerated: 'Site map generisan',
  changeFreq: 'Frekvencija promene',
  hourly: 'Po satu',
  daily: 'Dnevno',
  weekly: 'Sedmično',
  monthly: 'Mesečno',
  yearly: 'Godišnje',
  never: 'Nikada',
  postCount: 'Broj postova',
  seoFollow: 'Prati poveznice na ovoj stranici',
  seoIndex: 'Index post',
  canonicalPost: 'Kanonski post',
  canonicalPage: 'Kanonska stranica',
  trackingTools: 'Alati za praćenje',
  customHeader: 'Custom header',
  customFooter: 'Custom footer',
  googleAnalyticsIdentifier: 'Google analytics identifier',
  googleSearchConsoleIdentifier: 'Google search console identifier',
  googleTagManagerIdentifier: 'Google tag manager identifier',
  googleSearchPreview: 'Prikaz Google pretraživanja:',
  postSchedules: 'Vremenski rasporedi',
  createPostSchedule: 'Stvori vremenski raspored',
  postScheduleCreated: 'Vremenski raspored stvoren',
  scheduledDate: 'Datum primene',
  manageStatusSchedule: 'Upravljaj rasporedom statusa',
  productPages: 'Stranice proizvoda',

  //drive

  createDirectory: 'Stvori mapu',
  copy: 'Kopiraj',
  cut: 'Izreži',
  paste: 'Zalijepi',
  usedIn: 'Koristi se na',
  notInUse: 'Datoteka se ne koristi nigde',
  size: 'Veličina',
  versionHistory: 'Historija verzija',
  uploadNewVersion: 'Učitaj novu verziju',
  internalLink: 'Internal link',
  sharing: 'Delenje',
  accessPermissions: 'Pristupne dozvole',
  accessPermissionsSaved: 'Pristupne dozvole spremljene',
  publicLink: 'Javni link',
  editItem: 'Uredi stavku',
  minorVersion: 'Minor verzija',
  majorVersion: 'Major verzija',
  versionUploadQuestion: 'Koju verziju želite da učitate',
  moveTo: 'Premesti u',
  copyTo: 'Kopiraj u',
  read: 'Pregled',
  readWrite: 'Pregled, Uređivanje',
  readWriteDelete: 'Pregled, Uređivanje, Brisanje',
  permissionLevel: 'Razina ovlasti',
  fileArchived: 'Datoteka arhivirana',
  fileRestored: 'Datoteka vraćena',
  uploadFromDisc: 'Učitaj sa diska',
  makeActive: 'Učini aktivnom verzijom',
  activeVersionUpdated: 'Aktivna verzija datoteke uređena',

  //pagination

  previous: 'Prethodna',
  next: 'Sledeća',
  show: 'Prikaži',
  entries: 'rezultata',
  of: 'od',
  to: 'do',

  //filter

  filters: 'Filteri',
  apply: 'Primeni',
  industry: 'Industrija',
  inputtype: 'Tip polja',
  startdate: 'Datum početka',
  enddate: 'Datum završetka',
  createdat: 'Datum kreiranja',
  loggedby: 'Upisano od',
  flowtype: 'Tip toka',
  selectTwoDates: 'Odaberite dva datuma',
  removezerostock: 'Ukloni nultu zalihu',
  subjecttype: 'Tip subjekta',
  statustimestamp: 'Datum loga',

  //archive

  showUsers: 'Prikaži Korisnike',
  clientcompanies: 'Prikaži klijent kompanije',

  //SearchTranslations

  beginSearch: 'Pretraži...',

  //forbidden

  forbidden: 'Zabranjeno',
  goback: 'Vrati se natrag',

  //internalServerError

  titleInternal: 'Interna greška na serveru',

  //serviceUnavailable

  title1: 'Interna greška na serveru',
  text1: 'Server trenutno nije u mogućnosti da obradi ovaj zahtev. Molimo pokušajte ponovno',

  //VALIDATIONS
  DigitError:'Mora sadržavat barem jedan broj',
  UppercaseError:'Mora sadržavat barem jedno veliko slovo',
  LowercaseError:'Mora sadržavat barem jedno malo slovo',
  SpecCharError:'Mora sadržavat barem jedan specijalni znak',
  LengthError:'Mora sadržavat barem 8 znakova',

  //labelModal

  statuses: 'Statusi',
  statusName: 'Ime statusa',
  manageLabels: 'Upravljaj oznakama',
  labelNamePlaceholder: 'Ime oznake',

  //categoryModal

  manageCategories: 'Upravljaj kategorijama',
  categoryNamePlaceholder: 'Naziv kategorije',

  //occupationModal

  manageocupation: 'Upravljaj zanimanjima',
  occupationNamePlaceholder: 'Naziv zanimanja',

  //workGroupModal

  manageworkgroup: 'Upravljaj radnim grupama',
  workGroupNamePlaceholder: 'Naziv radne grupe',

  //participantModal

  educatorsOn: 'Predavači na tečaju',
  studentsOn: 'Polaznici na tečaju',
  employeesOn: 'Djelatnici na projektu',
  clientsOn: 'Klijenti na projektu',

  //groupModal

  manageGroups: 'Upravljaj grupama',
  groupNamePlaceholder: 'Ime grupe',

  //countries

  abw: 'Aruba',
  afg: 'Avganistan',
  ago: 'Angola',
  aia: 'Angila',
  alb: 'Albanija',
  and: 'Andora',
  are: 'Ujedinjeni Arapski Emirati ',
  arg: 'Argentina',
  arm: 'Jermenija',
  asm: 'Američka Samoa',
  ata: 'Antarktika',
  atf: 'Južni Francuski Teritoriji',
  atg: 'Antigva i Barbuda',
  aus: 'Australija',
  aut: 'Austrija',
  aze: 'Azerbajdžan',
  bdi: 'Burundi',
  bel: 'Belgija',
  ben: 'Benin',
  bes: 'Bonaire',
  bfa: 'Burkina Faso',
  bgd: 'Bangladeš',
  bgr: 'Bugarska',
  bhr: 'Bahrein',
  bhs: 'Bahami',
  bih: 'Bosna i Hercegovina',
  blm: 'Sveti Bartolomej',
  blr: 'Belorusija',
  blz: 'Belize',
  bmu: 'Bermuda',
  bol: 'Bolivija',
  bra: 'Brazil',
  brb: 'Barbados',
  brn: 'Bruneji',
  btn: 'Butan',
  bvt: 'Ostrvo Bouvet',
  bwa: 'Bocvana',
  caf: 'Centralnoafrička Republika',
  can: 'Kanada',
  cck: 'Keeling Ostrva',
  che: 'Švajcarska',
  chl: 'Čile',
  chn: 'Kina',
  civ: 'Obala Slonovače',
  cmr: 'Kameron',
  cod: 'Demokratska Republika Kongo',
  cog: 'Kongo',
  cok: 'Cookova Ostrva',
  col: 'Kolumbija',
  com: 'Komori',
  cpv: 'Zelenortska Republika',
  cri: 'Kostarika',
  cub: 'Kuba',
  cuw: 'Curacao',
  cxr: 'Božićna Ostrva',
  cym: 'Kajmanska Ostrva',
  cyp: 'Kipar',
  cze: 'Češka',
  deu: 'Nemačka',
  dji: 'Džibuti',
  dma: 'Dominika',
  dnk: 'Danska',
  dom: 'Dominikanska Republika',
  dza: 'Alžir',
  ecu: 'Ekvador',
  egy: 'Egipat',
  eri: 'Eritreja',
  esh: 'Zapadna Sahara',
  esp: 'Španija',
  est: 'Estonija',
  eth: 'Etiopija',
  fin: 'Finska',
  fji: 'Fidži',
  flk: 'Falkandska Ostrva',
  fra: 'Francuska',
  fro: 'Farska Ostrva',
  fsm: 'Mikronezija',
  gab: 'Gabon',
  gbr: 'Ujedinjeno Kraljevstvo',
  geo: 'Gruzija',
  ggy: 'Guernsey',
  gha: 'Gana',
  gib: 'Gibraltar',
  gin: 'Gvineja',
  glp: 'Gvadelupe',
  gmb: 'Gambija',
  gnb: 'Gvineja Bisau',
  gnq: 'Ekvatorijalna Gvineja',
  grc: 'Grčka',
  grd: 'Grenada',
  grl: 'Grenland',
  gtm: 'Gvatemala',
  guf: 'Francuska Gvajana',
  gum: 'Guam',
  guy: 'Gvajana',
  hkg: 'Hong Kong',
  hmd: 'Ostrvo Heard i McDonald Ostrva',
  hnd: 'Honduras',
  hrv: 'Hrvatska',
  hti: 'Haiti',
  hun: 'Mađarska',
  idn: 'Indonezija',
  imn: 'Isle of Man',
  ind: 'Indija',
  iot: 'Britanski teritoriji U Indijskom okeanu',
  irl: 'Irska',
  irn: 'Iran',
  irq: 'Irak',
  isl: 'Island',
  isr: 'Izrael',
  ita: 'Italija',
  jam: 'Jamajka',
  jey: 'Jersey',
  jor: 'Jordan',
  jpn: 'Japan',
  kaz: 'Kazahstan',
  ken: 'Kenija',
  kgz: 'Krigistan',
  khm: 'Kambodža',
  kir: 'Kiribati',
  kna: 'Sveti Kitts i Nevis',
  kor: 'Rebublika Koreja',
  kwt: 'Kuvajt',
  lao: 'Laos',
  lbn: 'Liban',
  lbr: 'Liberija',
  lby: 'Libija',
  lca: 'Sveta Lucija',
  lie: 'Lihtenštajn',
  lka: 'Šri Lanka',
  lso: 'Lesoto',
  ltu: 'Litva',
  lux: 'Luksmburg',
  lva: 'Latvija',
  mac: 'Makao',
  maf: 'Sveti Martin (fr.dio)',
  mar: 'Maroko',
  mco: 'Monako',
  mda: 'Moldavija',
  mdg: 'Madagaskar',
  mdv: 'Maldivi',
  mex: 'Meksiko',
  mhl: 'Maršalovi',
  mkd: 'Makedonija',
  mli: 'Mali',
  mlt: 'Malta',
  mmr: 'Mjanmar',
  mne: 'Crna Gora',
  mng: 'Mongolija',
  mnp: 'Sjeverna Marijanska Ostrva',
  moz: 'Mozambik',
  mrt: 'Mauritanija',
  msr: 'Monteserat',
  mtq: 'Martinik',
  mus: 'Mauricijus',
  mwi: 'Malavi',
  mys: 'Malezija',
  myt: 'Mayotte',
  nam: 'Namibija',
  ncl: 'Nova Kaledonija',
  ner: 'Niger',
  nfk: 'Norfolk',
  nga: 'Nigerija',
  nic: 'Nikaragva',
  niu: 'Niue',
  nld: 'Holandija',
  nor: 'Norveška',
  npl: 'Nepal',
  nru: 'Nauru',
  nzl: 'Novi Zeland',
  omn: 'Oman',
  pak: 'Pakistan',
  pan: 'Panama',
  pcn: 'Pitcairn',
  per: 'Peru',
  phl: 'Filipini',
  plw: 'Palau',
  png: 'Papua Nova Gvineja',
  pol: 'Poljska',
  pri: 'Portoriko',
  prk: 'DNR Koreja',
  prt: 'Portugal',
  pry: 'Paragvaj',
  pse: 'Palestina',
  pyf: 'Francuska Polinezija',
  qat: 'Katar',
  reu: 'Reunion',
  rou: 'Rumunjska',
  rus: 'Ruska Federacija',
  rwa: 'Ruanda',
  sau: 'Saudijska Arabija',
  sdn: 'Sudan',
  sen: 'Senegal',
  sgp: 'Singapur',
  sgs: 'Južna Georgia i južna Sandwich Ostrva',
  shn: 'Sveta Helena',
  sjm: 'Svalvard i Jan Mayen',
  slb: 'Salomoska Ostrva',
  sle: 'Siera Leone',
  slv: 'Salvador',
  smr: 'San Marino',
  som: 'Somalija',
  spm: 'Sveti Petar i Miquelon',
  srb: 'Srbija',
  ssd: 'Južni Sudan',
  stp: 'Sveti Toma i Princip',
  sur: 'Surinam',
  svk: 'Slovačka',
  svn: 'Slovenija',
  swe: 'Švedska',
  swz: 'Swaziland',
  sxm: 'Sveti Martin (niz.dio)',
  syc: 'Sejšeli',
  syr: 'Sirija',
  tca: 'Ostrva Turks i Caicos',
  tcd: 'Čad',
  tgo: 'Togo',
  tha: 'Tajland',
  tjk: 'Tadžikistan',
  tkl: 'Tokelau',
  tkm: 'Turkmenistan',
  tls: 'Istočni Timor',
  ton: 'Tonga',
  tto: 'Trinidad i Tobago',
  tun: 'Tunis',
  tur: 'Turska',
  tuv: 'Tuvalu',
  twn: 'Tajvan',
  tza: 'Tanzanija',
  uga: 'Uganda',
  ukr: 'Ukrajina',
  ury: 'Urugvaj',
  usa: 'Sjedinjenje Američke Države',
  uzb: 'Uzbekistan',
  vat: 'Vatikan',
  vct: 'Sveti Vincent i Grenadini',
  ven: 'Venezuela',
  vgb: 'Britanska Devičanska Ostrva',
  vir: 'Američka Devičanska Ostrva',
  vnm: 'Vietnam',
  vut: 'Vanuatu',
  wlf: 'Wallis i Futuna',
  wsm: 'Samoa',
  yem: 'Jemen',
  zaf: 'Južna Afrika',
  zmb: 'Zambija',
  zwe: 'Zimbabve',

  //industryTypes

  banking: 'Bankarstvo',
  construction: 'Građevina',
  consulting: 'Konzalting',
  delivery: 'Dostava',
  education: 'Edukacija',
  entertainment: 'Zabava',
  finance: 'Finansijski sektor',
  government: 'Državna uprava',
  it: 'Informacione tehnologije',
  manufacturing: 'Proizvodnja',
  nonprofit: 'Neprofitni sektor',
  sales: 'Prodajne djelatnosti',
  telecommunication: 'Telekomunikacije',
  other: 'Ostalo',

  //contactTypes

  'e-mail': 'Email',

  //addressTypes

  billing: 'Adresa za dostavu računa',
  shipping: 'Adresa isporuke',

  //userTypes

  employee: 'Zaposlenik',
  customer: 'Kupac',
  externalAssociate: 'Vanjski saradnik',

  //salutation

  Mr: 'g',
  Ms: 'gđa',
  Sir: 'g',
  Dr: 'dr',

  //confirm

  header: 'Potvrda',
  messageConfirm: 'Da li ste sigurni da želite da izvršite ovu radnju?',
  acceptlabel: 'Da',
  rejectlabel: 'Ne',


  //error message

  requiredError: 'Ovo polje je obavezno',
  min: 'Vrednost ne može biti manja od',
  max: 'Vrednost ne može biti veća od',
  emailError: 'Email nije validan',
  characters: 'znakova',
  minLength: 'Polje mora imati min',
  maxLength: 'Polje može imati max',
  validateOib: 'OIB nije ispravan',
  validPhone: 'Ovo polje može sadržavati samo brojeve i + znak',
  validPassword: 'Mora imati veliko slovo, znak i broj.',
  mailFormat: 'Email nije ispravan',
  salutationFormat: 'Titula može biti prazna, g, gđa...',
  shippingAddressMax: 'Ne može imati 2 adrese za isporuku roba',
  billingAddressMax: 'Ne može imati 2 adresa za dostavu računa',
  mainAddressMax: 'Ne može imati 2 glavne adrese',
  isOfAge: 'Korisnik treba biti najmanje 18 godina',
  maxCheckedItems: 'Možete odabrati max 5 stavci',
  spaceNotAllowed: 'Razmak nije dozvoljen',
  passwordNotMatch: 'Lozinke se ne podudaraju',
  notAvailableUsername: 'Korisničko ime je zazueto, predlog:',
  notAvailableEmail: 'Email je zauzet',
  startDateGreaterThenEndDate: 'Početni datum ne sme da bude veći od završnog',
  endDateLesserThenStartDate: 'Završni datum ne sme da bude manji od početnog',
  timeRegExp: 'Dozvoljeni su samo brojevi, tačka i dvotočka',
  numberFirst: 'Prvi znak mora biti broj',
  maxTagLength: 'Tag ne može biti duži od',
  passingPointsNegative: 'Bodovi za prolaz ne mogu da budu negativni',
  passingPointsMoreThanTotal: 'Bodovi za prolaz ne mogu da budu preko ukupnog broja bodova',
  atLeastOneQuestion: 'Ispit mora imati barem jedno pitanje',
  atLeastOneCorrectAnswer: 'Pitanje mora imati barem jedan točan odgovor',
  atLeastTwoAnswers: 'Pitanje mora imati barem dva ponuđena odgovora',
  sameAnswerText: 'Dva odgovora ne mogu imati jednaki tekst',
  atLeastOneAnswer: 'Pitanje mora imati barem jednu odgovor',
  numAnswerGaps: 'Broj odgovora mora odgovarati broju praznina u tekstu pitanja',
  atLeastTwoPairs: 'Pitanje mora imati barem dva para odgovora',
  minNotBiggerThenMaxStock: 'Minimalna zaliha ne može biti manja od maksimalne',
  usernameSpecialChars: 'Korisničko ime može sadržavati samo slova, brojeve - i _',
  startEvent: 'Početak događaja',
  endEvent: 'Kraj događaja',
  endRecurring: 'Kraj ponavljanja događaja',
  cantBeGreaterThen: 'ne može biti veći od',
  cantBeValue: 'Ne može da bude',
  atLeastOneRequired: 'Barem jedno od navedenih polja je obavezno',
  notInPastDate: 'Ne sme da bude u prijašnjem datumu',
  invalidSlug: 'Neispravan slug',
  slugTaken: 'Slug već postoji',
  isUrl: 'Unost mora bit valjan URL',
  mustBePositiveInteger: 'Unos mora biti pozitivan cijeli broj',
  isSlug: 'Unos mora biti valjan slug',

  //monthNumbers

  1: 'Januar',
  2: 'Februar',
  3: 'Mart',
  4: 'April',
  5: 'Maj',
  6: 'Jun',
  7: 'Jul',
  8: 'Avgust',
  9: 'Septembar',
  10: 'Oktobar',
  11: 'Novembar',
  12: 'Decembar',

  //datePicker

  firstDayOfWeek: 1,
  dayNames: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
  dayNamesShort: ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'],
  dayNamesMin: ['Ne', 'Po', 'Ut', 'Sri', 'Če', 'Pt', 'Su'],
  monthNames: ['januar', 'februar', 'mart', 'april', 'maj', 'jun', 'jul', 'avgust', 'septembar', 'oktobar', 'novembar', 'decembar'],
  monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'avg', 'sep', 'okt', 'nov', 'dec'],

  //koji nisu imali prijevod

  courseDiscussions: 'Tečajne diskusije',
  totalDiscount: 'Ukupni popust',
  discountAmount: 'Iznos popusta',
  taxAmount: 'Iznos poreza',
  finalNetPrice: 'Konačna neto cijena',
  amountApsolute: 'Aps. iznos',
  amountPercentage: 'Post. iznos',
  gmail: 'Gmail',
  documentDiscount: 'Popust',
  documentDiscountAmount: 'Dok. iznos popusta',
  content: 'Sadržaj',
  intRefNumber: 'Int. ref. broj',
  intRefNumberId: 'Int. ref. broj ID',
  intRefNumberDate: 'Int. ref. broj datuma',
  outRefNumberId: 'Out. ref. broj ID',
  outRefNumberDate: 'Out. ref. broj datuma',
  lnkRefNumber: 'Vez. ref. broj',
  lnkRefNumberId: 'Vez. ref. broj ID',
  lnkRefNumberDate: 'Vez. ref broj datuma',
  deliveryDetails: 'Detalji o dostavi',
  deliveryCompany: 'Kompanija za isporuku',
  deliveryPerson: 'Dostavljač',
  deliveryVehicleReg: 'Registracija vozila',
  externalReceivedBy: 'Prima',
  shippingMethod: 'Način kupovine',
  transportCost: 'Troškovi transporta',
  paymentTerm: 'Uslovi plaćanja',
  invoiceType: 'Tip fakture',
  device: 'Uređaj',
  internalNote: 'Interna napomena',
  authorize: 'Ovlastiti',
  authorizeDate: 'Odredite datum',
  cancelled: 'Otkazano',
  cancelledRelation: 'Otkazan odnos',
  isLegacy: 'Nasljedstvo',
  createdByType: 'Izrađeno prema tipu',
  documentTax: 'Dokument tax',
  supplierType: 'Tip dobavljača',
  phase: 'Faza',
  pdfTemplate: 'PDF šablon',
  itemNote: 'Napomena',
  deliveryDate: 'Datum isporuke',
  itemSku: 'SKU',
  taxClassValue: 'Vrednost klase poreza',
  originDocument: 'Izvorni dokument',
  originDocumentItem: 'Stavka prethodnog dokumenta',
  shippingCost: 'Trošak dostave',
  shippingTax: 'Porez na dostavu',
  invalidFile: 'Neispravan fajl',
  filter: 'Filter',
  selected: 'Izabrano',
  get: 'Dohvati',
  logs: 'Logovi',
  leads: 'Vodstva',
  opportunities: 'Mogućnosti',
  rating: 'Ocena',
  sourceType: 'Izvorni tip',
  poi: 'POI',
  noOfFamilyMembers: 'Broj članova porodice',
  noOfEmployees: 'Broj zaposlenih',
  nationality: 'Nacionalnost',
  newLead: 'Novo vodstvo',
  editLead: 'Uredi vodstvo',
  manageRatings: 'Upravljanje ocenama',
  ratingName: 'Naziv ocene',
  cmsSite: 'CMS stranica',
  externalWebSite: 'Vanjski web site',
  partner: 'Partner',
  employeeReferral: 'Referal zaposlenik',
  externalReferral: 'Vanjski referal',
  wordOfMouth: 'Usmena predaja',
  callIncome: 'Prihod od poziva',
  annualBudget: 'Godišnji budžet',
  uidNumber: 'UDI broj',
  contactPerson: 'Kontakt osoba',
  sourceTypes: 'Vrste Izvora',
  editSourceType: 'Uredi vrstu izvora',
  createSourceType: 'Kreirajte izvorni tip',
  contactPersonRole: 'Uloga kontakt osobe',
  manageContactPersonRoles: 'Upravljajte ulogama kontakt osoba',
  previousOwners: 'Prethodni vlasnici',
  event: 'Događaj',
  call: 'Poziv',
  meeting: 'Sastanak',
  phoneNumber: 'Tel. broj',
  newContact: 'Novi kontakt',
  newAddress: 'Nova adresa',
  contactType: 'Tip kontakta',
  addEvent: 'Dodaj događaj',
  addTask: 'Dodaj zadatak',
  newComment: 'Novi komentar',
  sendMail: 'Pošalji e-mail',
  scheduledActivity: 'Zakazana aktivnost',
  pastActivity: 'Past activity',
  pageTitle: 'Naslov',
  author: 'Autor',
  readability: 'Čitljivost',
  slug: 'Slug',
  termTitle: 'Naslov',
  newconsent: 'Novi pristanak',
  editconsent: 'Uredi pristanak',
  consentlogs: 'Consent logs',
  newagreement: 'Novi ugovor',
  editagreement: 'Uređivanje ugovora',
  newconsentlog: 'New consent log',
  editconsentlog: 'Edit consent log',
  consentLog: 'Consent log',
  editlink: 'Uredi link',
  leadmanagement: 'Upravljanje vodstvom',
  newlead: 'Novo vodstvo',
  editlead: 'Uredi vodstvo',
  useranonymised: 'Korisnik je anoniman',
  useridentified: 'Korisnik je identifikovan',
  listsettings: 'Lista postavki',
  profileName: 'Ime profila',
  selectProfile: 'Izaberite profil',
  accountSettings: 'Podešavanja naloga',
  incomingSettings: 'Ulazne postavke',
  outgoingSettings: 'Odlazne postavke',
  emailAccountType: 'Tip naloga e-pošte\n',
  protocol: 'Protokol',
  hostname: 'Hostname',
  port: 'Port',
  encryption: 'Šifrovan',
  authentication: 'Autentikacija',
  normalPassword: 'Normalna lozinka',
  encryptedPassword: 'Šifrovana lozinka',
  kerberosGSSAPI: 'Kerberos / GSSAPI',
  ntlm: 'NTLM',
  oAuth: 'OAuth',
  estimationLogCreate: 'Log procena',
  estimationLogEdit: 'Izmeni procenu',
  logCreated: 'Log Kreiran',
  importProject: 'Import project',
  noTasksSelected: 'Nije odabran nijedan zadatak',
  noDiscussionsSelected: 'Nema odabranih diskusija',
  nothingSelected: 'Ništa nije izabrano',
  internalProject: 'Interni projekat',
  projectImported: 'Projekat importan',
  projectFilters: 'Projektni filtri',
  clientType: 'Odaberite klijenta',
  selectClient: 'Select client',
  projectLabel: 'Oznaka projekta',
  projectCategory: 'Kategorija projekta',
  projectName: 'Naziv projekta',
  taskFilters: 'Vrsta zadatka',
  taskType: 'Task type',
  taskLabel: 'Oznaka zadatka',
  logFilters: 'Filtri zapisnika',
  logType: 'Vrsta zapisnika',
  logStatus: 'Status zapisnika',
  loggedDate: 'Datum zapisivanja',
  deliverynotes: 'Dostavnice',
  recievedqty: 'Primljen kti',
  publishPageSure: 'Da li ste sigurni da želite da objavite ovu stranicu?',
  unpublishPageSure: 'Jeste li sigurni da želite da poništite objavljivanje ove stranice?',
  archiveTagSure: 'Jeste li sigurni da želite izbrisati ovu oznaku?',
  publishPostSure: 'Jeste li sigurni da želite objaviti ovaj post?',
  unpublishPostSure: 'Jeste li sigurni da želite da poništite objavu ovog posta?',
  navigationUpdated: 'Navigacija je ažurirana',
  sourcetype: 'Izvorni tip',

  //tableHeaders
  detailsDisplayName: 'Naziv',
  contactsPhone: 'Telefon',
  contactsMobile: 'Mobitel',
  companyName: 'Naziv kompanije',
  detailsFirstName: 'Ime',
  detailsLastName: 'Prezime',
  detailsGender: 'Spol',
  detailsSalutation: 'Pozdrav',
  detailsVatNumber: 'Porezni broj',
  detailsPersonalIdNumber: 'OIB',
  detailsBirthDate: 'Datum rođenja',
  detailsPlaceOfBirth: 'Mjesto rođenja',
  detailsNotes: 'Bilješke',
  addressesMain: 'Glavna adresa',
  addressesShipping: 'Adresa isporuke',
  contactsEmail: 'Email',
  addressesBilling: 'Adresa za dostavu računa',
  contactsFax: 'Fax',
  contactsSkype: 'Skype',
  detailsCompanyIdNumber: 'ID broj tvrtke',
  detailsLogo: '',
  detailsGrandTotal: 'Ukupno',
  contactsValue: 'Kontakt',
  supervisor_thumbnail: '',
  mainAgreement: 'Osnovni sporazum',
  statusSourceIdentifier: 'Status source identifier',
  published_at: 'Datum objave',
  created_at: 'Datum kreiranja',
  background: 'Pozadina',
  navType: 'Tip',
  language_code: 'Jezik',
  use_archive_bar: 'Koristit arhivu',
  email_type_option: 'Vrsta email-a',
  double_optin: 'Opcija',
  marketing_option: 'Marketing opcija',

  // table settings header titles
  tableSettingsDetailsProfileImage: 'Profilna slika',
  tableSettingsDetailsLogo: 'Logo',
  tableSettingsUserImage: 'Korisnička fotografija',
  tableSettingsIcon: 'Ikona',
  tableSettingsLevel: 'Stupanj',
  tableSettingsSupervisor_thumbnail: 'Supervizorova slika',
  tableSettingsCreatedBy_thumbnail: 'Korisnikova slika',
  ganttTrans: {
    date: {
      month_full: ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"],
      month_short: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep",
        "Oct", "Nov", "Dec"],
      day_full: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday",
        "Saturday"],
      day_short: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    },
    labels: {
      new_task: "New task",
      icon_save: "Save",
      icon_cancel: "Cancel",
      icon_details: "Details",
      icon_edit: "Edit",
      icon_delete: "Delete",
      confirm_closing: "",//Your changes will be lost, are you sure ?
      confirm_deleting: "Task will be deleted permanently, are you sure?",

      section_description: "Description",
      section_time: "Time period",

      /* link confirmation */

      confirm_link_deleting: "Dependency will be deleted permanently, are you sure?",
      link_from: "From",
      link_to: "To",
      link_start: "Start",
      link_end: "End",

      minutes: "Minutes",
      hours: "Hours",
      days: "Days",
      weeks: "Week",
      months: "Months",
      years: "Years"
    }
  },
  profileImage: 'Profilna slika',
  subscribed: 'Pretplaćen',
  unsubscribed: 'Otkazana pretplata',
  cleaned: 'Neisporučivo',
  pending: 'Na čekanju',
  transactional: 'Transakcijski'
};
