import {environment} from '../../../../environments/environment';

export const ECommerceRoutes = {
  default: {
    payment: {
      get: {
        method: 'Get',
        url:`${environment.baseUrl}/api/${environment.version}/ecommerce/payment/getAllPaymentMethods/default/`
      },
      update: {
        method: 'Patch',
        url:`${environment.baseUrl}/api/${environment.version}/ecommerce/payment/save/default`
      }
    },
    delivery: {
      get: {
        method: 'Get',
        url:`${environment.baseUrl}/api/${environment.version}/ecommerce/delivery/getAllDeliveryMethods/default/`
      },
      update: {
        method: 'Patch',
        url:`${environment.baseUrl}/api/${environment.version}/ecommerce/delivery/save/default`
      }
    },
    generalSettings: {
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/ecommerce/settings/getGeneralSettings/default/`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/ecommerce/settings/saveGeneralSettings/default` //id
      }
    }
  },
  getMyDomains: {
    method: 'Get',
    url:`${environment.baseUrl}/api/${environment.version}/ecommerce/domain/getMyDomains`
  },
  getMyStores: {
    method: 'Get', //TODO trebat će post ako će biti filter
    url:`${environment.baseUrl}/api/${environment.version}/ecommerce/domain/getMyStores`
  },
  site: {
    // filter: {
    //   method: 'Get', //TODO trebat će post ako će biti filter
    //   url:`${environment.baseUrl}/api/${environment.version}/ecommerce/domain/getMyDomains`
    // },
    filter: {
      method: 'Get',
      url:`${environment.baseUrl}/api/${environment.version}/ecommerce/domain/getMyWebsites`
    },
    store:{
      method:'Post',
      url: `${environment.baseUrl}/api/${environment.version}/ecommerce/domain/addWebsite`
    },
    update: {
      method: 'Patch',
      url:`${environment.baseUrl}/api/${environment.version}/ecommerce/domain/editWebsite` //:id
    },
    archive:{
      method:'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/ecommerce/site/archive`
    },
    restore: {
      method:'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/ecommerce/site/restore` // /:id
    },
    payment: {
      get: {
        method: 'Get',
        url:`${environment.baseUrl}/api/${environment.version}/ecommerce/payment/getAllPaymentMethods/website/`
      },
      update: {
        method: 'Patch',
        url:`${environment.baseUrl}/api/${environment.version}/ecommerce/payment/save/website`
      }
    },
    delivery: {
      get: {
        method: 'Get',
        url:`${environment.baseUrl}/api/${environment.version}/ecommerce/delivery/getAllDeliveryMethods/website/`
      },
      update: {
        method: 'Patch',
        url:`${environment.baseUrl}/api/${environment.version}/ecommerce/delivery/save/website`
      }
    },
    generalSettings: {
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/ecommerce/settings/getGeneralSettings/website/`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/ecommerce/settings/saveGeneralSettings/website` //id
      }
    }
  },
  store: {
    get:{
      method:'Get',
      url: `${environment.baseUrl}/api/${environment.version}/ecommerce/domain/getStore` // /:id
    },
    filter:{
      method:'Post',
      url: `${environment.baseUrl}/api/${environment.version}/ecommerce/store/filter`
    },
    store:{
      method:'Post',
      url: `${environment.baseUrl}/api/${environment.version}/ecommerce/domain/addStore`
    },
    update: {
      method: 'Patch',
      url:`${environment.baseUrl}/api/${environment.version}/ecommerce/domain/editStore` //:id
    },
    archive:{
      method:'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/ecommerce/store/archive`
    },
    restore: {
      method:'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/ecommerce/store/restore` // /:id
    },
    payment: {
      get: {
        method: 'Get',
        url:`${environment.baseUrl}/api/${environment.version}/ecommerce/payment/getAllPaymentMethods/store/`
      },
      update: {
        method: 'Patch',
        url:`${environment.baseUrl}/api/${environment.version}/ecommerce/payment/save/store`
      }
    },
    delivery: {
      get: {
        method: 'Get',
        url:`${environment.baseUrl}/api/${environment.version}/ecommerce/delivery/getAllDeliveryMethods/store/`
      },
      update: {
        method: 'Patch',
        url:`${environment.baseUrl}/api/${environment.version}/ecommerce/delivery/save/store`
      }
    },
    generalSettings: {
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/ecommerce/settings/getGeneralSettings/store/`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/ecommerce/settings/saveGeneralSettings/store` //id
      }
    },
  },
  paymentMethods: {
    get: {
      method: 'Get',
      url:`${environment.baseUrl}/api/${environment.version}/ecommerce/payment/getAllPaymentMethods`
    }
  },
  domain: {
    get:{
      method:'Get',
      url: `${environment.baseUrl}/api/${environment.version}/settings/domain/get` // /:id
    },
    filter:{
      method:'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/domain/filter`
    },
    store:{
      method:'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/domain/create`
    },
    update: {
      method: 'Patch',
      url:`${environment.baseUrl}/api/${environment.version}/settings/domain/update` //:id
    },
    archive:{
      method:'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/settings/domain/archive`
    },
    restore: {
      method:'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/settings/domain/restore` // /:id
    },
  },

};
