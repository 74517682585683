import {NgModule} from '@angular/core';
import {OutsideClickDirective} from './outside-click.directive';

@NgModule({
  imports: [],
  providers: [],
  declarations: [
    OutsideClickDirective
  ],
  exports: [
    OutsideClickDirective
  ]
})
export class OutsideClickModule {
}
