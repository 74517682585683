import {Component, HostListener} from '@angular/core';
import {GlobalService} from './services/global.service';
import regeneratorRuntime from "regenerator-runtime";
import {ClickedElementService} from './services/clicked-element.service';

//This is some serious black magic shit lol
const a = regeneratorRuntime;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public _global: GlobalService,
              private _clickedElement: ClickedElementService) {}

  @HostListener('click', ['$event.target'])
  appClick(target: HTMLElement): void {
    this._clickedElement.element.next(target);
  }

}
