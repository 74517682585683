import {environment} from '../../../../environments/environment';

export const eLearningRoutes = {
  commons: {
    course: {
      get: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/common/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/common/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/common/update`
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/common/delete`
      }
    },
  },
  course: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/get` //:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/delete`
    },
    participants: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/participants/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/participants/manage`
      },
    },
    moderators: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/moderators/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/moderators/manage`
      },
    }
  },
  class: {
    commons: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/class/common/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/class/common/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/class/common/update`
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/class/common/delete`
      }
    },
    moderator: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/class/moderators/filter`
      }
    },
    participant: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/class/participants/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/class/participants/manage`
      }
    },
    comment: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/class/comment/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/class/comment/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/class/comment/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/class/comment/update`
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/class/comment/delete`
      },
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/class/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/class/get` //:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/class/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/class/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/class/delete`
    },
  },
  discussion: {
    commons: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/label/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/label/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/label/delete`
        }
      },
      category: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/category/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/category/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/category/delete`
        }
      }
    },
    participant: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/subscribers/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/subscribers/manage`
      }
    },
    moderator: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/moderators/filter`
      }
    },
    comment: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/comment/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/comment/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/comment/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/comment/update`
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/comment/delete`
      },
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/get` //:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/discussion/delete`
    },
  },
  classroom: {
    commons: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/class/classroom/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/class/classroom/label/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/class/classroom/label/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/class/classroom/label/delete`
        }
      },
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/class/classroom/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/class/classroom/get` //:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/class/classroom/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/class/classroom/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/class/classroom/delete`
    },
  },
  exam: {
    commons: {
      category: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/category/filter`
        },
        get: {
          method: 'Get',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/category/get` // /:id
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/category/update` // /:id
        },
        delete: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/category/delete` // /:id
        },
      },
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/label/filter`
        },
        get: {
          method: 'Get',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/label/get` // /:id
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/label/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/label/update` // /:id
        },
        delete: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/label/delete` // /:id
        },
      }
    },
    comment: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/exam/comment/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/exam/comment/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/exam/comment/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/exam/comment/update`
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/exam/comment/delete`
      },
    },
    candidates: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/participants/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/participants/manage`
      },
    },
    answers: {
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/answers/get` // /:id
      },
      update: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/results/update` // /:id
      }
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/filter`
    },
    details: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/details` // /:id
    },
    results: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/statistics` // /:id
    },
    resultsFilter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/results/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/get` // /:id
    },
    take: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/take` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/create`
    },
    submit: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/submit`
    },
    grade: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/check`
    },
    reset: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/reset`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/elearning/course/exam/delete` // /:id
    },
  }
}
