import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {Observable} from 'rxjs';
import {urlValues} from '../../../../configs/url.values';

@Injectable({
  providedIn: 'root'
})
export class EvnasUserService {

  constructor(
    private _api: ApiService
  ) {
  }

  public createEvnasUser(formData: any): Observable<any> {
    const {method, url} = urlValues.evnas.humanResources.user.create;
    return new Observable<any>(obs => {
      this._api.send(method, url, formData).subscribe(res => {
        obs.next(res['data']);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  public createHrConfig(formData: any): Observable<any> {
    const {method, url} = urlValues.evnas.humanResources.user.createHrConfig;
    return new Observable<any>(obs => {
      this._api.send(method, url, formData).subscribe((res: any) => {
        obs.next(res.data);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  public getEvnasUser(id: number): Observable<any> {
    const {method, url} = urlValues.evnas.humanResources.user.get(id);
    return new Observable<any>(obs => {
      this._api.send(method, url).subscribe(res => {
        obs.next(res['data']);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }
}
