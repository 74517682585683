import { urlValues } from './../../../configs/url.values';
import {environment} from '../../../../environments/environment';

export const AdminRoutes = {
  general: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/settings/general/apiKey`
    },
    save: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/general/save/apiKey`,
    },
  },
  attributes: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attribute/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attribute/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attribute/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attribute/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attribute/delete`
    }
  },
  attributeSet: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attributeSet/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attributeSet/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attributeSet/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attributeSet/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/settings/attributeSet/delete`
    }
  },
  notifications: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/settings/notifications/user`
    },
    save: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/notifications/save`
    }
  },
  shippingMethods: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/settings/shippingMethod/get` // /:id
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/shippingMethod/filter`
    },
    save: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/shippingMethod/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/settings/shippingMethod/update`
    },
    restore: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/settings/shippingMethod/restore`
    },
    archive: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/settings/shippingMethod/archive`
    }
  },
  taxes: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/settings/tax/get` // /:id
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/tax/filter`
    },
    save: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/tax/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/settings/tax/update` // /:id
    },
    restore: {
      method:'Patch',
      url:`${environment.baseUrl}/api/${environment.version}/settings/tax/restore` //:id
    },
    archive: {
      method:'Delete',
      url:`${environment.baseUrl}/api/${environment.version}/settings/tax/archive` //:id
    }
  },
  emailAccount: {
    user: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/settings/emailAccount/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/settings/emailAccount/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/settings/emailAccount/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/settings/emailAccount/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/settings/emailAccount/delete` // /:id
      }
    },
    company: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/settings/emailAccount/company/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/settings/emailAccount/company/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/settings/emailAccount/company/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/settings/emailAccount/company/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/settings/emailAccount/company/delete` // /:id
      }
    }
  },
  notificationTemplates:{
    getDefaults:{
      method:'Get',
      url:`${environment.baseUrl}/api/${environment.version}/notification/default`//:subject(i.e. 'documents','projects' ...etc )
    },
    get:{
      method:'Get',
      url:`${environment.baseUrl}/api/${environment.version}/notification/template/get`//:id
    },
    create:{
      method:'Post',
      url:`${environment.baseUrl}/api/${environment.version}/notification/template/create`//:id
    },
    update:{
      method:'Patch',
      url:`${environment.baseUrl}/api/${environment.version}/notification/template/update`//:id
    },
    delete:{
      method:'Delete',
      url:`${environment.baseUrl}/api/${environment.version}/notification/template/delete`//:id
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/notification/template/filter`
    },
  }
};
