import {environment} from '../../../../environments/environment';

export const ProjectRoutes = {
  common: {
    label: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/label/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/label/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/projects/label/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/projects/label/delete` // /:id
      },
    },
    category: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/category/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/category/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/projects/category/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/projects/category/delete` // /:id
      },
    }
  },
  participant: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/participants/filter`
    },
    manage: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/participants/manage`
    },
    hasTaskAssigned: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/assignees/all`
    },
    multiManage: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/participants/multi/manage`
    }
  },
  moderator: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/moderators/filter`
    },
    manage: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/moderators/manage`
    },
  },
  task: {
    common: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/projects/task/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/projects/task/label/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/projects/task/label/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/projects/task/label/delete` // /:id
        },
      },
      category: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/projects/task/category/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/projects/task/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/projects/task/category/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/projects/task/category/delete` // /:id
        },
      }
    },
    subscriber: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/task/assignees/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/task/assignees/manage`
      }
    },
    observer: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/task/observers/filter`
      }
    },
    estimation: {
      get: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/task/log/total`
      }
    },
    moderator: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/task/moderators/filter`
      }
    },
    comment: {
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/projects/task/comment/get`
      },
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/task/comment/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/task/comment/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/projects/task/comment/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/projects/task/comment/delete` // /:id
      }
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/get`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/filter`
    },
    kanban: {
      get: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/task/kanban`
      },
      update: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/task/kanban/update`
      }
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/delete` // /:id
    },
    archive: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/archive` // /:id
    },
    restore: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/restore` // /:id
    },
    findEstimationUnit: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/checkEstimationUnits` // /:projectID
    }
  },
  subTask: {
    common: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/projects/subtask/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/projects/subtask/label/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/projects/subtask/label/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/projects/subtask/label/delete` // /:id
        },
      },
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/projects/subtask/get`
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/subtask/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/subtask/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/projects/subtask/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/projects/subtask/delete` // /:id
    }
  },
  milestone: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/milestone/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/projects/milestone/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/milestone/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/projects/milestone/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/projects/milestone/delete` // /:id
    }
  },
  estimation: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/estimation/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/projects/estimation/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/estimation/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/projects/estimation/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/projects/estimation/delete` // /:id
    }
  },
  discussion: {
    common: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/label/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/label/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/label/delete` // /:id
        },
      },
      category: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/category/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/category/update` // /:id
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/category/delete` // /:id
        },
      }
    },
    moderator: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/moderators/filter`
      }
    },
    participant: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/subscribers/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/subscribers/manage`
      }
    },
    comment: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/comment/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/comment/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/comment/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/comment/update`
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/comment/delete`
      },
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/get` // /:id
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/delete` // /:id
    },
    archive: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/archive` // /:id
    },
    restore: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/projects/discussion/restore` // /:id
    },
  },
  timeAndExpenses: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/log/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/log/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/log/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/log/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/log/delete` // /:id
    },
    getUsersWithLog: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/log/getUsers`
    },
    getLoggedDays: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/projects/task/log/getDates`
    }
  },
  get: {
    method: 'Get',
    url: `${environment.baseUrl}/api/${environment.version}/projects/get`
  },
  filter: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/projects/filter`
  },
  create: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/projects/create`
  },
  update: {
    method: 'Patch',
    url: `${environment.baseUrl}/api/${environment.version}/projects/update` // /:id
  },
  delete: {
    method: 'Delete',
    url: `${environment.baseUrl}/api/${environment.version}/projects/delete` // /:id
  },
  importProject: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/projects/template/createFrom`
  }
};
