import {environment} from '../../environments/environment';

export const FileUploadRoutes =  {
  upload: `${environment.baseUrl}/${environment.version}/media/upload`,
  uploadv2: `${environment.baseUrl}/v2/media/upload`,
  get: {
    method: 'Get',
    url: `${environment.baseUrl}/${environment.version}/media/get` // /:id
  },
  getAll: {
    method: 'Post',
    url: `${environment.baseUrl}/${environment.version}/media/userFile/getByHashes`
  },
  delete: {
    method: 'Delete',
    url: `${environment.baseUrl}/${environment.version}/media/delete` // /:id
  },
  export: {
    method: 'Post',
    url: `${environment.baseUrl}/${environment.version}/export/data`
  }
};
