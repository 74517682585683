import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';

@Injectable()
export class ThemeService {

  public colorScheme: string = 'defaultTheme';
  public defaultSchemes: Array<string> = ['defaultTheme', 'customTheme', 'businessTheme'];


  constructor() {
    this.onThemeChange(this.colorScheme ? this.colorScheme : 'defaultTheme');
  }

  onThemeChange(param: string): void {
    this.colorScheme = param;
  }
}


