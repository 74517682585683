import {environment} from '../../../../environments/environment';

export const ChatRoutes = {
  chat: {
    getChatMeta: {
      method: 'get',
      url: `${environment.baseUrl}/api/${environment.version}/chat/chatMeta/all`
    },
    showUserChats: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/chat/chats/filter`
    },
    showChatMessages: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/chat/messages`
    },
    filter: {
      url: `${environment.baseUrl}/api/${environment.version}/user/filter`,
      method: 'Post'
    },
    get: {
      url: `${environment.baseUrl}/api/${environment.version}/user/get`,
      method: 'Get'
    },
    findOrCreateChat: {
      url: `${environment.baseUrl}/api/${environment.version}/chat/chats/findOrCreate`,
      method: 'Post'
    }
  }
};

