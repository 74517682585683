import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorMsgComponent} from './error-msg.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
  ],
  declarations: [
    ErrorMsgComponent
  ],
  exports: [
    ErrorMsgComponent
  ]
})
export class ErrorMsgModule {
}
