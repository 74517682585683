import {Category} from "../interfaces/common/categoty";
import {Label} from "../interfaces/common/label";
import {Country} from "../interfaces/common/country";

export class BaseClass {

  public static reformatCountryCode(countries: Country[]): Country[] {
    return countries.map(country => {
      return {
        id: country.id,
        name: country.name,
        code: country.code.slice(0, -1),
        flagUrl: ''
      };
    });
  }

  //Returns a random color from colors array
  public static getRandomColor(id?: number): string {
    const colors: string[] = ['#BF5783', '#1DBFBF', '#6F80C7', '#EFA02A', '#ED6C76'];
    if (id) return colors[id % colors.length]; //If id then bind color to that id
    else return colors[Math.floor(Math.random() * colors.length)]; //Return random color
  }

  //Removes all undefined params
  //Removes all params that are empty arrays
  public static clearJson(json: Object): Object {
    Object.keys(json).forEach(key => (json[key] === undefined) && delete json[key]);
    return json;
  }

  //Accepts categories from common data, and id of the category that needs to be found
  public getCategory(categories: Category[], categoryId: number | string): Category {
    if (!categoryId) return new Category();
    let category = categories.find(cat => cat.id == categoryId);
    return category ? new Category(category) : new Category();
  }

  //Accepts labels from common data, and id of the label that needs to be found
  public getLabel(labels: Label[], labelId: number | string): Label {
    if (!labelId) return new Label();
    let label = labels.find(l => l.id == labelId);
    return label ? new Label(label) : new Label();
  }


  public static findDelimiter(currencyString: string): string {
    for (let i = currencyString.length - 1; i > 0; i--) {
      if (isNaN(+currencyString[i])) {
        return currencyString[i];
      }
    }
  }

  public static formatFromCurToNum(currencyString: string): number {
    let delimiter = BaseClass.findDelimiter(currencyString);
    let splitedValue = currencyString.split(delimiter);
    splitedValue[0] = splitedValue[0].replace(/\D/g, '');
    let joinedValue = splitedValue.join('.');
    return parseFloat(joinedValue);
  }


  public static stringToBase64(string: string): string {
    return btoa(string);
  }
}
