import {environment} from '../../environments/environment';

export const FilterDataRoutes = { //List state routes
    get: {
        method: 'Get',
        url: (listName: string, parentId?: number) => `${environment.baseUrl}/api/${environment.version}/settings/listState/get?name=${listName}` + (parentId || parentId === 0 ? `&parentId=${parentId}` : '')
    },
    save: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/settings/listState/save` //By api key
    },
    delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/settings/listState/clear` //By api key
    }
};
