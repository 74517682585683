export enum FileDirectories {
  CHAT = 'chat',
  INSURANCE = 'insurances',
  COMPANY = 'company',
  GDPR = 'gdpr',
  PRODUCT = 'products',
  MARKETING = 'marketing',
  MAILBOX = 'mailbox',
  USER = 'users',
  WAREHOUSE = 'warehouses',
  DOCUMENT = 'documents',
  NOTIFICATION = 'notifications',
  LEAD = 'leads',
  ELEARNING = 'eLearning',
  PROJECT = 'projects',
  CRM = 'crm'
}
