import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityComponent } from './activity.component';
import {ScrollPanelModule, TooltipModule} from 'primeng/primeng';
import {PaginationModule} from '../pagination/pagination.module';
import {FilterModule} from '../filter/filter.module';
import {NoRecordsModule} from '../no-records/no-records.module';

@NgModule({
  imports: [
    CommonModule,
    ScrollPanelModule,
    PaginationModule,
    FilterModule,
    TooltipModule,
    NoRecordsModule
  ],
  declarations: [ActivityComponent],
  exports: [ActivityComponent]
})
export class ActivityModule { }
