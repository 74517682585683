import {Injectable} from '@angular/core';
import {Activity} from '../classes/activity';
import {FilterData} from '../classes/filter-data';
import {BehaviorSubject, Observable} from 'rxjs/index';
import {ApiService} from './api.service';
import {urlValues} from '../configs/url.values';
import {GlobalService} from './global.service';

@Injectable()
export class ActivityService {

  public activities: Activity[] = [];

  //<editor-fold desc="Filter data">
  public filterData: BehaviorSubject<any> = new BehaviorSubject(null);
  public totalPages: number = 0;
  public totalRecords: number = 0;

  //</editor-fold>

  constructor(private _api: ApiService, private _global: GlobalService) {
    this.filterData.subscribe((data: FilterData) => {
      if (!data) return;
      data.limit = 15; //Default 15 activities on page
      data.sort = {param: 'createdAt', order: 'DESC'};
      this.getActivities(data, true).subscribe();
    });
  }

  getActivities(filters: FilterData, updateCount?: boolean): Observable<Activity[]> {
    return new Observable<Activity[]>(obs => {
      this._api.send(urlValues.activity.filter.method, urlValues.activity.filter.url, filters).subscribe(res => {

        if (updateCount) {
          this.totalPages = res['data'].totalPages; //Set how many total pages are in db
          this.totalRecords = res['data'].totalRecords; //Set how many records are in db
        }

        this.activities = res['data'].records.map(record => {
          if (!record.createdBy.iconColor) record.createdBy.iconColor = this._global.getRandomColor(record.createdBy.id);
          return new Activity(record);
        });
        obs.next(this.activities);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete()
      });
    });
  }

}
