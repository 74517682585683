import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {ElectronService} from 'ngx-electron';
import {LocalizationService} from '../../../services/localization.service';
import {GlobalService} from '../../../services/global.service';

@Component({
  selector: 'app-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss']
})
export class ServiceUnavailableComponent {

  constructor(public _router: Router,
              public _locale: LocalizationService,
              public _electronService: ElectronService,
              public _global: GlobalService) {

    _global.saleError.subscribe(() => {
      this.back();
    });
  }

  back(): Promise<boolean> {
    return this._router.navigate(['/home']);
  }


}
