import { Injectable } from '@angular/core';
import {ListInfo, ListPageMemory} from '../classes/list-page-memory';
import * as _ from 'lodash';
import {SessionStorageService} from './session-storage.service';

@Injectable()
export class ListPageMemoryService {

    constructor(
        private _sessionStorage: SessionStorageService
    ) { }

    private getListPageMemoriesFromStorage(): ListPageMemory[] {
        return this._sessionStorage.get('listPageMemories') ? this._sessionStorage.get('listPageMemories') as ListPageMemory[] : [];
    }

    saveListPage(listInfo: ListInfo, page: number, keywords?: string): void {
        let listPageMemory: ListPageMemory = new ListPageMemory(listInfo, page, keywords);
        let listPageMemories: ListPageMemory[] = this.getListPageMemoriesFromStorage();
        let index: number = listPageMemories.findIndex(listPageMemory => _.isEqual(listPageMemory.listInfo, listInfo));
        if(~index) listPageMemories[index] = listPageMemory;
        else listPageMemories.push(listPageMemory);
        this._sessionStorage.set('listPageMemories', listPageMemories);
    }

    getListPage(listInfo: ListInfo): number {
        let listPageMemories: ListPageMemory[] = this.getListPageMemoriesFromStorage();
        let pageMemory: ListPageMemory = listPageMemories.find(listPageMemory => _.isEqual(listPageMemory.listInfo, listInfo));
        return pageMemory ? pageMemory.page : 1;
    }

    getListKeywords(listInfo: ListInfo): string {
        let listPageMemories: ListPageMemory[] = this.getListPageMemoriesFromStorage();
        let pageMemory: ListPageMemory = listPageMemories.find(listPageMemory => _.isEqual(listPageMemory.listInfo, listInfo));
        return pageMemory ? pageMemory.keywords : '';
    }

    clear(): void {
        this._sessionStorage.set('listPageMemories', []);
    }

}
