import {UserDetails} from '../interfaces/user-details';
import {Permission} from '../../../../interfaces/permission';
import {Occupation} from '../../../../interfaces/common/occupation';
import {UserType} from '../../../../interfaces/common/user-type';
import {ContactType} from '../../../../interfaces/common/contact-type';
import {Country} from '../../../../interfaces/common/country';
import {ModulePermission} from '../../../../interfaces/module-permission';
import {Iban} from '../../../../interfaces/iban';
import {Role} from '../../role/classes/role';
import {Categorizable} from '../../../../interfaces/categorizable';
import {Contact} from '../../../../classes/contact';
import {Address} from '../../../../classes/address';
import {BaseClass} from '../../../../classes/base-class';
import {CustomAddressField} from '../../../../components/addresses-section/addresses-section.component';
import {UploadedFile} from '../../../../interfaces/uploaded-file';

export interface UserMultiSelect {
  id: number,
  name: string,
  details?: {
    firstName?: string,
    lastName?: string
  }
}

export class User extends BaseClass implements Categorizable {
  id?: number = null;
  email?: string;
  username?: string;
  title?: string;
  tags?: string[];
  permission?: number;
  company?: number | any;
  branch?: number | any;
  department?: number;
  category?: number;
  label?: number[];
  type?: number | string;
  occupation?: any;
  workgroups?: number[];
  hasAccess?: boolean = false;
  archived?: boolean;
  details?: UserDetails;
  addresses?: Address[] = [];
  contacts?: Contact[] = [];
  iconColor?: string = '#BF5783';
  permissions?: Permission[];
  modulePermissions?: ModulePermission[];
  documentPermissions?: any[];
  results?: any;
  ibans?: Iban[];
  isAnonimysed?: boolean;
  role?: Role | number;
  order?: number; //Used in some cases when backend returns the list of users in a specific order
  attributeSet?: any = null;
  decisionMaker: boolean = false;
  productCategories: number[];
  name: string;
  initials: string;
  apiKey: number;
  chatStatus: string;
  updateDesired: string;
  institute?: string;
  oib?: string;
  address?: string;
  last_occupation_date?: string;
  externalAssociate = false;
  phone_number?: string;
  employmentPercentage?: number;
  start_of_work?: string;

  constructor(init = {}) {
    super();
    if (init['id']) this.id = init['id'];
    if (init['phone_number']) this.phone_number = init['phone_number'];
    if (init['institute']) this.institute = init['institute'];
    if (init['oib']) this.oib = init['oib'];
    if (init['address']) this.address = init['address'];
    if (init['last_occupation_date']) this.last_occupation_date = init['last_occupation_date'];
    if (init['occupation']) this.occupation = init['occupation'];
    if (!init['employment']) this.externalAssociate = !init['employment'];
    if (init['email']) this.email = init['email'] || '';
    if (init['username']) this.username = init['username'] || '';
    if (init['title']) this.title = init['title'] || '';
    if (init['tags']) this.tags = init['tags'];
    if (init['permission']) this.permission = init['permission'] || null;
    if (init['company']) this.company = init['company'];
    if (init['results']) this.results = init['results'];
    if (init['branch']) this.branch = init['branch'] || null;
    if (init['department']) this.department = init['department'] || null;
    if (init['category']) this.category = init['category'] || null;
    if (init['label']) this.label = init['label'] || null;
    if (init['type']) this.type = init['type'] || null;
    if (init['archived']) this.archived = init['archived'];
    if (init['chatStatus']) this.chatStatus = init['chatStatus'];
    if (init['updateDesired']) this.updateDesired = init['updateDesired'];
    if (init['workgroups']) this.workgroups = init['workgroups'] || null;
    if (typeof init['employmentPercentage'] !== 'undefined') this.employmentPercentage = init['employmentPercentage'];
    this.hasAccess = init['hasAccess'] || false;
    if (init['details']) {
      if (init['details']['birthDate']) init['details']['birthDate'] = new Date(init['details']['birthDate']);
      this.details = init['details'];
    }
    if (init['start_of_work']) {
      this.start_of_work = init['start_of_work'];
    }

    if (init && init['contacts'] && init['contacts'] instanceof Array && init['contacts'].length) this.contacts = init['contacts'].map(contact => new Contact(contact));
    if (init && init['addresses']) this.addresses = init['addresses'].map(address => new Address(address));
    if (init['ibans']) this.ibans = init['ibans'];
    if (init['iconColor']) this.iconColor = init['iconColor'] ? init['iconColor'] : User.getRandomColor(init['id']);
    if (init['permissions']) this.permissions = init['permissions'];
    if (init['modulePermissions']) this.modulePermissions = init['modulePermissions'];
    if (init['documentPermissions']) this.documentPermissions = init['documentPermissions'];
    if (init['isAnonimysed']) this.isAnonimysed = init['isAnonimysed'];
    if (init['order']) this.order = init['order'];
    if (init['role']) this.role = new Role(init['role']);
    if (init && init['decisionMaker']) this.decisionMaker = init['decisionMaker'];
    if (init && init['productCategories']) this.productCategories = init['productCategories'];
    if (init && init['apiKey']) this.apiKey = init['apiKey'];

    //Check if backend has returned notSet then transform it to empty string
    if (init['details'] && init['details'].salutation == 'notSet') this.details.salutation = '';

    if (init['attributeSet']) { //Check if attributeSet is set, and format object for backend
      this.attributeSet = {
        id: init['attributeSet'] && init['attributeSet']['id'] ? init['attributeSet']['id'] : init['attributeSet'],
      };

      if (init['attributes']) {
        this.attributeSet['attributes'] = init['attributes'] && init['attributes'].length ? init['attributes'] : null;
      } else {
        this.attributeSet['attributes'] = init['attributeSet'] && init['attributeSet'].attributes ? init['attributeSet'].attributes : null;
      }
    }


    //DODO JEL MI SE NEDA DEBUGIRAT BOGA I ISUSA JEL SE RADI NA STO ISUSOVIH NACINA
    this.name = this.getFullName();
    this.initials = this.getInitials();
  };

  toJson() { //Return JSON object (always use this function before sending to backend)
    let data = {};
    if (this.id) data['id'] = this.id;
    if (this.email) data['email'] = this.email;
    if (this.username) data['username'] = this.username;
    if (this.title) data['title'] = this.title;
    if (this.tags) data['tags'] = this.tags;
    if (this.permission) data['permission'] = this.permission;
    if (this.category) data['category'] = this.category;
    if (this.label) data['label'] = this.label;
    if (this.archived) data['archived'] = this.archived;
    if (this.type) data['type'] = this.type;
    if (this.chatStatus) data['chatStatus'] = this.chatStatus;
    if (this.updateDesired) data['updateDesired'] = this.updateDesired;
    if (this.occupation) data['occupation'] = this.occupation;
    if (this.workgroups) data['workgroups'] = this.workgroups;
    data['hasAccess'] = this.hasAccess;
    if (this.details) data['details'] = this.details;
    if (this.contacts) data['contacts'] = this.contacts;
    if (this.addresses) data['addresses'] = this.addresses;
    if (this.ibans) data['ibans'] = this.ibans;
    if (this.hasAccess) data['hasAccess'] = this.hasAccess;
    if (this.decisionMaker) data['decisionMaker'] = this.decisionMaker;
    if (this.productCategories) data['productCategories'] = this.productCategories;

    //If Birth date is datetime object , convert it to timestamp
    if (this.details && this.details.birthDate && this.details.birthDate instanceof Date) {
      data['details'].birthDate = this.details.birthDate.getTime();
    }
    //check for branch
    data['branch'] = (this.branch && (this.branch.hasOwnProperty('id'))) ? this.branch['id'] :
      this.branch ? this.branch : null;
    //check for department
    data['department'] = (this.department && (this.department.hasOwnProperty('id'))) ? this.department['id'] :
      this.department ? this.department : null;
    //Check for company
    data['company'] = (this.company && this.company.hasOwnProperty('id')) ? this.company['id'] :
      this.company ? this.company : null;
    // #ZAGIQUICKFIX
    data['details']['profileImage'] =
      data['details'] && data['details']['profileImage'] && typeof data['details']['profileImage'] === 'object' ?
        data['details'].profileImage.media : null;

    if (this.attributeSet) { //Format attribute set according to backend
      data['attributeSet'] = {
        id: this.attributeSet.id,
        attributes: this.attributeSet.attributes && this.attributeSet.attributes.length ? this.attributeSet.attributes : []
      };
    } else data['attributeSet'] = null;

    return data;
  }

  getInitials(): string {
    return `${this.details && this.details['lastName'] ? this.details['lastName'][0] : ''}${this.details && this.details['firstName'] ? this.details['firstName'][0] : ''}`;
  }

  getFullName(): string {
    return `${this.details && this.details['lastName'] ? this.details['lastName'] : ''} ${this.details && this.details['firstName'] ? this.details['firstName'] : ''}`;
  }

  getAddress(countries: Country[], translations: any) {
    if (!this.addresses || this.addresses.length < 1) return '-'; //If no addresses at all return -
    const address = this.addresses.find(address => address.type == 'main'); //find main address

    if (!address) return '-'; //If did not found address with type main return -

    const country = countries.find(country => country.id == address.country); //find the country

    let street = address.street ? `${address.street}` : '';
    let houseNumber = address.houseNumber ? `${address.houseNumber}` : '';
    let zipCode = address.zipCode ? address.zipCode : '';
    let city = address.city ? `${address.city},` : '';
    let region = address.region ? `${address.region},` : '';

    if (street !== '' && houseNumber !== '') {
      street = `${street} ${houseNumber},`;
      houseNumber = '';
    }

    return `${street} ${houseNumber} ${street || houseNumber ? '<br />' : ''}
            ${zipCode} ${city} ${zipCode || city ? '<br />' : ''}
            ${region} ${region ? '<br />' : ''}
            ${translations[country.code.toLowerCase()]}`;
  }

  public static generateCustomAddressFields(): CustomAddressField[] {
    return [
      {
        slug: 'contactPersonFirstName',
        required: false
      },
      {
        slug: 'contactPersonLastName',
        required: false
      },
      {
        slug: 'contactPersonPhone',
        required: false
      }
    ];
  }

  public static formatUsersForSelect(users: User[], type?: string): UserMultiSelect[] {
    return users.map(user => {
      return {
        id: user.id,
        name: `${user.details && user.details.firstName ? user.details.firstName : ''} ${user.details && user.details.firstName ? user.details.lastName : ''}`, //Used for multiselect display property
        initials: `${user.details && user.details.firstName ? user.details.firstName[0] : ''} ${user.details && user.details.lastName ? user.details.lastName[0] : ''}`, //Used for multiselect display property
        type: user.type,
        details: {
          firstName: user.details && user.details.firstName ? user.details.firstName : '',
          lastName: user.details && user.details.lastName ? user.details.lastName : '',
          profileImage: user.details && user.details.profileImage ? user.details.profileImage : null,
        }
      };
    });
  }

  public static formatUserForSelect(user: User): UserMultiSelect {
    return {
      id: user.id,
      name: `${user.details.firstName} ${user.details.lastName}`, //Used for multiselect display property
      details: {
        firstName: user.details.firstName,
        lastName: user.details.lastName
      }
    };
  }

  getMobile(contacts: ContactType[]): string {
    if (!this.contacts) return '-';
    if (!contacts) return '-';

    let type = contacts.find(c => c.slug == 'mobile');
    let contact = this.contacts.find(contact => contact.type == type.id);
    return contact ? contact.value : '-';
  }

  getOccupation(occupations: Occupation[]): string {
    if (!this.occupation) return '-';
    if (!occupations) return '-';

    let find = occupations.find(occ => occ.id == this.occupation);
    return find ? find.name : '-';
  }

  getType(userTypes: UserType[]): string {
    if (!this.type) return '-';
    if (!userTypes) return '-';

    let type = userTypes.find(uType => uType.id == this.type);
    return type ? type.slug : '-';
  }

  getCompanyName(): string {
    if (!this.company) return '-';
    return this.company && this.company.name ? this.company.name : '-';
  }
}
