export const environment = {
  production: true,
  mainDomain: `evnas.gaussbox.net`,
  envName: 'evnas',
  baseUrl: `https://evnas-masterapi-master-hlsyodlnjq-ew.a.run.app`,
  port: '8000',
  version: `v1`,
  subdomain: 'Productionn',
  httpsSocketUrl: '',
  httpSocketUrl: '',
  transferUrl: 'https://evnas-transferapi-master-hlsyodlnjq-ew.a.run.app'
};