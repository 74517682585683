import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthComponent} from "./components/auth/auth.component";
import {ForgotPasswordComponent} from "./components/auth/forgot-password/forgot-password.component";
import {ResetPasswordComponent} from "./components/auth/reset-password/reset-password.component";
import {NotFoundComponent} from "./components/errors/not-found/not-found.component";
import {InternalServerErrorComponent} from "./components/errors/internal-server-error/internal-server-error.component";
import {ForbiddenComponent} from "./components/errors/forbidden/forbidden.component";
import {ServiceUnavailableComponent} from "./components/errors/service-unavailable/service-unavailable.component";

const appRoutes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
  },
  {
    path: 'auth/forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent
  },
  {
    path: 'internal-server-error',
    component: InternalServerErrorComponent
  },
  {
    path: 'service-unavailable',
    component: ServiceUnavailableComponent
  },
  {path: 'guidelines', loadChildren: './components/guidelines/guidelines.module#GuidelinesModule'},
  {path: '', loadChildren: './modules/layout/layout.module#LayoutModule'},
  {path: '**', redirectTo: 'not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
