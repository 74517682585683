import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Location, PlatformLocation} from '@angular/common';
import {ApiService} from '../../../services/api.service';
import {urlValues} from '../../../configs/url.values';
import {GlobalService} from '../../../services/global.service';
import {LocalizationService} from '../../../services/localization.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  public form: FormGroup;

  private platformLocation: string = null;

  constructor(private _fb: FormBuilder,
              public _location: Location,
              public _locale: LocalizationService,
              private _api: ApiService,
              private _global: GlobalService,
              private _platformLocation: PlatformLocation) {

    //Set platform location, for domain checking
    this.platformLocation = this._platformLocation['location'].origin;

    this.form = this._fb.group({
      username: ['', Validators.required],
      subdomain: [this.platformLocation.includes('localhost') ? 'gaussdev' : this._platformLocation['location'].host.split('.')[0]]
    });
  }


  send(formData: object): void {
    this._api.send(urlValues.auth.forgotPassword.method, urlValues.auth.forgotPassword.url, formData).subscribe(res => {
      this._global.pushAppMessage('success','Success', res['message']);
    });
  }

}
