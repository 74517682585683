import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'toHumanReadable'
})
export class ToHumanReadablePipe implements PipeTransform {

  //Split word by split param then grab the last one and capitalize first letter of each word
  //Turn camel case to words divided by space

  transform(text:string, splitBy:string): string {
    let val = text.split(splitBy);
    let newVal = val[val.length - 1].match(/[A-Za-z0-9][a-z]*/g).map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
    /* custum attribute sets have 'att_' in their names , pipe transforms this to 'Att Some Attribute', we have to check if there is a string 'Att ' and remove it
    to correct the displayed values */
    if(newVal.substring(0,4) === 'Att ') return newVal.substr(4);

    return newVal;
  }

}
