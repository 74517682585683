import {environment} from '../../../../environments/environment';

export const ReportRoutes = {
  document: {
    outgoingInvoice: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/reports/documents/invoice/outgoing`
    },
    inboundInvoice: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/reports/documents/invoice/inbound`
    },
    outgoingOrder: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/reports/documents/order/outgoing`
    },
    inboundOrder: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/reports/documents/order/inbound`
    },
    contract: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/reports/documents/contracts`
    },
  },
  project: {
    generalReport: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/reports/projects/general`
    },
    loggedTimeReport: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/reports/projects/byLoggedTime`
    },
    getAllLeaders: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/projects/getLeaders`
    },
    getPortfolioData: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/reports/projects/portfolio`
    },
    projectStatus: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/reports/projects/status`
    },
    employeeWorkload: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/reports/projects/workload`
    }
  },
  timeAndExpense: {
    get: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/reports/projects/logs`
    },
    getLogsForInvoice: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/reports/projects/getLogsForInvoice`
    }
  }
};
