import {environment} from '../../environments/environment';
import {eLearningRoutes} from '../modules/layout/e-learning/e-learning.routests';
import {AdminRoutes} from '../modules/layout/admin/admin.routes';
import {CompanyRoutes} from '../modules/layout/company/company.routes';
import {UserRoutes} from '../modules/layout/user/user.routes';
import {ProjectRoutes} from '../modules/layout/projects/project.routes';
import {WarehouseRoutes} from '../modules/layout/warehouse/warehouse.routes';
import {ReportRoutes} from '../modules/layout/reports/report.routes';
import {ProductMaterialRoutes} from '../modules/layout/products-materials/product-material.routes';
import {AuthRoutes} from '../routes/auth.routes';
import {SettingsRoutes} from '../routes/settings.routes';
import {CalendarRoutes} from '../modules/layout/calendar/calendar.routes';
import {CommonDataRoutes} from '../routes/common-data.routes';
import {DocumentRoutes} from '../modules/layout/documents/document.routes';
import {FileUploadRoutes} from '../routes/file-upload.routes';
import {ECommerceRoutes} from "../modules/layout/e-commerce/ecommerce.routes";
import {CmsRoutes} from '../modules/layout/cms/cms.routes';
import {GdprRoutes} from "../modules/layout/gdpr/gdpr-routes";
import {EmailRoutes} from '../modules/layout/mailbox/mailbox.routes';
import {RoleRoutes} from "../modules/layout/role/role.routes";
import {DriveRoutes} from "../modules/layout/drive/drive-routes";
import {MarketingRoutes} from '../modules/layout/marketing/marketing-routes';
import {LeadManagementRoutes} from "../modules/layout/lead-management/lead-management-routes";
import {InsuranceSalesRoutes} from "../modules/layout/insurance-sales/insurance-sales-routes";
import {MailChimpRoutes} from "../modules/layout/newsletter/routes/mailchimp.routes";
import {ChatRoutes} from '../modules/layout/chat/chat-routes';
import {HumanResourcesRoutes} from '../modules/layout/human-resources/human-resources.routes';
import {EvnasRoutes} from '../modules/layout/evnas/evnas.routes';
import {FilterDataRoutes} from "../routes/filter-data.routes";

export const urlValues = {

  permissionCheck: `${environment.baseUrl}/api`, //used for checking permissions in global service
  base: environment.baseUrl, //Base url
  version: environment.version,

  placeholderPath: (extension: string) => {
    return `assets/images/file-formats/${extension}.svg`;
  },

  test: {
    method: 'Post',
    url: `${environment.baseUrl}/api/v2/product/filter`
  },

  filterData: FilterDataRoutes,
  auth: AuthRoutes,
  common: CommonDataRoutes,
  settings: SettingsRoutes,
  file: FileUploadRoutes,
  role: RoleRoutes,
  calendar: CalendarRoutes,
  document: DocumentRoutes,
  user: UserRoutes,
  company: CompanyRoutes,
  productMaterials: ProductMaterialRoutes,
  eLearning: eLearningRoutes,
  admin: AdminRoutes,
  project: ProjectRoutes,
  chat: ChatRoutes,
  warehouse: WarehouseRoutes,
  reports: ReportRoutes,
  gdpr: GdprRoutes,
  ecommerce: ECommerceRoutes,
  cms: CmsRoutes,
  mailbox: EmailRoutes,
  marketing: MarketingRoutes,
  newsletter: {
    mailchimp: MailChimpRoutes
  },
  drive: DriveRoutes,
  lm: LeadManagementRoutes,
  insuranceSales: InsuranceSalesRoutes,
  humanResources: HumanResourcesRoutes,
  evnas: EvnasRoutes,

  activity: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/activity`
    }
  },

  //<editor-fold desc="Charts">
  charts: {
    method: 'Get',
    url: `${environment.baseUrl}/${environment.version}/widgets`
  },
  //</editor-fold>

  //<editor-fold desc="Custom">
  assign: {
    url: `${environment.baseUrl}/api/${environment.version}/user/assign`,
    method: 'Post'
  },
  //</editor-fold>
  //<editor-fold desc="Transfer">
  transfer: {
    pdf: {
      url: `${environment.baseUrl}/api/${environment.version}/transfer/list/pdf`,
      method: 'Post'
    },
    excel: {
      url: `${environment.baseUrl}/api/${environment.version}/transfer/list/excel`,
      method: 'Post'
    }
  },
  //</editor-fold>

};
