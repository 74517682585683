import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {CompanyService} from '../company.service';
import {Observable} from 'rxjs';
import {Company} from '../classes/company';
import {ApiService} from '../../../../services/api.service';

@Injectable()
export class CompanyGetResolver implements Resolve<any> {

  constructor(private _company: CompanyService, private _api: ApiService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Company> {
    return this._api.handleRequest(this._company.getCompany(parseInt(route.params.id)));
  }
}
