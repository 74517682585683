import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MultiselectComponent} from './multiselect.component';
import {MultiselectSearchPipe} from './multiselect-search.pipe';
import {FormsModule} from '@angular/forms';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {OutsideClickModule} from '../../../directives/outside-click/outside-click.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    VirtualScrollerModule,
    OutsideClickModule
  ],
  providers: [],
  declarations: [
    MultiselectComponent,
    MultiselectSearchPipe
  ],
  exports: [
    MultiselectComponent
  ]
})
export class MultiselectModule {
}
