import {environment} from '../../../../environments/environment';

export const UserRoutes = {
  get: {
    url: `${environment.baseUrl}/api/${environment.version}/user/get`,
    method: 'Get'
  },
  filter: {
    url: `${environment.baseUrl}/api/${environment.version}/user/filter`,
    method: 'Post'
  },
  create: {
    url: `${environment.baseUrl}/api/${environment.version}/user/create`,
    method: 'Post'
  },
  update: {
    url: `${environment.baseUrl}/api/${environment.version}/user/update`,
    method: 'Patch'
  },
  archive: {
    url: `${environment.baseUrl}/api/${environment.version}/user/delete`,
    method: 'Delete'
  },
  restore: {
    url: `${environment.baseUrl}/api/${environment.version}/user/restore`,
    method: 'Patch'
  },
  anonimyse: {
    url: `${environment.baseUrl}/api/${environment.version}/user/anonymise`, // :id
    method: 'Post',
  },
  identify: {
    url: `${environment.baseUrl}/api/${environment.version}/user/identify`, // :id
    method: 'Post',
  },
  permissions: {
    method: 'Get',
    url: `${environment.baseUrl}/api/${environment.version}/permissions`
  },
  documents: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/document/singleUserDocument`
  },
  priceList: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/user/priceList/filter`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/user/priceList/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/user/priceList/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/user/priceList/delete` // /:id
    }
  },
  commons: {
    label: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/label/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/label/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/user/label/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/user/label/delete` // /:id
      },
    },
    category: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/category/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/category/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/user/category/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/user/category/delete` // /:id
      },
    },
    occupation: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/occupation/filter`
      },
      get: id => ({
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/user/occupation/get/${id}`
      }),
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/occupation/create`
      },
      update: id => ({
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/user/occupation/update/${id}` // /:id
      }),
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/user/occupation/delete` // /:id
      },
    },
    workgroup: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/workgroup/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/user/workgroup/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/user/workgroup/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/user/workgroup/delete` // /:id
      },
    },
  },
  webUser: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/user/web/filter`,
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/user/getWebUser`, // /:id
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/user/web/update`, // /:id
    },
    anonimyse: {
      url: `${environment.baseUrl}/api/${environment.version}/user/web/anonymise`, // :id
      method: 'Post',
    },
    identify: {
      url: `${environment.baseUrl}/api/${environment.version}/user/web/identify`, // :id
      method: 'Post',
    },
  },
  settings: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/settings/general/user`,
    },
    save: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/general/save/user`,
    }
  }
};
