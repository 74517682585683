export class Contact {
  id: number;
  type: number;
  typeMain: boolean;
  value: string;
  description?: string;

  constructor(init: any) {
    if (init && init.id) this.id = init.id;
    if (init && init.type) this.type = init.type;
    if (init && init.typeMain) this.typeMain = init.typeMain;
    if (init && init.value) this.value = init.value;
    if (init && init.description) this.description = init.description;
  }

  public static getNewContactObject(translations: any): {id: 'new', name: string} {
    return {id: 'new', name: translations.newContact}
  }

}
