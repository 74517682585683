import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {Observable} from 'rxjs';
import {urlValues} from '../../../../configs/url.values';
import {EvnasOccupation} from './classes/EvnasOccupation';
import {Params} from '@angular/router';
import {UserDownloadWordParams} from './classes/UserDownloadWordParams';

@Injectable()
export class HumanResourcesService {

  constructor(private _api: ApiService) {
  }

  getOccupations(): Observable<any> {
    return new Observable<any>(obs => {
      const {method, url} = urlValues.user.commons.occupation.filter;
      this._api.send(method, url, {}).subscribe((res: any) => {
        obs.next(res.data);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  createOccupation(occupation: EvnasOccupation): Observable<boolean> {
    return new Observable<boolean>(obs => {
        this.createGaussBoxOccupation(occupation.name)
          .mergeMap(data => {
            occupation.setOccupationId(data.id);
            return this.createEvnasOccupation(occupation);
          })
          .subscribe(_ => {
            obs.next(true);
            obs.complete();
          }, err => {
            obs.error(err);
            obs.complete();
          });
      }
    );
  }

  getOccupation(id: number): Observable<any> {
    const {method, url} = urlValues.humanResources.occupation.get(id);
    return new Observable<any>(obs => {
      this._api.send(method, url).subscribe(res => {
        obs.next(res['data']);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  updateOccupation(id: number, occupation: EvnasOccupation): Observable<boolean> {
    occupation.setOccupationId(id);
    return new Observable<any>(obs => {
      this.updateGaussBoxOccupation(occupation.name, occupation.occupationId)
        .mergeMap(() => {
          return this.createEvnasOccupation(occupation);
        })
        .subscribe(res => {
          obs.next(res['data']);
          obs.complete();
        }, err => {
          obs.error(err);
          obs.complete();
        });
    });
  }

  deleteOccupation(id: number): Observable<any> {
    const {method, url} = urlValues.humanResources.occupation.delete(id);
    return new Observable<any>(obs => {
      this._api.send(method, url).subscribe(res => {
        obs.next(res['data']);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  /**
   * Creates occupation on EVNAS backend
   * @param occupation : occupation to create
   */
  private createEvnasOccupation(occupation: EvnasOccupation): Observable<object> {
    return new Observable<object>(obs => {
      this._api.send(urlValues.humanResources.occupation.create.method, urlValues.humanResources.occupation.create.url, occupation)
        .subscribe(res => {
          obs.next(res);
          obs.complete();
        }, err => {
          obs.error(err);
          obs.complete();
        });
    });
  }

  /**
   * Creates occupation on gauss box backend
   * @param name : occupation name
   */
  private createGaussBoxOccupation(name: string): Observable<any> {
    return new Observable<any>(obs => {
      this._api
        .send(urlValues.user.commons.occupation.create.method, urlValues.user.commons.occupation.create.url, {name: name})
        .subscribe(res => {
          obs.next(res['data']);
          obs.complete();
        });
    });
  }

  private updateGaussBoxOccupation(name: string, id: number): Observable<any> {
    return new Observable<any>(obs => {
      const {method, url} = urlValues.user.commons.occupation.update(id);
      this._api
        .send(method, url, {name: name})
        .subscribe(res => {
          obs.next(res['data']);
          obs.complete();
        });
    });
  }

  public getHrUser(id: number): Observable<any> {
    const {method, url} = urlValues.evnas.humanResources.user.getHrUser(id);
    return new Observable<any>(obs => {
      this._api.send(method, url).subscribe(res => {
        obs.next(res['data']);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  userDownloadWord(data: UserDownloadWordParams): Observable<any> {
    const {method, url} = urlValues.evnas.humanResources.user.downloadWord;
    return new Observable<any>(observer => {
      this._api.send(method, url, data).subscribe(res => {
        observer.next(res['data']);
        observer.complete();
      }, err => {
        observer.error(err);
        observer.complete();
      });
    });
  }

}
