import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GroupModalComponent} from './group-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ErrorMsgModule} from '../../error-msg/error-msg.module';
import {BaseModalModule} from "../base-modal/base-modal.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BaseModalModule,
    ErrorMsgModule
  ],
  declarations: [GroupModalComponent],
  exports: [GroupModalComponent]
})
export class GroupModalModule {
}
