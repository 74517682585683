import {environment} from '../../../../environments/environment';

export const GdprRoutes = {

  consent: {
    get: {
      url: `${environment.baseUrl}/api/${environment.version}/gdpr/consent/get`, // /:id
      method: 'Get'
    },
    filter: {
      url: `${environment.baseUrl}/api/${environment.version}/gdpr/consent/filter`,
      method: 'Post'
    },
    create: {
      url: `${environment.baseUrl}/api/${environment.version}/gdpr/consent/create`,
      method: 'Post'
    },
    update: {
      url: `${environment.baseUrl}/api/${environment.version}/gdpr/consent/update`, // /:id
      method: 'Patch'
    }
  },
  agreement: {
    get: {
      url: `${environment.baseUrl}/api/${environment.version}/gdpr/agreement/get`, // /:id
      method: 'Get'
    },
    filter: {
      url: `${environment.baseUrl}/api/${environment.version}/gdpr/agreement/filter`,
      method: 'Post'
    },
    create: {
      url: `${environment.baseUrl}/api/${environment.version}/gdpr/agreement/create`,
      method: 'Post'
    },
    update: {
      url: `${environment.baseUrl}/api/${environment.version}/gdpr/agreement/update`, // /:id
      method: 'Patch'
    }
  },
  consentLog: {
    get: {
      url: `${environment.baseUrl}/api/${environment.version}/gdpr/consent/log/get`, // /:id
      method: 'Get'
    },
    filter: {
      url: `${environment.baseUrl}/api/${environment.version}/gdpr/consent/log/filter`,
      method: 'Post'
    },
    create: {
      url: `${environment.baseUrl}/api/${environment.version}/gdpr/consent/log/create`,
      method: 'Post'
    },
    update: {
      url: `${environment.baseUrl}/api/${environment.version}/gdpr/consent/log/update`, // /:id
      method: 'Patch'
    }
  }

};
