import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {GlobalService} from '../../../services/global.service';
import {Company} from './classes/company';
import {Observable} from 'rxjs';
import * as _ from 'lodash';
import {urlValues} from '../../../configs/url.values';
import {LocalizationService} from '../../../services/localization.service';
import {Location} from '@angular/common';
import {FilterData} from '../../../classes/filter-data';
import {FilterField} from '../../../components/filter/filter.component';
import {AuthService} from '../../../services/auth.service';
import {User} from "../user/classes/user";
import {FileDirectories} from "../../../enums/file-directories";

@Injectable()
export class CompanyService {

  public directory: FileDirectories = FileDirectories.COMPANY;
  public sidebarActived: boolean = false;
  public activeCompany: Company = null; //Active company on company tab

  public companies: Company[] = [];
  public company: Company = null; //Company that is being manipulated (edited...)

  public listFilters: FilterField[] = []; //Filters that will be shown above the list

  constructor(private _api: ApiService,
              private _locale: LocalizationService,
              private _location: Location,
              private _auth: AuthService,
              public _global: GlobalService) {
  }

  //<editor-fold desc="CRUD">
  getCompany(id: number, active?: boolean): Observable<Company> {
    return new Observable<Company>(obs => {
      this._api.send(urlValues.company.get.method, `${urlValues.company.get.url}/${id}`).subscribe(res => {
        res['data'].iconColor = this._global.getRandomColor(res['data'].id); //Set a color for company icon
        if (active) this.activeCompany = new Company(res['data']); //When loading company module get the active company that is selected
        obs.next(new Company(res['data']));
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  getCompanies(filters?: FilterData, updateCount?: boolean): Observable<Company[]> {
    return new Observable<Company[]>(obs => {
      this._api.send(urlValues.company.filter.method, urlValues.company.filter.url, filters).subscribe(res => {
        this.companies = _.map(res['data'].records, company => {
          if (!company.iconColor) _.extend(company, {iconColor: this._global.getRandomColor(company.id)});
          return new Company(company);
        });
        obs.next(this.companies);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  create(data: object): void {
    this._api.send(urlValues.company.create.method, urlValues.company.create.url, data).subscribe(res => {
      //Update user with new company settings if user does not have company
      this._location.back(); //Return user back to the page he came from
      this._global.pushAppMessage('success', this._locale.trans.success, this._locale.trans.companycreated);
    });
  }

  update(id: number, data: object, redirect?: boolean, returnCompany: boolean = false): Observable<boolean | Company> {
    if (returnCompany) _.extend(data, {returnData: returnCompany});
    return new Observable<boolean | Company>(obs => {
      if (this._global.isEmptyObject(data)) {
        obs.next(true);
        obs.complete();
        return this._location.back();
      }
      this._api.send(urlValues.company.update.method, `${urlValues.company.update.url}/${id}`, data).subscribe(res => {
        if (redirect) this._location.back(); //Return user back to the page he came from
        if (returnCompany) {
          res['data'].iconColor = this._global.getRandomColor(res['data'].id); //Set a color for company icon
          let company = new Company(res['data']);
          obs.next(company);
          obs.complete()
        } else {
          obs.next(true);
          obs.complete();
        }
      });
    });
  }

  createMain(data: object): void {
    this._api.send(urlValues.company.createMain.method, urlValues.company.createMain.url, data).subscribe(res => {
      //Update user with new company settings if user does not have company
      this._global.pushAppMessage('success', this._locale.trans.success, this._locale.trans.companycreated);
      // if (!this._auth.loggedUser.company) this._auth.updateLoggedUserCompany(res['data'].id, this._auth.loggedUser.company);
      if (!this._auth.loggedUser.company) {
        this._auth.loggedUser.company = res['data'].id;
        this._auth.updateLoggedUser(this._auth.loggedUser, true);
      }
    });
  }

  updateMain(id: number, data: object): Observable<boolean> {
    return new Observable<boolean>(obs => {
      if (this._global.isEmptyObject(data)) {
        this._global.pushAppMessage('success', this._locale.trans.success, this._locale.trans.companyupdated);
        obs.next(true);
        obs.complete();
      }

      this._api.send(urlValues.company.update.method, `${urlValues.company.update.url}/${id}`, data).subscribe(res => {
        this._global.pushAppMessage('success', this._locale.trans.success, this._locale.trans.companyupdated);
        obs.next(true);
        obs.complete();
      });
    });
  }

  archive(id: number): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this._api.send(urlValues.company.archive.method, `${urlValues.company.archive.url}/${id}`).subscribe(res => {
        obs.next(true);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  restore(id: number): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this._api.send(urlValues.company.restore.method, `${urlValues.company.restore.url}/${id}`).subscribe(res => {
        obs.next(true);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  //</editor-fold>

  setListFilters(): void {
    this.listFilters = [
      {
        name: 'Labels', // will be translated inside the filter component
        values: _.mapFilterFields(this._global.commonData.company.label),
        selected: null,
        type: 'select',
        key: 'label'
      },
      {
        name: 'Category',
        values: _.mapFilterFields(this._global.commonData.company.category),
        selected: null,
        type: 'select',
        key: 'category' //Optional
      },
      {
        name: 'Industry',
        values: _.map(this._global.commonData.industryTypes, iType => {
          return {name: this._locale.trans[iType.slug], sendValue: iType.id};
        }),
        selected: null,
        type: 'select',
        key: 'industryType' //Optional
      },
    ];
  }
}
