import {environment} from '../../environments/environment';

export const CommonDataRoutes =  {
  get: {
    url: `${environment.baseUrl}/api/${environment.version}/commons`,
    method: 'Get'
  },
  document: {
    get: {
      url: `${environment.baseUrl}/api/${environment.version}/document/commons`,
      method: 'Get'
    }
  }
};
