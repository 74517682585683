export interface Permissions {
  module: string //Name of the module
  data: Array<{
    [key: string]: boolean | 'all' | 'user'
  }>
}

export class Role {
  id?: number;
  name: string;
  module: string;
  type: string;
  permissions: Permissions[];

  constructor(init: any) {
    if (init.id) this.id = init.id;
    if (init.name) this.name = init.name;
    if (init.type) this.type = init.type;
    if (init.module) this.module = init.module;
    if (init.permissions) this.permissions = init.permissions;
  }

  public static generateModuleName(param: string): string {
    let n = param.indexOf('-');
    return param.substring(0, n != -1 ? n : param.length);
  }

  toJson(): Object {
    let json = {
      name: this.name,
      module: this.module,
      type: this.type,
      permissions: Object.keys(this.permissions).map((key) => {
        return {module: key, data: this.permissions[key]}
      })
    };

    if (this.id) json['id'] = this.id;

    return json;
  }
}
