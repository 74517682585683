import {environment} from '../../../../environments/environment';

export const CalendarRoutes = {
  data: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/calendar/data`
  },
  event: {
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/calendar/event/get`
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/calendar/event/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/calendar/event/update`
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/calendar/event/delete`
    }
  }
};
