import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {UserService} from "../modules/layout/user/user.service";
import {StorageService} from "../services/storage.service";
import {AuthService} from "../services/auth.service";
import {Observable, zip} from "rxjs";
import {User} from "../modules/layout/user/classes/user";

@Injectable()
export class GetLoggedUserResolver implements Resolve<any> {

  constructor(private _user: UserService,
              private _auth: AuthService,
              private _storage: StorageService) {
  }

  resolve(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      let user = this._storage.get('user');
        if (!user.id) {
          this._auth.logOut();
          obs.next(true);
          obs.complete();
        } else {
          zip(this._user.getUser(user.id), this._auth.getUserPermissions(true)).subscribe(([user, permissions]) => {
            //Extract company id from company object because user get route returns the object and login returns id
            user.company = user .company ? user.company.id : null;
            user.branch = user .branch ? user.branch.id : null;
            this._auth.updateLoggedUser(new User(user));
            this._auth.getUserPermissions(true);
            obs.next(true);
            obs.complete();
          });
        }
      }
    );
  }
}
