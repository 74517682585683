import {Injectable} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ToggleNewService {

  public modals: string[] = [];
  public showDropDown: boolean = false;
  public showMenu: boolean = false;
  public showGlobalDropdown: boolean = false;

  constructor(private _router: Router) {
    _router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) this.modals = [];
    });
  }

  toggleSameElement(modalName: string, event: any): void {
    if (event) event.stopPropagation();
    this.modals = this.modals.filter((item) => item.indexOf('single_') !== 0);
    this.toggleCheck(modalName) ? this.toggleRemove(modalName) : this.toggleAdd(modalName, event);
  }

  toggleAdd(modalName: string, event?: any): void {
    if (event) event.stopPropagation();
    if (this.modals.indexOf(modalName) === -1) this.modals.push(modalName);
  }

  toggleRemove(modalName: string): void {
    this.modals = this.modals.filter((e) => e !== modalName);
  }

  toggleCheck(modalName: string): boolean {
    return this.modals.includes(modalName);
  }

}
