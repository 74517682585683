import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../user.service';
import {LocalizationService} from '../../../../../services/localization.service';
import {UserType} from '../../../../../interfaces/common/user-type';
import {GlobalService} from '../../../../../services/global.service';
import * as _ from 'lodash';
import {ToggleService} from '../../../../../services/toggle.service';
import {User} from '../../classes/user';
import {FileManagementService} from '../../../../../services/file-management.service';

@Component({
  selector: 'app-user-sidebar',
  templateUrl: './user-sidebar.component.html',
  styleUrls: ['./user-sidebar.component.scss']
})
export class UserSidebarComponent implements OnInit, OnDestroy {

  @Input() public user: User = null;
  public type: UserType = null;

  constructor(public _user: UserService,
              public _locale: LocalizationService,
              public _fileUpload: FileManagementService,
              public _toggle: ToggleService,
              public _global: GlobalService) {
  }

  ngOnInit() {
    this.type = _.find(this._global.commonData.userTypes, ['id', this.user.type]);
  }

  ngOnDestroy() {
    this._toggle.userSidebar.next(false);
    this._toggle.companySidebar.next(true);
    this._user.sidebarUser = null;
  }

  getContactTypeName(id:number):string{
    return this._global.commonData.contactTypes.find(type => type.id === id).slug
  }

}
