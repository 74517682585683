import {Injectable} from '@angular/core';
import {LocalizationService} from './localization.service';
import {ToggleNewService} from './toggleNew.service';
import {Item} from "../components/modals/gallery-modal/classes/item";

@Injectable()
export class TinyMceService {

  //Available plugins: gallery

  constructor(private _locale: LocalizationService,
              private _toggle: ToggleNewService) {
  }

  public onEditorInit(event, pluginList?: string[]): void {
    if (pluginList && pluginList.length) {
      if (pluginList.includes('gallery')) this.addGalleryButton(event);
    }
  }

  private addGalleryButton(event): void {
    event.editor.addButton('galleryButton', {
      text: this._locale.trans.gallery,
      icon: false,
      onclick: () => {
        this._toggle.toggleAdd('galleryModal');
      }
    });
    let button = event.editor.buttons['galleryButton'];  //the button now becomes
    let bg = event.editor.theme.panel.find('toolbar buttongroup')[0]; //find the buttongroup in the toolbar found in the panel of the theme
    bg._lastRepaintRect = bg._layoutRect; //without this, the buttons look weird after that
    bg.append(button); //append the button to the group
  }

  insertMedia(editorComponent: any, items: Item[]): void {
    items.forEach((item: Item) => {
      let content: string = '';
      if(item.type == 'image'){
        content = '&nbsp;<img src="' + item.path + '" style="display:inline-block"/>&nbsp;';
      }
      else if(item.type == 'video'){
        content = '<video controls style="width:150px;height:150px">\n' +
          '<source src="' + item.path + '" type="video/mp4">\n' +
          '<source src="' + item.path + '" type="video/ogg">\n' +
          'Your browser does not support the video tag.\n' +
          '</video>'
      }
      editorComponent.editor.insertContent(content);
    });
  }
}
