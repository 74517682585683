export abstract class StorageService {

    protected readonly abstract storage: any;

    public set(name: string, value?: any): void {
        if (!value) this.storage.removeItem(name);
        else {
            if (typeof value !== 'string') value = JSON.stringify(value);
            this.storage.setItem(name, value);
        }
    }

    public get(name: string): any {
        let item = this.storage.getItem(name);
        try {
            item = JSON.parse(item);
        } catch (err) {
            // console.log(err);
        }
        return item;
    }

    public update(name: string, added: any): void {
        let current: any = this.storage.getItem(name);
        try {
            current = JSON.parse(current);
        } catch (err) {
        }

        if (!current) current = {};

        for (let key in added) current[key] = added[key];

        this.storage.setItem(name, JSON.stringify(current));
    }

    public delete(name: string): void {
        this.storage.removeItem(name);
    }

    public clear(): void {
        this.storage.clear();
    }

}
