import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {GlobalService} from '../../../services/global.service';
import {User} from './classes/user';
import {urlValues} from '../../../configs/url.values';
import {LocalizationService} from '../../../services/localization.service';
import {Location} from '@angular/common';
import {Observable} from 'rxjs';
import * as _ from 'lodash';
import {FilterData} from '../../../classes/filter-data';
import {FilterField} from '../../../components/filter/filter.component';
import {CompanyService} from '../company/company.service';
import {FileManagementService} from '../../../services/file-management.service';
import {AuthService} from '../../../services/auth.service';
import {UploadedFile} from '../../../interfaces/uploaded-file';
import {StorageService} from '../../../services/storage.service';
import {BreadcrumbService} from "../../../services/breadcrumb.service";
import {FileDirectories} from "../../../enums/file-directories";

@Injectable()
export class UserService {

  public directory: FileDirectories = FileDirectories.USER;
  public selectedUser: User = null;
  public sidebarUser: User = null; //Only user for showing user in sidebar and nothing more

  public listFilters: FilterField[] = []; //Filters that will be shown above the list

  constructor(private _api: ApiService,
              private _locale: LocalizationService,
              private _fileUpload: FileManagementService,
              private _location: Location,
              private _company: CompanyService,
              private _auth: AuthService,
              private _storage: StorageService,
              private _breadcrumb: BreadcrumbService,
              public _global: GlobalService) {
  }

  public setUserBreadcrumbs(page: string): void {
    this._breadcrumb.addCustomBreadcrumb(this._locale.trans.user, 0, `/user/${this.selectedUser.id}/${page}`);
    this._breadcrumb.addCustomBreadcrumb(this._locale.trans[page], 1, `/user/${this.selectedUser.id}/${page}`);
    this._breadcrumb.addCustomBreadcrumb(this.selectedUser.getFullName(), 2, `/user/${this.selectedUser.id}/${page}`);
  }

  //<editor-fold desc="Functions">

  setUserProfileImage(image: UploadedFile): void {
    this._auth.loggedUser.details.profileImage = image; //Set image to logged user in service
    let user = this._storage.get('user'); //Set image to local storage
    user.details.profileImage = image;
    this._storage.set('user', user);
  }

  checkIsAvailableUsername(username: string): Observable<any> {
    return new Observable<any>(obs => {
      this._api.send(urlValues.auth.checkUserParamAvailable.method, urlValues.auth.checkUserParamAvailable.url, {username: username}).subscribe(res => {
        obs.next(res['data']);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  checkIsAvailableEmail(email: string): Observable<any> {
    return new Observable<any>(obs => {
      this._api.send(urlValues.auth.checkUserParamAvailable.method, urlValues.auth.checkUserParamAvailable.url, {email: email}).subscribe(res => {
        obs.next(res['data']);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  //</editor-fold>

  //<editor-fold desc="CRUD">
  getUsers(filters: FilterData): Observable<User[]> {
    return new Observable<User[]>(obs => {
      this._api.send(urlValues.user.filter.method, urlValues.user.filter.url, filters).subscribe(res => {
        let users = _.map(res['data'].records, user => new User(user));
        obs.next(users);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  getUser(id: number): Observable<User> {
    return new Observable<User>(obs => {
      this._api.send(urlValues.user.get.method, `${urlValues.user.get.url}/${id}`).subscribe(res => {
        let user = new User(res['data']);
        obs.next(user);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  getWebUsers(filters: FilterData): Observable<User[]> {
    return new Observable<User[]>(obs => {
      this._api.send(urlValues.user.webUser.filter.method, urlValues.user.webUser.filter.url, filters).subscribe(res => {
        let users = _.map(res['data'].records, user => { //Check if user has profile image and get it
          if (!user.iconColor) _.extend(user, {iconColor: this._global.getRandomColor(user.id)});
          return new User(user);
        });
        // if (!manage) this.users = users; //If not manage bool then store users in service else just return users
        obs.next(users);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  getWebUser(id: number): Observable<User> {
    return new Observable<User>(obs => {
      this._api.send(urlValues.user.webUser.get.method, `${urlValues.user.webUser.get.url}/${id}`).subscribe(res => {
        if (!res['data'].iconColor) res['data'].iconColor = this._global.getRandomColor(res['data'].id); //Add random color to user
        let user = new User(res['data']);
        obs.next(user);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  updateWebUser(id: number, data: object, returnUser: boolean = false): Observable<boolean | User> {
    if (this._global.isEmptyObject(data)) return;
    if (returnUser) _.extend(data, {returnData: returnUser});
    return new Observable<boolean | User>(obs => {
      this._api.send(urlValues.user.webUser.update.method, `${urlValues.user.webUser.update.url}/${id}`, data).subscribe(res => {
        if (id == this._auth.loggedUser.id) this._auth.updateLoggedUser(data);
        if (returnUser) {
          if (!res['data'].iconColor) res['data'].iconColor = this._global.getRandomColor(res['data'].id); //Add random color to user
          let user = new User(res['data']);
          obs.next(user);
          obs.complete()
        } else {
          obs.next(true);
          obs.complete();
        }
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  create(data: object): Observable<any> {
    return new Observable<any>(obs => {
      this._api.send(urlValues.user.create.method, urlValues.user.create.url, data).subscribe(res => {
        obs.next(res['data']);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  update(id: number, data: object, returnUser: boolean = false): Observable<boolean | User> {
    if (this._global.isEmptyObject(data)) return;
    if (returnUser) _.extend(data, {returnData: returnUser});
    return new Observable<boolean | User>(obs => {
      this._api.send(urlValues.user.update.method, `${urlValues.user.update.url}/${id}`, data).subscribe(res => {
        if (id == this._auth.loggedUser.id) this._auth.updateLoggedUser(data);
        if (returnUser) {
          if (!res['data'].iconColor) res['data'].iconColor = this._global.getRandomColor(res['data'].id); //Add random color to user
          let user = new User(res['data']);
          obs.next(user);
          obs.complete()
        } else {
          obs.next(true);
          obs.complete();
        }
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  archive(id: number): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this._api.send(urlValues.user.archive.method, `${urlValues.user.archive.url}/${id}`).subscribe(res => {
        obs.next(true);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  restore(id: number): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this._api.send(urlValues.user.restore.method, `${urlValues.user.restore.url}/${id}`).subscribe(res => {
        obs.next(true);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  //it didnt have body
  anonymise(id: number): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this._api.send(urlValues.user.anonimyse.method, `${urlValues.user.anonimyse.url}/${id}`, {}).subscribe(res => {
        obs.next(true);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  //it didnt have body
  identify(id: number): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this._api.send(urlValues.user.identify.method, `${urlValues.user.identify.url}/${id}`, {}).subscribe(res => {
        obs.next(true);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });

  }

  //it didnt have body
  anonymiseWebUser(id: number): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this._api.send(urlValues.user.webUser.anonimyse.method, `${urlValues.user.webUser.anonimyse.url}/${id}`, {}).subscribe(res => {
        obs.next(true);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  //it didnt have body
  identifyWebUser(id: number): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this._api.send(urlValues.user.webUser.identify.method, `${urlValues.user.webUser.identify.url}/${id}`, {}).subscribe(res => {
        obs.next(true);
        obs.complete();
      }, err => {
        obs.error(err);
        obs.complete();
      });
    });

  }


  //</editor-fold>

  //<editor-fold desc="Filters">
  setListFilters(): void {
    //Check user permissions and based on them create array of filters that will be displayed on the list
    this.listFilters = [
      {
        name: 'Labels', // will be translated inside the filter component
        values: _.mapFilterFields(this._global.commonData.user.label),
        selected: null,
        type: 'select',
        key: 'label'
      },
      {
        name: 'Category',
        values: _.mapFilterFields(this._global.commonData.user.category),
        selected: null,
        type: 'select',
        key: 'category' //Optional
      },
      {
        name: 'Workgroup',
        values: _.mapFilterFields(this._global.commonData.user.workgroup),
        selected: null,
        type: 'multiselect',
        key: 'workgroups' //Optional
      },
      {
        name: 'Occupation',
        values: _.mapFilterFields(this._global.commonData.user.occupation),
        selected: null,
        type: 'select',
        key: 'occupation' //Optional
      },
      {
        name: 'Tags',
        values: null,
        selected: null,
        type: 'tags',
        key: 'tags'
      }
    ];

    //Check if user has permission to see companies, and enable the filter
    if (this._auth.checkPermission('company_filter')) {
      this._company.getCompanies(new FilterData({
        filters: [
          {key: 'isClient', value: true, default: true},
          {key: 'archived', value: false, default: true}
        ]
      })).subscribe(companies => {
        this.listFilters.push({
          name: 'Company', // will be translated inside the filter component
          values: _.mapFilterFields(companies),
          selected: null,
          type: 'select',
          key: 'company'
        });
      });
    }
    //Return appropriate user types in filter based on
    // let params = this.getFilterValue(this.filterData.getValue());
    // this.listFilters.push(this.filterUserTypes(params));
  }

  filterUserTypes(params): FilterField {
    let hasLogicOperator: boolean = params['logicOperator'].indexOf('!=') != -1;
    let filteredTypes = _.filter(this._global.commonData.userTypes, uType => (params['values'].indexOf(uType.id) == -1) == hasLogicOperator);
    return {
      name: 'Type', // will be translated inside the filter component
      values: _.map(filteredTypes, uType => {
        return {name: this._locale.trans[uType.slug], sendValue: uType.id};
      }),
      selected: null,
      type: 'select',
      key: 'type'
    };
  }

  getFilterValue(filterData: FilterData): object {
    let types = _.find(filterData.filters, ['key', 'type']);
    if (!_.isObject(types.value)) {
      return types.value;
    } else return {logicOperator: _.keys(types.value), values: _.flatten(_.values(types.value))};
  }

  //</editor-fold>

}
