export interface ListInfo {
  name: string;
  parentId?: number; //id of an entity the list items are related to (eg. tasks to project)
  project?: number;
}

export class ListPageMemory {
  listInfo: ListInfo;
  page: number;
  keywords: string;

  constructor(listInfo: ListInfo, page: number, keywords?: string) {
    this.listInfo = listInfo;
    this.page = page;
    this.keywords = keywords;
  }

}
