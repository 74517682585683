import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'removeChar'
})
export class RemoveCharPipe implements PipeTransform {

  //Character param is character that should be removed from text
  //Merges text on that character
  //Example john.doe = johndoe

  transform(text: string, character: string): string {
    return text.split(character).join('');
  }

}
