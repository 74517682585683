export class Address {
  id: number;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  region: string;
  country: number;
  type: string;
  firstName: string;
  lastName: string;
  contactPhone: string;
  typeMain: boolean = false;


  constructor(init: any) {
    if (init.id) this.id = init.id;
    if (init.street) this.street = init.street;
    if (init.typeMain) this.typeMain = init.typeMain;
    if (init.houseNumber) this.houseNumber = init.houseNumber;
    if (init.zipCode) this.zipCode = init.zipCode;
    if (init.city) this.city = init.city;
    if (init.region) this.region = init.region;
    if (init.country) this.country = init.country;
    if (init.type) this.type = init.type;
    if (init.firstName) this.firstName = init.firstName;
    if (init.lastName) this.lastName = init.lastName;
    if (init.contactPhone) this.contactPhone = init.contactPhone;
  }

  public static getNewAddressObject(translations: any): {id: 'new', name: string} {
    return {id: 'new', name: translations.newAddress}
  }
}
