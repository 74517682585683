import {environment} from '../../../../environments/environment';

export const DocumentRoutes = {
  get: {
    url: `${environment.baseUrl}/api/${environment.version}/document/get`,
    method: 'Get'
  },
  update: {
    url: `${environment.baseUrl}/api/${environment.version}/document/update`,  //:ID
    method: 'Patch'
  },
  create: {
    url: `${environment.baseUrl}/api/${environment.version}/document/create`,
    method: 'Post'
  },
  filter: {
    url: `${environment.baseUrl}/api/${environment.version}/document/filter`,
    method: 'Post'
  },
  delete: {
    url: `${environment.baseUrl}/api/${environment.version}/document/delete`,
    method: 'Patch'
  },
  batch: {
    url: `${environment.baseUrl}/api/${environment.version}/document/batch`,
    method: 'Post'
  },
  pdf: {
    url: `${environment.baseUrl}/${environment.version}/export/pdf`, // :ID
    urlB: `https://pdf.${environment.mainDomain}/${environment.version}/transfer/pdf`,
    method: 'Get'
  },
  stock:{
    url:`${environment.baseUrl}/api/${environment.version}/warehouse/stock/state/items`,
    method:'Post'
  },
  webUser:{
    get:{
      method:'Get',
      url:`${environment.baseUrl}/api/${environment.version}/user/getWebUser`, // /:id
    },
    filter:{
      method:'Post',
      url:`${environment.baseUrl}/api/${environment.version}/user/web/filter`,
    },
  },
  documentHistory:{
    get:{
      method:'Get',
      url:`${environment.baseUrl}/api/${environment.version}/document/logs`, // /:id
    },
  },
  shippingtMethods:{
    filter:{
      method:'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/shippingMethod/filter`
    }
  },
  category:{
    create:{
      method:'Post',
      url:`${environment.baseUrl}/api/${environment.version}/document/category/create`
    },
    update:{
      method:'Patch',
      url:`${environment.baseUrl}/api/${environment.version}/document/category/update` //:id
    },
    filter:{
      method:'Post',
      url:`${environment.baseUrl}/api/${environment.version}/document/category/filter`
    },
    delete:{
      method:'Delete',
      url:`${environment.baseUrl}/api/${environment.version}/document/category/delete` // :id
    }
  },
  ecommerce: {
    filter: {
      method: 'Post',
      url:`${environment.baseUrl}/api/${environment.version}/document/filter/ecommerce` // :id
    }
  },
  occupation:{
    get:{
      method:'Get',
      url:`${environment.baseUrl}/api/${environment.version}/user/occupation/get` // :id
    }
  },
  utils:{
    create:{
      method:'Post',
      url:`${environment.baseUrl}/api/${environment.version}/document/util/create`
    },
    filter:{
      method:'Post',
      url:`${environment.baseUrl}/api/${environment.version}/document/util/filter`
    },
    update:{
      method:'Patch',
      url:`${environment.baseUrl}/api/${environment.version}/document/util/update` //:ID
    },
    delete:{
      method:'Delete',
      url:`${environment.baseUrl}/api/${environment.version}/document/util/delete` // :ID
    }
  },
  media:{
    get:{
      method:'Post',
      url:`${environment.baseUrl}/api/${environment.version}/media/userFile/getByHashes`
    }
  }
};
