import {environment} from '../../../../../environments/environment';

const root: string = `${environment.baseUrl}/api/${environment.version}`;

export const HumanResourcesRoutes = {
  occupation: {

    create: {
      url: `${root}/hr/occupation/create`,
      method: 'Post'
    },

    filter: {
      url: `${root}/hr/occupation/filter`,
      method: 'Post'
    }
  },

  user: {
    filter: {
      url: `${root}/hr/user/filter`,
      method: 'Post'
    },
    createHrConfig: {
      url: `${root}/hr/user/create`,
      method: 'Post'
    },
    getHrUser: id => ({
      url: `${root}/hr/user/get/${id}`,
      method: 'Get'
    }),
    create: {
      url: `${root}/evnas/evnasUser/create`,
      method: 'Post'
    },
    get: id => ({
      url: `${root}/evnas/evnasUser/get/${id}`,
      method: 'Get'
    }),
    checkOib: oib => ({
      url: `${root}/evnas/evnasUser/checkOib/${oib}`,
      method: 'Get'
    }),
    downloadWord: {
      url: `${root}/evnas/evnasUser/downloadWord`,
      method: 'Post'
    }
  }
};
