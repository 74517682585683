import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BreadcrumbService} from './breadcrumb.service';
import {ActivatedRoute} from '@angular/router';
import {Translations} from "../localizations/trans";

@Injectable()
export class LocalizationService {

  public main: BehaviorSubject<string> = new BehaviorSubject<string>('hrv');
  public trans: any;

  constructor(private _breadcrumb: BreadcrumbService,
              private _route: ActivatedRoute) {
    this.main.subscribe((lang: string) => {
      lang = lang.toLowerCase(); //Change it to lower case in case someone managed to type it in uppercase
      this.trans = Translations['hrv'];
    });
  }

  set(lang: string): void {
    this.main.next(lang);
    this._breadcrumb.getBreadcrumbs(this._route.root);
  }

  public translate(path: string, text: string, separator: string = '/'): string {
    const pathAsArray = path.replace('-', '_').split(separator);
    let translationPath = this.trans;

    pathAsArray.forEach(pathStep => {
      if (!translationPath[pathStep]) {
        return;
      }
      translationPath = translationPath[pathStep];
    });

    return translationPath[text] ? translationPath[text] : this.trans[text];
  }

}
