import {environment} from '../../../../../environments/environment';

const root: string = `${environment.baseUrl}/api/${environment.version}`

export const BuildingsRoutes = {
    
    buildings: {

    },

    rooms: {

    }
};