import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs/index";
import {ApiService} from "../services/api.service";
import {FilterData} from "../classes/filter-data";
import {FilterDataService} from "../services/filter-data.service";

@Injectable()
export class FilterDataResolver implements Resolve<FilterData> {

    constructor(private _filterData: FilterDataService,
                private _api: ApiService) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<FilterData> {
        return this._api.handleRequest(this._filterData.get(route.data.filterDataName, route.data.filterDataHasParent ? this.findFilterDataParentId(route) : undefined))
    }

    findFilterDataParentId(route: ActivatedRouteSnapshot): number {
        if(route.params.id) return route.params.id;
        if(route.parent) return this.findFilterDataParentId(route.parent);
        return undefined;
    }

}
