import {Component} from '@angular/core';
import {LocalizationService} from '../../../services/localization.service';
import {Router} from '@angular/router';
import {ElectronService} from 'ngx-electron';
import {GlobalService} from '../../../services/global.service';

@Component({
  selector: 'app-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  styleUrls: ['./internal-server-error.component.scss']
})
export class InternalServerErrorComponent {

  constructor(public _router: Router,
              public _locale: LocalizationService,
              public _electronService: ElectronService,
              public _global: GlobalService) {

    _global.saleError.subscribe(() => {
      this.back();
    });
  }

  back(): Promise<boolean> {
    return this._router.navigate(['/home']);
  }

}
