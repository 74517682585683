import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LabelModalComponent} from './label-modal.component';
import {ErrorMsgModule} from '../../error-msg/error-msg.module';
import {BaseModalModule} from "../base-modal/base-modal.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BaseModalModule,
    ReactiveFormsModule,
    ErrorMsgModule
  ],
  declarations: [
    LabelModalComponent
  ],
  exports: [
    LabelModalComponent
  ]
})
export class LabelModalModule {
}
