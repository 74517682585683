import {environment} from "../../../../environments/environment";

export const LeadManagementRoutes = {
  lead: {
    user: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/person/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/lead/person/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/person/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/person/update` // /:id
      },
      archive: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/person/archive` // /:id
      },
      restore: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/person/restore` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/lead/person/delete` // /:id
      }
    },
    company: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/company/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/lead/company/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/company/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/company/update` // /:id
      },
      archive: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/company/archive` // /:id
      },
      restore: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/company/restore` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/lead/company/delete` // /:id
      }
    },
    commons: {
      categories: {
        user: {
          filter: {
            method: 'Post',
            url: `${environment.baseUrl}/api/${environment.version}/lead/person/category/filter`
          },
          create: {
            method: 'Post',
            url: `${environment.baseUrl}/api/${environment.version}/lead/person/category/create`
          },
          update: {
            method: 'Patch',
            url: `${environment.baseUrl}/api/${environment.version}/lead/person/category/update`
          },
          delete: {
            method: 'Delete',
            url: `${environment.baseUrl}/api/${environment.version}/lead/person/category/delete`
          }
        },
        company: {
          filter: {
            method: 'Post',
            url: `${environment.baseUrl}/api/${environment.version}/lead/company/category/filter`
          },
          create: {
            method: 'Post',
            url: `${environment.baseUrl}/api/${environment.version}/lead/company/category/create`
          },
          update: {
            method: 'Patch',
            url: `${environment.baseUrl}/api/${environment.version}/lead/company/category/update`
          },
          delete: {
            method: 'Delete',
            url: `${environment.baseUrl}/api/${environment.version}/lead/company/category/delete`
          }
        }
      },
      statuses: {
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/status/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/lead/status/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/lead/status/delete`
        },
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/status/filter`
        }
      },
      ratings: {
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/rating/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/lead/rating/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/lead/rating/delete`
        },
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/rating/filter`
        },
      }
    },
    sourceType: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/source/type/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/lead/source/type/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/source/type/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/source/type/update` // /:id
      },
      archive: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/lead/source/type/delete` // /:id
      },
    },
    contactPersonRole: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/company/contact/role/filter`
      },
      get: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/lead/company/contact/role/get` // /:id
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/company/contact/role/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/company/contact/role/update` // /:id
      },
      delete: {
        method: 'Delete',
        url: `${environment.baseUrl}/api/${environment.version}/lead/company/contact/role/delete` // /:id
      },
    },
    activity: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/activity/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/activity/label/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/lead/activity/label/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/lead/activity/label/delete`
        },
        get: {
          method: 'Get',
          url: `${environment.baseUrl}/api/${environment.version}/lead/activity/label/get` // /:id
        },
      },
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/activity/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/activity/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/activity/update` // /:id
      },
      archive: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/activity/archive` // /:id
      },
      restore: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/activity/restore` // /:id
      },
      complete: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/activity/complete` // /:id
      },
      reassign: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/activity/reassign`
      },
      history: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/lead/activity/history` // /:activityId
      },
    },
    subscribers: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/subscribers/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/subscribers/manage`
      },
    },
  },
  opportunity: {
    commons: {
      categories: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/category/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/category/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/category/delete`
        }
      },
      stages: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/stage/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/stage/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/stage/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/stage/delete`
        }
      }
    },
    subscribers: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/subscribers/filter`
      },
      manage: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/subscribers/manage`
      },
    },
    activity: {
      label: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/label/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/label/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/label/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/label/delete`
        },
        get: {
          method: 'Get',
          url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/label/get` // /:id
        },
      },
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/filter`
      },
      create: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/create`
      },
      update: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/update` // /:id
      },
      archive: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/archive` // /:id
      },
      restore: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/restore` // /:id
      },
      complete: {
        method: 'Patch',
        url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/complete` // /:id
      },
      reassign: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/reassign`
      },
      history: {
        method: 'Get',
        url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/activity/history` // /:activityId
      },
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/update` // /:id
    },
    archive: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/archive` // /:id
    },
    restore: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/lead/opportunity/restore` // /:id
    },
  },
  team: {
    commons: {
      categories: {
        filter: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/team/category/filter`
        },
        create: {
          method: 'Post',
          url: `${environment.baseUrl}/api/${environment.version}/lead/team/category/create`
        },
        update: {
          method: 'Patch',
          url: `${environment.baseUrl}/api/${environment.version}/lead/team/category/update`
        },
        delete: {
          method: 'Delete',
          url: `${environment.baseUrl}/api/${environment.version}/lead/team/category/delete`
        }
      }
    },
    members: {
      filter: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/team/members/filter`
      },
      get: {
        method: 'Post',
        url: `${environment.baseUrl}/api/${environment.version}/lead/team/members/manage`
      },
    },
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/lead/team/filter`
    },
    get: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/lead/team/get` // /:id
    },
    create: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/lead/team/create`
    },
    update: {
      method: 'Patch',
      url: `${environment.baseUrl}/api/${environment.version}/lead/team/update` // /:id
    },
    delete: {
      method: 'Delete',
      url: `${environment.baseUrl}/api/${environment.version}/lead/team/delete` // /:id
    },
    getLeaders: {
      method: 'Get',
      url: `${environment.baseUrl}/api/${environment.version}/lead/team/getLeaders`
    }
  },
  userRole: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/lead/role/get`
  }
};
