import {environment} from '../../../../environments/environment';

export const EmailRoutes = {
  inbox: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/email/mailbox/filter/inbox`
    }
  },
  draft: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/email/mailbox/filter/Drafts`
    }
  },
  trash: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/email/mailbox/filter/Trash`
    }
  },
  sent: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/email/mailbox/filter/Sent`
    }
  },
  archive: {
    filter: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/email/mailbox/filter/Archive`
    }},
  accounts: {
    get: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/emailAccount/filter`
    },
    set: {
      method: 'Post',
      url: `${environment.baseUrl}/api/${environment.version}/settings/emailAccount/setDefault` // :accountId
    }
  },

  saveDraft: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/email/mailbox/draft`
  },

  newMessage: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/email/mailbox/send`
  },
  moveToTrash: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/email/mailbox/flag`
  },
  moveToArchive: {
    method: 'Post',
    url:  `${environment.baseUrl}/api/${environment.version}/email/mailbox/flag`
  },
  deleteForever: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/email/mailbox/flag`
  },
  getAttachment: {
    method: 'Get',
    url: `${environment.baseUrl}/api/${environment.version}/email/mailbox/attachment` // /:email_id, /:attachment_id
  },
  sendSeenFlag: {
    method: 'Post',
    url: `${environment.baseUrl}/api/${environment.version}/email/mailbox/flag`
  },

  getSingleMail: {
    method: 'Get',
    url: `${environment.baseUrl}/api/${environment.version}/email/mailbox/fetch`  // /:mailBox/:mailId

  },
};
