import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import 'rxjs-compat/add/observable/empty';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {StorageService} from './storage.service';

@Injectable()
export class ApiService {
  baseUrl: string;

  constructor(private _http: HttpClient, private _router: Router, private _storage: StorageService) {
  }

  send(method: string, url: string, body: any = {}, headers?: HttpHeaders, responseType?: 'arraybuffer' | 'blob' | 'json' | 'text') {
    return this._http.request(method, url, {body: body, headers: headers, reportProgress: true, responseType});
  }

  handleRequest(observable: Observable<any>): Observable<any> {
    return new Observable<any>(obs => {
      observable.subscribe(res => {
          obs.next(res);
          obs.complete();
        },
        (err: HttpErrorResponse) => { //Catch the error and redirect to page
          //Error messages are handled inside api.interceptor.ts
          switch (err.status) {
            case 500:
              this._router.navigate(['internal-server-error']);
              break;
            case 503:
              this._router.navigate(['service-unavailable']);
              break;
            case 403:
              this._router.navigate(['forbidden']);
              break;
            case 406:
              this._storage.clear();
              window.location.href = '/auth';
              break;
          }
          obs.next(null); //next instead of error, so the console does not show the error
          obs.complete();
        });
    });
  }

}
