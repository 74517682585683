import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../../services/storage.service';
import {GlobalService} from '../../services/global.service';
import {PlatformLocation} from '@angular/common';
import {AuthService} from '../../services/auth.service';
import {environment} from '../../../environments/environment';
import {LocalizationService} from "../../services/localization.service";
import regeneratorRuntime from "regenerator-runtime";
import { SocketService } from '../../services/socket.service';

//This is some serious black magic shit lol
const a = regeneratorRuntime;

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  public showPassword: boolean = false;
  public showDownloadChrome: boolean = false;
  public form: FormGroup;
  readonly platformLocation: string = null;
  readonly domain: string = null;

  constructor(private _router: Router,
              private _route: ActivatedRoute,
              private _platformLocation: PlatformLocation,
              private _storage: StorageService,
              private _fb: FormBuilder,
              private _auth: AuthService,
              private _socket: SocketService,
              public _locale: LocalizationService,
              public _global: GlobalService) {
    //Set platform location, for domain checking
    this.platformLocation = this._platformLocation['location'].origin;
    this.domain = this.platformLocation.search('localhost') !== -1 ? 'gaussdev' : AuthComponent.getSubDomain(this.platformLocation);
    this.form = this._fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      subdomain: 'evnas'
    });

    this._locale.main.next('eng'); //Always set lang to english on this page
  }

  ngOnInit(): void {
    let user = this._storage.get('user');
    if (user && user.id) this.redirectUser();

    const winNav = window.navigator,
      vendorName = winNav.vendor,
      isOpera = winNav.userAgent.indexOf('OPR') > -1,
      isIEdge = winNav.userAgent.indexOf('Edge') > -1,
      isIOSChrome = winNav.userAgent.match('CriOS');

    if (isIOSChrome) {
      alert('is Google Chrome on IOS');
    } else if (vendorName === 'Google Inc.' && isOpera === false && isIEdge === false) {
    } else this.showDownloadChrome = true;
  }

  login(formData: Object): void {
    if (!this.form.valid) return this._global.checkFormErrors(this.form);
    this._auth.login(formData).subscribe((loggedIn: boolean) => {
      if (!loggedIn) return this._auth.logOut();
      this.redirectUser();
      this._socket.connect();
    });
  }

  private static getSubDomain(domain: string): string {
    let removeHttp = domain.replace('https://', '');
    return removeHttp.replace(`.${environment.mainDomain}`, '');
  }

  redirectUser(): void {
    //Check if user is employee and if he has company
    if (this._auth.loggedUser.type === 1) { //Type is employee and its hardcoded because its DB seeder param, always is number 1
      this._auth.loggedUser.company ? this._router.navigate([this._auth.routerState]) : this._router.navigate(['company/main/create']);
    } else this._router.navigate([this._auth.routerState]); //Navigate user to the app
  }

}
