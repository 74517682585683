import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LocalizationService} from "../../../services/localization.service";
import {ToggleNewService} from "../../../services/toggleNew.service";

@Component({
  selector: 'app-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss']
})
export class BaseModalComponent implements OnInit, OnDestroy {

  @Input('title') public title: string;
  @Input('modalWidth') public modalWidth: string = '75vw';
  @Input('modalName') public modalName: string;
  @Input('showCloseButton') public showCloseButton: boolean = true;

  constructor(public _locale: LocalizationService,
              public _toggle: ToggleNewService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {

  }

}
