import {BaseClass} from "../../../../classes/base-class";
import {UploadedFile} from "../../../../interfaces/uploaded-file";

export class QuotedMessage extends BaseClass {

  id?: number;
  type: string;
  body: string;
  chat_id: number;
  created_at: string;
  medias?: UploadedFile;
  updated_at: string;
  quotedMessage: QuotedMessage;


  constructor(init: any) {
    super();
    this.id = init.id;
    if (init && init.type) this.type = init.type;
    if (init && init.body) this.body = init.body;
    if (init && init.chat_id) this.chat_id = init.chat_id;
    if (init && init.created_at) this.created_at = init.created_at;
    if (init && init.updated_at) this.updated_at = init.updated_at;
    if (init && init.quotedMessage) this.quotedMessage = new QuotedMessage(init.quotedMessage);
    if (init && init.medias) this.medias = init.medias;
  }

  toJson(): Object {
    return QuotedMessage.clearJson({
      id: this.id ? this.id : undefined,
      type: this.type,
      body: this.body,
      chat_id: this.chat_id,
      created_at: this.created_at,
      updated_at: this.updated_at,
      quotedMessage: this.quotedMessage,
      medias: this.medias,
    });
  }

}


