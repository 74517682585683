import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewChecked,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Input, forwardRef} from '@angular/core';
import {LocalizationService} from "../../../services/localization.service";
import { HostListener } from '@angular/core';
import { ElementRef } from '@angular/core';
import {VirtualScrollerComponent} from 'ngx-virtual-scroller';
import {FilterData, FilterValue} from '../../../classes/filter-data';
import {ApiService} from '../../../services/api.service';
import {Params} from '@angular/router';
import {HttpHeaders} from '@angular/common/http';
import {Pagination} from '../../../modules/layout/cms/classes/pagination';
import {Subscription} from 'rxjs';

export interface MultiSelectOption {
  name: string,
  id?: number,
  sendValue: boolean | number | string | number[] | string[] | {
    [logicalOperator: string]: boolean | number | string | number[] | string[] //Operator, != ....
  },
  color?: string
}

export interface ApiDataForSelected {
  isFilter: boolean;
  route: {method: string, url: string}; //TODO napraviti da url može biti funkcija (koja prima id)
  mapFunc: (res: any) => any;
}

export interface Sort {
  param: string,
  order: string // ASC, DESC
}

export interface ApiData {
  route: {method: string, url: string};
  urlQuery?: string,
  httpHeaders?: HttpHeaders,
  mapFunc: (res: any) => any[];
  //TODO remove wwhen httpBody implemented
  filters?: FilterValue[];
  sort?: Sort;
  select?: string[];
  storeID?: number;
  project?: number;
  estimation?: number;
  httpBody?: any;
  dataForSelected?: ApiDataForSelected;
}

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true
    }
  ]
})
export class MultiselectComponent implements OnInit, AfterViewChecked, ControlValueAccessor {
  // TODO multiselect ne valja - u formi je vrijednost id a onSelect izbaci cijeli objekt... isto tako ako se odznači ostane upamćena zadnja vrijednost
  //TODO implement onPush startegy?
  public showMultiselectDropdown: boolean = false;
  private innerValue: any[] = [];
  public isOpen: boolean = false;
  public searchValue: string = '';
  private clearSearch: any;
  public allSelected: any = []; //If multiselect all selected values
  private paginationInfo: {totalRecords: number, totalPages: number} = {totalRecords: 0, totalPages: 0};
  private paginationLimit: number = 10;
  private lastScrollEndIndex: number; //hack fix because sometimes onEndEvent fires two same endIndexes (when page = 1)
  @Input() multiple: boolean = false; //true for multiple, false for single select
  @Input() hasSelectAll: boolean = false; //used only if multiple also true
  @Input() name: string = 'name'; //property of option objects to show
  @Input() valueName: string; //property of option objects to use as value
  @Input() placeholder: string = '- Select- '; //text to show when nothing is selected
  @Input() searchPlaceholder: string = 'Search...'; //text to show when nothing is selected
  @Input() noRecordsPlaceholder: string = 'No records found...';
  @Input() displayNumber: number = 3; //max selected options to display
  @Input() options: MultiSelectOption[] = []; //array of option objects
  @Input() readonly: boolean = false; //readonly attribute to prevent input typing
  @Input() isDisabled: boolean = false; //prevents opening select dropdown
  @Input() applyDisabledStyle: boolean = true; //when isDisabled is true determine if select style will change
  @Input() optionsSelectedText: string = 'options selected';
  @Input() minSearchLength: number = 3; // Min characters needed to start searching
  @Input() showLoadMore: boolean = false; //Show lead more button
  @Input() enableSearch: boolean = true; //Enable search input
  @Input() hideCheckbox: boolean = false; // Hide checkbox on multiselct dropdown
  @Input() showDisplayValues: boolean = true; //Show display values in multiselect
  @Input() clearSelected: boolean = false;
  @Input() selectAsButton: boolean = false;
  @Input() selectedValue: any = null;
  @Input() searchByPhraseBeginning: boolean = false;
  @Input() showSelectedList: boolean = true;
  //TODO refactor fullyColored into another component, it should actually be checkboxless select item either colored or not
  @Input() fullyColored: boolean = false; // works only with multiple=false
  @Input() displayDefaultBackgroundColor: string = 'transparent'; // works with fullyColored when nothing selected or has no color
  @Input() displayDefaultNonColoredTextColor: string = '#4294E6';
  @Input() displayDefaultColoredTextColor: string = 'white';
  @Input() valueNotSetDisplay: string = this._locale.trans.notSetLabel;

  _apiData: ApiData;
  get apiData(): ApiData {
    return this._apiData;
  }
  @Input('apiData') set apiData(apiData: ApiData) {
    if(this.apiDataSearchSubscription !== undefined) {
      this.apiDataSearchSubscription.unsubscribe();
    }
    if(!apiData) {
      this._apiData = undefined;
      return;
    }
    // if(!apiData.httpBody) apiData.httpBody = {};
    this._apiData = apiData;
    //TODO remove when httpBody implemented
    this.apiDataBody.filters = this.apiData.filters;
    this.apiDataBody.select = this.apiData.select;
    this.apiDataBody.storeID = this.apiData.storeID;
    this.apiDataBody.project = this.apiData.project;
    this.apiDataBody.estimation = this.apiData.estimation;
    this.apiDataBody.sort = this.apiData.sort;

    this.apiDataSearchSubscription = this.onSearch.subscribe(() => {
      this.resetApiDataList(); //TODO provjeriti jel potrebno baš sve iz te funkcije u ovom call-u
      this.updateOptionsByRequest(1);
    });
  }
  apiDataBody: any = {};

  apiDataSearchSubscription: Subscription;

  // @Input() apiData: ApiData;

  @Input() apiDataEntityIdName: string = 'id'; //used when apiData; used only without entityDifferentialAttrs;
  @Input() entityDifferentialAttrs: string[]; //used when apiData; attrs which are used to determine if option is selected; used only without apiDataEntityIdName
  @Input() entityAttrsToKeep: string[] = []; //used when apiData; attrs which will be attached to fresh item from current inner value item; example usage: participants modal

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onLoadMore: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('gbSearch') searchInput:ElementRef<HTMLInputElement>
  @ViewChild('virtualScroller') virtualScroller: VirtualScrollerComponent;
  @ViewChild('apiVirtualScroller') apiVirtualScroller: VirtualScrollerComponent;

  @HostListener('click', ['$event']) onSelectClicked(e){
    this.enableSearch && this.searchInput.nativeElement.focus();
  }

  constructor(private _cdRef: ChangeDetectorRef,
              public _locale: LocalizationService,
              private _api: ApiService) { }

  ngOnInit() {
    if (!this.options) this.options = [];
    if (this.selectedValue) { //Check if ngModel option is enabled
      if (this.multiple) {
        this.innerValue = this.options.filter(option => this.selectedValue.find(value => value[this.valueName] == option[this.valueName]));
        if(this.apiData) this.innerValue = this.selectedValue;
      } else {
        this.innerValue = [this.options.find(option => option[this.valueName] == this.selectedValue[this.valueName])].filter(item => item);
        if(this.apiData) this.innerValue = [this.selectedValue];
      }
    }
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  isSelectedOption(option: any) {
    return this.innerValue.indexOf(option) != -1;
  }

  openSelect(disabled) {
    if (disabled) return;
    this.isOpen = true;
    if(this.apiData) this.onRequestingSelectOpen();
  }

  closeSelect() {
    if(this.apiData && this.isOpen) {
      this.resetApiDataList();
    }
    this.isOpen = false;
  }

  private resetApiDataList(): void {
    this.apiVirtualScroller.viewPortItems = [];
    this.options = [];
    this.apiVirtualScroller.scrollToPosition(0, 0);
    this.lastScrollEndIndex = undefined;
    this.paginationInfo = {totalRecords: 0, totalPages: 0};
  }

  public selectAll(event): void {
    for (let option of this.options) {
      if (event.target.checked) {
        if (!this.innerValue.find(value => value == option)) this.innerValue.push(option);
      } else this.innerValue = [];
    }
    this.innerValue = this.innerValue.filter(val => val !== null); //Remove all null values if there are any
    this.valueName ? this.propagateChange(this.innerValue.map(value => value[this.valueName])) : this.propagateChange(this.innerValue);
    this.onSelect.emit(this.innerValue);
  }

  public isReallyAllSelected(): boolean {
    return this.innerValue.length == this.options.length;
  }

  getDisplayValue() {
    if (!this.showDisplayValues) return this.getPlaceholder(); //Do nothing if sowDisplayValues is turned off
    let opts = this.innerValue.filter(option => option); //Filter all options that are not null
    if (opts.length) this.allSelected = opts.map(option => option[this.name]);

    if (!opts.length) return this.getPlaceholder();
    else if (opts.length < this.displayNumber) return opts.map(option => option[this.name]).join(', ');
    else return `${opts.length} ${this._locale.trans.optionsSelected}`;
  }

  private getPlaceholder(): string {
    return this.fullyColored ? this.valueNotSetDisplay : this.placeholder;
  }

  getDisplayBackgroundColor(): string {
    if (!this.showDisplayValues) return this.displayDefaultBackgroundColor; //Do nothing if sowDisplayValues is turned off
    let opts = this.getSelectedOptions();
    if(opts.length == 1) {
      return opts[0]['color'] ? opts[0]['color'] : this.displayDefaultBackgroundColor;
    } else {
      return this.displayDefaultBackgroundColor;
    }
  }

  getDisplayTextColor(): string {
    if (!this.showDisplayValues) return this.displayDefaultNonColoredTextColor; //Do nothing if sowDisplayValues is turned off
    let opts = this.getSelectedOptions();
    if(opts.length == 1) {
      return opts[0]['color'] ? this.displayDefaultColoredTextColor : this.displayDefaultNonColoredTextColor;
    } else {
      return this.displayDefaultNonColoredTextColor;
    }
  }

  private getSelectedOptions(): any[] {
    return this.innerValue.filter(option => option); //Filter all options that are not null
  }

  onEndChange(event): void {
    if(this.options.length === event.endIndex + 1 && this.lastScrollEndIndex !== event.endIndex && this.options.length < this.paginationInfo.totalRecords){
      this.lastScrollEndIndex = event.endIndex;
      this.updateOptionsByRequest(Math.floor((this.options.length / this.paginationLimit)) + 1);
    }
  }

  onRequestingSelectOpen(): void {
    if(this.options.length === 0) {
      this.updateOptionsByRequest(1);
    }
  }

  updateOptionsByRequest(page: number, saveInfo: boolean = true): void {
    this.apiDataBody.page = page;
    this.apiDataBody.limit = this.paginationLimit;
    if(this.searchValue) {
      this.apiDataBody.keywords = this.searchValue;
    }
    else {
      this.apiDataBody.keywords = undefined;
    }

    //TODO replace
    // this.apiData.httpBody.page = page;
    // this.apiData.httpBody.limit = this.paginationLimit;
    // this.apiData.httpBody.keywords = this.searchValue;

    this._api.send(this.apiData.route.method, this.apiData.route.url + (this.apiData.urlQuery ? this.apiData.urlQuery : ''), this.apiDataBody, this.apiData.httpHeaders).subscribe(res => {
      // TODO bude bug (ako se ne koristi lastScrollEndIndex) ako je odkomentirano jer se dva puta okine onEndChange zbog this.apiVirtualScroller.scrollToPosition(0, 0)
      if(page === 1) {
        this.apiVirtualScroller.viewPortItems = [];
        this.options = [];
        this.apiVirtualScroller.scrollToPosition(0, 0);
      }
      let newItems: any = this.apiData.mapFunc(res);
      this.options.push(...newItems);

      this.innerValue.filter(value => value).forEach((singleInnerValue, index, innerValues) => { //.filter(value => value) je hack jer item može biti null
        let freshItem = newItems.find(newItem => {
          if(this.entityDifferentialAttrs && this.entityDifferentialAttrs.length){
            let allDifferentialAttrsMatch: boolean = true;
            for(let differentialAttr of this.entityDifferentialAttrs){
              if(newItem[differentialAttr] !== singleInnerValue[differentialAttr]) {
                allDifferentialAttrsMatch = false;
                break;
              }
            }
            return allDifferentialAttrsMatch;
          }
          else {
            if(newItem[this.apiDataEntityIdName]){
              return newItem[this.apiDataEntityIdName] === singleInnerValue[this.apiDataEntityIdName]
            }
            return false;
          }
        });
        if(freshItem) {
          if(this.entityAttrsToKeep.length) {
            this.entityAttrsToKeep.forEach(attr => {
              freshItem[attr] = singleInnerValue[attr];
            });
          }
          this.innerValue[index] = freshItem;
        }
      });

      if(saveInfo) {
        this.paginationInfo.totalPages = res['data'].pagination.lastPage;
        this.paginationInfo.totalRecords = res['data'].pagination.total;
      }

      this._cdRef.markForCheck();
    });
  }

  selectedOptions(): MultiSelectOption[] {
    return this.options.filter(option => this.isSelectedOption(option));
  }

  unselectedOptions(): MultiSelectOption[] {
    return this.options.filter(option => !this.isSelectedOption(option));
  }

  selectOption(value: any) {
    if (this.multiple) {
      this.innerValue = this.innerValue.filter(val => val !== null); //Remove all null values if there are any
      if (this.innerValue.indexOf(value) == -1 && value !== null) this.innerValue.push(value);
      else this.innerValue.splice(this.innerValue.indexOf(value), 1);
      this.valueName ? this.propagateChange(this.innerValue.map(value => value[this.valueName])) : this.propagateChange(this.innerValue);
      this.onSelect.emit(this.innerValue);
      // this.searchValue = null; //Reset search value on selected
    } else {
      if (value == this.innerValue[0]) {
        this.innerValue = [];
        this.onSelect.emit(null);
        this.propagateChange(null);
      } else {
        if (!this.clearSelected) this.innerValue = [value];
        this.valueName ? this.propagateChange(value[this.valueName]) : this.propagateChange(value);
        this.onSelect.emit(value);
      }

      this.searchValue = null; //Reset search value on selected
      this.closeSelect(); //Close the select if not multiple
    }
  }

  writeValue(value: any): void {
    setTimeout(() => { //TODO vidjeti jel se može maknuti
      if (value !== undefined) {
        if (!this.valueName) {
          Array.isArray(value) ? this.innerValue = value : this.innerValue = [value];
        } else {
          let values = Array.isArray(value) ? value : [value];
          this.innerValue = values.map(value => this.options.find(option => option[this.valueName] == value)).filter(value => value);
          if (!this.multiple && this.innerValue.length) {
            //If valueName is set and not empty string return only that value from object else return the whole object
            (this.valueName && this.valueName !== '') ? this.onSelect.emit(this.innerValue[0][this.valueName]) : this.onSelect.emit(this.innerValue[0]);
            this.readonly = !!this.valueName;
          }
        }
      }
      this._cdRef.markForCheck();
    });
  }

  propagateChange(_: any) {
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  onSearchStart() {
    clearTimeout(this.clearSearch);
    if(this.apiData){
      this.clearSearch = setTimeout(() => this.onSearch.emit(this.searchValue), 500);
    }
    else if (this.searchValue.length > this.minSearchLength || this.searchValue == '') {
      this.clearSearch = setTimeout(() => this.onSearch.emit(this.searchValue), 500);
    }
  }

  preventCheck(e) {
    if (this.clearSelected) e.target.checked = false;
  }

  onShowMultiselectDropdown() {
    this.showMultiselectDropdown = true;
  }

  onHideMultiselectDropdown() {
    this.showMultiselectDropdown = false;
  }

  getIconFontSize(icon: string): number {
    if(icon === 'icon-people') return 12;
    if(icon === 'icon-business') return 14;
    // if(icon === 'icon-cms-users') return 16;
    return 16;
  }

  selectedListVisible(): boolean {
    return this.showSelectedList && !this.isOpen && this.showMultiselectDropdown && this.multiple && this.allSelected.length >= 2;
  }

}
