import {EnglishTranslation} from "./languages/eng";
import {CroatianTranslations} from "./languages/cro";
import {SerbianTranslations} from "./languages/srp";
import {GermanTranslations} from "./languages/ger";

export const Translations = {
  'eng': EnglishTranslation,
  'hrv': CroatianTranslations,
  'srp': SerbianTranslations,
  'ger': GermanTranslations
};
