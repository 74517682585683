import { Injectable } from '@angular/core';
import {StorageService} from "../classes/storage-service";

@Injectable()
export class SessionStorageService extends StorageService {

    protected readonly storage: any = sessionStorage;

    constructor() {
        super();
    }

}
