import {Categorizable} from "../categorizable";

export class Category {
  id?: number = null;
  name: string = '-';
  items: Categorizable[] = [];
  isDefault?: boolean = false;

  constructor(init?: any) {
    if (init && init.id) this.id = init.id;
    if (init && init.name) this.name = init.name;
    if (init && init.isDefault) this.isDefault = init.isDefault;
  }

}
